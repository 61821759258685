import { useEffect, useMemo, useState } from "react";
import { Col, Row, Container, Card, CardHeader, CardBody, UncontrolledDropdown, DropdownToggle } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import * as moment from "moment";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import Loader from "../../../../Components/Common/Loader";
import TableContainer from "../../../../Components/Common/TableContainer";
import { FlatpickrSingleColumnFilter, SelectColumnFilter } from "../../../../Components/Common/filters";

import {
    getReturnAdjustments as onGetReturnAdjustments
} from "../../../../store/actions";

function ReturnAdjustment() {
    const dispatch = useDispatch();
    const [returnAdjustmentsArr, setReturnAdjustmentsArr] = useState([]);

    const { isReturnAdjustmentsSuccess, returnAdjustments, error } = useSelector((state) => ({
        isReturnAdjustmentsSuccess: state.Returns.isReturnAdjustmentsSuccess,
        returnAdjustments: state.Returns.returnAdjustments,
        error: state.Returns.error,
    }));

    useEffect(() => {
        dispatch({ type: 'RESET_APP' })
    }, [dispatch])

    useEffect(() => {
        if (returnAdjustments && !returnAdjustments.length) {
            dispatch(onGetReturnAdjustments());
        }
    }, [dispatch, returnAdjustments]);

    useEffect(() => {
        // let temp = returnAdjustments.filter((item) => item.status === 'pending');
        setReturnAdjustmentsArr(returnAdjustments);
    }, [returnAdjustments]);

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("Y-MM-DD");
        return date1;
    };

    // Checked All
    const checkedAll = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".invoiceCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="invoiceCheckBox" value={cellProps.row.original._id} />;
                },
                id: '#',
            },
            {
                Header: "RETURN ADJUSTMENT ID",
                accessor: "id",
                filterable: true,
                Cell: (cell) => {
                    return <Link to={`/order-desk/return-adjustment/${cell.row.original.id}`} className="fw-medium link-primary">{cell.row.original.id}</Link>;
                },
            },
            {
                Header: "COMPANY NAME",
                accessor: 'company_name',
                Filter: SelectColumnFilter,
                Cell: (invoice) => (
                    <>
                        <div className="d-flex align-items-center">
                            {invoice.row.original.company_name}
                        </div>
                    </>
                ),
            },
            {
                Header: "RETURN ID",
                accessor: "return_invoice_id",
                filterable: true,
                Cell: (cell) => (
                    <>
                        <div className="d-flex align-items-center">
                            {cell.row.original.return_invoice_id}
                        </div>
                    </>
                ),
            },
            {
                Header: "RETURN REQUEST CREATED",
                accessor: 'date_created',
                filterable: false,
                Filter: FlatpickrSingleColumnFilter,
                Cell: (invoice) => (
                    <>
                        {handleValidDate(invoice.row.original.date_created)}
                    </>
                ),
            },
            // {
            //     Header: "STATUS",
            //     accessor: 'status',
            //     Filter: SelectColumnFilter,
            //     Cell: (invoice) => (
            //         <>
            //             {invoice.row.original.status}
            //         </>
            //     ),
            // },
            // {
            //     Header: "Action",
            //     Cell: (cellProps) => {
            //         return (
            //             <UncontrolledDropdown >
            //                 <DropdownToggle
            //                     href="#"
            //                     className="btn-soft-secondary btn-sm dropdown"
            //                     tag="button"
            //                 >
            //                     <i className="ri-more-fill align-middle"></i>
            //                 </DropdownToggle>
            //             </UncontrolledDropdown>
            //         );
            //     },
            // },
        ],
        []
    );

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />

    document.title = "Return Adjustments | Max Advanced Brakes - Prozone";

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Return Adjustment" pageTitle="Return Adjustment" linkTo={`/order-desk/return-adjustment`} />
                
                <Row>
                    <Col lg={12}>
                        <Card id="return-adjustments">
                            <CardHeader className="border-0">
                                <div className="d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Return Adjustments</h5>
                                </div>
                            </CardHeader>

                            <CardBody className="pt-0">
                                <div>
                                    {(isReturnAdjustmentsSuccess && returnAdjustmentsArr) ? (
                                        <TableContainer
                                            columns={columns}
                                            data={(returnAdjustmentsArr || [])}
                                            isGlobalFilter={false}
                                            isAddUserList={false}
                                            customPageSize={30}
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-nowrap"
                                            theadClass="text-muted"
                                            thClass="text-muted"
                                            isInvoiceListFilter={false}
                                        />
                                    ) : (<Loader error={error} />)}
                                    <ToastContainer closeButton={false} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ReturnAdjustment;