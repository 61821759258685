// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const RESET_APP = 'RESET_APP';

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_WITH_ROWID = "GET_PRODUCTS_WITH_ROWID";

export const POST_PRICELIST = "GET_PRICELIST";
export const POST_PRICELIST_WITH_ROWID = "POST_PRICELIST_WITH_ROWID";

export const DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID = "DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID";
export const DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID_SUCCESS = "DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID_SUCCESS";
export const DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID_FAIL = "DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID_FAIL";

export const DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID = "DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID";
export const DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID_SUCCESS = "DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID_SUCCESS";
export const DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID_FAIL = "DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID_FAIL";

export const POST_PRODUCT_SELECT_LOG = "POST_PRODUCT_SELECT_LOG";
export const POST_PRODUCT_SELECT_LOG_SUCCESS = "POST_PRODUCT_SELECT_LOG_SUCCESS";
export const POST_PRODUCT_SELECT_LOG_FAIL = "POST_PRODUCT_SELECT_LOG_FAIL";

export const POST_PRODUCT_WITH_PRICES = "POST_PRODUCT_WITH_PRICES";

export const POST_PRODUCT_AND_PRICE_ARRAY = "POST_PRODUCT_AND_PRICE_ARRAY";
export const POST_PRODUCT_AND_PRICE_ARRAY_SUCCESS = "POST_PRODUCT_AND_PRICE_ARRAY_SUCCESS";
export const POST_PRODUCT_AND_PRICE_ARRAY_FAIL = "POST_PRODUCT_AND_PRICE_ARRAY_FAIL";

export const ON_BACK_EMPTY_PRODUCTS = "ON_BACK_EMPTY_PRODUCTS";