import { 
    API_RESPONSE_ERROR,
    API_RESPONSE_SUCCESS,
    DELETE_RETURN_BUCKET_ITEM_FAIL,
    DELETE_RETURN_BUCKET_ITEM_SUCCESS,
    DOWNLOAD_RETURN_INVOICE,
    GET_CUSTOMER_PRODUCTS,
    GET_RETURN,
    GET_RETURNS,
    GET_RETURN_BUCKET,
    GET_RETURN_DETAILS,
    GET_RGR_FORM,
    POST_RETURN_BUCKET_FAIL,
    POST_RETURN_BUCKET_SUCCESS,
    POST_RETURN_INVOICE_CREATE_FAIL,
    POST_RETURN_INVOICE_CREATE_SUCCESS,
    UPDATE_RETURN_BUCKET_FAIL,
    UPDATE_RETURN_BUCKET_ITEMS_FAIL,
    UPDATE_RETURN_BUCKET_ITEMS_SUCCESS,
    UPDATE_RETURN_BUCKET_SUCCESS,
    POST_RETURN_INVOICE_APPROVAL_SUCCESS,
    POST_RETURN_INVOICE_APPROVAL_FAIL,
    UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET_SUCCESS,
    UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET_FAIL,
    GET_RETURN_DETAILS_EXTRA,
    GET_RETURN_ADJUSTMENTS,
    GET_RETURN_ADJUSTMENT,
    GET_RETURN_ADJUSTMENT_DETAILS,
    UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL_FAIL,
    UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL_SUCCESS,
    UPDATE_RETURN_INVOICE_CREDIT_ISSUE_SUCCESS,
    UPDATE_RETURN_INVOICE_CREDIT_ISSUE_FAIL,
    DOWNLOAD_RETURN_BATCH_TEMPLATE,
    DELETE_RETURN_TABLE_ITEM,
    GET_RETURNS_FILTERED,
} from "./actionType";

const INIT_STATE = {
    returns: [],
    returnInvoice: {},
    returnDetails: [],
    customerProducts: {},
    returnAdjustments: [],
    returnAdjustment: {},
    returnAdjustmentDetails: [],
    returnBatchTemplate: null,
    isReturnBatchTemplateDownloaded: false,
    error: {},
};

const Returns = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_RETURNS:
                case GET_RETURNS_FILTERED:
                    return {
                        ...state,
                        returns: action.payload.data,
                        returnInvoice: {},
                        returnDetails: [],
                        isReturnCreated: false,
                        isReturnSuccess: true,
                        isReturnDetailsSuccess: false,
                        isRgrFormDownloadSuccess: false,
                        isRgrFormDownloadFail: false,
                        isReturnAdjustmentsSuccess: false,
                        isReturnAdjustmentsFail: false,
                        isReturnAdjustmentSuccess: false,
                        isReturnAdjustmentFail: false,
                        isReturnAdjustmentDetailsSuccess: false,
                        isReturnAdjustmentDetailsFail: false,
                        isUpdateReturnAdjustmentItemsApprovalSuccess: false,
                        isUpdateReturnAdjustmentItemsApprovalFail: false,
                        isUpdateReturnInvoiceCreditIssueSuccess: false,
                        isUpdateReturnInvoiceCreditIssueFail: false,
                    };
                case GET_RETURN:
                    return {
                        ...state,
                        returnInvoice: action.payload.data[0],
                        isReturnCreated: false,
                        isReturnSuccess: true,
                    };
                case GET_RETURN_DETAILS:
                    return {
                        ...state,
                        returnDetails: action.payload.data,
                        isReturnCreated: false,
                        isReturnDetailsSuccess: true,
                        isPostReturnInvoiceApprovalSuccess: false,
                        isPostReturnInvoiceApprovalFail: false,
                        isUpdateReturnInvoiceItemsWorksheetSuccess: false,
                        isUpdateReturnInvoiceItemsWorksheetFail: false
                    }
                case GET_RETURN_DETAILS_EXTRA:
                    return {
                        ...state,
                        returnDetailsExtra: action.payload.data,
                    }
                case GET_CUSTOMER_PRODUCTS:
                    return {
                        ...state,
                        customerProducts: {
                            ...state.customerProducts,
                            [action.payload.data.rowId]: action.payload.data.products
                        }
                    }
                case GET_RETURN_BUCKET:
                    return {
                        ...state,
                        returnBucket: action.payload.data,
                        customerProducts: {},
                        postBucketResponse: false,
                        isCreateReturnInvoiceSuccess: false,
                        isCreateReturnInvoiceFail: false,
                        isDeleteBucketItemSuccess: false,
                        isDeleteBucketItemFail: false,
                    }
                case GET_RGR_FORM:
                    return {
                        ...state,
                        rgrForm: action.payload.data,
                        isRgrFormDownloadSuccess: true,
                        isRgrFormDownloadFail: false,
                    }
                case GET_RETURN_ADJUSTMENTS:
                    return {
                        ...state,
                        returnAdjustments: action.payload.data,
                        isReturnAdjustmentsSuccess: true,
                        isReturnAdjustmentsFail: false,
                    }
                case GET_RETURN_ADJUSTMENT:
                    return {
                        ...state,
                        returnAdjustment: action.payload.data[0],
                        isReturnAdjustmentSuccess: true,
                        isReturnAdjustmentFail: false,
                    }
                case GET_RETURN_ADJUSTMENT_DETAILS:
                    return {
                        ...state,
                        returnAdjustmentDetails: action.payload.data,
                        isReturnAdjustmentDetailsSuccess: true,
                        isReturnAdjustmentDetailsFail: false,
                    }
                case DOWNLOAD_RETURN_INVOICE:
                    return {
                        ...state,
                        returnInvoicePdf: action.payload.data,
                        isReturnInvoiceDownloaded: true
                    }
                case DOWNLOAD_RETURN_BATCH_TEMPLATE:
                    return {
                        ...state,
                        returnBatchTemplate: action.payload.data,
                        isReturnBatchTemplateDownloaded: true
                    }
                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_RETURNS:
                case GET_RETURNS_FILTERED:
                    return {
                        ...state,
                        error: action.payload.error,
                        isReturnCreated: false,
                        isReturnSuccess: false
                    };
                case GET_RETURN:
                    return {
                        ...state,
                        error: action.payload.error,
                        isReturnCreated: false,
                        isReturnSuccess: false,
                    };
                case GET_RETURN_DETAILS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isReturnCreated: false,
                        isReturnDetailsSuccess: false,
                    }
                case GET_RETURN_DETAILS_EXTRA:
                    return {
                        ...state,
                        error: action.payload.error,
                    }
                case GET_CUSTOMER_PRODUCTS:
                    return {
                        ...state,
                        customerProducts: {
                            ...state.customerProducts,
                            [action.payload.data.rowId]: []
                        }
                    }
                case GET_RETURN_BUCKET:
                    return {
                        ...state,
                        returnBucket: action.payload.data,
                        postBucketResponse: false,
                        isCreateReturnInvoiceSuccess: false,
                        isCreateReturnInvoiceFail: false,
                        isDeleteBucketItemSuccess: false,
                        isDeleteBucketItemFail: false,
                    }
                case GET_RGR_FORM:
                    return {
                        ...state,
                        error: action.payload.error,
                        isRgrFormDownloadSuccess: false,
                        isRgrFormDownloadFail: true,
                    }
                case GET_RETURN_ADJUSTMENTS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isReturnAdjustmentsSuccess: false,
                        isReturnAdjustmentsFail: true,
                    }
                case GET_RETURN_ADJUSTMENT:
                    return {
                        ...state,
                        error: action.payload.error,
                        isReturnAdjustmentSuccess: false,
                        isReturnAdjustmentFail: true,
                    }
                case GET_RETURN_ADJUSTMENT_DETAILS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isReturnAdjustmentDetailsSuccess: false,
                        isReturnAdjustmentDetailsFail: true,
                    }
                case DOWNLOAD_RETURN_INVOICE:
                    return {
                        ...state,
                        error: action.payload.error,
                        isReturnInvoiceDownloaded: false
                    }
                case DOWNLOAD_RETURN_BATCH_TEMPLATE:
                    return {
                        ...state,
                        error: action.payload.error,
                        isReturnBatchTemplateDownloaded: false
                    }
                default:
                    return { ...state };
            }

        case POST_RETURN_BUCKET_SUCCESS:
            return {
                ...state,
                postBucketResponse: action.payload.data
            }
        
        case POST_RETURN_INVOICE_CREATE_SUCCESS: 
            return {
                ...state,
                returnBucket: [],
                isCreateReturnInvoiceSuccess: true,
                isCreateReturnInvoiceFail: false,
            }
        
        case DELETE_RETURN_BUCKET_ITEM_SUCCESS: 
            return {
                ...state,
                isDeleteBucketItemSuccess: true,
                isDeleteBucketItemFail: false,
            }

        case UPDATE_RETURN_BUCKET_ITEMS_SUCCESS: 
        case UPDATE_RETURN_BUCKET_SUCCESS:
            return {
                ...state,
            }
            
            case POST_RETURN_INVOICE_APPROVAL_SUCCESS:
                return {
                    ...state,
                    isPostReturnInvoiceApprovalSuccess: true,
                    isPostReturnInvoiceApprovalFail: false,
            }

        case UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET_SUCCESS:
            return {
                ...state,
                isUpdateReturnInvoiceItemsWorksheetSuccess: true,
                isUpdateReturnInvoiceItemsWorksheetFail: false
            }

        case UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL_SUCCESS:
            return {
                ...state,
                isUpdateReturnAdjustmentItemsApprovalSuccess: true,
                isUpdateReturnAdjustmentItemsApprovalFail: false,
            }

        case UPDATE_RETURN_INVOICE_CREDIT_ISSUE_SUCCESS:
            return {
                ...state,
                isUpdateReturnInvoiceCreditIssueSuccess: true,
                isUpdateReturnInvoiceCreditIssueFail: false,
            }
        
        case POST_RETURN_INVOICE_CREATE_FAIL: 
            return {
                ...state,
                isCreateReturnInvoiceSuccess: false,
                isCreateReturnInvoiceFail: true,
                error: action.payload.error
            }
        
        case POST_RETURN_BUCKET_FAIL: 
        case UPDATE_RETURN_BUCKET_ITEMS_FAIL: 
        case UPDATE_RETURN_BUCKET_FAIL:
            return {
                ...state,
                error: action.payload.error
            }

        case DELETE_RETURN_BUCKET_ITEM_FAIL: 
            return {
                ...state,
                isDeleteBucketItemSuccess: false,
                isDeleteBucketItemFail: true,
                error: action.payload.error
            }

        case POST_RETURN_INVOICE_APPROVAL_FAIL:
            return {
                ...state,
                isPostReturnInvoiceApprovalSuccess: false,
                isPostReturnInvoiceApprovalFail: true,
                error: action.payload.error
            }

        case UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET_FAIL:
            return {
                ...state,
                error: action.payload.error,
                isUpdateReturnInvoiceItemsWorksheetSuccess: false,
                isUpdateReturnInvoiceItemsWorksheetFail: true
            }

        case UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL_FAIL:
            return {
                ...state,
                isUpdateReturnAdjustmentItemsApprovalSuccess: false,
                isUpdateReturnAdjustmentItemsApprovalFail: true,
            }

        case UPDATE_RETURN_INVOICE_CREDIT_ISSUE_FAIL:
            return {
                ...state,
                isUpdateReturnInvoiceCreditIssueSuccess: false,
                isUpdateReturnInvoiceCreditIssueFail: true,
            }

        case DELETE_RETURN_TABLE_ITEM:
            delete state.customerProducts[action.payload];
            return {
                ...state,
                customerProducts: state.customerProducts
            }

        default:
            return { ...state };
    }
};

export default Returns;