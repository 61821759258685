import { all, call, fork, put, takeEvery } from "redux-saga/effects";

// Credit Redux States
import {
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_EXCLUSION,
    DELETE_PRODUCT_GROUP_FROM_USER,
    GET_CUSTOMERS,
    GET_CUSTOMER_INFO,
    GET_CUSTOMER_PRODUCT_EXCLUSION,
    GET_CUSTOMER_USER_GROUP,
    GET_PRODUCT_GROUPS,
    GET_UPDATE_USER_CREDIT_BALANCES,
    GET_USERS_PRODUCT_GROUPS,
    GET_USER_GROUPS,
    POST_NEW_CUSTOMER,
    POST_NEW_CUSTOMER_EXCLUSION,
    POST_NEW_PRODUCT_GROUP_TO_USER,
    POST_USER_PASSWORD_RESET,
    UPDATE_CUSTOMER_INFO,
    UPDATE_USER_GROUP,
} from "./actionType";

import {
    customerApiResponseError,
    customerApiResponseSuccess,
    deleteCustomerExclusionFail,
    deleteCustomerExclusionSuccess,
    deleteCustomerFail,
    deleteCustomerSuccess,
    deleteProductGroupFromUserFail,
    deleteProductGroupFromUserSuccess,
    postNewCustomerExclusionFail,
    postNewCustomerExclusionSuccess,
    postNewCustomerFail,
    postNewCustomerSuccess,
    postNewProductGroupToUserFail,
    postNewProductGroupToUserSuccess,
    postUserPasswordResetFail,
    postUserPasswordResetSuccess,
    updateCustomerInformationFail,
    updateCustomerInformationSuccess,
    updateUserGroupFail,
    updateUserGroupSuccess
} from "./action";

//Include Both Helper File with needed methods
import {
    deleteCustomer as deleteCustomerApi,
    deleteCustomerExclusion as deleteCustomerExclusionApi,
    deleteProductGroupFromUser as deleteProductGroupFromUserApi,
    getCustomerInformation as getCustomerInformationApi,
    getCustomerProductExclusion as getCustomerProductExclusionApi,
    getCustomerUserGroup as getCustomerUserGroupApi,
    getCustomers as getCustomersApi,
    getProductGroups as getProductGroupsApi,
    getUpdateUserCreditBalances as getUpdateUserCreditBalancesApi,
    getUserGroups as getUserGroupsApi,
    getUserProductGroups as getUserProductGroupsApi,
    postNewCustomer as postNewCustomerApi,
    postNewCustomerExclusion as postNewCustomerExclusionApi,
    postNewProductGroupToUser as postNewProductGroupToUserApi,
    postUserPasswordReset as postUserPasswordResetApi,
    updateCustomerInformation as updateCustomerInformationApi,
    updateUserGroup as updateUserGroupApi,
} from "../../helpers/backend_helper";

function* getCustomers() {
    try {
        const response = yield call(getCustomersApi);
        yield put(customerApiResponseSuccess(GET_CUSTOMERS, response));
    } catch (error) {
        yield put(customerApiResponseError(GET_CUSTOMERS, error));
    }
}

function* getCustomerInformation({ payload: username }) {
    try {
        const response = yield call(getCustomerInformationApi, username);
        yield put(customerApiResponseSuccess(GET_CUSTOMER_INFO, response));
    } catch (error) {
        yield put(customerApiResponseError(GET_CUSTOMER_INFO, error));
    }
}

function* getCustomerProductExclusion({ payload: username }) {
    try {
        const response = yield call(getCustomerProductExclusionApi, username);
        yield put(customerApiResponseSuccess(GET_CUSTOMER_PRODUCT_EXCLUSION, response));
    } catch(error) {
        yield put(customerApiResponseError(GET_CUSTOMER_PRODUCT_EXCLUSION, error));
    }
}

function* getCustomerUserGroup({ payload: userGroupId }) {
    try {
        const response = yield call(getCustomerUserGroupApi, userGroupId);
        yield put(customerApiResponseSuccess(GET_CUSTOMER_USER_GROUP, response));
    } catch(error) {
        yield put(customerApiResponseError(GET_CUSTOMER_USER_GROUP, error));
    }
}

function* getUserGroups() {
    try {
        const response = yield call(getUserGroupsApi);
        yield put(customerApiResponseSuccess(GET_USER_GROUPS, response));
    } catch (error) {
        yield put(customerApiResponseError(GET_USER_GROUPS, error))
    }
}

function* updateCustomerInformation({ payload: userInfo }) {
    try {
        yield call(updateCustomerInformationApi, userInfo);
        yield put(updateCustomerInformationSuccess(userInfo));
    } catch (error) {
        yield put(updateCustomerInformationFail(error))
    }
}

function* deleteCustomer({ payload: user }) {
    try {
        yield call(deleteCustomerApi, user);
        yield put(deleteCustomerSuccess())
    } catch (error) {
        yield put(deleteCustomerFail(error));
    }
}

function* postNewCustomerExclusion({ payload: obj }) {
    try {
        const response = yield call(postNewCustomerExclusionApi, obj.username, obj.type, obj.line);
        yield put(postNewCustomerExclusionSuccess(response));
    } catch (error) {
        yield put(postNewCustomerExclusionFail(error))
    }
}

function* deleteCustomerExclusion({ payload: id }) {
    try {
        const response = yield call(deleteCustomerExclusionApi, id);
        yield put(deleteCustomerExclusionSuccess(response));
    } catch (error) {
        yield put(deleteCustomerExclusionFail(error))
    }
}

function* updateUserGroup({ payload: userGroupArr }) {
    try {
        const response = yield call(updateUserGroupApi, userGroupArr);
        yield put(updateUserGroupSuccess(response));
    } catch (error) {
        yield put(updateUserGroupFail(error));
    }
}

function* postNewCustomer({ payload: newUserInfo }) {
    try {
        const response = yield call(postNewCustomerApi, newUserInfo);
        yield put(postNewCustomerSuccess(response));
    } catch (error) {
        yield put(postNewCustomerFail(error));
    }
}

function* postUserPasswordReset({ payload: obj }) {
    try {
        const response = yield call(postUserPasswordResetApi, obj.username, obj.password);
        yield put(postUserPasswordResetSuccess(response));
    } catch (error) {
        yield put(postUserPasswordResetFail(error));
    }
}

function* getUpdateUserCreditBalances() {
    try {
        const response = yield call(getUpdateUserCreditBalancesApi);
        yield put(customerApiResponseSuccess(GET_UPDATE_USER_CREDIT_BALANCES, response));
    } catch (error) {
        yield put(customerApiResponseError(GET_UPDATE_USER_CREDIT_BALANCES, error));
    }
}

// TODO: Add functions for product groups
function* getProductGroups() {
    try {
        const response = yield call(getProductGroupsApi);
        yield put(customerApiResponseSuccess(GET_PRODUCT_GROUPS, response));
    } catch (error) {
        yield put(customerApiResponseError(GET_PRODUCT_GROUPS, error));
    }
}

function* getUsersProductGroups({ payload: username }) {
    try {
        const response = yield call(getUserProductGroupsApi, username);
        yield put(customerApiResponseSuccess(GET_USERS_PRODUCT_GROUPS, response));
    } catch (error) {
        yield put(customerApiResponseError(GET_USERS_PRODUCT_GROUPS, error));
    }
}

function* postNewProductGroupToUser({ payload: data }) {
    try {
        const response = yield call(postNewProductGroupToUserApi, data.username, data.groupId);
        yield put(postNewProductGroupToUserSuccess(response));
    } catch (error) {
        yield put(postNewProductGroupToUserFail(error));
    }
}

function* deleteProductGroupFromUser({ payload: data }) {
    try {
        yield call(deleteProductGroupFromUserApi, data.groupId);
        yield put(deleteProductGroupFromUserSuccess());
    } catch (error) {
        yield put(deleteProductGroupFromUserFail(error));
    }
}

export function* watchGetCustomers() {
    yield takeEvery(GET_CUSTOMERS, getCustomers);
}

export function* watchGetCustomerInformation() {
    yield takeEvery(GET_CUSTOMER_INFO, getCustomerInformation);
}

export function* watchGetCustomerProductExclusion() {
    yield takeEvery(GET_CUSTOMER_PRODUCT_EXCLUSION, getCustomerProductExclusion);
}

export function* watchGetCustomerUserGroup() {
    yield takeEvery(GET_CUSTOMER_USER_GROUP, getCustomerUserGroup);
}

export function* watchGetUserGroups() {
    yield takeEvery(GET_USER_GROUPS, getUserGroups);
}

export function* watchUpdateCustomerInformation() {
    yield takeEvery(UPDATE_CUSTOMER_INFO, updateCustomerInformation);
}

export function* watchDeleteCustomer() {
    yield takeEvery(DELETE_CUSTOMER, deleteCustomer);
}

export function* watchPostNewCustomerExclusion() {
    yield takeEvery(POST_NEW_CUSTOMER_EXCLUSION, postNewCustomerExclusion);
}

export function* watchDeleteCustomerExclusion() {
    yield takeEvery(DELETE_CUSTOMER_EXCLUSION, deleteCustomerExclusion);
}

export function* watchUpdateUserGroup() {
    yield takeEvery(UPDATE_USER_GROUP, updateUserGroup);
}

export function* watchPostNewCustomer() {
    yield takeEvery(POST_NEW_CUSTOMER, postNewCustomer);
}

export function* watchPostUserPasswordReset() {
    yield takeEvery(POST_USER_PASSWORD_RESET, postUserPasswordReset);
}

export function* watchGetUpdateUserCreditBalances() {
    yield takeEvery(GET_UPDATE_USER_CREDIT_BALANCES, getUpdateUserCreditBalances);
}

export function* watchGetProductGroups() {
    yield takeEvery(GET_PRODUCT_GROUPS, getProductGroups)
}

export function* watchGetUsersProductGroups() {
    yield takeEvery(GET_USERS_PRODUCT_GROUPS, getUsersProductGroups)
}

export function* watchPostNewProductGroupToUser() {
    yield takeEvery(POST_NEW_PRODUCT_GROUP_TO_USER, postNewProductGroupToUser)
}

export function* watchDeleteProductGroupFromUser() {
    yield takeEvery(DELETE_PRODUCT_GROUP_FROM_USER, deleteProductGroupFromUser)
}

function* customerSaga() {
    yield all([
        fork(watchGetCustomers),
        fork(watchGetCustomerInformation),
        fork(watchGetCustomerProductExclusion),
        fork(watchGetCustomerUserGroup),
        fork(watchGetUserGroups),
        fork(watchUpdateCustomerInformation),
        fork(watchDeleteCustomer),
        fork(watchPostNewCustomerExclusion),
        fork(watchDeleteCustomerExclusion),
        fork(watchUpdateUserGroup),
        fork(watchPostNewCustomer),
        fork(watchPostUserPasswordReset),
        fork(watchGetUpdateUserCreditBalances),
        fork(watchGetProductGroups),
        fork(watchGetUsersProductGroups),
        fork(watchPostNewProductGroupToUser),
        fork(watchDeleteProductGroupFromUser),
    ]);
}

export default customerSaga;