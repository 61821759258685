import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Registration from "./auth/register/reducer";

// IdleTimer
import IdleTimer from "./idleTimer/reducer";

// Products
import Products from "./products/part-search/reducer";
import Interchange from "./products/interchange/reducer";
import Vehicle from "./products/vehicle-search/reducer";

// Drafts
import Drafts from "./drafts/reducer";

// Customer
import Customer from "./customer/reducer";

// History
import Invoice from "./history/order-history/reducer";
import Returns from "./history/return-history/reducer";
import Credit from "./history/credit-history/reducer";
import Statements from "./history/statements/reducer";

// Global Settings
import GlobalSettings from "./administrator/globalSettings/reducer";

// Promotions Coupons
import PromotionsCoupons from "./administrator/promotionsCoupons/reducer";

// System Messages
import SystemMessages from "./administrator/systemMessages/reducer";

const wrapperRootReducer = (state, action) => {
    if (action.type === 'RESET_APP') {
        const { Drafts, Customer } = state;
        state = { Drafts, Customer };
    }

    return rootReducer(state, action);
}

const rootReducer = combineReducers({
    // public
    Layout,
    Login,
    ForgetPassword,
    Profile,
    Invoice,
    Products,
    Interchange,
    Vehicle,
    Drafts,
    Returns,
    Credit,
    Statements,
    Customer,
    GlobalSettings,
    PromotionsCoupons,
    Registration,
    IdleTimer,
    SystemMessages,
});

export default wrapperRootReducer;