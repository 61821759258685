import { 
    API_RESPONSE_ERROR, 
    API_RESPONSE_SUCCESS, 
    GET_SYSTEM_MESSAGES,
    GET_SYSTEM_MESSAGES_TODAY,
    POST_SYSTEM_MESSAGE,
    POST_SYSTEM_MESSAGE_FAIL,
    POST_SYSTEM_MESSAGE_SUCCESS
} from "./actionType";

// common success
export const systemMessagesApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const systemMessagesApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getSystemMessages = () => ({
    type: GET_SYSTEM_MESSAGES
});

export const getSystemMessagesToday = () => ({
    type: GET_SYSTEM_MESSAGES_TODAY
});

export const postSystemMessage = (system_message) => ({
    type: POST_SYSTEM_MESSAGE,
    payload: system_message
});

export const postSystemMessageSuccess = () => ({
    type: POST_SYSTEM_MESSAGE_SUCCESS,
});

export const postSystemMessageFail = (error) => ({
    type: POST_SYSTEM_MESSAGE_FAIL,
    payload: error
});