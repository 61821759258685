import React, { useEffect, useState } from 'react';
import { Link, Redirect, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import { AccessRoute } from '../../../Routes/AuthProtected';

//Import Icons
import FeatherIcon from "feather-icons-react";

// User info
import { useProfile } from '../../../Components/Hooks/UserHooks';

// Formik validation
import BreadCrumb from '../../../Components/Common/BreadCrumb';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// actions
import { 
    getDrafts as onGetDrafts,
    addDraft as onAddDraft,
    deleteDraft as onDeleteDraft,
    deleteDraftProductsAndPriceListWithDraftId as onDeleteDraftProductAndPriceListWithDraftId,
} from "../../../store/actions";

const NewOrder = ({ routes }) => {
    const { userProfile } = useProfile();
    const dispatch = useDispatch();
    let { url } = useRouteMatch();
    let location = useLocation();
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    
    const { drafts, isDraftsSuccess, isDraftsCreated, isDraftsDelete, error } = useSelector((state) => ({
        drafts: state.Drafts.drafts,
        isDraftsSuccess: state.Drafts.isDraftsSuccess,
        isDraftsCreated: state.Drafts.isDraftsCreated,
        isDraftsDelete: state.Drafts.isDraftsDelete,
        error: state.Drafts.error,
    }));

    useEffect(() => {
        dispatch(onGetDrafts());
    }, [dispatch])

    useEffect(() => {
        if (isDraftsCreated || isDraftsDelete) dispatch(onGetDrafts());
    }, [dispatch, isDraftsCreated, isDraftsDelete])

    useEffect(() => {
        let cart = JSON.parse(sessionStorage.getItem('cart'))
        if (drafts.length < 5 && cart?.length) {
            console.log('cart')
            let draftItems = cart.map((item) => {
                return { product: item.product, max_product: item.product, qty:item.qty }
            })
            dispatch(onAddDraft({ user: userProfile.username, drafthash: uuidv4(), shipto: '', shipping: '', po: '', coupon:'', mynotes: '', maxnotes: '', draftitems: draftItems }));
            sessionStorage.removeItem('cart');
        }

        if (drafts.length >= 5 && cart?.length) {
            alert("Cannot add draft for cart items. Delete a draft to add cart items.");
        }
    }, [dispatch, drafts, userProfile])

    useEffect(() => {
        if (!isLoading) {
            setIsLoading(true);
            setTimeout(() => setIsLoading(false), 2000)
        }
    }, [location])

    function addDraft() {
        if (drafts.length < 8) {
            dispatch(onAddDraft({ user: userProfile.username, drafthash: uuidv4(), shipto: '', shipping: '', po: '', coupon:'', mynotes: '', maxnotes: '', draftitems: [] }));
        } else {
            alert('Too many drafts. Delete a draft before creating a new one.');
        }
    }

    function deleteDraft() {
        let drafthash = location.pathname.split('/')[2];
        if (location.pathname.split('/')[2] !== undefined) {
            dispatch(onDeleteDraftProductAndPriceListWithDraftId(drafthash));
            dispatch(onDeleteDraft(drafthash));
            history.replace("/new-order");
        } else {
            alert('Select a draft to delete');
        }
    }

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />
    if (!userProfile.permissions.order_creation) return <Redirect to="/not-authorized" />

    document.title = "New Order | Max Advanced Brakes - Prozone";

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="New Order" pageTitle="New Order" linkTo={'/new-order'} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="text-start mb-3" xl={2} md={6}>
                                            <Button color="success" className="btn btn-secondary" onClick={addDraft}>New Draft</Button>{' '}
                                            {(isLoading) ? (
                                                <button type="button" className="btn btn-danger btn-load">
                                                    <span className="d-flex align-items-center">
                                                        <span className="spinner-border flex-shrink-0" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </span>
                                                        <span className="flex-grow-1 ms-2">
                                                            Loading...
                                                        </span>
                                                    </span>
                                                </button>
                                            ) : (
                                                <Button color="danger" className="btn btn-danger" onClick={deleteDraft}>Delete</Button>
                                            )}
                                        </Col>
                                        {(isDraftsSuccess && drafts.length !== 0) ? (
                                            drafts.map((draft, key) => {
                                                let active = location.pathname === `/new-order/${draft.drafthash}` ? 'activeHover' : '';
                                                return (
                                                    <React.Fragment key={key}>
                                                        <Col xl={2} md={6}>
                                                            <Link className="link-dark" to={`${url}/${draft.drafthash}`}>
                                                                <Card className={`card-animate ${active}`}>
                                                                    <CardBody>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="flex-grow-1">
                                                                                <p className="text-uppercase fw-medium text-muted mb-0">Draft {key + 1} {/*draft.draft_name*/}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                                                            <div>
                                                                                {(draft.draft_name) ? (<h4 className="fs-22 fw-semibold ff-secondary mb-4">{draft.draft_name}{/*draft.draft_total*/}</h4>) : (<h4 className="fs-22 fw-semibold ff-secondary mb-4 text-light text-opacity-25">{key}</h4>)}
                                                                                <span className="text-muted">{draft.date_created}</span>
                                                                            </div>
                                                                            <div className="avatar-sm flex-shrink-0">
                                                                                <span className="avatar-title bg-light rounded fs-3">
                                                                                    <FeatherIcon icon={'file-text'} className="text-success icon-dual-success" />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Link>
                                                        </Col>
                                                    </React.Fragment>
                                                )
                                            }
                                        )) : (null)}
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <h4 className={`text-center ${(location.pathname.match(/\/new-order\//) && location.pathname.match(/\/new-order\//)[0] === "/new-order/") ? ("d-none") : ("d-block")}`}>Please Select a Draft</h4>
                    <Switch>
                        {routes.map((route, i) => (
                            <AccessRoute key={i} {...route} />
                        ))}
                    </Switch>
                </Container>
            </div>
        </>
    );
};

export default NewOrder;