import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useProfile } from "../Components/Hooks/UserHooks";

//Import Icons
import FeatherIcon from "feather-icons-react";

const Navdata = () => {
    const history = useHistory();
    const { userProfile } = useProfile();

    //state data
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isProducts, setIsProducts] = useState(false);
    const [isHistory, setIsHistory] = useState(false);
    const [isAdministrator, setIsAdministrator] = useState(false);
    const [isOrderDesk, setIsOrderDesk] = useState(false);
    const [isWarehouse, setIsWarehouse] = useState(false);
    const [isAccounting, setIsAccounting] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                let id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'Products') {
            setIsProducts(false);
        }
        if (iscurrentState !== 'History') {
            setIsHistory(false);
        }
        if (iscurrentState !== 'Administrator') {
            setIsAdministrator(false);
        }
        if (iscurrentState !== 'Order Desk') {
            setIsOrderDesk(false);
        }
        if (iscurrentState !== 'Warehouse') {
            setIsWarehouse(false);
        }
        if (iscurrentState !== 'Accounting') {
            setIsAccounting(false);
        }
    }, [history, iscurrentState, isAuth, isHistory, isPages, isProducts, isAdministrator, isOrderDesk, isWarehouse, isAccounting]);

    function checkPageAccessPermissionNotEqual(menuItemsArr, access, page) {
        if (userProfile.access !== access && userProfile.access !== 1) {
            menuItemsArr = menuItemsArr.filter((item) => {
                if (item.id === 'administrator') {
                    item.subItems = item.subItems.filter((subRoute) => subRoute.id !== 'promotions-coupons');
                }
                return item.id !== page
            });
        }
        return menuItemsArr;
    }

    function checkPageAccessPermissionEqual(menuItemsArr, access, page) {
        if (userProfile.access === access) {
            menuItemsArr = menuItemsArr.filter((item) => item.id !== page);
        }
        return menuItemsArr;
    }

    function checkPagePermission(menuItemsArr, fieldId, permission) {
        if (!permission) {
            menuItemsArr = menuItemsArr.filter((item) => item.id !== fieldId);
        }

        return menuItemsArr;
    }

    function checkPagePermissionHistory(menuItemsArr, fieldId, permission) {
        if (!permission) {
            menuItemsArr = menuItemsArr.map((item) => {
                if (item.id === 'history') {
                    item.subItems = item.subItems.filter((subRoutes) => subRoutes.id !== fieldId);
                }
                return item;
            });
        }

        return menuItemsArr;
    }

    function checkPermissions(menuItemsArr) {
        // Check Administrator Access Permission
        menuItemsArr = checkPageAccessPermissionNotEqual(menuItemsArr, 3, 'administrator');

        // Check Order Desk Access Permission
        menuItemsArr = checkPageAccessPermissionNotEqual(menuItemsArr, 3, 'order-desk');

        // Check Return Request Management
        // menuItemsArr = checkPageAccessPermissionNotEqual(menuItemsArr, 3, 'return-request-management');

        // Check Warehouse Access Permission
        menuItemsArr = checkPageAccessPermissionNotEqual(menuItemsArr, 9, 'warehouse');

        // Check Warehouse Access Permission
        menuItemsArr = checkPageAccessPermissionNotEqual(menuItemsArr, 7, 'accounting');

        // Check RMA Access Permission
        // menuItemsArr = checkPageAccessPermissionEqual(menuItemsArr, 5, 'return-material-authorization');

        // Check order creation permission
        menuItemsArr = checkPagePermission(menuItemsArr, 'new-order', userProfile.permissions.order_creation);

        // Check return creation permission
        menuItemsArr = checkPagePermission(menuItemsArr, 'returns', userProfile.permissions.return_creation);

        // Check view order history permission
        menuItemsArr = checkPagePermissionHistory(menuItemsArr, 'order-history', userProfile.permissions.view_order_history);

        // Check view return history permission
        menuItemsArr = checkPagePermissionHistory(menuItemsArr, 'return-history', userProfile.permissions.view_return_history);

        // Check view credit history permission
        menuItemsArr = checkPagePermissionHistory(menuItemsArr, 'credit-history', userProfile.permissions.view_credit_history);

        // Check view statements permission
        menuItemsArr = checkPagePermissionHistory(menuItemsArr, 'statements', userProfile.permissions.view_statements);

        return menuItemsArr;
    }

    const menuItems = [
        {
            label: "pages",
            isHeader: true,
        },
        {
            id: "home",
            label: "Home",
            icon: <FeatherIcon icon="home" className="icon-home" />,
            link: "/home",
            click: function (e) {
                e.preventDefault();
                setIsPages(!isPages);
                setIscurrentState('Home');
                updateIconSidebar(e);
            },
            stateVariables: isPages,
        },
        {
            id: 'products',
            label: "Products",
            icon: <FeatherIcon icon="search" className="icon-dual" />,
            stateVariables: isProducts,
            click: function (e) {
                e.preventDefault();
                setIsProducts(!isProducts);
                setIscurrentState('Products');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'interchange',
                    label: 'Interchange',
                    link: '/products/interchange',
                    parentId: 'products'
                },
                {
                    id: "part-search",
                    label: 'Part Search',
                    link: '/products/part-search',
                    parentId: 'products'
                },
                {
                    id: "vehicle-search",
                    label: 'Vehicle Search',
                    link: '/products/vehicle-search',
                    parentId: 'products'
                },
            ]
        },
        {
            id: "new-order",
            label: "New Order",
            icon: <FeatherIcon icon="clipboard" className="icon-search" />,
            link: "/new-order",
            click: function (e) {
                e.preventDefault();
                setIsPages(!isPages);
                setIscurrentState('NewOrder');
                updateIconSidebar(e);
            },
            stateVariables: isPages,
        },
        {
            id: "returns",
            label: "Returns",
            icon: <FeatherIcon icon="delete" className="icon-delete" />,
            link: "/returns",
            click: function (e) {
                e.preventDefault();
                setIsPages(!isPages);
                setIscurrentState('Returns');
                updateIconSidebar(e);
            },
            stateVariables: isPages,
        },
        {
            id: 'history',
            label: "History",
            icon: <FeatherIcon icon="user" className="icon-dual" />,
            stateVariables: isHistory,
            click: function (e) {
                e.preventDefault();
                setIsHistory(!isHistory);
                setIscurrentState('History');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'order-history',
                    label: 'Order History',
                    link: '/history/order-history',
                    parentId: 'history'
                },
                {
                    id: 'return-history',
                    label: 'Return History',
                    link: '/history/return-history',
                    parentId: 'history'
                },
                {
                    id: 'credit-history',
                    label: 'Credit History',
                    link: '/history/credit-history',
                    parentId: 'history'
                },
                {
                    id: 'statements',
                    label: 'Statements',
                    link: '/history/statements',
                    parentId: 'history'
                }
            ]
        },
        {
            id: 'administrator',
            label: "Administrator",
            icon: <FeatherIcon icon="database" className="icon-dual" />,
            stateVariables: isAdministrator,
            click: function (e) {
                e.preventDefault();
                setIsAdministrator(!isAdministrator);
                setIscurrentState('Administrator');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'account-management',
                    label: 'Account Settings',
                    link: '/administrator/account-management',
                    parentId: 'administrator'
                },
                {
                    id: 'globalsettings',
                    label: 'Global Settings',
                    link: '/administrator/globalsettings',
                    parentId: 'administrator'
                },
                {
                    id: 'system-messages',
                    label: 'System Messages',
                    link: '/administrator/system-messages',
                    parentId: 'administrator'
                }
            ]
        },
        {
            id: 'order-desk',
            label: "Order Desk",
            icon: <FeatherIcon icon="edit" className="icon-dual" />,
            stateVariables: isOrderDesk,
            click: function (e) {
                e.preventDefault();
                setIsOrderDesk(!isOrderDesk);
                setIscurrentState('Order Desk');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'order-approval',
                    label: 'Order Approval',
                    link: '/order-desk/order-approval',
                    parentId: 'order-desk'
                },
                {
                    id: 'return-material-authorization',
                    label: 'RMA',
                    link: '/order-desk/return-material-authorization',
                    parentId: 'order-desk'
                },
                {
                    id: 'return-adjustment',
                    label: 'Return Adjustment',
                    link: '/order-desk/return-adjustment',
                    parentId: 'order-desk'
                },
            ]
        },
        {
            id: 'warehouse',
            label: "Warehouse",
            icon: <FeatherIcon icon="truck" className="icon-truck" />,
            stateVariables: isWarehouse,
            click: function (e) {
                e.preventDefault();
                setIsWarehouse(!isWarehouse);
                setIscurrentState('Warehouse');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'dispatch',
                    label: 'Dispatch',
                    link: '/warehouse/dispatch',
                    parentId: 'warehouse'
                },
                {
                    id: 'quanlity-assurance',
                    label: 'Quality Assurance',
                    link: '/warehouse/quality-assurance',
                    parentId: 'warehouse'
                },
            ]
        },
        {
            id: 'accounting',
            label: "Accounting",
            icon: <FeatherIcon icon="book" className="icon-book" />,
            stateVariables: isAccounting,
            click: function (e) {
                e.preventDefault();
                setIsAccounting(!isAccounting);
                setIscurrentState('Accounting');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'issue-credit',
                    label: 'Issue Credit',
                    link: '/accounting/issue-credit',
                    parentId: 'accounting'
                },
                {
                    id: 'overdue-statements',
                    label: 'Overdue Statements',
                    link: '/accounting/overdue-statements',
                    parentId: 'accounting'
                },
            ]
        },
    ];
    return <React.Fragment>{checkPermissions(menuItems)}</React.Fragment>;
};
export default Navdata;