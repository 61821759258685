import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardBody, Row, Col, Card, Table, CardHeader, Container } from "reactstrap";
import { Link, Redirect, useLocation } from "react-router-dom";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import { useProfile } from "../../../../Components/Hooks/UserHooks";

import {
    getCredit as onGetCredit,
    getCreditDetails as onGetCreditDetails,
    getCustomerInformation as onGetCustomerInformation,
} from "../../../../store/actions";

import logoDark from "../../../../assets/images/BLACKLOGO1.png";

const CreditHistoryDetails = () => {
    let location = useLocation();
    const dispatch = useDispatch();
    const { userProfile } = useProfile()
    const { credit, isCreditSuccess, creditDetails, isCreditDetailsSuccess, userInfo, isUserInfoSuccess, creditError, customerError } = useSelector((state) => ({
        credit: state.Credit.credit,
        isCreditSuccess: state.Credit.isCreditSuccess,
        creditDetails: state.Credit.creditDetails,
        isCreditDetailsSuccess: state.Credit.isCreditDetailsSuccess,
        userInfo: state.Customer.userInfo,
        isUserInfoSuccess: state.Customer.isUserInfoSuccess,
        creditError: state.Credit.error,
        customerError: state.Customer.error,
    }));

    useEffect(() => {
        let credit_id = location.pathname.split('/')[3];
        dispatch(onGetCredit(credit_id));
        dispatch(onGetCreditDetails(credit_id));
    }, [dispatch, location])

    useEffect(() => {
        if (creditDetails && creditDetails.length) dispatch(onGetCustomerInformation(credit.username));
    }, [dispatch, credit, creditDetails])

    //Print the Invoice
    const printInvoice = () => {
        window.print();
    };

    if ((typeof creditError === 'string' && creditError.match("401")) || (typeof customerError === 'string' && customerError.match("401"))) return <Redirect to="/login" />
    if (!userProfile.permissions.view_credit_history) return <Redirect to="/not-authorized" />

    document.title = `${location.pathname.split('/')[3]} | Credit History | Max Advanced Brakes - Prozone`;

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Credit History Details" pageTitle="Credit History" linkTo={`/history/credit-history`} />

                <Row className="justify-content-center">
                    <Col>
                        <Card id="demo">
                            <CardHeader className="border-bottom-dashed p-4 border-dark">
                                {(isCreditDetailsSuccess && credit && Object.keys(credit).length) ? (
                                    <div className="d-sm-flex">
                                        <div className="flex-grow-1">
                                            <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="110" />
                                            <img src={logoDark} className="card-logo card-logo-light" alt="logo light" height="17" />
                                            {/* <div className="mt-sm-5 mt-4">
                                                <h6 className="text-muted text-uppercase fw-semibold"> Address </h6>
                                                <p className="text-muted mb-1"> Ontario, Canada </p>
                                                <p className="text-muted mb-0"><span>Postal Code:</span> L6C3A1</p>
                                            </div> */}
                                        </div>
                                        <div className="flex-shrink-0 mt-sm-0 mt-3">
                                            <h6>
                                                <span className="text-muted fw-normal"> Credit No: </span>{" "}
                                                <span id="legal-register-no">{credit.id}</span>
                                            </h6>
                                            <h6>
                                                <span className="text-muted fw-normal">Website:</span>{" "}
                                                <Link to="#" className="link-primary"> www.maxbrakes.com/prozone </Link>
                                            </h6>
                                            <h6 className="mb-0">
                                                <span className="text-muted fw-normal">Contact No:</span>{" "}
                                                <span id="contact-no"> 1-888-835-2399 ext 124</span>
                                            </h6>
                                        </div>
                                    </div>
                                ) : (null)}
                            </CardHeader>
                            <CardBody className="p-4">
                                {(isCreditSuccess && Object.keys(credit).length) ? (
                                    <Row className="g-3">
                                        <Col lg={3} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold"> Credit No </p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-no">{credit.id}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={3} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold"> Date </p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-date">{credit.date_created.slice(0, 10)}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={3} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Return Invoice</p>
                                            <h5 className="fs-14 mb-0">
                                                <span>{credit.return_invoice_id}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={3} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold"> Total Amount </p>
                                            <h5 className="fs-14 mb-0">
                                                $<span id="total-amount">{credit.refund_total}</span>
                                            </h5>
                                        </Col>
                                    </Row>
                                ) : (null)}
                            </CardBody>
                            <CardBody className="p-4 border-top border-top-dashed border-dark">
                                <Row className="g-3">
                                    <Col sm={6}>
                                        {(isUserInfoSuccess && Object.keys(userInfo).length !== 0) ? (
                                            <>
                                                <h6 className="text-muted text-uppercase fw-semibold mb-3"> Shipping Address </h6>
                                                <p className="fw-medium mb-2" id="shipping-name">{userInfo.company_name}</p>
                                                <p className="text-muted mb-1">{userInfo.address}, {userInfo.city}</p>
                                                <p className="text-muted mb-1"> {userInfo.state}, {userInfo.country} - {userInfo.zip} </p>
                                                <p className="text-muted mb-1"><span>Phone: </span><span id="shipping-phone-no">{userInfo.phone_primary}</span></p>
                                            </>
                                        ) : (null)}
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardBody className="p-4">
                                <div className="table-responsive">
                                    <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                                        <thead>
                                            <tr className="table-active">
                                                <th scope="col" style={{ width: "50px" }}>#</th>
                                                <th scope="col">Invoice</th>
                                                <th scope="col">Product</th>
                                                <th scope="col">MAX-Product</th>
                                                <th scope="col">Unit Cost</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col" className="text-end">Credit</th>
                                                <th scope="col">Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(isCreditDetailsSuccess && creditDetails.length) ? (
                                                creditDetails.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{item?.order_id}</td>
                                                            <td>{item.product_query}</td>
                                                            <td>{item.product}</td>
                                                            <td>${item.price}</td>
                                                            <td>{item.qty}</td>
                                                            <td className="text-end">${(Number(item.qty) * Number(item.price)).toFixed(2)}</td>
                                                            <td>{item.remark}</td>
                                                        </tr>
                                                    )
                                                })
                                            ) : (null)}
                                            <tr className="border-top border-top-dashed mt-2 border-dark">
                                                <td colSpan="5"></td>
                                                <td colSpan="2" className="fw-medium p-0">
                                                    <Table className="table-borderless text-start table-nowrap align-middle mb-0">
                                                        <tbody>
                                                            {(isCreditSuccess && Object.keys(credit).length) ? (
                                                                <>
                                                                    <tr>
                                                                        <td>Subtotal</td>
                                                                        <td className="text-end">${credit.refund_subtotal}</td>
                                                                    </tr>
                                                                    {(credit.admin_fee) ? (
                                                                        <>
                                                                            <tr>
                                                                                <td>Administration Fee</td>
                                                                                <td className="text-end">-${(Number(credit.refund_subtotal) * 0.15).toFixed(2)}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Tax</td>
                                                                                <td className="text-end">${((Number(credit.refund_subtotal) - Number(Number(credit.refund_subtotal) * 0.15)) * Number(userInfo.rate)).toFixed(2)}</td>
                                                                            </tr>
                                                                            <tr className="border-top border-top-dashed">
                                                                                <th scope="row">Total</th>
                                                                                <td className="text-end">${credit.refund_total}</td>
                                                                            </tr>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <tr>
                                                                                <td>Tax</td>
                                                                                <td className="text-end">${(Number(credit.refund_total) - Number(credit.refund_subtotal)).toFixed(2)}</td>
                                                                            </tr>
                                                                            <tr className="border-top border-top-dashed border-dark">
                                                                                <th scope="row">Total</th>
                                                                                <td className="text-end">${credit.refund_total}</td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                </>
                                                            ) : (null)}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                    {/* <Link to="#" onClick={printInvoice} className="btn btn-soft-primary">
                                        <i className="ri-printer-line align-bottom me-1"></i> Print
                                    </Link>
                                    <Link to="#" className="btn btn-primary">
                                        <i className="ri-download-2-line align-bottom me-1"></i>{" "} Download
                                    </Link> */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CreditHistoryDetails;
