import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { 
    LOGIN_USER, 
    LOGOUT_USER,
} from "./actionTypes";

import { 
    apiError, 
    loginSuccess, 
    logoutUserSuccess, 
} from "./actions";

import {
    postLogin as postLoginApi,
    logout as logoutApi,
} from "../../../helpers/backend_helper";

function* loginUser({ payload: { user, history } }) {
    try {
        const response = yield call(postLoginApi, {
            username: user.username,
            password: user.password,
        });
        sessionStorage.setItem("authUser", JSON.stringify(response));
        if (response.status === "OK") {
            yield put(loginSuccess(response));
            history.push("/home");
        } else {
            // console.log(response)
            yield put(apiError(response));
        }
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser() {
    try {
        sessionStorage.removeItem("authUser");
        yield call(logoutApi);
        yield put(logoutUserSuccess(LOGOUT_USER, true));
    } catch (error) {
        yield put(apiError(LOGOUT_USER, error));
    }
}

function* authSaga() {
    yield takeEvery(LOGIN_USER, loginUser);
    yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
