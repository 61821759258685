import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import { ToastContainer } from "react-toastify";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../../../Components/Common/TableContainer";
import Loader from "../../../../Components/Common/Loader";
import { SelectColumnFilter } from "../../../../Components/Common/filters";

import { activeLabels } from "../../../../Components/constants/accountDetails";

// actions
import {
    getCustomers as onGetCustomers,
} from "../../../../store/actions";

const AccountManagement = () => {
    const dispatch = useDispatch();
    const { customers, customerError } = useSelector((state) => ({
        customers: state.Customer.customers,
        customerError: state.Customer.error,
    }));

    useEffect(() => {
        dispatch({ type: 'RESET_APP' });
    }, [dispatch])

    useEffect(() => {
        dispatch(onGetCustomers());
    }, [dispatch])

    // Checked All
    const checkedAll = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".invoiceCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };
    
    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="invoiceCheckBox" value={cellProps.row.original._id} />;
                },
                id: '#',
            },
            {
                Header: "USERNAME",
                accessor:'username',
                Cell: (customer) => {
                    return <Link to={`/administrator/account-management/${customer.row.original.username}`} className="fw-medium link-primary">{customer.row.original.username}</Link>;
                },
            },
            {
                Header: "COMPANY NAME",
                accessor: 'company_name',
                Filter: SelectColumnFilter,
                Cell: (cell) => (
                    <>
                        {cell.row.original.company_name}
                    </>
                )
            },
            {
                Header: "EMAIL",
                accessor: 'email',
                Filter: SelectColumnFilter,
                Cell: (cell) => (
                    <>
                        {cell.row.original.email}
                    </>
                )
            },
            {
                Header: "Active",
                accessor: 'active',
                Filter: SelectColumnFilter,
                Cell: (cell) => (
                    <>
                        {activeLabels[cell.row.original.active]}
                    </>
                )
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <UncontrolledDropdown >
                            <DropdownToggle
                                href="#"
                                className="btn-soft-secondary btn-sm dropdown"
                                tag="button"
                            >
                                <i className="ri-more-fill align-middle"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem href={`/administrator/account-management/${cellProps.row.original.username}`}>
                                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                                    View
                                </DropdownItem>

                                <DropdownItem divider />

                            </DropdownMenu>
                        </UncontrolledDropdown>
                    );
                },
            },
        ],
        []
    );

    if (typeof customerError === 'string' && customerError.match("401")) return <Redirect to="/login" />
    if (typeof customerError === 'string' && customerError.match("403")) return <Redirect to="/not-authorized" />

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Account Settings" pageTitle="Account Settings" linkTo={`/account-management`} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Button>
                                        <Link className="text-white" to={'/administrator/account-management/new-user'}>New User</Link>
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div>
                                        {customers.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(customers || [])}
                                                isGlobalFilter={false}
                                                isAddUserList={false}
                                                customPageSize={30}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="text-muted"
                                                thClass="text-muted"
                                                isInvoiceListFilter={false}
                                            />
                                        ) : (<Loader error={customerError} />)}
                                        <ToastContainer closeButton={false} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default AccountManagement;