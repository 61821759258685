// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const RESET_APP = "RESET_APP";

// Get Return
export const GET_RETURNS = "GET_RETURNS";
export const GET_RETURNS_FILTERED = "GET_RETURNS_FILTERED";
export const GET_RETURN = "GET_RETURN";
export const GET_RETURN_DETAILS = "GET_RETURN_DETAILS";
export const GET_RETURN_DETAILS_EXTRA = "GET_RETURN_DETAILS_EXTRA";
export const GET_RETURN_BUCKET = "GET_RETURN_BUCKET";
export const GET_RGR_FORM = "GET_RGR_FORM";
export const DOWNLOAD_RETURN_INVOICE = "DOWNLOAD_RETURN_INVOICE";
export const GET_RETURN_ADJUSTMENTS = "GET_RETURN_ADJUSTMENTS";
export const GET_RETURN_ADJUSTMENT = "GET_RETURN_ADJUSTMENT";
export const GET_RETURN_ADJUSTMENT_DETAILS = "GET_RETURN_ADJUSTMENT_DETAILS";
export const DOWNLOAD_RETURN_BATCH_TEMPLATE = "DOWNLOAD_RETURN_BATCH_TEMPLATE";

// Get customer products
export const GET_CUSTOMER_PRODUCTS = "GET_CUSTOMER_PRODUCTS";

// Post items to bucket
export const POST_RETURN_BUCKET = "POST_RETURN_BUCKET";
export const POST_RETURN_BUCKET_SUCCESS = "POST_RETURN_BUCKET_SUCCESS";
export const POST_RETURN_BUCKET_FAIL = "POST_RETURN_BUCKET_FAIL";

// Delete bucket item
export const DELETE_RETURN_BUCKET_ITEM = "DELETE_RETURN_BUCKET_ITEM";
export const DELETE_RETURN_BUCKET_ITEM_SUCCESS = "DELETE_RETURN_BUCKET_ITEM_SUCCESS";
export const DELETE_RETURN_BUCKET_ITEM_FAIL = "DELETE_RETURN_BUCKET_ITEM_FAIL";

// Post new return invoice
export const POST_RETURN_INVOICE_CREATE = "POST_RETURN_INVOICE_CREATE";
export const POST_RETURN_INVOICE_CREATE_SUCCESS = "POST_RETURN_INVOICE_CREATE_SUCCESS";
export const POST_RETURN_INVOICE_CREATE_FAIL = "POST_RETURN_INVOICE_CREATE_FAIL";

// Update type/reason for return bucket items
export const UPDATE_RETURN_BUCKET_ITEMS = "UPDATE_RETURN_BUCKET_ITEMS";
export const UPDATE_RETURN_BUCKET_ITEMS_SUCCESS = "UPDATE_RETURN_BUCKET_ITEMS_SUCCESS";
export const UPDATE_RETURN_BUCKET_ITEMS_FAIL = "UPDATE_RETURN_BUCKET_ITEMS_FAIL";

// Update column for return_bucket
export const UPDATE_RETURN_BUCKET = "UPDATE_RETURN_BUCKET";
export const UPDATE_RETURN_BUCKET_SUCCESS = "UPDATE_RETURN_BUCKET_SUCCESS";
export const UPDATE_RETURN_BUCKET_FAIL = "UPDATE_RETURN_BUCKET_FAIL";

// Post return invoice approval
export const POST_RETURN_INVOICE_APPROVAL = "POST_RETURN_INVOICE_APPROVAL";
export const POST_RETURN_INVOICE_APPROVAL_SUCCESS = "POST_RETURN_INVOICE_APPROVAL_SUCCESS";
export const POST_RETURN_INVOICE_APPROVAL_FAIL = "POST_RETURN_INVOICE_APPROVAL_FAIL";

// Update worksheet for return details items
export const UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET = "UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET";
export const UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET_SUCCESS = "UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET_SUCCESS";
export const UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET_FAIL = "UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET_FAIL";

// Update return adjustment items approval
export const UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL = "UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL";
export const UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL_SUCCESS = "UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL_SUCCESS";
export const UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL_FAIL = "UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL_FAIL";

// Update issue credit for return invoice
export const UPDATE_RETURN_INVOICE_CREDIT_ISSUE = "UPDATE_RETURN_INVOICE_CREDIT_ISSUE";
export const UPDATE_RETURN_INVOICE_CREDIT_ISSUE_SUCCESS = "UPDATE_RETURN_INVOICE_CREDIT_ISSUE_SUCCESS";
export const UPDATE_RETURN_INVOICE_CREDIT_ISSUE_FAIL = "UPDATE_RETURN_INVOICE_CREDIT_ISSUE_FAIL";

// delete return table item
export const DELETE_RETURN_TABLE_ITEM = "DELETE_RETURN_TABLE_ITEM";