import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  API_RESPONSE_ERROR, 
  API_RESPONSE_SUCCESS, 
  POST_REGISTRATION,
} from "./actionType";

import { 
  registrationApiResponseSuccess,
  registrationApiResponseError,
} from "./action";

import {
  postRegistration as postRegistrationApi,
} from "../../../helpers/backend_helper";

function* postRegistration({payload: requestParams}) {
  try {
      const response = yield call(postRegistrationApi, requestParams)
      yield put(registrationApiResponseSuccess(POST_REGISTRATION, response));
  } catch(error) {
      yield put(registrationApiResponseError(POST_REGISTRATION, error));
  }
}

function* watchPostRegistration() {
  yield takeEvery(POST_REGISTRATION, postRegistration)
}

function* registrationSaga() {
  yield all([
      fork(watchPostRegistration),
  ]);
}                          

export default registrationSaga;
