import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { CardBody, Row, Col, Card, Table, CardHeader, Container, Button, ModalFooter, Modal, ModalHeader, ModalBody, Label, Input } from "reactstrap";
import Select from "react-select";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import { useProfile } from "../../../../Components/Hooks/UserHooks";
import { reasonOptions, typeOptions } from "../../../../Components/constants/rgrform";
import { returnShipMethodOptions } from "../../../../Components/constants/returnDetails";

import { 
    getReturn, 
    getReturnDetails, 
    getCustomerInformation,
    postReturnInvoiceApproval as onPostReturnInvoiceApproval,
    updateReturnBucket as onUpdateReturnBucket,
} from "../../../../store/actions";

import logoDark from "../../../../assets/images/BLACKLOGO1.png";

const ReturnMaterialAuthorizationDetails = () => {
    const { userProfile } = useProfile();
    const dispatch = useDispatch();
    const [shipMethod, setShipMethod] = useState('pickup');
    const [adminFee, setAdminFee] = useState(0)
    const [modal, setModal] = useState(false);
    const [returnInfo, setReturnInfo] = useState({});
    const [rejectReason, setRejectReason] = useState('');
    let location = useLocation();
    let history = useHistory();

    const { returnInvoice, isReturnSuccess, returnDetails, isReturnDetailsSuccess, isPostReturnInvoiceApprovalSuccess, isPostReturnInvoiceApprovalFail, userInfo, isUserInfoSuccess, returnsError, customerError } = useSelector((state) => ({
        returnInvoice: state.Returns.returnInvoice,
        isReturnSuccess: state.Returns.isReturnSuccess,
        returnDetails: state.Returns.returnDetails,
        isReturnDetailsSuccess: state.Returns.isReturnDetailsSuccess,
        isPostReturnInvoiceApprovalSuccess: state.Returns.isPostReturnInvoiceApprovalSuccess,
        isPostReturnInvoiceApprovalFail: state.Returns.isPostReturnInvoiceApprovalFail,
        userInfo: state.Customer.userInfo,
        isUserInfoSuccess: state.Customer.isUserInfoSuccess,
        returnsError: state.Returns.error,
        customerError: state.Customer.error,
    }));

    const toggle = () => setModal(!modal);

    useEffect(() => {
        let return_id = location.pathname.split('/')[3];
        console.log(return_id)
        dispatch(getReturn(return_id));
        dispatch(getReturnDetails(return_id));
    }, [dispatch, location])

    useEffect(() => {
        if (returnDetails && returnDetails.length !== 0) {
            setReturnInfo(returnInvoice);
            dispatch(getCustomerInformation(returnInvoice.username));
        }
    }, [dispatch, returnDetails, returnInvoice])

    useEffect(() => {
        if (isPostReturnInvoiceApprovalSuccess) {
            alert('Order status changed');
            history.push(location.pathname.replace('/' + returnInfo.id, ''));
        }

        if (isPostReturnInvoiceApprovalFail) alert('Status change failed');
    }, [dispatch, history, location, isPostReturnInvoiceApprovalSuccess, isPostReturnInvoiceApprovalFail, returnInfo])

    function handleApproval(approval) {
        if (approval === 'Reject' && !rejectReason) {
            alert('A reason for rejection needs to be filled in');
            return;
        }
        dispatch(onPostReturnInvoiceApproval(approval, rejectReason, shipMethod, adminFee, returnInfo.id))
    }

    //Print the Invoice
    const printInvoice = () => {
        window.print();
    };

    if ((typeof returnsError === 'string' && returnsError.match("401")) || (typeof customerError === 'string' && customerError.match("401"))) return <Redirect to="/login" />
    if (!userProfile.permissions.view_return_history) return <Redirect to="/not-authorized" />

    document.title = `${location.pathname.split('/')[3]} | RMA | Max Advanced Brakes - Prozone`;
    
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="RMA Details" pageTitle="RMA" linkTo={`/order-desk/return-material-authorization`} />

                <Row className="justify-content-center">
                    <Col>
                        <Card id="demo">
                            <CardHeader className="border-bottom-dashed p-4">
                                {(isReturnSuccess && Object.keys(returnInfo).length !== 0) ? (
                                    <div className="d-sm-flex">
                                        <div className="flex-grow-1">
                                            <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="110" />
                                            <img src={logoDark} className="card-logo card-logo-light" alt="logo light" height="17" />
                                            <Row>
                                                <Col>
                                                    {/* <div className="mt-sm-5 mt-4">
                                                        <h6 className="text-muted text-uppercase fw-semibold"> Address </h6>
                                                        <p className="text-muted mb-1"> Ontario, Canada </p>
                                                        <p className="text-muted mb-0"><span>Postal Code:</span> L6C3A1</p>
                                                    </div> */}
                                                </Col>
                                                <Col>
                                                    {(returnInfo.reject_reason) ? (
                                                        <div className="mt-4">
                                                            <div className="alert alert-danger">
                                                                <p className="mb-0">
                                                                    <span className="fw-semibold">Reject Reason:</span> {returnInfo.reject_reason}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : (null)}
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="flex-shrink-0 mt-sm-0 mt-3">
                                            <h6>
                                                <span className="text-muted fw-normal"> Return No: </span>{" "}
                                                <span id="legal-register-no">{returnInfo.id}</span>
                                            </h6>
                                            <h6>
                                                <span className="text-muted fw-normal">Website:</span>{" "}
                                                <Link to="#" className="link-primary"> www.maxbrakes.com/prozone </Link>
                                            </h6>
                                            <h6 className="mb-0">
                                                <span className="text-muted fw-normal">Contact No:</span>{" "}
                                                <span id="contact-no"> 1-888-835-2399 ext 124</span>
                                            </h6>
                                        </div>
                                    </div>
                                ) : (null)}
                            </CardHeader>
                            <CardBody className="p-4">
                                {(isReturnSuccess && Object.keys(returnInfo).length !== 0) ? (    
                                    <Row className="g-3">
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold"> Return No </p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-no">{returnInfo.id}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            {(returnInfo.reference_return_adjustment_id) ? (
                                                <>
                                                    <p className="text-muted mb-2 text-uppercase fw-semibold">Reference Return Adjustment Id</p>
                                                    <h5 className="fs-14 mb-0">
                                                        <span id="total-amount">{returnInfo.reference_return_adjustment_id}</span>
                                                    </h5>
                                                </>
                                            ) : (null)}
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold"> Date </p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-date">{returnInfo.date_created.slice(0, 10)}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Shipped Method</p>
                                            <Row>
                                                <Col md={8}>
                                                    {((userProfile.access === 1 || userProfile.access === 3) && returnInfo.status === 'pending') ? (
                                                        <Select 
                                                            options={returnShipMethodOptions}
                                                            value={returnShipMethodOptions.find((item) => item.value === shipMethod)}
                                                            onChange={(e) => setShipMethod(e.value)}
                                                        />
                                                    ) : (
                                                        <h5 className="fs-14 mb-0">
                                                            <span id="invoice-date">{returnInfo.ship_method}</span>
                                                        </h5>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Administration Fee</p>
                                            <Row>
                                                <Col md={8}>
                                                    {((userProfile.access === 1 || userProfile.access === 3) && returnInfo.status === 'pending') ? (
                                                        <>
                                                            <Input type={'checkbox'} checked={(adminFee) ? (1) : (0)} onChange={(e) => setAdminFee((e.target.checked) ? (1) : (0))} />
                                                        </>
                                                    ) : (
                                                        <h5 className="fs-14 mb-0">
                                                            <span id="invoice-date">{returnInfo.adminFee}</span>
                                                        </h5>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                ) : (null)}
                            </CardBody>
                            <CardBody className="p-4 border-top border-top-dashed">
                                <Row className="g-3">
                                    <Col sm={6}>
                                        {(isUserInfoSuccess && Object.keys(userInfo).length !== 0) ? (
                                            <>
                                                <h6 className="text-muted text-uppercase fw-semibold mb-3"> Shipping Address </h6>
                                                <p className="fw-medium mb-2" id="shipping-name">{userInfo.username}</p>
                                                <p className="text-muted mb-1">{userInfo.address}, {userInfo.city}</p>
                                                <p className="text-muted mb-1"> {userInfo.state}, {userInfo.country} - {userInfo.zip} </p>
                                                <p className="text-muted mb-1"><span>Phone: </span><span id="shipping-phone-no">{userInfo.phone_primary}</span></p>
                                            </>
                                        ) : (null)}
                                    </Col>
                                    <Col sm={6} className="text-end">
                                        {/* <h6 className="text-muted text-uppercase fw-semibold mb-3">
                                            Billing Address
                                        </h6>
                                        <p className="fw-medium mb-2">David Nichols</p>
                                        <p className="text-muted mb-1">305 S San Gabriel Blvd</p>
                                        <p className="text-muted mb-1">
                                            California, United States - 91776
                                        </p>
                                        <p className="text-muted mb-1"><span>Phone: +</span><span id="billing-phone-no">(123) 456-7890</span></p>
                                        <p className="text-muted mb-0"><span>Tax: </span><span id="billing-tax-no">12-3456789</span></p> */}
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardBody className="p-4">
                                <div className="table-responsive">
                                    <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                                        <thead>
                                            <tr className="table-active">
                                                <th scope="col" style={{ width: "50px" }}>#</th>
                                                <th scope="col">Product</th>
                                                <th scope="col">Order Id</th>
                                                <th scope="col">Created</th>
                                                <th scope="col">Requested</th>
                                                <th scope="col">Returned</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Reason</th>
                                                <th scope="col">Warehouse Reason</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(isReturnDetailsSuccess && returnDetails.length !== 0) ? (
                                                returnDetails.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td className="text-start">
                                                                <span className="fw-medium">
                                                                    {item.product}
                                                                </span>
                                                                <p className="text-muted mb-0">
                                                                    
                                                                </p>
                                                            </td>
                                                            <td>{item.orderid}</td>
                                                            <td>{(item.invoice_created) ? (item.invoice_created.slice(0, 10)) : (null)}</td>
                                                            <td>{item.qty_to_return}</td>
                                                            <td>{item.qty_returned}</td>
                                                            <td>
                                                                {(item.type) ? (
                                                                    typeOptions.find((type) => item.type === type.value).label
                                                                ) : (null)}
                                                            </td>
                                                            <td>
                                                                {(item.reason) ? (
                                                                    reasonOptions.find((reason) => item.reason === reason.value).label
                                                                ) : (null)}
                                                            </td>
                                                            <td>{item.warehouse_reason}</td>
                                                        </tr>
                                                    )
                                                })
                                            ): (null)}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                    {((userProfile.access === 1 || userProfile.access === 3) && returnInfo.status === 'pending') ? (
                                        <>
                                            <Button color={'success'} onClick={() => handleApproval('Approve')}>Accept</Button>
                                            <Button color={'danger'} onClick={toggle}>Reject</Button>
                                            <Modal isOpen={modal} toggle={toggle}>
                                                <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                                                <ModalBody>
                                                    <Label>Reason for Rejection:</Label>
                                                    <Input type="text" value={rejectReason} onChange={(e) => setRejectReason(e.target.value)} />
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="danger"
                                                        onClick={() => {
                                                            handleApproval("Reject");
                                                            toggle();
                                                        }}
                                                    >Reject</Button>{' '}
                                                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                                                </ModalFooter>
                                            </Modal>
                                        </>
                                    ) : (null)}
                                    {/* <Link to="#" onClick={printInvoice} className="btn btn-soft-primary">
                                        <i className="ri-printer-line align-bottom me-1"></i> Print
                                    </Link>
                                    <Link to="#" className="btn btn-primary">
                                        <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                                        Download
                                    </Link> */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ReturnMaterialAuthorizationDetails;
