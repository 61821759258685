import {
  API_RESPONSE_ERROR, 
  API_RESPONSE_SUCCESS, 
  POST_REGISTRATION,
} from "./actionType"

export const registrationApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const registrationApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const postRegistration = (requestParams) => ({
  type: POST_REGISTRATION,
  payload: requestParams,
  
});

