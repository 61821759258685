import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { 
    DOWNLOAD_STATEMENT,
    GET_STATEMENT, 
    GET_STATEMENTS, 
    GET_STATEMENTS_WITH_USERNAME, 
    GET_STATEMENT_DETAILS,
    POST_STATEMENT_PAYMENT
} from "./actionType";

import { 
    postStatementPaymentFail,
    postStatementPaymentSuccess,
    statementsApiResponseError, 
    statementsApiResponseSuccess 
} from "./action";

//Include Both Helper File with needed methods
import {
    getStatements as getStatementsApi,
    getStatementsWithUsername as getStatementsWithUsernameApi,
    getStatement as getStatementApi,
    getStatementDetails as getStatementDetailsApi,
    downloadStatement as downloadStatementApi,
    postStatementPayment as postStatementPaymentApi,
} from "../../../helpers/backend_helper";

function* getStatements() {
    try {
        const response = yield call(getStatementsApi);
        yield put(statementsApiResponseSuccess(GET_STATEMENTS, response));
    } catch (error) {
        yield put(statementsApiResponseError(GET_STATEMENTS, error));
    }
}

function* getStatementsWithUsername({ payload: username }) {
    try {
        const response = yield call(getStatementsWithUsernameApi, username);
        yield put(statementsApiResponseSuccess(GET_STATEMENTS_WITH_USERNAME, response));
    } catch (error) {
        yield put(statementsApiResponseError(GET_STATEMENTS_WITH_USERNAME, error));
    }
}

function* getStatement({ payload: statement_id }) {
    try {
        const response = yield call(getStatementApi, statement_id);
        yield put(statementsApiResponseSuccess(GET_STATEMENT, response));
    } catch (error) {
        yield put(statementsApiResponseError(GET_STATEMENT, error));
    }
}

// function* getStatementDetails({ payload: statement_id }) {
//     try {
//         const response = yield call(getStatementDetailsApi, statement_id);
//         yield put(statementsApiResponseSuccess(GET_STATEMENT_DETAILS, response));
//     } catch (error) {
//         yield put(statementsApiResponseError(GET_STATEMENT_DETAILS, error));
//     }
// }

function* downloadStatement({ payload: statementId }) {
    try {
        const response = yield call(downloadStatementApi, statementId);
        yield put(statementsApiResponseSuccess(DOWNLOAD_STATEMENT, response));
    } catch(error) {
        yield put(statementsApiResponseError(DOWNLOAD_STATEMENT, error));
    }
}

function* postStatementPayment({ payload: obj }) {
    try {
        const response = yield call(postStatementPaymentApi, obj.statement_id, obj.paid_amount, obj.type)
        yield put(postStatementPaymentSuccess(response));
    } catch (error) {
        yield put(postStatementPaymentFail(error));
    }
}

export function* watchGetStatements() {
    yield takeEvery(GET_STATEMENTS, getStatements);
}

export function* watchGetStatementsWithUsername() {
    yield takeEvery(GET_STATEMENTS_WITH_USERNAME, getStatementsWithUsername);
}

export function* watchGetStatement() {
    yield takeEvery(GET_STATEMENT, getStatement);
}

// export function* watchGetStatementDetails() {
//     yield takeEvery(GET_STATEMENT_DETAILS, getStatementDetails);
// }

export function* watchDownloadStatement() {
    yield takeEvery(DOWNLOAD_STATEMENT, downloadStatement);
}

export function* watchPostStatementPayment() {
    yield takeEvery(POST_STATEMENT_PAYMENT, postStatementPayment);
}

function* statementSaga() {
    yield all([
        fork(watchGetStatements),
        fork(watchGetStatementsWithUsername),
        fork(watchGetStatement),
        // fork(watchGetStatementDetails),
        fork(watchDownloadStatement),
        fork(watchPostStatementPayment),
    ]);
}

export default statementSaga;