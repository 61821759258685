import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Returns Redux States
import { 
    DELETE_RETURN_BUCKET_ITEM,
    DOWNLOAD_RETURN_INVOICE,
    GET_CUSTOMER_PRODUCTS,
    GET_RETURN,
    GET_RETURNS,
    GET_RETURN_BUCKET,
    GET_RETURN_DETAILS,
    GET_RGR_FORM,
    POST_RETURN_BUCKET,
    POST_RETURN_INVOICE_CREATE,
    UPDATE_RETURN_BUCKET,
    UPDATE_RETURN_BUCKET_ITEMS,
    POST_RETURN_INVOICE_APPROVAL,
    UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET,
    GET_RETURN_DETAILS_EXTRA,
    GET_RETURN_ADJUSTMENTS,
    GET_RETURN_ADJUSTMENT,
    GET_RETURN_ADJUSTMENT_DETAILS,
    UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL,
    UPDATE_RETURN_INVOICE_CREDIT_ISSUE,
    DOWNLOAD_RETURN_BATCH_TEMPLATE,
    GET_RETURNS_FILTERED,
} from "./actionType";

import { 
    deleteReturnBucketItemFail,
    deleteReturnBucketItemSuccess,
    postReturnBucketFail,
    postReturnBucketSuccess,
    postReturnInvoiceCreateFail,
    postReturnInvoiceCreateSuccess,
    returnApiResponseError,
    returnApiResponseSuccess,
    updateReturnBucketFail,
    updateReturnBucketItemsFail,
    updateReturnBucketSuccess,
    postReturnInvoiceApprovalSuccess,
    postReturnInvoiceApprovalFail,
    updateReturnInvoiceItemsWorksheetFail,
    updateReturnInvoiceItemsWorksheetSuccess,
    updateReturnAdjustmentItemsApprovalFail,
    updateReturnAdjustmentItemsApprovalSuccess,
    updateReturnInvoiceCreditIssueFail,
    updateReturnInvoiceCreditIssueSuccess,
} from "./action";

//Include Both Helper File with needed methods
import {
    getReturns as getReturnsApi,
    getReturnsFiltered as getReturnsFilteredApi,
    getReturn as getReturnApi,
    getReturnDetails as getReturnDetailsApi,
    getReturnDetailsExtra as getReturnDetailsExtraApi,
    getCustomerProducts as getCustomerProductsApi,
    getRgrForm as getRgrFormApi,
    postReturnBucket as postReturnBucketApi,
    getReturnBucket as getReturnBucketApi,
    getReturnAdjustments as getReturnAdjustmentsApi,
    getReturnAdjustment as getReturnAdjustmentApi,
    getReturnAdjustmentDetails as getReturnAdjustmentDetailsApi,
    postReturnInvoiceCreate as postReturnInvoiceCreateApi,
    deleteReturnBucketItem as deleteReturnBucketItemApi,
    updateReturnBucketItems as updateReturnBucketItemsApi,
    updateReturnBucket as updateReturnBucketApi,
    downloadReturnInvoice as downloadReturnInvoiceApi,
    postReturnInvoiceApproval as postReturnInvoiceApprovalApi,
    updateReturnInvoiceItemsWorksheet as updateReturnInvoiceItemsWorksheetApi,
    updateReturnAdjustmentItemsApproval as updateReturnAdjustmentItemsApprovalApi,
    updateReturnInvoiceCreditIssue as updateReturnInvoiceCreditIssueApi,
    downloadReturnBatchTemplate as downloadReturnBatchTemplateApi,
} from "../../../helpers/backend_helper";

function* getReturns() {
    try {
        const response = yield call(getReturnsApi);
        yield put(returnApiResponseSuccess(GET_RETURNS, response));
    } catch (error) {
        yield put(returnApiResponseError(GET_RETURNS, error));
    }
}

function* getReturnsFiltered({ payload: filter }) {
    try {
        const response = yield call(getReturnsFilteredApi, filter);
        yield put(returnApiResponseSuccess(GET_RETURNS_FILTERED, response));
    } catch (error) {
        yield put(returnApiResponseError(GET_RETURNS_FILTERED, error));
    }
}

function* getReturn({ payload: return_id }) {
    try {
        const response = yield call(getReturnApi, return_id);
        yield put(returnApiResponseSuccess(GET_RETURN, response));
    } catch (error) {
        yield put(returnApiResponseError(GET_RETURN, error));
    }
}

function* getReturnDetails({ payload: return_id }) {
    try {
        const response = yield call(getReturnDetailsApi, return_id);
        yield put(returnApiResponseSuccess(GET_RETURN_DETAILS, response));
    } catch (error) {
        yield put(returnApiResponseError(GET_RETURN_DETAILS, error));
    }
}

function* getReturnDetailsExtra({ payload: return_id }) {
    try {
        const response = yield call(getReturnDetailsExtraApi, return_id);
        yield put(returnApiResponseSuccess(GET_RETURN_DETAILS_EXTRA, response));
    } catch (error) {
        yield put(returnApiResponseError(GET_RETURN_DETAILS_EXTRA, error));
    }
}

function* getCustomerProducts({ payload: obj }) {
    try {
        const response = yield call(getCustomerProductsApi, obj.username, obj.query, obj.line);
        yield put(returnApiResponseSuccess(GET_CUSTOMER_PRODUCTS, { products: response, rowId: obj.rowId }));
    } catch (error) {
        yield put(returnApiResponseError(GET_CUSTOMER_PRODUCTS, error));
    }
}

function* getRgrForm() {
    try {
        const response = yield call(getRgrFormApi);
        yield put(returnApiResponseSuccess(GET_RGR_FORM, response));
    } catch (error) {
        yield put(returnApiResponseError(GET_RGR_FORM, error));
    }
}

function* getReturnAdjustments() {
    try {
        const response = yield call(getReturnAdjustmentsApi);
        yield put(returnApiResponseSuccess(GET_RETURN_ADJUSTMENTS, response));
    } catch (error) {
        yield put(returnApiResponseError(GET_RETURN_ADJUSTMENTS, error))
    }
}

function* getReturnAdjustment({ payload: return_adjustment_id }) {
    try {
        const response = yield call(getReturnAdjustmentApi, return_adjustment_id);
        yield put(returnApiResponseSuccess(GET_RETURN_ADJUSTMENT, response));
    } catch (error) {
        yield put(returnApiResponseError(GET_RETURN_ADJUSTMENT, error))
    }
}

function* getReturnAdjustmentDetails({ payload: return_adjustment_id }) {
    try {
        const response = yield call(getReturnAdjustmentDetailsApi, return_adjustment_id);
        yield put(returnApiResponseSuccess(GET_RETURN_ADJUSTMENT_DETAILS, response));
    } catch (error) {
        yield put(returnApiResponseError(GET_RETURN_ADJUSTMENT_DETAILS, error))
    }
}

function* postReturnBucket({ payload: obj }) {
    try {
        const response = yield call(postReturnBucketApi, obj.productsToReturn, obj.username);
        yield put(postReturnBucketSuccess(response));
    } catch (error) {
        yield put(postReturnBucketFail(error));
    }
}

function* getReturnBucket({ payload: username }) {
    try {
        const response = yield call(getReturnBucketApi, username);
        yield put(returnApiResponseSuccess(GET_RETURN_BUCKET, response));
    } catch (error) {
        yield put(returnApiResponseError(GET_RETURN_BUCKET, error));
    }
}

function* deleteReturnBucketItem({ payload: obj }) {
    try {
        const response = yield call(deleteReturnBucketItemApi, obj.product, obj.returnBucketId);
        yield put(deleteReturnBucketItemSuccess(response));
    } catch (error) {
        yield put(deleteReturnBucketItemFail(error));
    }
}

function* postReturnInvoiceCreate({ payload: obj }) {
    try {
        yield call(postReturnInvoiceCreateApi, obj.username, obj.returnBucketItems, obj.returnTotal, obj.returnQty);
        yield put(postReturnInvoiceCreateSuccess(POST_RETURN_INVOICE_CREATE));
    } catch (error) {
        yield put(postReturnInvoiceCreateFail(POST_RETURN_INVOICE_CREATE, error));
    }
}

function* updateReturnBucketItems({ payload: obj }) {
    try {
        const response = yield call(updateReturnBucketItemsApi, obj.value, obj.product, obj.return_bucket_id, obj.column);
        yield put(updateReturnBucketItemsFail(response));
    } catch (error) {
        yield put(updateReturnBucketItemsFail(error));
    }
}

function* updateReturnBucket({ payload: obj }) {
    try {
        const response = yield call(updateReturnBucketApi, obj.returnBucketId, obj.value, obj.column);
        yield put(updateReturnBucketSuccess(response));
    } catch (error) {
        yield put(updateReturnBucketFail(error))
    }
}

function* downloadReturnInvoice({ payload: returnId }) {
    try {
        const response = yield call(downloadReturnInvoiceApi, returnId);
        yield put(returnApiResponseSuccess(DOWNLOAD_RETURN_INVOICE, response));
    } catch (error) {
        yield put(returnApiResponseError(DOWNLOAD_RETURN_INVOICE, error));
    }
}

function* postReturnInvoiceApproval({ payload: obj}) {
    try {
        const response = yield call(postReturnInvoiceApprovalApi, obj.status, obj.reject_reason, obj.ship_method, obj.admin_fee, obj.returnId);
        yield put(postReturnInvoiceApprovalSuccess(response));
    } catch (error) {
        yield put(postReturnInvoiceApprovalFail(error));
    }
}

function* updateReturnInvoiceItemsWorksheet({ payload: obj }) {
    try {
        const response = yield call(updateReturnInvoiceItemsWorksheetApi, obj.items, obj.extra, obj.returnId);
        yield put(updateReturnInvoiceItemsWorksheetSuccess(response))
    } catch (error) {
        yield put(updateReturnInvoiceItemsWorksheetFail(error))
    }
}

function* updateReturnAdjustmentItemsApproval({ payload: obj }) {
    try {
        yield call(updateReturnAdjustmentItemsApprovalApi, obj.returnAdjustmentItems, obj.status, obj.returnAdjustmentId);
        yield put(updateReturnAdjustmentItemsApprovalSuccess());
    } catch (error) {
        yield put(updateReturnAdjustmentItemsApprovalFail(error));
    }
}

function* updateReturnInvoiceCreditIssue({ payload: obj }) {
    try {
        yield call(updateReturnInvoiceCreditIssueApi, obj.status, obj.returnId);
        yield put(updateReturnInvoiceCreditIssueSuccess());
    } catch (error) {
        yield put(updateReturnInvoiceCreditIssueFail(error));
    }
}

function* downloadReturnBatchTemplate() {
    try {
        const response = yield call(downloadReturnBatchTemplateApi)
        yield put(returnApiResponseSuccess(DOWNLOAD_RETURN_BATCH_TEMPLATE, response));
    } catch (error) {
        yield put(returnApiResponseError(DOWNLOAD_RETURN_BATCH_TEMPLATE, error));
    }
}

export function* watchGetReturns() {
    yield takeEvery(GET_RETURNS, getReturns);
}
export function* watchGetReturnsFiltered() {
    yield takeEvery(GET_RETURNS_FILTERED, getReturnsFiltered);
}

export function* watchGetReturn() {
    yield takeEvery(GET_RETURN, getReturn);
}

export function* watchGetReturnDetails() {
    yield takeEvery(GET_RETURN_DETAILS, getReturnDetails);
}

export function* watchGetReturnDetailsExtra() {
    yield takeEvery(GET_RETURN_DETAILS_EXTRA, getReturnDetailsExtra);
}

export function* watchGetCustomerProducts() {
    yield takeEvery(GET_CUSTOMER_PRODUCTS, getCustomerProducts);
}

export function* watchGetRgrForm() {
    yield takeEvery(GET_RGR_FORM, getRgrForm);
}

export function* watchPostReturnBucket() {
    yield takeEvery(POST_RETURN_BUCKET, postReturnBucket);
}

export function* watchGetReturnBucket() {
    yield takeEvery(GET_RETURN_BUCKET, getReturnBucket);
}

export function* watchGetReturnAdjustments() {
    yield takeEvery(GET_RETURN_ADJUSTMENTS, getReturnAdjustments);
}

export function* watchGetReturnAdjustment() {
    yield takeEvery(GET_RETURN_ADJUSTMENT, getReturnAdjustment);
}

export function* watchGetReturnAdjustmentDetails() {
    yield takeEvery(GET_RETURN_ADJUSTMENT_DETAILS, getReturnAdjustmentDetails);
}

export function* watchDeleteReturnBucketItem() {
    yield takeEvery(DELETE_RETURN_BUCKET_ITEM, deleteReturnBucketItem);
}

export function* watchPostReturnInvoiceCreate() {
    yield takeEvery(POST_RETURN_INVOICE_CREATE, postReturnInvoiceCreate);
}

export function* watchUpdateReturnBucketItems() {
    yield takeEvery(UPDATE_RETURN_BUCKET_ITEMS, updateReturnBucketItems);
}

export function* watchUpdateReturnBucket() {
    yield takeEvery(UPDATE_RETURN_BUCKET, updateReturnBucket);
}

export function* watchDownloadReturnInvoice() {
    yield takeEvery(DOWNLOAD_RETURN_INVOICE, downloadReturnInvoice);
}

export function* watchPostReturnInvoiceApproval() {
    yield takeEvery(POST_RETURN_INVOICE_APPROVAL, postReturnInvoiceApproval);
}

export function* watchUpdateReturnInvoiceItemsWorksheet() {
    yield takeEvery(UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET, updateReturnInvoiceItemsWorksheet);
}

export function* watchUpdateReturnAdjustmentItemsApproval() {
    yield takeEvery(UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL, updateReturnAdjustmentItemsApproval)
}

export function* watchUpdateReturnInvoiceCreditIssue() {
    yield takeEvery(UPDATE_RETURN_INVOICE_CREDIT_ISSUE, updateReturnInvoiceCreditIssue);
}

export function* watchDownloadReturnBatchTemplate() {
    yield takeEvery(DOWNLOAD_RETURN_BATCH_TEMPLATE, downloadReturnBatchTemplate);
}

function* returnsSaga() {
    yield all([
        fork(watchGetReturns),
        fork(watchGetReturnsFiltered),
        fork(watchGetReturn),
        fork(watchGetReturnDetails),
        fork(watchGetReturnDetailsExtra),
        fork(watchGetCustomerProducts),
        fork(watchGetRgrForm),
        fork(watchPostReturnBucket),
        fork(watchGetReturnBucket),
        fork(watchGetReturnAdjustments),
        fork(watchGetReturnAdjustment),
        fork(watchGetReturnAdjustmentDetails),
        fork(watchUpdateReturnBucket),
        fork(watchUpdateReturnBucketItems),
        fork(watchDeleteReturnBucketItem),
        fork(watchPostReturnInvoiceCreate),
        fork(watchDownloadReturnInvoice),
        fork(watchPostReturnInvoiceApproval),
        fork(watchUpdateReturnInvoiceItemsWorksheet),
        fork(watchUpdateReturnAdjustmentItemsApproval),
        fork(watchUpdateReturnInvoiceCreditIssue),
        fork(watchDownloadReturnBatchTemplate),
    ]);
}

export default returnsSaga;