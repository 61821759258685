import { 
    API_RESPONSE_ERROR, 
    API_RESPONSE_SUCCESS, 
    GET_SYSTEM_MESSAGES, 
    GET_SYSTEM_MESSAGES_TODAY, 
    POST_SYSTEM_MESSAGE_FAIL, 
    POST_SYSTEM_MESSAGE_SUCCESS 
} from "./actionType";

const INIT_STATE = {
    systemMessages: [], 
}

const SystemMessages = (state = INIT_STATE, action) => {
    switch(action.type) {
        case API_RESPONSE_SUCCESS:
            switch(action.payload.actionType) {
                case GET_SYSTEM_MESSAGES:
                case GET_SYSTEM_MESSAGES_TODAY:
                    return {
                        ...state,
                        systemMessages: action.payload.data,
                        isPostSystemMessageSuccess: false,
                        isPostSystemMessageFail: false,
                    }
                default:
                    return {
                        ...state
                    }
            }
        case API_RESPONSE_ERROR:
            switch(action.payload.actionType) {
                case GET_SYSTEM_MESSAGES:
                case GET_SYSTEM_MESSAGES_TODAY:
                    return {
                        ...state,
                        error: action.payload.error
                    }
                default:
                    return {
                        ...state
                    }
            }

        case POST_SYSTEM_MESSAGE_SUCCESS:
            return {
                ...state,
                isPostSystemMessageSuccess: true,
                isPostSystemMessageFail: false,
            }

        case POST_SYSTEM_MESSAGE_FAIL:
            return {
                ...state,
                isPostSystemMessageFail: true,
                isPostSystemMessageSuccess: false,
                error: action.payload
            }

        default:
            return { ...state, }
    }
}

export default SystemMessages;