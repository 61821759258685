const provincesOptions = [{ value: 'AB', label: 'AB - Alberta' }, { value: 'BC', label: 'BC - British Columbia' }, { value: 'MB', label: 'MB - Manitoba' }, { value: 'NB', label: 'NB - New Brunswick' }, { value: 'NL', label: 'NL - Newfoundland and Labrador' }, { value: 'NT', label: 'NT - Northwest Territories' }, { value: 'NS', label: 'NS - Nova Soctia' }, { value: 'NU', label: 'NU - Nunavut' }, { value: 'ON', label: 'ON - Ontario' }, { value: 'PE', label: ' PE - Prine Edward Island' }, { value: 'QC', label: 'QC - Quebec' }, { value: 'SK', label: 'SK - Saskatchewan' }, { value: 'YT', label: 'YT - Yukon' }];

const accessOptions = [{ value: 1, label: '1 - Admin' }, { value: 3, label: '3 - Sales Team' }, { value: 5, label: '5 - Customer' }, { value: 7, label: '7 - Accounting' }, { value: 9, label: '9 - Warehouse' }];

const activeOptions = [{ value: 0, label: '0 - Not Active' }, { value: 1, label: '1 - Active' }, { value: 2, label: '2 - Pending' }];

const activeLabels = { 0: '0 - Not Active', 1: '1 - Active', 2: '2 - Pending' }

const clientTypeOptions = [{ value: 1, label: '1 - Jobber' }, { value: 2, label: '2 - Warehouse' }, { value: 3, label: '3 - Garage' }, { value: 4, label: '4 - Supplier' }, { value: 5, label: '5 - Max Subsidiary' }, { value: 6, label: '6 - Max Employee' }];

const creditDaysOptions = [{ value: 1, label: '1 - COD' }, { value: 2, label: '2 - 30 Days' }, { value: 3, label: '3 - 60 Days' }];

const paymentBehaviourOptions = [{ value: 1, label: '1 - Early Pay' }, { value: 2, label: '2 - Mostly early Pay' }, { value: 3, label: '3 - Normal' }, { value: 4, label: '4 - Pay attention' }, { value: 5, label: '5 - Be careful' }];

const advancedPaymentDiscountOptions = [{ value: 1, label: 'Yes (2%)' }, { value: 2, label: 'No' }];

const accountTierOptions = [{ value: 1, label: 'A - Top' }, { value: 2, label: 'B - Major' }, { value: 3, label: 'C - Average' }, { value: 4, label: 'D - Minor' }, { value: 5, label: 'E - Inactive' }, { value: 6, label: 'N - New' }, { value: 7, label: 'P - Prospect' }, { value: 8, label: 'I - Internal' }];

const priceListOptions = [{ value: 'net_price', label: 'net_price' }, { value: 'accel_price', label: 'accel_price' }];

const rebateProgramOptions = [{ value: 1, label: '1 - no rebate' }, { value: 2, label: '2 - quarterly' }, { value: 3, label: '3 - semiannually' }, { value: 4, label: '4 - annually' }];

const accessLabels = { 1: 'Admin', 3: 'Sales Team', 5: 'Customer', 7: 'Accounting', 9: 'Warehouse', 0: 'Unknown' };

const deliveryOptions = [{ value: 1, label: '1 - Pick up or deliver by max truck' }, { value: 2, label: '2 - Deliver by forwarder' }];

const defaultShipMethodOptions = [{ value: 1, label: '1 - Shipping' }, { value: 2, label: '2 - Pickup' }];

const numOfDaysToShippedDateOptions = [{ value: 0, label: '0 Day' }, { value: 1, label: '1 Day' }, { value: 2, label: '2 Days' }, { value: 3, label: '3 Days' }];

export { provincesOptions, accessOptions, activeOptions, clientTypeOptions, creditDaysOptions, paymentBehaviourOptions, advancedPaymentDiscountOptions, accountTierOptions, priceListOptions, rebateProgramOptions, accessLabels, activeLabels, deliveryOptions, defaultShipMethodOptions, numOfDaysToShippedDateOptions };