import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, Input, Row, Table } from 'reactstrap';

import TableRows from '../../../../Components/Products/Interchange/TableRow';
import Loader from '../../../../Components/Common/Loader';

// Formik validation
import * as Yup from "yup";
import { useFormik } from 'formik';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// actions
import { 
    getInterchange as onGetInterchange
} from '../../../../store/actions';

const Interchange = () => {
    const dispatch = useDispatch();
    const [productsArr, setProductsArr] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isQuerySubmit, setIsQuerySubmit] = useState(false);
    
    const { products, error } = useSelector(state => ({
        products: state.Interchange.products,
        error: state.Interchange.error,
    }));

    useEffect(() => {
        dispatch({ type: 'RESET_APP' });
    }, [dispatch])

    useEffect(() => {
        if (!products || !products.length || !validation.values.query) {
            setProductsArr([]);
            setIsQuerySubmit(true);
            setIsLoading(false);
            return;
        }

        let interchangeProducts = products.flatMap(item => {
            // Get the max num
            let temp = item.Max; 

            let tempQuery = validation.values.query;
            if (validation.values.method === "begins") tempQuery = `^${tempQuery}`; 
            else if (validation.values.method === "exact") tempQuery = `^${tempQuery}$`; 
            else if (validation.values.method === "ends") tempQuery = `${tempQuery}$`;
            else tempQuery = `${tempQuery}*`;

            return Object.entries(item).map(([key, values]) => {
                let queryText = null;
                if (values !== null && values.match(tempQuery)) queryText = values;

                // make object that shows query, brand name, max num
                return { query: queryText, brand_name: key, maxnum: temp };
            }).filter((item) => item.query !== null)
        })
        
        setProductsArr(interchangeProducts);
        setIsQuerySubmit(true);
        setIsLoading(false);
    }, [products])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            query: '',
            method: 'contains',
        },
        validationSchema: Yup.object({
            query: Yup.string().required("Please enter a product code"),
            method: Yup.string().required("Please select a method"),
        }),
        onSubmit: (values) => {
            setIsLoading(true);
            setIsQuerySubmit(false);
            setProductsArr([]);
            dispatch(onGetInterchange(values));
        }
    })

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />

    document.title = "Interchange | Max Advanced Brakes - Prozone";

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Products" pageTitle="Interchange" linkTo={`/interchange`} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Form className="mb-3"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col xxl={3} md={6}>
                                                <div>
                                                    <Input type="text" className="form-control" id="query" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.query} />
                                                </div>
                                            </Col>
                                            <Col lg={3} >
                                                <select className="form-select mb-3" id="method" aria-label="Default select" value={validation.values.method} onChange={validation.handleChange}>
                                                    <option value="contains">Contains</option>
                                                    <option value="exact">Exact</option>
                                                    <option value="begins">Begins with</option>
                                                    <option value="ends">Ends with</option>
                                                </select>
                                            </Col>
                                            <Col>
                                                <Button color="max" className="btn btn-max" type="submit">Search</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    {(isLoading) ? (<Loader />) : (null)}
                                    {(productsArr && productsArr.length) ? (
                                        <div className="table-responsive">
                                            <Table className="align-middle table-striped table-bordered table-nowrap mb-0">
                                                <thead className="table-dark">
                                                    <tr>
                                                        <th>Part Number</th>
                                                        <th>Brand</th>
                                                        <th>Max Number</th>
                                                        <th>Part Type</th>
                                                        <th>Description</th>
                                                        <th>Price</th>
                                                        <th>Availability</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {productsArr.map((item, index) => <TableRows key={index} item={item} index={index} />)}
                                                </tbody>
                                            </Table>
                                        </div>
                                    ) : (null)}
                                    {(isQuerySubmit && !productsArr.length) ? (<p>Query {validation.values.query} was not found</p>) : (null)}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Interchange;