const order = { "ONZ": 1, "OCG": 2, "SCB": 3, "SNZ": 4, "CNZ": 5, "CDM": 6, "CDP": 7, "AFN": 8,"TSU":9 ,"MD": 10, "Other": 11 };

const pattern = /ONZ|OCG|SCB|SNZ|CNZ|CDM|CDP|AFN|MD|TSU|^8100|^9100|^9200/;

const maxPattern = /^ONZ|^OCG|^SCB|^SNZ|^CNZ|^CDM|^CDP|^AFN|^TSU|^MD|^8100|^9100|^9200/;

const productPatternRotor = /ONZ|OCG|SCB|SNZ|CNZ/;

const invoiceDetailsPattern = /^(ONZ|OCG|SCB|SNZ|CNZ|CDM|CDP|AFN|MD|TSU)/i;

const batchReturnPattern = /ONZ|OCG|SNZ|SCB|AFN|CDM|CDP|MD|TSU|CNZ|8100|9100|CASTROLE|CASTROLG|EVER|TIGER|MOBI1|BFD|LAURENTIDE|KLEENFLO|CERTIFIED|ABSORBENT|ACDELCO|DMGLOVES-M|DMGLOVES-L|ROTORCLOCK|EMZONE|9100001|9100002|9100005|9100006|9100007|9200/i;

export {
    order,
    pattern,
    maxPattern,
    invoiceDetailsPattern,
    batchReturnPattern,
    productPatternRotor,
}