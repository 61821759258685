import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import { CardBody, Row, Col, Card, Table, CardHeader, Container } from "reactstrap";

import { useProfile } from "../../../../Components/Hooks/UserHooks";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import StatementDetailsAccountingPayment from "../../../../Components/History/Statements/StatementDetailsAccountingPayment";

import { 
    getStatement as onGetStatement,
    getCustomerInformation as onGetCustomerInformation
} from "../../../../store/actions";

import logoDark from "../../../../assets/images/BLACKLOGO1.png";

const StatementDetails = () => {
    let location = useLocation();
    const dispatch = useDispatch();
    const { userProfile } = useProfile();
    const [statementOrders, setStatementOrders] = useState([]);
    const [statementMultiTermOrders, setStatementMultiTermOrders] = useState([]);

    const { statement, isStatementSuccess, isStatementPaymentSuccess, isStatementPaymentFail, userInfo, isUserInfoSuccess, statementError, customerError } = useSelector((state) => ({
        statement: state.Statements.statement,
        isStatementSuccess: state.Statements.isStatementSuccess,
        isStatementPaymentSuccess: state.Statements.isStatementPaymentSuccess,
        isStatementPaymentFail: state.Statements.isStatementPaymentFail,
        userInfo: state.Customer.userInfo,
        isUserInfoSuccess: state.Customer.isUserInfoSuccess,
        statementError: state.Statements.error,
        customerError: state.Customer.error,
    }));

    useEffect(() => {
        let statement_id = location.pathname.split('/')[3];
        dispatch(onGetStatement(statement_id));
    }, [dispatch, location])

    useEffect(() => {
        if (isStatementPaymentSuccess) {
            alert('Payment recorded');
            let statement_id = location.pathname.split('/')[3];
            dispatch(onGetStatement(statement_id));
        }

        if (isStatementPaymentFail) alert('Failed to record payment');
    }, [dispatch, location, isStatementPaymentSuccess, isStatementPaymentFail])

    useEffect(() => {
        let tempStatementMultiTermOrders = statement.orders?.filter((item) => item.otype === 'M');
        let tempStatementOrders = statement.orders?.filter((item) => item.otype !== 'M');

        setStatementMultiTermOrders(tempStatementMultiTermOrders);
        setStatementOrders(tempStatementOrders);
    }, [statement])

    useEffect(() => {
        if (statement && statement.statement) dispatch(onGetCustomerInformation(statement.statement[0].username));
    }, [dispatch, statement])

    //Print the Invoice
    const printInvoice = () => {
        window.print();
    };

    if ((typeof statementError === 'string' && statementError.match("401")) || (typeof customerError === 'string' && customerError.match("401"))) return <Redirect to="/login" />
    if (!userProfile.permissions.view_statements) return <Redirect to="/not-authorized" />

    document.title = `${location.pathname.split('/')[3]} | Statement | Max Advanced Brakes - Prozone`;
    
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Statement Details" pageTitle="Statement" linkTo={`/statements`} />

                <Row className="justify-content-center">
                    <Col>
                        <Card id="demo">
                            <CardHeader className="border-bottom-dashed p-4 border-dark">
                                {(isStatementSuccess && statement && Object.keys(statement).length !== 0) ? (
                                    <div className="d-sm-flex">
                                        <div className="flex-grow-1">
                                            <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="110" />
                                            <img src={logoDark} className="card-logo card-logo-light" alt="logo light" height="17" />
                                        </div>
                                        <div className="flex-shrink-0 mt-sm-0 mt-3">
                                            <h6>
                                                <span className="text-muted fw-normal"> Statement No: </span>{" "}
                                                <span id="legal-register-no">{statement.statement[0].statement_id}</span>
                                            </h6>
                                            <h6>
                                                <span className="text-muted fw-normal">Website:</span>{" "}
                                                <Link to="#" className="link-primary"> www.maxbrakes.com/prozone </Link>
                                            </h6>
                                            <h6 className="mb-0">
                                                <span className="text-muted fw-normal">Contact No:</span>{" "}
                                                <span id="contact-no"> 1-888-835-2399 ext 124</span>
                                            </h6>
                                        </div>
                                    </div>
                                ) : (null)}
                            </CardHeader>
                            <CardBody className="p-4">
                                {(isStatementSuccess && statement && statement.statement) ? (    
                                    <Row className="g-3">
                                        <Col lg={3} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold"> Statement No </p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-no">{statement.statement[0].statement_id}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={3} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold"> Date </p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-date">{statement.statement[0].date_created.slice(0, 10)}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={3} xs={6}></Col>
                                        <Col lg={3} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold"> Total Amount </p>
                                            <h5 className="fs-14 mb-0">$<span id="total-amount">{statement.statement[0].balance}</span></h5>
                                        </Col>
                                    </Row>
                                ) : (null)}
                            </CardBody>
                            <CardBody className="p-4 border-top border-top-dashed border-dark">
                                <Row className="g-3">
                                    <Col sm={6}>
                                        {(isUserInfoSuccess && Object.keys(userInfo).length !== 0) ? (
                                            <>
                                                <h6 className="text-muted text-uppercase fw-semibold mb-3"> Shipping Address </h6>
                                                <p className="fw-medium mb-2" id="shipping-name">{userInfo.company_name}</p>
                                                <p className="text-muted mb-1">{userInfo.address}, {userInfo.city}</p>
                                                <p className="text-muted mb-1"> {userInfo.state}, {userInfo.country} - {userInfo.zip} </p>
                                                <p className="text-muted mb-1"><span>Phone: </span><span id="shipping-phone-no">{userInfo.phone_primary}</span></p>
                                            </>
                                        ) : (null)}
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardBody className="p-4">
                                {(statementOrders && statementOrders.length) ? (
                                    <div className="table-responsive">
                                        <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                                            <thead>
                                                <tr className="table-active">
                                                    <th scope="col" style={{ width: "50px" }}>#</th>
                                                    <th scope="col">Date Shipped</th>
                                                    <th scope="col">Code</th>
                                                    <th scope="col">Invoice/Credit#</th>
                                                    <th scope="col">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    {statementOrders.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>
                                                                    <span className="fw-medium"> {(item.date) ? (item.date.slice(0, 10)) : (null)} </span>
                                                                    <p className="text-muted mb-0"> </p>
                                                                </td>
                                                                <td>
                                                                    <span className="fw-medium"> {item.otype} </span>
                                                                    <p className="text-muted mb-0"> </p>
                                                                </td>
                                                                <td>
                                                                    <span className="fw-medium"> {item.invoiceid} </span>
                                                                    <p className="text-muted mb-0"> </p>
                                                                </td>
                                                                <td>${item.ototal}</td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th className="fw-medium text-end" colSpan={4}>Total</th>
                                                    <td>${statement.statement[0].balance}</td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                ): (null)}
                                <Row className="mt-3">
                                    <Col>
                                        {(statement && statement.statement) ? (
                                            <Table>
                                                <tbody>
                                                    {(userProfile.access === 7 || userProfile.access === 1) ? (
                                                        <>
                                                            <tr>
                                                                <th className="border-0">10 Days 2% Eligible Total:</th>
                                                                <td className="border-0">${(statement.statement[0].eligible_total !== null) ? (statement.statement[0].eligible_total) : (0.00)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>10 Days 2% Excluded Total:</th>
                                                                <td>${(statement.statement[0].excluded_total !== null) ? (statement.statement[0].excluded_total) : (0.00)}</td>
                                                            </tr>
                                                        </>
                                                    ) : (null)}
                                                    <tr className={`${(userProfile.access !== 7 && userProfile.access !== 1) ? (`mt-5_7`) : (null)}`}>
                                                        <th className="border-0">Pay Within 10 Days Balance:</th>
                                                        <td className="border-0">${statement.statement[0].balance_discounted}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        ) : (null)}
                                    </Col>
                                    <Col>
                                        {(statement && statement.statement) ? (
                                            <Table className={`${(userProfile.access !== 7 && userProfile.access !== 1) ? (null) : (`mt-5_7`)}`}>
                                                <tbody>
                                                    <tr>
                                                        <th className={`border-bottom-0 ${(userProfile.access !== 7 && userProfile.access !== 1) ? (null) : ("border-top")}`}>Statement Balance:</th>
                                                        <td className={`border-bottom-0 ${(userProfile.access !== 7 && userProfile.access !== 1) ? (null) : ("border-top")}`}>${statement.statement[0].balance}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        ) : (null)}
                                    </Col>
                                </Row>
                                {(statementMultiTermOrders && statementMultiTermOrders.length) ? (
                                    <>
                                        <div className="table-responsive mt-5">
                                            <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                                                <thead>
                                                    <tr className="table-active">
                                                        <th scope="col" style={{ width: "50px" }}>#</th>
                                                        <th scope="col">Date Shipped</th>
                                                        <th scope="col">Code</th>
                                                        <th scope="col">Invoice/Credit#</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Remaining</th>
                                                        <th scope="col">Due Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {statementMultiTermOrders.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>
                                                                    <span className="fw-medium"> {(item.date) ? (item.date.slice(0, 10)) : (null)} </span>
                                                                    <p className="text-muted mb-0"> </p>
                                                                </td>
                                                                <td>
                                                                    <span className="fw-medium"> {item.otype} </span>
                                                                    <p className="text-muted mb-0"> </p>
                                                                </td>
                                                                <td>
                                                                    <span className="fw-medium"> {item.invoiceid} </span>
                                                                    <p className="text-muted mb-0"> </p>
                                                                </td>
                                                                <td>${item.ototal}</td>
                                                                <td>${(item.multi_term_remaining) ? (Number(item.multi_term_remaining).toFixed(2)) : ((0.00).toFixed(2))}</td>
                                                                <td>{(item.pay_by_date) ? (item.pay_by_date.slice(0, 10)) : ('')}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th className="fw-medium text-end" colSpan={5}>Total</th>
                                                        <td>${statement.statement[0].multi_term_orders_total}</td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </div>
                                        <Row className="mt-5">
                                            <Col></Col>
                                            <Col>
                                                <Table>
                                                    <tbody>
                                                        <tr>
                                                            <th className="border-bottom-0">Statement Balance:</th>
                                                            <td className="border-bottom-0">${statement.statement[0].balance}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Multi Term Orders Total:</th>
                                                            <td>${statement.statement[0].multi_term_orders_total}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="border-bottom-0">Balance with Multi Term Order:</th>
                                                            <td className="border-bottom-0">${statement.statement[0].balance_with_multi_term_orders}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </>
                                ): (null)}
                                {((userProfile.access === 1 || userProfile.access === 7) && userProfile.permissions.statement_payment && statement && statement.statement) ? (
                                    <StatementDetailsAccountingPayment statement={statement.statement[0]} />
                                ) : (null)}
                                <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                    {/* <Link to="#" onClick={printInvoice} className="btn btn-soft-primary" >
                                        <i className="ri-printer-line align-bottom me-1"></i> Print
                                    </Link>
                                    <Link to="#" className="btn btn-primary">
                                        <i className="ri-download-2-line align-bottom me-1"></i>{" "} Download
                                    </Link> */}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default StatementDetails;
