import { 
    API_RESPONSE_ERROR, 
    API_RESPONSE_SUCCESS, 
    GET_ALLPRICES_DATA,
    POST_GLOBAL_PRICES,
    GET_USERS,
    POST_GLOBAL_MAX,
    POST_GLOBAL_DISCOUNT,
    GET_MONTH_TEST,
    GET_SALES_REPRESENTATIVES,
    POST_SALES_REPRESENTATIVE,
    POST_SALES_REPRESENTATIVE_SUCCESS,
    POST_SALES_REPRESENTATIVE_FAIL,
    DELETE_SALES_REPRESENTATIVE,
    DELETE_SALES_REPRESENTATIVE_SUCCESS,
    DELETE_SALES_REPRESENTATIVE_FAIL,
    UPDATE_SALES_REPRESENTATIVE,
    UPDATE_SALES_REPRESENTATIVE_SUCCESS,
    UPDATE_SALES_REPRESENTATIVE_FAIL,
    GET_MONTH_TEST_MONTH_YEAR,
    GET_STATEMENT_OVERDUE_SUMMARY,
} from "./actionType";

// common success
export const globalSettingsApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const globalSettingsApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getGlobalPrices = () => ({
    type: GET_ALLPRICES_DATA
});

export const updateGlobalPrices = (data) => ({
    type: POST_GLOBAL_PRICES,
    payload: {data}
});

export const getUsers = () => ({
    type: GET_USERS,
});

export const getSalesRepresentatives = () => ({
    type: GET_SALES_REPRESENTATIVES
});

export const postGlobalMax = (globalParam) => ({
    type: POST_GLOBAL_MAX,
    payload: globalParam,
});

export const postGlobalDiscount = (globalParam) => ({
    type: POST_GLOBAL_DISCOUNT,
    payload: globalParam,
});

export const postSalesRepresentative = (username) => ({
    type: POST_SALES_REPRESENTATIVE,
    payload: username
});

export const postSalesRepresentativeSuccess = () => ({
    type: POST_SALES_REPRESENTATIVE_SUCCESS,
});

export const postSalesRepresentativeFail = (error) => ({
    type: POST_SALES_REPRESENTATIVE_FAIL,
    payload: error
});

export const deleteSalesPresentative = (id) => ({
    type: DELETE_SALES_REPRESENTATIVE,
    payload: id
});

export const deleteSalesPresentativeSuccess = () => ({
    type: DELETE_SALES_REPRESENTATIVE_SUCCESS,
});

export const deleteSalesPresentativeFail = (error) => ({
    type: DELETE_SALES_REPRESENTATIVE_FAIL,
    payload: error
});

export const updateSalesRepresentative = (replaceRep, newRep) => ({
    type: UPDATE_SALES_REPRESENTATIVE,
    payload: { replaceRep, newRep }
});

export const updateSalesRepresentativeSuccess = () => ({
    type: UPDATE_SALES_REPRESENTATIVE_SUCCESS,
});

export const updateSalesRepresentativeFail = (error) => ({
    type: UPDATE_SALES_REPRESENTATIVE_FAIL,
    payload: error
});

export const getMonthTest = () => ({
    type: GET_MONTH_TEST
});

export const getMonthTestMonthYear = (monthYear) => ({
    type: GET_MONTH_TEST_MONTH_YEAR,
    payload: monthYear
});

export const getStatementOverdueSummary = () => ({
    type: GET_STATEMENT_OVERDUE_SUMMARY
})