// common
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const RESET_APP = "RESET_APP";

// Get Customers
export const GET_CUSTOMERS = "GET_CUSTOMERS";

// Get User Information
export const GET_CUSTOMER_INFO = "GET_CUSTOMER_INFO";

// Get Customer User Group
export const GET_CUSTOMER_USER_GROUP = "GET_CUSTOMER_USER_GROUP"

// Get Customer Product Exclusion
export const GET_CUSTOMER_PRODUCT_EXCLUSION = "GET_CUSTOMER_PRODUCT_EXCLUSION";

// Get user groups
export const GET_USER_GROUPS = "GET_USER_GROUPS";

// Get Update User credit balances
export const GET_UPDATE_USER_CREDIT_BALANCES = "UPDATE_USER_CREDIT_BALANCES";

// Get Product Groups
export const GET_PRODUCT_GROUPS = "GET_PRODUCT_GROUPS";

// Get Users Product Groups 
export const GET_USERS_PRODUCT_GROUPS = "GET_USERS_PRODUCT_GROUPS";

// Update User Information
export const UPDATE_CUSTOMER_INFO = "UPDATE_CUSTOMER_INFO";
export const UPDATE_CUSTOMER_INFO_SUCCESS = "UPDATE_CUSTOMER_INFO_SUCCESS";
export const UPDATE_CUSTOMER_INFO_FAIL = "UPDATE_CUSTOMER_INFO_FAIL";

// Delete Customer
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL";

// Post New Customer Exclusion
export const POST_NEW_CUSTOMER_EXCLUSION = "POST_NEW_CUSTOMER_EXCLUSION";
export const POST_NEW_CUSTOMER_EXCLUSION_SUCCESS = "POST_NEW_CUSTOMER_EXCLUSION_SUCCESS";
export const POST_NEW_CUSTOMER_EXCLUSION_FAIL = "POST_NEW_CUSTOMER_EXCLUSION_FAIL";

// Delete customer exclusion
export const DELETE_CUSTOMER_EXCLUSION = "DELETE_CUSTOMER_EXCLUSION";
export const DELETE_CUSTOMER_EXCLUSION_SUCCESS = "DELETE_CUSTOMER_EXCLUSION_SUCCESS";
export const DELETE_CUSTOMER_EXCLUSION_FAIL = "DELETE_CUSTOMER_EXCLUSION_FAIL";

// Update user group
export const UPDATE_USER_GROUP = "UPDATE_USER_GROUP";
export const UPDATE_USER_GROUP_SUCCESS = "UPDATE_USER_GROUP_SUCCESS";
export const UPDATE_USER_GROUP_FAIL = "UPDATE_USER_GROUP_FAIL";

// Post new customer
export const POST_NEW_CUSTOMER = "POST_NEW_CUSTOMER";
export const POST_NEW_CUSTOMER_SUCCESS = "POST_NEW_CUSTOMER_SUCCESS";
export const POST_NEW_CUSTOMER_FAIL = "POST_NEW_CUSTOMER_FAIL";

// Post user password reset
export const POST_USER_PASSWORD_RESET = "POST_USER_PASSWORD_RESET";
export const POST_USER_PASSWORD_RESET_SUCCESS = "POST_USER_PASSWORD_RESET_SUCCESS";
export const POST_USER_PASSWORD_RESET_FAIL = "POST_USER_PASSWORD_RESET_FAIL";

// Post New Product Group to user
export const POST_NEW_PRODUCT_GROUP_TO_USER = "POST_NEW_PRODUCT_GROUP_TO_USER";
export const POST_NEW_PRODUCT_GROUP_TO_USER_SUCCESS = "POST_NEW_PRODUCT_GROUP_TO_USER_SUCCESS";
export const POST_NEW_PRODUCT_GROUP_TO_USER_FAIL = "POST_NEW_PRODUCT_GROUP_TO_USER_FAIL";

// Delete Product Group from user
export const DELETE_PRODUCT_GROUP_FROM_USER = "DELETE_PRODUCT_GROUP_FROM_USER";
export const DELETE_PRODUCT_GROUP_FROM_USER_SUCCESS = "DELETE_PRODUCT_GROUP_FROM_USER_SUCCESS";
export const DELETE_PRODUCT_GROUP_FROM_USER_FAIL = "DELETE_PRODUCT_GROUP_FROM_USER_FAIL";