import { 
    API_RESPONSE_ERROR, 
    API_RESPONSE_SUCCESS, 
    GET_INTERCHANGE
} from "./actionType";

const INIT_STATE = {
    products: [],
    error: {}
}

const Interchange = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_INTERCHANGE:
                    return {
                        ...state,
                        products: action.payload.data,
                    };
                default:
                    return { ...state };
            }
        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_INTERCHANGE:
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
}

export default Interchange;