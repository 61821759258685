import { Input, ListGroupItem } from "reactstrap";

function AdminListGroupItemDiscount({ field, fieldValue, accountInfo, setAccountInfo, disabled }) {
    return (
        <ListGroupItem>
            <span className="font-size-18">[{field}: {fieldValue}]</span>
            <br /><br />
            <Input bsSize="sm" type="number" value={accountInfo[field]} onChange={(e) => setAccountInfo({ ...accountInfo, [field]: e.target.value })} disabled={disabled} />
        </ListGroupItem>
    )
}

export default AdminListGroupItemDiscount;