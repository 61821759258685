import { useEffect, useState } from "react";
import { Card, CardBody, Table } from "reactstrap";
import Select from 'react-select';

import BasicInfoTableRow from "./BasicInfoTableRow";
import { provincesOptions } from "../constants/accountDetails";
import { countriesOptions } from "../constants/administratorDetails";

function BasicInfoAccountPanel({ accountInfo, setAccountInfo, userInfo, salesRepresentatives }) {
    const [salesRepList, setSalesRepList] = useState([{ value: 0, label: 'No Users' }]);

    useEffect(() => {
        let tempSalesRepList = [];
        let formattedSalesRepresentatives = salesRepresentatives;

        if (!formattedSalesRepresentatives || !formattedSalesRepresentatives.length) {
            setSalesRepList([{ value: 0, label: 'No Users' }]);
            return;
        }

        formattedSalesRepresentatives.forEach((item) => tempSalesRepList.push({ value: item.id, label: `${item.id} - ${item.sales_firstname} ${item.sales_lastname}` }));

        setSalesRepList(tempSalesRepList);
    }, [salesRepresentatives])

    return (
        <Card className="mt-3">
            <CardBody>
                <Table className="text-center" striped bordered>
                    <thead>
                        <tr>
                            <th>Field</th>
                            <th>Field Value</th>
                            <th>Edit Field Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Username</th>
                            <td>{userInfo.username}</td>
                            <td>{accountInfo.username}</td>
                        </tr>
                        <BasicInfoTableRow field={'First Name'} fieldValue={userInfo.firstname} property={'firstname'} type={'text'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        <BasicInfoTableRow field={'Last Name'} fieldValue={userInfo.lastname} property={'lastname'} type={'text'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        <BasicInfoTableRow field={'Company Name'} fieldValue={userInfo.company_name} property={'company_name'} type={'text'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        <BasicInfoTableRow field={'Company Group'} fieldValue={userInfo.companygroup} property={'companygroup'} type={'text'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        <BasicInfoTableRow field={'Email Primary'} fieldValue={userInfo.email} type={'email'} property={'email'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        <BasicInfoTableRow field={'Email Returns'} fieldValue={userInfo.email_secondary} type={'email'} property={'email_secondary'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        <BasicInfoTableRow field={'Email Statement'} fieldValue={userInfo.email_tertiary} type={'email'} property={'email_tertiary'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        <BasicInfoTableRow field={'Phone Number 1'} fieldValue={userInfo.phone_primary} property={'phone_primary'} type={'tel'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        <BasicInfoTableRow field={'Phone Number 2'} fieldValue={userInfo.phone_secondary} property={'phone_secondary'} type={'tel'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        <BasicInfoTableRow field={'Phone Number 3'} fieldValue={userInfo.phone_tertiary} property={'phone_tertiary'} type={'tel'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        <BasicInfoTableRow field={'Fax'} fieldValue={userInfo.fax} property={'fax'} type={'text'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        <BasicInfoTableRow field={'Address'} fieldValue={userInfo.address} property={'address'} type={'text'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        <BasicInfoTableRow field={'City'} fieldValue={userInfo.city} property={'city'} type={'text'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        <tr>
                            <td>State/Province</td>
                            <td>{provincesOptions.filter((item) => item.value === userInfo.state)[0]?.label}</td>
                            <td>
                                <Select 
                                    options={provincesOptions}
                                    value={provincesOptions.filter((item) => item.value === accountInfo.state)}
                                    onChange={(e) => setAccountInfo({ ...accountInfo, state: e.value })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Country</td>
                            <td>{countriesOptions.filter((item) => item.value === userInfo.country)[0]?.label}</td>
                            <td>
                                <Select 
                                    options={countriesOptions}
                                    value={countriesOptions.filter((item) => item.value === accountInfo.country)}
                                    onChange={(e) => setAccountInfo({ ...accountInfo, country: e.value })}
                                />
                            </td>
                        </tr>
                        <BasicInfoTableRow field={'Postal Code'} fieldValue={userInfo.zip} property={'zip'} type={'text'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        <tr>
                            <td>{'Sales Representative'}</td>
                            <td>{salesRepList.filter((item) => item.value === userInfo.sales_representative_id)[0]?.label}</td>
                            <td>
                                <Select
                                    isClearable={true}
                                    options={salesRepList.filter((item) => item.value !== userInfo.sales_representative_id_secondary)}
                                    value={salesRepList.filter((item) => item.value === accountInfo.sales_representative_id)}
                                    onChange={(e) => setAccountInfo({ ...accountInfo, sales_representative_id: (e) ? (e.value) : (null) }) } />
                            </td>
                        </tr>
                        <tr>
                            <td>{'Sales Representative Secondary'}</td>
                            <td>{salesRepList.filter((item) => item.value === userInfo.sales_representative_id_secondary)[0]?.label}</td>
                            <td>
                                <Select
                                    isClearable={true}
                                    options={salesRepList.filter((item) => item.value !== userInfo.sales_representative_id)}
                                    value={salesRepList.filter((item) => item.value === accountInfo.sales_representative_id_secondary)} 
                                    onChange={(e) => setAccountInfo({ ...accountInfo, sales_representative_id_secondary: (e) ? (e.value) : (null) }) } />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    )
}

export default BasicInfoAccountPanel;