import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Input, Row, Spinner, Table } from "reactstrap";
import * as XLSX from "xlsx";

import { useUserRowQuantity } from "../Hooks/ProductRowQuantity";
import OrderDraftTableRow from "./OrderDraftTableRow";
import ProperDraftTableRow from "./ProperDraftTableRow";
import { downloadFiles } from "../Common/downloadFiles";
import { toast, ToastContainer } from 'react-toastify';

import {
    deleteDraftItems as onDeleteDraftItems,
    downloadDraftTemplate as onDownnloadDraftTemplate,
    onBackEmptyProducts,
    postProductAndPriceArray as onPostProductAndPriceArray,
} from "../../store/actions";


function ProperDraftTable({ draftRowItems, username, API, draftid, draftProductArr, setDraftRowItems, drafthash, draftTotal, setDraftTotal, setDraftProductArr, previewOrderButtonRef, setInvalidSCBAndCNGRows }) {
    const [rows, setRows] = useState([]);

    const dispatch = useDispatch();
    const { productRowQuantity } = useUserRowQuantity();
    const [tableKey, setTableKey] = useState(0);
    const [numberOfRows, setNumberOfRows] = useState(10);
    const [currentRow, setCurrentRow] = useState(0);
    const [rowsTotal, setRowsTotal] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    // const [draftTotal, setDraftTotal] = useState(0);


    const { rowLogId, isDraftTemplateDownloaded, draftTemplate } = useSelector((state) => ({
        rowLogId: state.Products.rowLogId,
        isDraftTemplateDownloaded: state.Drafts.isDraftTemplateDownloaded,
        draftTemplate: state.Drafts.draftTemplate
    }));

    useEffect(() => {
        if (draftRowItems.length > 10 && numberOfRows <= 10) setNumberOfRows(draftRowItems.length);
        let tmprows = [];
        let total = 0.0;
        let preview = false;
        for (let i = 0; i < numberOfRows; i++) {
            if (draftRowItems[i]) {
                tmprows[i] = draftRowItems[i];
                if (draftRowItems[i].total) {
                    total += Number(draftRowItems[i].total);
                }
                if (draftRowItems[i].bulk) {
                    preview = true;
                }
            } else
                tmprows[i] = null;
        }
        setRows(tmprows);
        setDraftTotal(total);
        // console.log(rows, draftRowItems);
        previewOrderButtonRef.current.disabled = preview;
    }, [draftRowItems, numberOfRows])

    useEffect(() => {
        setIsLoading(false);
    }, [draftProductArr])

    useEffect(() => {
        if (isDraftTemplateDownloaded) {
            downloadFiles(draftTemplate, `order-template.xlsx`, 'application/json');
        }
    }, [draftTemplate, isDraftTemplateDownloaded])

    function handleDownloadDraftTempate() {
        dispatch(onDownnloadDraftTemplate());
    }
    function removedup(rowId, itemarr) {
        console.log(rowId, draftRowItems, itemarr);
        let tempProducts = [];
        for (let it of itemarr) {
            let found = false;
            let c = 0;
            if (Number(it.q) < 1) {
                continue;
            }
            for (let i of draftRowItems) {
                if (c === rowId) {
                    continue;
                }
                if (i.product === it.n) {
                    found = true;
                    break;
                }
                c++;
            }
            if (!found) {
                tempProducts.push(it);
            }
        }
        // for(let i=tempProducts.length; i>0;i--){

        // }

        setDraftProductArr(prevState => {
            if (!prevState) {
                return [tempProducts];
            }
            const newState = [...prevState];
            newState[rowId] = tempProducts;
            return newState;
        });
        // return out;
    }

    function handleFileUpload(e) {
        let file = e.target.files[0];
        let filetype = 'other';

        if (!file) return;
        if (file.name.toLowerCase().endsWith('xlsx')) filetype = 'xlsx';

        let reader = new FileReader();
        reader.onload = function (evt) {
            let data = evt.target.result;
            let parsed = '';
            if (filetype === 'xlsx') {
                let workbook = XLSX.read(data, { type: 'binary' });
                parsed = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
            } else {
                parsed = data.trim().split("\n");
                for (let i in parsed) {
                    parsed[i] = parsed[i].split(",");
                }

                for (let i = 1; i < parsed.length; i++) {
                    parsed[i][1] = Number(parsed[i][1]);
                }
            }

            handleParsedUploadFile(parsed);
        }

        if (filetype === 'xlsx') reader.readAsBinaryString(file);
        else reader.readAsText(file);
    }

    function handleParsedUploadFile(parsedFileItems) {
        if (!parsedFileItems && parsedFileItems.length !== 0) return;
        let tempDraftRowItems = draftRowItems;
        for (let fileItem in parsedFileItems) {
            if (fileItem === 0 || fileItem === '0') continue;
            let tempRowItem = { pcode: parsedFileItems[fileItem][0], product: parsedFileItems[fileItem][0], max_product: parsedFileItems[fileItem][0], qty: parsedFileItems[fileItem][1], bulk: true };
            tempDraftRowItems.push(tempRowItem);
        }

        tempDraftRowItems = tempDraftRowItems.filter((value, index, self) => value && value.product && value.max_product && value.qty && index === self.findIndex((t) => t.product === value.product));

        if (tempDraftRowItems.length > 10) {
            setNumberOfRows(tempDraftRowItems.length);
        }

        let uploadProductArr = tempDraftRowItems.map((item) => item.product)
        let tempUsername = '';
        if (username && username.value) tempUsername = username.value;
        // dispatch(onPostProductAndPriceArray(uploadProductArr, tempUsername, drafthash))
        setDraftRowItems(tempDraftRowItems);
        // setIsLoading(true);
    }

    function handleRowTotal(rowId, rowTotal) {
        setRowsTotal(prevState => ({ ...prevState, [rowId]: Number(rowTotal) }));
    }

    function handleButtonClear() {
        dispatch(onDeleteDraftItems(drafthash));
        dispatch(onBackEmptyProducts());
        let tmp = [];
        for (let i = 0; i < 10; i++) tmp.push({ pcode: '' });
        setDraftRowItems(tmp);
        // setDraftProductArr([]);
        setNumberOfRows(10);
        // setUsername(null);
        setRowsTotal({});
        setDraftTotal(0.00);
        setTableKey(i => i + 1);
    }
    function changerow(rowId, newrow, updateprice = false) {
        console.log(newrow);
        if (updateprice) {
            setDraftRowItems(prevState => {
                if (rowId >= prevState.length) {
                    return [...prevState, newrow];
                } else
                    return prevState.map((item, index) => {
                        return index === rowId ? { ...item, ...newrow } : item;
                    })
            });
        } else
            API.create('/draftitem', newrow).then((data) => {
                if (data && data.id) {
                    newrow.id = data.id;
                }
                setDraftRowItems(prevState => {
                    if (rowId >= prevState.length) {
                        return [...prevState, newrow];
                    } else
                        return prevState.map((item, index) => {
                            return index === rowId ? newrow : item;
                        })
                });
                // fup();
                // console.log(data);
            });

    }

    // console.log(draftProductArr);

    function fup() {
        // Force a render without state change...
        this.forceUpdate();
    }
    return (
        <Card className="darkerBoxShadow mobile-overflow">
            <CardBody>
                <Row>
                    <Col md={6}>
                        <Input type="file" onChange={(e) => handleFileUpload(e)} />
                        <span className="text-muted fs-6">Upload a xlsx(excel) or csv(comma separated values) file to upload a premade order and submit. Follow the template provided. <strong>Note: Only the columns 1 and 2 are used. The first line of the file is not read.</strong></span>
                    </Col>
                    <Col md={2}>
                        <Button onClick={handleDownloadDraftTempate}>
                            <strong>
                                <u>Download Draft Template</u>
                            </strong>
                        </Button>
                    </Col>
                </Row>
                {(isLoading) ? (
                    <div className="d-flex justify-content-center mx-2 mt-2 mb-3">
                        <Spinner className="spinner-size-4" color="info" />
                    </div>
                ) : (
                    <>
                        <Table key={'table-' + tableKey} className="text-center tableLayoutFixed mt-3 mobile-inherit" striped bordered>
                            <thead>
                                <tr>
                                    <th className="removeImgSize"></th>
                                    <th className="productInputSize">Product #</th>
                                    <th>Description</th>
                                    <th className="qtyInputSize">Quantity</th>
                                    <th className="displaySize">Availability</th>
                                    <th className="priceDisplaySize">Price</th>
                                    <th className="rowTotalSize">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((v, i) => {
                                    return <ProperDraftTableRow key={i} removedup={removedup} productRowQuantity={productRowQuantity} setDraftProductArr={setDraftProductArr} setDraftRowItems={setDraftRowItems} changerow={changerow} rowId={i} row={v} rowproduct={draftProductArr[i]} API={API} draftid={draftid} currentRow={currentRow} setCurrentRow={setCurrentRow} selectedItemsCode={rows.filter(f => f).map(r => r.product)} setInvalidSCBAndCNGRows={setInvalidSCBAndCNGRows} />;
                                })
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={6} className="text-end"><strong>Total:</strong></td>
                                    <td>${Number(draftTotal).toFixed(2)}</td>
                                </tr>
                            </tfoot>
                        </Table>
                        <Row>
                            <Col>
                                <Button color="secondary" className="btn btn-secondary" onClick={handleButtonClear}>Empty Order</Button>{' '}
                                <Button color="secondary" className="btn btn-secondary">Print</Button>{' '}
                                <Button color="secondary" className="btn btn-secondary" onClick={() => setNumberOfRows(i => i + 5)}>Add 5 Rows</Button>{' '}
                            </Col>
                            <ToastContainer />
                        </Row>
                    </>
                )}
            </CardBody>
        </Card>
    )
}

export default ProperDraftTable;