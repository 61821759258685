const PreviewRGRTableRow = ({ item, index }) => {
    return (
        <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.product}</td>
            <td>{item.quantity_to_return}</td>
            <td>{item.orderid}</td>
            <td>{item.invoice_created.slice(0, 10)}</td>
        </tr>
    )
}

export default PreviewRGRTableRow;