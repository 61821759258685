import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import Select from 'react-select';

import { 
    deleteSalesPresentative as onDeleteSalesPresentative,
    getSalesRepresentatives as onGetSalesRepresentatives, 
    postSalesRepresentative as onPostSalesRepresentative,
    updateSalesRepresentative as onUpdateSalesRepresentative,
} from "../../store/actions";

import removeButton from '../../assets/images/close.png';

function GlobalSettingsSalesRepresentatives({ users }) {
    const dispatch = useDispatch();
    const [usersList, setUsersList] = useState([{ value: 1, label: 'No Users' }]);
    const [salesRepList, setSalesRepList] = useState([{ value: 1, label: 'No Users' }]);
    const [selectedUser, setSelectedUser] = useState('');
    const [replaceRep, setReplaceRep] = useState('');
    const [newRep, setNewRep] = useState('');

    const { salesRepresentatives, isPostSalesRepresentativeSuccess, isPostSalesRepresentativeFail, isDeleteSalesRepresentativeSuccess, isDeleteSalesRepresentativeFail, isUpdateSalesRepresentativeSuccess, isUpdateSalesRepresentativeFail } = useSelector((state) => ({
        salesRepresentatives: state.GlobalSettings.salesRepresentatives,
        isPostSalesRepresentativeSuccess: state.GlobalSettings.isPostSalesRepresentativeSuccess,
        isPostSalesRepresentativeFail: state.GlobalSettings.isPostSalesRepresentativeFail,
        isDeleteSalesRepresentativeSuccess: state.GlobalSettings.isDeleteSalesRepresentativeSuccess,
        isDeleteSalesRepresentativeFail: state.GlobalSettings.isDeleteSalesRepresentativeFail,
        isUpdateSalesRepresentativeSuccess: state.GlobalSettings.isUpdateSalesRepresentativeSuccess,
        isUpdateSalesRepresentativeFail: state.GlobalSettings.isUpdateSalesRepresentativeFail,
    }));

    useEffect(() => {
        dispatch(onGetSalesRepresentatives());
    }, [dispatch]);

    useEffect(() => {
        let tempSalesReps = salesRepresentatives;
        let tempSalesRepList = [];

        if (!tempSalesReps || !tempSalesReps.length) {
            setSalesRepList([{ value: 0, label: 'No Users' }]);
            return;
        }

        tempSalesReps.forEach((item) => tempSalesRepList.push({ value: item.id, label: `${item.id} - ${item.sales_firstname} ${item.sales_lastname}` }));

        setSalesRepList(tempSalesRepList);
    }, [salesRepresentatives])

    useEffect(() => {
        if (isPostSalesRepresentativeSuccess) {
            alert('Sales Representative Addded');
            dispatch(onGetSalesRepresentatives());
        }

        if (isPostSalesRepresentativeFail) alert('An error occured adding sales representative');
    }, [dispatch, isPostSalesRepresentativeSuccess, isPostSalesRepresentativeFail])

    useEffect(() => {
        if (isUpdateSalesRepresentativeSuccess) {
            alert('Sales Representative Updated');
            dispatch(onGetSalesRepresentatives());
        }

        if (isUpdateSalesRepresentativeFail) alert('An error occured updating sales representative');
    }, [dispatch, isUpdateSalesRepresentativeSuccess, isUpdateSalesRepresentativeFail])

    useEffect(() => {
        if (isDeleteSalesRepresentativeSuccess) {
            alert('Sales Representative Deleted');
            dispatch(onGetSalesRepresentatives());
        }

        if (isDeleteSalesRepresentativeFail) alert('An error occured deleting sales representative');
    }, [dispatch, isDeleteSalesRepresentativeSuccess, isDeleteSalesRepresentativeFail])

    useEffect(() => {
        let temp = users;
        let tempUserList = [];
        
        if (!temp || !temp.length) {
            tempUserList = [{ value: 0, label: 'No Users' }];
            return;
        }

        temp = temp.filter((item) => item.company_name === 'Max Advanced Brakes');

        temp.forEach((item) => tempUserList.push({ value: item.username, label: `${item.username} - ${item.firstname} ${item.lastname}` }));
        
        setUsersList(tempUserList)
    }, [users])
    
    function handleOnDelete(id) {
        dispatch(onDeleteSalesPresentative(id));
    }

    function handleOnSubmit() {
        let checkNotExists = false;
        if (!selectedUser) alert('A user must be selected');
        
        checkNotExists = salesRepresentatives.every((item) => item.sales_username !== selectedUser)
        
        if (!checkNotExists) {
            alert('Select a user that is not already a Sales Representative');
        } else {
            dispatch(onPostSalesRepresentative(selectedUser));
        }
    }

    function handleOnReplace() {
        if ((replaceRep === newRep) || (replaceRep && !newRep) || (!replaceRep && !newRep)) {
            alert('Select a sales representative to replace current sales representative.')
            return;
        }

        if (!replaceRep && newRep) {
            alert('Select a sales representative to replace');
            return;
        }

        dispatch(onUpdateSalesRepresentative(replaceRep, newRep));
    }

    return (
        <>
            <h4>Sales Representatives</h4>
            <Row className="mt-3">
                <Col>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Username</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(salesRepresentatives && salesRepresentatives.length) ? (
                                salesRepresentatives.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.sales_username}</td>
                                            <td>{item.sales_firstname} {item.sales_lastname}</td>
                                            <td>{item.sales_email}</td>
                                            <td>{item.sales_phone}</td>
                                            <td>
                                                <img className="cursorPointer" src={removeButton} alt="delete" onClick={() => handleOnDelete(item.id)} />
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td>No Users</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <Card>
                        <CardHeader>
                            <h5>Add Sales Representative</h5>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Select
                                        options={usersList}
                                        isClearable={true}
                                        onChange={(e) => (e) ? (setSelectedUser(e.value)) : (setSelectedUser(''))}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Button color={'max'} onClick={handleOnSubmit}>Submit</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <h5>Replace Sales Representative</h5>
                            <span className="text-muted fs-6">Only replaces primary sales representative</span>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Select
                                        options={salesRepList.filter((item) => item.value !== newRep)}
                                        isClearable={true}
                                        onChange={(e) => (e) ? (setReplaceRep(e.value)) : (setReplaceRep(''))}
                                    />
                                </Col>
                                <Col>
                                    <Select
                                        options={salesRepList.filter((item) => item.value !== replaceRep)}
                                        isClearable={true}
                                        onChange={(e) => (e) ? (setNewRep(e.value)) : (setNewRep(''))}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Button color={'max'} onClick={handleOnReplace}>Submit</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default GlobalSettingsSalesRepresentatives;