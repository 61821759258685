import { 
    API_RESPONSE_ERROR,
    API_RESPONSE_SUCCESS,
    DOWNLOAD_STATEMENT,
    GET_STATEMENT, 
    GET_STATEMENTS, 
    GET_STATEMENTS_WITH_USERNAME, 
    GET_STATEMENT_DETAILS, 
    POST_STATEMENT_PAYMENT,
    POST_STATEMENT_PAYMENT_FAIL,
    POST_STATEMENT_PAYMENT_SUCCESS
} from "./actionType";

// common success
export const statementsApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const statementsApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getStatements = () => ({
    type: GET_STATEMENTS,
});

export const getStatementsWithUsername = (username) => ({
    type: GET_STATEMENTS_WITH_USERNAME,
    payload: username
});

export const getStatement = (statement_id) => ({
    type: GET_STATEMENT,
    payload: statement_id,
});

export const getStatementDetails = (statement_id) => ({
    type: GET_STATEMENT_DETAILS,
    payload: statement_id,
});

export const downloadStatement = (statementId) => ({
    type: DOWNLOAD_STATEMENT,
    payload: statementId
});

export const postStatementPayment = (statement_id, paid_amount, type) => ({
    type: POST_STATEMENT_PAYMENT,
    payload: { statement_id, paid_amount, type }
});

export const postStatementPaymentSuccess = (data) => ({
    type: POST_STATEMENT_PAYMENT_SUCCESS,
    payload: data
});

export const postStatementPaymentFail = (error) => ({
    type: POST_STATEMENT_PAYMENT_FAIL,
    payload: error
});