import { useRef } from "react";
import { Input } from "reactstrap";

import { reasonOptions, typeOptions } from "../../constants/rgrform";

function QualityAssuranceWorksheetTableRowMain({ item, index, status, handleReturnDetailsChange, handleReturnedQtyChanges }) {
    let restockInputRef = useRef();
    let scrapInputRef = useRef();
    let miscInputRef = useRef();

    return (
        <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.product}</td>
            <td>{item.orderid}</td>
            <td>{item.qty_to_return}</td>
            <td>
                {(status === 'approved') ? (
                    <Input innerRef={restockInputRef} className="hide-number-arrows" type="number" value={item.restock_qty} min="0" onChange={(e) => handleReturnedQtyChanges(index, item.id, e.target.value, 'restock_qty', restockInputRef)} />
                ) : (item.restock_qty)}
            </td>
            <td>
                {(status === 'approved') ? (
                    <Input innerRef={scrapInputRef} className="hide-number-arrows" type="number" value={item.scrap_qty} min="0" onChange={(e) => handleReturnedQtyChanges(index, item.id, e.target.value, 'scrap_qty', scrapInputRef)} />
                ) : (item.scrap_qty)}
            </td>
            {/* <td>
                {(status === 'approved') ? (
                    <Input innerRef={miscInputRef} className="hide-number-arrows" type="number" value={item.misc_qty} min="0" onChange={(e) => handleReturnedQtyChanges(index, item.id, e.target.value, 'misc_qty', miscInputRef)} />
                ) : (item.misc_qty)}
            </td> */}
            <td>
                {(item.type) ? (
                    typeOptions.find((type) => item.type === type.value).label
                ) : (null)}
            </td>
            <td>
                {(item.reason) ? (
                    reasonOptions.find((reason) => item.reason === reason.value).label
                ) : (null)}
            </td>
            <td>
                {(status === 'approved') ? (
                    <Input type="text" value={item.warehouse_reason} onChange={(e) => handleReturnDetailsChange(item.id, e.target.value, 'warehouse_reason')} />
                ) : (item.warehouse_reason)}
            </td>
            {/* <td>
                <span>Restock: {item.restock_qty}</span>
                <br />
                <span>Scrap: {item.scrap_qty}</span>
                <br />
                <span>Misc: {item.misc_qty}</span>
            </td> */}
        </tr>
    )
}

export default QualityAssuranceWorksheetTableRowMain;