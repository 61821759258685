import { productLineDescription, productLines } from "../../../Components/constants/vehicleDetails";

function TableRows({ item, index }) {

    return (
        <tr key={index}>
            <td>{item.query}</td>
            <td>{item.brand_name}</td>
            <td>{item.maxnum}</td>
            <td>{productLineDescription[item.maxnum.match(productLines)]}</td>
        </tr>
    )
}

export default TableRows;