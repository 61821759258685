// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";

// Get Statements
export const GET_STATEMENTS = "GET_STATEMENTS";
export const GET_STATEMENTS_WITH_USERNAME = "GET_STATEMENTS_WITH_USERNAME";
export const GET_STATEMENT = "GET_STATEMENT";
export const GET_STATEMENT_DETAILS = "GET_STATEMENT_DETAILS";

// Download Statement
export const DOWNLOAD_STATEMENT = "DOWNLOAD_STATEMENT";

// Post statement paymenet
export const POST_STATEMENT_PAYMENT = "POST_STATEMENT_PAYMENT";
export const POST_STATEMENT_PAYMENT_SUCCESS = "POST_STATEMENT_PAYMENT_SUCCESS";
export const POST_STATEMENT_PAYMENT_FAIL = "POST_STATEMENT_PAYMENT_FAIL";