import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { CardBody, Row, Col, Card, CardHeader, Container, Button } from "reactstrap";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import { useProfile } from "../../../../Components/Hooks/UserHooks";

import { 
    getReturn, 
    getReturnDetails,
    updateReturnInvoiceItemsWorksheet as onUpdateReturnInvoiceItemsWorksheet,
} from "../../../../store/actions";

import logoDark from "../../../../assets/images/BLACKLOGO1.png";
import QualityAssuranceWorksheet from "../../../../Components/Warehouse/QualityAssurance/QualityAssuranceWorksheet";
import Loader from "../../../../Components/Common/Loader";
import { returnShipMethodOptions } from "../../../../Components/constants/returnDetails";

const QualityAssuranceDetails = () => {
    const dispatch = useDispatch();
    const { userProfile } = useProfile();
    const [returnInfo, setReturnInfo] = useState({});
    const [returnDetailsArr, setReturnDetailsArr] = useState(null);
    const [returnDetailsExtraArr, setReturnDetailsExtraArr] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    let location = useLocation();
    let history = useHistory();
    
    const { returnInvoice, isReturnSuccess, returnDetails, isReturnDetailsSuccess, isUpdateReturnInvoiceItemsWorksheetSuccess, isUpdateReturnInvoiceItemsWorksheetFail, returnsError, customerError } = useSelector((state) => ({
        returnInvoice: state.Returns.returnInvoice,
        isReturnSuccess: state.Returns.isReturnSuccess,
        returnDetails: state.Returns.returnDetails,
        isReturnDetailsSuccess: state.Returns.isReturnDetailsSuccess,
        isUpdateReturnInvoiceItemsWorksheetSuccess: state.Returns.isUpdateReturnInvoiceItemsWorksheetSuccess,
        isUpdateReturnInvoiceItemsWorksheetFail: state.Returns.isUpdateReturnInvoiceItemsWorksheetFail,
        returnsError: state.Returns.error,
        customerError: state.Customer.error,
    }));

    useEffect(() => {
        let return_id = location.pathname.split('/')[3];
        dispatch(getReturn(return_id));
        dispatch(getReturnDetails(return_id));
    }, [dispatch, location])

    useEffect(() => {
        if (returnInvoice) {
            setReturnInfo(returnInvoice);
        }

        if (returnDetails && returnDetails.length !== 0) {
            setReturnDetailsArr(returnDetails);
        }
    }, [dispatch, returnDetails, returnInvoice])

    useEffect(() => {
        if (isUpdateReturnInvoiceItemsWorksheetSuccess) {
            alert('Return Invoice Worksheet Inspected');
            history.push('/warehouse/quality-assurance');
        }

        if (isUpdateReturnInvoiceItemsWorksheetFail) alert('There was as error with worksheet completion');
        setIsLoading(false);
    }, [location, history, isUpdateReturnInvoiceItemsWorksheetSuccess, isUpdateReturnInvoiceItemsWorksheetFail])

    function handleCompleteWorkSheet() {
        setIsLoading(true);
        let check = returnDetailsArr.every((item) => (item.restock_qty || item.scrap_qty || item.misc_qty));
        let check2 = false;
        
        if (returnDetailsExtraArr || !returnDetailsExtraArr.length) {
            check2 = true;
        } else {
            check2 = returnDetailsExtraArr.every((item) => item.returned && item.description);
        }
        
        if (check && check2) {
            dispatch(onUpdateReturnInvoiceItemsWorksheet(returnDetailsArr, returnDetailsExtraArr, returnInfo.id));
        } else {
            alert('Worksheet not complete');
            setIsLoading(false);
        }
    }

    if ((typeof returnsError === 'string' && returnsError.match("401")) || (typeof customerError === 'string' && customerError.match("401"))) return <Redirect to="/login" />
    if (!userProfile.permissions.view_return_history) return <Redirect to="/not-authorized" />

    document.title = `${location.pathname.split('/')[3]} | Return History | Max Advanced Brakes - Prozone`;
    
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Quality Assurance Details" pageTitle="Quality Assurance" linkTo={`/warehouse/quality-assurace`} />

                <Row className="justify-content-center">
                    <Col>
                        <Card id="demo" color={'dark'} outline>
                            <CardHeader className="border-bottom-dashed p-4">
                                {(isReturnSuccess && Object.keys(returnInfo).length !== 0) ? (
                                    <div className="d-sm-flex">
                                        <div className="flex-grow-1">
                                            <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="110" />
                                            <img src={logoDark} className="card-logo card-logo-light" alt="logo light" height="17" />
                                        </div>
                                    </div>
                                ) : (null)}
                            </CardHeader>
                            <CardBody className="p-4">
                                {(isReturnSuccess && Object.keys(returnInfo).length !== 0) ? (    
                                    <Row className="g-3">
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Return No</p>
                                            <h5 className="fs-14 mb-0"><span id="invoice-no">{returnInfo.id}</span></h5>
                                        </Col>
                                        <Col lg={3} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Company</p>
                                            <h5 className="fs-14 mb-0"><span id="invoice-date">
                                                {returnInfo.company_name}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Date</p>
                                            <h5 className="fs-14 mb-0"><span id="invoice-date">
                                                {returnInfo.date_created.slice(0, 10)}</span>
                                            </h5>
                                        </Col>
                                        <Col lg={3} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Return Invoice Status</p>
                                            <h5 className="fs-14">{returnShipMethodOptions.find((item) => item.value = returnInfo.ship_method).label}</h5>
                                        </Col>
                                        <Col lg={2} xs={6}>
                                            <p className="text-muted mb-2 text-uppercase fw-semibold">Return Invoice Status</p>
                                            <span className="badge badge-soft-info fs-14">{returnInfo.status}</span>
                                        </Col>
                                    </Row>
                                ) : (null)}
                            </CardBody>
                            <CardBody className="p-4">
                                <div>
                                    {(isReturnDetailsSuccess && returnDetailsArr && returnDetailsArr.length) ? (
                                        <QualityAssuranceWorksheet returnId={returnInfo.id} returnDetailsArr={returnDetailsArr} setReturnDetailsArr={setReturnDetailsArr} returnDetailsExtraArr={returnDetailsExtraArr} setReturnDetailsExtraArr={setReturnDetailsExtraArr} status={returnInfo.status} />
                                    ) : (null)}
                                </div>
                                <hr />
                                <div className="text-center justify-content-center d-print-none mt-4">
                                    {(isLoading) ? (
                                        <Loader />
                                    ) : (
                                        ((userProfile.access === 9 || userProfile.access === 1) && (returnInfo.status !== 'inspected')) ? (
                                            <Button color="max" onClick={handleCompleteWorkSheet}>Complete</Button>
                                        ) : (null)
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default QualityAssuranceDetails;
