import { useEffect, useState } from "react";
import { Input } from "reactstrap";

import removeButton from '../../../assets/images/close.png';

function QualityAssuranceWorksheetTableRow({ returnId, index, returnDetailsExtraArr, setReturnDetailsExtraArr, handelRemoveRow }) {
    const [extraItem, setExtraItem] = useState({ returnId: returnId, product: '', returned: '', condition: '' });

    useEffect(() => {
        if (returnDetailsExtraArr && returnDetailsExtraArr.length) setExtraItem(returnDetailsExtraArr[index]);
    }, [returnDetailsExtraArr])

    useEffect(() => {
        let temp = returnDetailsExtraArr;
        temp[index] = extraItem;
        setReturnDetailsExtraArr(temp);
    }, [extraItem])

    return (
            <tr>
                <td>
                    <Input type="text" value={(extraItem) ? (extraItem.product) : ('')} onChange={(e) => setExtraItem({ ...extraItem, product: e.target.value })} />
                </td>
                <td>
                    <Input type="number" value={(extraItem) ? (extraItem.returned) : ('')} onChange={(e) => setExtraItem({ ...extraItem, returned: e.target.value })} />
                </td>
                <td>
                    <Input type="text" onChange={(e) => setExtraItem({ ...extraItem, description: e.target.value })} value={(extraItem) ? (extraItem.description) : ('')} />
                </td>
                <td>
                    <img className="cursorPointer" src={removeButton} alt="remove button" onClick={() => handelRemoveRow(index)} />
                </td>
            </tr>
    )
}

export default QualityAssuranceWorksheetTableRow;