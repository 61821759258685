import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { ListGroupItem } from "reactstrap";

import { 
    getModels as onGetModels,
    resetVehicleCategories,
    resetVehicleProducts,
} from "../../../store/actions";
import Loader from "../../Common/Loader";

function VehicleSearchModelList() {
    const dispatch = useDispatch();
    const history = useHistory();
    let { year, make } = useParams();
    let match = useRouteMatch('/products/vehicle-search/:year/:make');
    let location = useLocation();

    const [isLoading, setIsLoading] = useState(true);

    const { models } = useSelector((state) => ({
        models: state.Vehicle.models,
    }))
    
    useEffect(() => {
        if (match.isExact || !models.length){
            setIsLoading(true);
            dispatch(onGetModels(year, make));
        }
    }, [dispatch, year, make]);

    useEffect(() => {
        if (models && models.length) {
            setIsLoading(false);
        }
    }, [models]);

    const handleModelSelection = (to) => {
        dispatch(resetVehicleCategories());
        history.push(to);
    };

    return (
        <>
        {
            (isLoading) ? (<Loader />) :
            ((models) ? (
                models.sort().map((item, index) => {
                    const toLink = `/products/vehicle-search/${year}/${make}/${item}`;
                    return (
                        <ListGroupItem className="p-0" onClick={() => handleModelSelection(toLink) } active={(location.pathname.match(item) !== null) ? (true) : (false)} action tag="button" key={index}>
                            <a className="text-black d-block w-100 padding-0_7-1">{item}</a>
                        </ListGroupItem>
                    )
                })
            ) : (null))
        }
        </>
    )
}

export default VehicleSearchModelList;