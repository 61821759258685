import { 
    ADD_DRAFT,
    ADD_DRAFT_FAIL,
    ADD_DRAFT_SUCCESS,
    API_RESPONSE_ERROR, 
    API_RESPONSE_SUCCESS, 
    CREATE_NEW_ORDER, 
    CREATE_NEW_ORDER_FAIL, 
    CREATE_NEW_ORDER_SUCCESS, 
    DELETE_DRAFT, 
    DELETE_DRAFT_FAIL, 
    DELETE_DRAFT_ITEMS, 
    DELETE_DRAFT_ITEMS_FAIL, 
    DELETE_DRAFT_ITEMS_SUCCESS, 
    DELETE_DRAFT_SUCCESS, 
    DOWNLOAD_DRAFT_TEMPLATE, 
    GET_DRAFT, 
    GET_DRAFTS,
    POST_DRAFT_UPDATE,
    POST_DRAFT_UPDATE_FAIL,
    POST_DRAFT_UPDATE_SUCCESS,
    PREVIEW_ORDER_DRAFT,
    PREVIEW_ORDER_USERNAME,
} from "./actionType";

// common success
export const draftsApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const draftsApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getDrafts = () => ({
    type: GET_DRAFTS,
});

export const getDraft = (drafthash) => ({
    type: GET_DRAFT,
    payload: drafthash,
})

export const addDraft = (fields) => ({
    type: ADD_DRAFT,
    payload: fields,
});

export const addDraftSuccess = () => ({
    type: ADD_DRAFT_SUCCESS,
});

export const addDraftFail = (error) => ({
    type: ADD_DRAFT_FAIL,
    payload: error
});

export const createNewOrder = (order) => ({
    type: CREATE_NEW_ORDER,
    payload: order
});

export const createNewOrderSuccess = () => ({
    type: CREATE_NEW_ORDER_SUCCESS,
});

export const createNewOrderFail = (error) => ({
    type: CREATE_NEW_ORDER_FAIL,
    payload: error
});

export const postDraftUpdate = (fields) => ({
    type: POST_DRAFT_UPDATE,
    payload: fields,
});

export const postDraftUpdateSuccess = (actionType, data) => ({
    type: POST_DRAFT_UPDATE_SUCCESS,
    payload: { actionType, data }
});

export const postDraftUpdateFail = (error) => ({
    type: POST_DRAFT_UPDATE_FAIL,
    payload: error,
});

export const deleteDraft = (drafthash) => ({
    type: DELETE_DRAFT,
    payload: drafthash
});

export const deleteDraftSuccess = (draft) => ({
    type: DELETE_DRAFT_SUCCESS,
    payload: draft
});

export const deleteDraftFail = (error) => ({
    type: DELETE_DRAFT_FAIL,
    payload: error
});

export const deleteDraftItems = (drafthash) => ({
    type: DELETE_DRAFT_ITEMS,
    payload: drafthash
});

export const deleteDraftItemsSuccess = (data) => ({
    type: DELETE_DRAFT_ITEMS_SUCCESS,
    payload: data
});

export const deleteDraftItemsFail = (error) => ({
    type: DELETE_DRAFT_ITEMS_FAIL,
    payload: error
});

export const previewOrderDraft = (draftItems) => ({
    type: PREVIEW_ORDER_DRAFT,
    payload: draftItems
});

export const previewOrderUsername = (username) => ({
    type: PREVIEW_ORDER_USERNAME,
    payload: username,
});

export const downloadDraftTemplate = () => ({
    type: DOWNLOAD_DRAFT_TEMPLATE
});