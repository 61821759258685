//LOGIN
//MAX ADVANCED BRAKES Forgot Password
export const POST_PASSWORD_FORGET = "/forgotpassword";

// MAX ADVANCED BRAKES RESET PASSWORD
export const POST_RESET_PASSWORD = "/passwordreset";

// MAX ADVANCED BRAKES LOGIN
export const POST_MAX_LOGIN = "/login";

// MAX ADVANCED BRAKES LOGOUT
export const GET_MAX_LOGOUT = "/logout";

// MAX ADVANCED BRAKES IDLE TIMER
export const GET_PING = "/ping";

// MAX ADVANCED BRAKES REGISTRATION
export const POST_REGISTRATION ="/signup";

// MAX ADVANCED BRAKES PRODUCTS
export const GET_PRODUCTS = "/product";
export const POST_PRODUCT_WITH_PRICES = "/product-price";
export const POST_PRICELIST = "/pricelist";
export const POST_PRODUCT_SELECT_LOG = "/product-select-log";
export const POST_PRODUCT_AND_PRICE_ARRAY = "/product-price-array";
export const POST_PRODUCT_ARRAY = "/product-array";

// MAX ADVANCED BRAKES INTERCHANGE
export const GET_INTERCHANGE = "/interchange";

// MAX ADVANCED BRAKES VEHICLE SEARCH
export const GET_YEARS = "/ecat";
export const GET_MAKES = "/ecat";
export const GET_MODELS = "/ecat";
export const GET_CATEGORIES = "/ecat";
export const GET_ECAT_PRODUCTS = "/ecat";
export const POST_PRODUCTS_WHOLESALE_INVENTORY = "/wholesaleinv";

// MAX ADVANCED BRAKES NEW ORDER
export const GET_DRAFTS = "/drafts";
export const GET_DRAFT = "/draft";
export const CREATE_NEW_ORDER = "/order";
export const ADD_DRAFT = "/draft";
export const POST_DRAFT_UPDATE = "/draft";
export const DELETE_DRAFT = "/draft";
export const DELETE_DRAFT_ITEMS = "/draft-delete-items";
export const DOWNLOAD_DRAFT_TEMPLATE = "/download/draft-template";

// MAX ADVANCED BRAKES INVOICE
export const GET_INVOICES = "/orders";
export const GET_FILTERED_INVOICES = "/orders";
export const GET_ARCHIVED_INVOICES = "/archived-orders";
export const GET_FILTERED_ARCHIVED_INVOICES = "/archived-orders";
export const GET_INVOICE = "/orders";
export const GET_INVOICE_DETAILS = "/orderitems";
export const POST_INVOICE_DETAILS_PRODUCT_QUANTITY = "/wholesaleinv";
export const DOWNLOAD_INVOICE_PDF = "/invoice/invoice";
export const DOWNLOAD_PACKING_LIST_PDF = "/invoice/packinglist";
export const POST_SHIP_ORDER = "/shiporder";
export const UPDATE_ORDER_STATUS = "/invoice/approval";
export const POST_CANCEL_ORDER = "/cancelorder";
export const GET_OLD_ORDERS_ORDER_ITEMS = "/get-orderitems";
export const GET_REFORMAT_ORDERITEMS = "/orderitems-reformat";

// MAX ADVANCED BRAKES RETURNS
export const GET_RETURNS = "/return-invoices";
export const GET_RETURNS_FILTERED = "/return-invoices";
export const GET_RETURN = "/return-invoices";
export const GET_RETURN_DETAILS = "/return-invoice-items";
export const GET_RETURN_DETAILS_EXTRA = "/return-invoice-items-extra";
export const GET_CUSTOMER_PRODUCTS = "/orderitems";
export const GET_RGR_FORM = "/download/return-rgr-form";
export const GET_RETURN_ADJUSTMENTS = "/return-adjustments";
export const GET_RETURN_ADJUSTMENT = "/return-adjustments";
export const GET_RETURN_ADJUSTMENT_DETAILS = "/return-adjustment-items";
export const POST_RETURN_BUCKET = "/return-bucket-multiple-items";
export const GET_RETURN_BUCKET = "/return-bucket-items";
export const DELETE_RETURN_BUCKET_ITEM = "/return-bucket-items-delete";
export const POST_RETURN_INVOICE_CREATE = "/return-invoice-create";
export const UPDATE_RETURN_BUCKET_ITEMS = "/return-bucket-items-update-column";
export const UPDATE_RETURN_BUCKET = "/return-bucket-update-column";
export const DOWNLOAD_RETURN_INVOICE = "/invoice/returninvoice";
export const POST_RETURN_INVOICE_APPROVAL = "/return-invoice/approval";
export const UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET = "/return-invoice-items";
export const UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL = "/return-adjustment/approval";
export const UPDATE_RETURN_INVOICE_CREDIT_ISSUE = "/issue-credit/approval";
export const DOWNLOAD_RETURN_BATCH_TEMPLATE = "/download/return-batch-template";

// MAX ADVANCED BRAKES CREDIT
export const GET_CREDITS = "/credit-invoices";
export const GET_CREDITS_FILTERED = "/credit-invoices";
export const GET_CREDIT = "/credit-invoices";
export const GET_CREDIT_DETAILS = "/credit-invoice-items";
export const DOWNLOAD_CREDIT_INVOICE = "/invoice/creditinvoice";

// MAX ADVANCED BRAKES STATEMEMTS
export const GET_STATEMENTS = "/statements";
export const GET_STATEMENTS_WITH_USERNAME = "/statements";
export const GET_STATEMENT = "/statement";
// export const GET_STATEMENT_DETAILS = "/statement";
export const DOWNLOAD_STATEMENT = "/invoice/statement";
export const POST_STATEMENT_PAYMENT = "/statement";

// MAX ADVANCED BRAKES CUSTOMER INFORMATION
export const GET_CUSTOMERS = "/users";
export const GET_CUSTOMER_INFO = "/user";
export const GET_CUSTOMER_PRODUCT_EXCLUSION = "/exclusions";
export const POST_NEW_CUSTOMER_EXCLUSION = "/exclusions";
export const DELETE_CUSTOMER_EXCLUSION = "/exclusions";
export const GET_CUSTOMER_USER_GROUP = "/user-group";
export const UPDATE_USER_GROUP = "/user-group";
export const UPDATE_CUSTOMER_INFO = "/user";
export const DELETE_CUSTOMER = "/user";
export const POST_NEW_CUSTOMER = "/newuser";
export const GET_USER_GROUPS = "/user-groups";
export const POST_USER_PASSWORD_RESET = "/user/passwordreset";
export const GET_UPDATE_USER_CREDIT_BALANCES = "/update-user-credit-balance";
export const GET_PRODUCT_GROUPS = "/groups";
export const GET_USER_PRODUCT_GROUPS = "groupset";
export const POST_NEW_PRODUCT_GROUP_TO_USER = "groupset";
export const DELETE_PRODUCT_GROUP_FROM_USER = "groupset";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

// MAX ADVANCED BRAKES GLOBAL SETTINGS
export const GET_ALLPRICES_DATA = "/prices";
export const POST_GLOBAL_PRICES = "/prices";
export const GET_USERS = "/users";
export const POST_GLOBAL_MAX = "/global/max";
export const POST_GLOBAL_DISCOUNT = "/global/discounts";
export const GET_MONTH_TEST = "/monthtest";
export const GET_MONTH_TEST_MONTH_YEAR = "/statement-monthly-statement";
export const GET_SALES_REPRESENTATIVES = "/global/sales-representatives";
export const POST_SALES_REPRESENTATIVE = "/global/sales-representative";
export const DELETE_SALES_REPRESENTATIVE = "/global/sales-representative";
export const UPDATE_SALES_REPRESENTATIVE = "/global/sales-representative";
export const GET_STATEMENT_OVERDUE_SUMMARY = "/statements-overdue-summary";

// MAX ADVANCED BRAKES PROMOTIONS AND COUPONS
export const DOWNLOAD_GROUP_TEMPLATE = "/download/group-template";
export const GET_GROUPS = "/groups";
export const GET_GROUP_ITEMS = "/groupitems";
export const DELETE_GROUP ="/groups";
export const POST_GROUPS = "/groups";
export const GET_ACCESSORY = "/global/oil";
export const POST_ACCESSORY = "/global/oil";
export const POST_ADD_ACCESSORY = "/global/oil";
export const DELETE_ACCESSORY = "/global/oil";

// MAX ADVANCED BRAKES WHOLESALE SYSTEM MESSAGES
export const GET_SYSTEM_MESSAGES = "/system-messages";
export const GET_SYSTEM_MESSAGES_TODAY = "/system-messages/today";
export const POST_SYSTEM_MESSAGE = "/system-message";