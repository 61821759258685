import {
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    GET_STATEMENTS,
    GET_STATEMENT,
    GET_STATEMENT_DETAILS,
    GET_STATEMENTS_WITH_USERNAME,
    DOWNLOAD_STATEMENT,
    POST_STATEMENT_PAYMENT_SUCCESS,
    POST_STATEMENT_PAYMENT_FAIL,
} from "./actionType";

const INIT_STATE = {
    statements: [],
    statement: {},
    statementDetails: [],
    error: {},
};

const Statements = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_STATEMENTS:
                case GET_STATEMENTS_WITH_USERNAME:
                    return {
                        ...state,
                        statements: action.payload.data,
                        statement: {},
                        statementDetails: [],
                        isStatementCreated: false,
                        isStatementSuccess: true,
                        isStatementDetailsSuccess: false,
                    };
                case GET_STATEMENT:
                    return {
                        ...state,
                        statement: action.payload.data,
                        isStatementCreated: false,
                        isStatementSuccess: true,
                        isStatementPaymentSuccess: false,
                        isStatementPaymentFail: false,
                    };
                case GET_STATEMENT_DETAILS:
                    return {
                        ...state,
                        statementDetails: action.payload.data,
                        isStatementCreated: false,
                        isStatementDetailsSuccess: true
                    }
                case DOWNLOAD_STATEMENT:
                    return {
                        ...state,
                        statementPdf: action.payload.data,
                        isStatementPdfDownloaded: true,
                    }
                default:
                    return { ...state };
            }
        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_STATEMENTS:
                case GET_STATEMENTS_WITH_USERNAME:
                    return {
                        ...state,
                        error: action.payload.error,
                        isStatementCreated: false,
                        isStatementSuccess: false
                    };
                case GET_STATEMENT:
                    return {
                        ...state,
                        error: action.payload.error,
                        isStatementCreated: false,
                        isStatementSuccess: false,
                    };
                case GET_STATEMENT_DETAILS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isStatementCreated: false,
                        isStatementDetailsSuccess: false,
                    }
                case DOWNLOAD_STATEMENT:
                    return {
                        ...state,
                        error: action.payload.error,
                        isStatementPdfDownloaded: false,
                    }
                default:
                    return { ...state };
            }
        
        case POST_STATEMENT_PAYMENT_SUCCESS:
            return {
                ...state,
                isStatementPaymentSuccess: true,
                isStatementPaymentFail: false,
            }

        case POST_STATEMENT_PAYMENT_FAIL:
            return {
                ...state,
                isStatementPaymentSuccess: false,
                isStatementPaymentFail: true,
            }

        default:
            return { ...state };
    }
};

export default Statements;