import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { ListGroupItem } from "reactstrap";

import { categoryAxle, catergoryOrder } from "../../../Components/constants/vehicleDetails";

import { 
    getCategories as onGetCategories,
    resetVehicleProducts,
} from "../../../store/actions";
import Loader from "../../Common/Loader";

function VehicleSearchCategoryList() {
    let { year, make, model } = useParams();
    const history = useHistory();
    let match = useRouteMatch('/products/vehicle-search/:year/:make/:model');
    let location = useLocation();
    const dispatch = useDispatch();
    const [categoriesArr, setCategoriesArr] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    const { categories } = useSelector((state) => ({
        categories: state.Vehicle.categories,
    }))
    
    useEffect(() => {
        if (match.isExact || !categoriesArr.length) {
            setIsLoading(true);
            dispatch(resetVehicleProducts());
            setCategoriesArr([]);
            dispatch(onGetCategories(year, make, model));
        }
    }, [dispatch, year, make, model]);

    useEffect(() => {
        if (categories && categories.length) {
            let tempCategories = [];
            let uniqueObject = {};

            // Get categories
            categories.forEach((item) => uniqueObject[categoryAxle[item.axle] + ' ' + item.producttype] = { category: categoryAxle[item.axle] + ' ' + item.producttype, order: catergoryOrder[categoryAxle[item.axle] + ' ' + item.producttype], url: item.itemtype + ' ' + item.axle });
            
            // Remove duplicates
            tempCategories = Object.values(uniqueObject).map((value) => value);
            
            // sort based on order
            tempCategories.sort((a, b) => a.order - b.order);
            
            setCategoriesArr(tempCategories);

            setIsLoading(false);
        }
    }, [categories])

    const handleCategorySelection = (to) => {
        dispatch(resetVehicleProducts());
        history.push(to);
    };

    return (
        <>
        {
            (isLoading) ? (<Loader />) :
            ((categoriesArr.length) ? (
                categoriesArr.map((item, index) => {
                    const toLink = `/products/vehicle-search/${year}/${make}/${model}/${item.url}`;
                    return (
                        <ListGroupItem className="p-0" onClick={() => handleCategorySelection(toLink) } active={(location.pathname.match(item.url) !== null) ? (true) : (false)} action tag="button" key={index}>
                            <a className="text-black d-block w-100 padding-0_7-1">{item.category}</a>
                        </ListGroupItem>
                    )
                })
            ) : (null))
        }
        </>
    )
}

export default VehicleSearchCategoryList;