import { Card, CardBody, CardHeader, Col, Input, Label, ListGroup, Row, Table } from "reactstrap";
import Select from 'react-select';

import AdminTableRowSelect from "./AdminTableRowSelect";
import AdminListGroupItemDiscount from "./AdminListGroupItemDiscount";
import AdminListGroupItemMaxQuantity from "./AdminListGroupItemMaxQuantity";
import AdminInfoProductExclusion from "./AdminInfoProductExclusion";
import AdminInfoSelect from "./AdminInfoSelect";
import NewUserInputFields from "../Administrator/NewUserInputFields";
import { useProfile } from "../Hooks/UserHooks";

import { accessOptions, accountTierOptions, activeOptions, advancedPaymentDiscountOptions, paymentBehaviourOptions, priceListOptions, creditDaysOptions, rebateProgramOptions, clientTypeOptions, deliveryOptions, defaultShipMethodOptions, numOfDaysToShippedDateOptions } from "../constants/accountDetails";

function AdminInfoAccountPanel({ accountInfo, setAccountInfo, userInfo }) {
    const { userProfile } = useProfile();

    return (
        <>
        <Card>
                <CardHeader tag="h5">Account Details</CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <Label>Account Creation Date: {userInfo.created_at}</Label>
                        </Col>
                        <Col>
                            <Label>Account Age: </Label>
                        </Col>
                        <Col>
                            <Label>Account Tier:</Label>
                            <Select 
                                options={accountTierOptions}
                                value={accountTierOptions.find((option) => option.value === accountInfo.account_tier_id)}
                                onChange={(e) => setAccountInfo({ ...accountInfo, 'account_tier_id': e.value })}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card className="mt-3">
                <CardHeader tag="h5">Access</CardHeader>
                <CardBody>
                    <Table className="text-center" striped bordered>
                        <tbody>
                            <AdminTableRowSelect field={'Access'} fieldValue={userInfo.access} options={accessOptions} property={'access'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                            <AdminTableRowSelect field={'Active'} fieldValue={userInfo.active} options={activeOptions} property={'active'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                            <AdminTableRowSelect field={'Client Type'} fieldValue={userInfo.client_type_id} options={clientTypeOptions} property={'client_type_id'} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />

                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            <Card>
                <CardHeader tag="h5">Credit Details</CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <Label>Credit Days:</Label>
                            <Select 
                                options={creditDaysOptions}
                                value={creditDaysOptions.find((option) => option.value === accountInfo.terms)}
                                onChange={(e) => setAccountInfo({ ...accountInfo, 'terms': e.value })}
                                isDisabled={(userProfile.access === 1 || userProfile.access === 7) ? (false) : (true)}
                            />
                        </Col>
                        <Col>
                            <Label>Credit Limit:</Label>
                            <Input type="number" value={accountInfo.credit_limit || ""} onChange={(e) => setAccountInfo({ ...accountInfo, 'credit_limit': e.target.value })} disabled={(userProfile.access === 1 || userProfile.access === 7) ? (false) : (true)} />
                        </Col>
                        <Col>
                            <p><strong>Credit Balance:</strong> ${accountInfo.credit_remaining}</p>
                            <p><strong>Credit Balance Account Lifetime:</strong> ${accountInfo.credit_balance_account_lifetime}</p>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Label>Payment Behaviour:</Label>
                            <Select
                                options={paymentBehaviourOptions}
                                value={paymentBehaviourOptions.find((option) => option.value === accountInfo.payment_behaviour_id)}
                                onChange={(e) => setAccountInfo({ ...accountInfo, 'payment_behaviour_id': e.value })}
                                isDisabled={(userProfile.access === 1 || userProfile.access === 7) ? (false) : (true)}
                            />
                        </Col>
                        <Col>
                            <Label>Advanced Payment Discount:</Label>
                            <Select
                                options={advancedPaymentDiscountOptions}
                                value={advancedPaymentDiscountOptions.find((option) => option.value === accountInfo.advanced_payment_discount_id)}
                                onChange={(e) => setAccountInfo({ ...accountInfo, 'advanced_payment_discount_id': e.value })}
                                isDisabled={(userProfile.access === 1 || userProfile.access === 7) ? (false) : (true)}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            
            <Card>
                <CardHeader tag="h5">Shipping and Delivery Option</CardHeader>
                <CardBody>
                    <Row className="mb-3">
                        <Col md="4">
                            <AdminInfoSelect label={'Default Ship Method'} field={'default_ship_method'} options={defaultShipMethodOptions} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        </Col>
                        <Col md="4">
                            <AdminInfoSelect label={'Number Of Shipped Days'} field={'num_of_days_to_shipped_date'} options={numOfDaysToShippedDateOptions} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        </Col>
                        <Col md="4">
                            <AdminInfoSelect label={'Default Return Delivery Option'} field={'delivery_option'} options={deliveryOptions} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <NewUserInputFields labelName={'MOQ for Freight Prepaid (CAD)'} type={'number'} field={'default_moq'} value={accountInfo.default_moq} newUserInfo={accountInfo} setNewUserInfo={setAccountInfo} />
                        </Col>
                        <Col>
                            <Label>{'Same Day Shipping'} <span className="text-danger">*</span></Label>
                            <Input type={'checkbox'} checked={(accountInfo.same_day_shipping) ? (1) : (0)} onChange={(e) => setAccountInfo({ ...accountInfo, same_day_shipping: (e.target.checked) ? (1) : (0) })} />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardHeader tag="h5">Price Details and Category Discounts</CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <Label>Price List:</Label>
                            <Select
                                options={priceListOptions}
                                value={priceListOptions.find((option) => option.value === accountInfo.price_set)}
                                onChange={(e) => setAccountInfo({ ...accountInfo, 'price_set': e.value })}
                                isDisabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)}
                            />
                        </Col>
                        <Col>
                            <Label>Rebate Program:</Label>
                            <Select 
                                options={rebateProgramOptions}
                                isDisabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Row>
                                <Col>
                                    <h4>Rotors</h4>
                                    <ListGroup flush>
                                        <AdminListGroupItemDiscount field={"ONZ"} fieldValue={userInfo.ONZ} accountInfo={accountInfo} setAccountInfo={setAccountInfo} disabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)} />
                                        <AdminListGroupItemDiscount field={"OCG"} fieldValue={userInfo.OCG} accountInfo={accountInfo} setAccountInfo={setAccountInfo} disabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)} />
                                        <AdminListGroupItemDiscount field={"CNZ"} fieldValue={userInfo.CNZ} accountInfo={accountInfo} setAccountInfo={setAccountInfo} disabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)} />
                                        <AdminListGroupItemDiscount field={"SCB"} fieldValue={userInfo.SCB} accountInfo={accountInfo} setAccountInfo={setAccountInfo} disabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)} />
                                        <AdminListGroupItemDiscount field={"SNZ"} fieldValue={userInfo.SNZ} accountInfo={accountInfo} setAccountInfo={setAccountInfo} disabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)} />
                                        
                                    </ListGroup>
                                </Col>
                                <Col>
                                    <h4>Pads</h4>
                                    <ListGroup flush>
                                        <AdminListGroupItemDiscount field={"CDM"} fieldValue={userInfo.CDM} accountInfo={accountInfo} setAccountInfo={setAccountInfo} disabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)} />
                                        <AdminListGroupItemDiscount field={"CDP"} fieldValue={userInfo.CDP} accountInfo={accountInfo} setAccountInfo={setAccountInfo} disabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)} />
                                        <AdminListGroupItemDiscount field={"AFN"} fieldValue={userInfo.AFN} accountInfo={accountInfo} setAccountInfo={setAccountInfo} disabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)} />
                                        <AdminListGroupItemDiscount field={"TSU"} fieldValue={userInfo.TSU} accountInfo={accountInfo} setAccountInfo={setAccountInfo} disabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)} />
                                        <AdminListGroupItemDiscount field={"MD"} fieldValue={userInfo.MD} accountInfo={accountInfo} setAccountInfo={setAccountInfo} disabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)} />
                                    </ListGroup>
                                </Col>
                                <Col>
                                    <h4>Other</h4>
                                    <ListGroup flush>
                                        <AdminListGroupItemDiscount field={"bearing"} fieldValue={userInfo.bearing} accountInfo={accountInfo} setAccountInfo={setAccountInfo} disabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)} />
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Label>Exceptions:</Label>
                            <Input type="textarea" value={accountInfo['exceptions'] || ""} onChange={(e) => setAccountInfo({ ...accountInfo, 'exceptions': e.target.value })} disabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)} />
                        </Col>
                        <Col>
                            <Label>Program Details:</Label>
                            <Input type="textarea" value={accountInfo['program_details'] || ""} onChange={(e) => setAccountInfo({ ...accountInfo, 'program_details': e.target.value })} disabled={(userProfile.access === 1 || userProfile.access === 7 || userProfile.access === 3) ? (false) : (true)} />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            {/* <AdminInfoProductExclusion username={userInfo.username} /> */}
            <Card>
                <CardHeader tag="h5">Maximum Quantity</CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <h4>Rotors</h4>
                                    <ListGroup flush>
                                        <AdminListGroupItemMaxQuantity field={"maxonz"} fieldValue={userInfo.maxonz} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                                        <AdminListGroupItemMaxQuantity field={"maxocg"} fieldValue={userInfo.maxocg} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                                        <AdminListGroupItemMaxQuantity field={"maxcnz"} fieldValue={userInfo.maxcnz} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                                        <AdminListGroupItemMaxQuantity field={"maxscb"} fieldValue={userInfo.maxscb} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                                        <AdminListGroupItemMaxQuantity field={"maxsnz"} fieldValue={userInfo.maxsnz} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />                                        
                                    </ListGroup>
                                </Col>
                                <Col>
                                    <h4>Pads</h4>
                                    <ListGroup flush>
                                        <AdminListGroupItemMaxQuantity field={"maxcdm"} fieldValue={userInfo.maxcdm} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                                        <AdminListGroupItemMaxQuantity field={"maxcdp"} fieldValue={userInfo.maxcdp} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                                        <AdminListGroupItemMaxQuantity field={"maxafn"} fieldValue={userInfo.maxafn} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                                        <AdminListGroupItemMaxQuantity field={"maxmd"} fieldValue={userInfo.maxmd} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                                    </ListGroup>
                                </Col>
                                <Col>
                                    <h4>Other</h4>
                                    <ListGroup flush>
                                        <AdminListGroupItemMaxQuantity field={"maxbearing"} fieldValue={userInfo.maxbearing} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                                        <AdminListGroupItemMaxQuantity field={"maxother"} fieldValue={userInfo.maxother} accountInfo={accountInfo} setAccountInfo={setAccountInfo} />
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            
            <Card>
                <CardHeader tag="h5">Other</CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <Label>Other Remarks</Label>
                            <Input type="textarea" value={accountInfo.special_info || ""} onChange={(e) => setAccountInfo({ ...accountInfo, 'special_info': e.target.value })} />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default AdminInfoAccountPanel;