import {Label, Input} from 'reactstrap';

function RegistrationInput(props) {
    return (
        <fieldset>
            <Label>{props.label} <span className="text-danger">*</span></Label>
            <Input type={props.type} value={props.value} onChange={(e) => { props.setNewUser({...props.newUser, [props.name]: e.target.value}) }} required />
        </fieldset>
    )
}

export default RegistrationInput;