export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const RESET_APP = 'RESET_APP';

export const DOWNLOAD_GROUP_TEMPLATE = "DOWNLOAD_GROUP_TEMPLATE";
export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUP_ITEMS = "GET_GROUP_ITEMS";

export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_FAIL = "DELETE_GROUP_FAIL";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";

export const POST_GROUPS = "POST_GROUPS";
export const POST_GROUPS_SUCCESS = "POST_GROUPS_SUCCESS";
export const POST_GROUPS_FAIL = "POST_GROUPS_FAIL";

export const GET_ACCESSORY = "GET_ACCESSORY";
export const POST_ACCESSORY = "POST_ACCESSORY";
export const POST_ADD_ACCESSORY = "POST_ADD_ACCESSORY";
export const DELETE_ACCESSORY = "DELETE_ACCESSORY";
export const DELETE_ACCESSORY_SUCCESS = "DELETE_ACCESSORY_SUCCESS";
export const DELETE_ACCESSORY_FAIL = "DELETE_ACCESSORY_FAIL";
