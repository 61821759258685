import { Input } from 'reactstrap';

function AddAccessoryInput({ objectValues, setObjectValues, property, min, type, rowid }) {
    
    function handleChange(e, rowid) {
        const newArr = [...objectValues];
        newArr.forEach((accessory, index) => {
            if (index === rowid) {
                accessory[property] = (isNaN(e.target.value)) ? (e.target.value) : (Number(e.target.value));
            }
        })
        console.log(newArr)
        setObjectValues(newArr);
    }

    return (
        <Input type={type} min={min} onChange={(e) => handleChange(e, rowid)} />
    )
}

export default AddAccessoryInput;