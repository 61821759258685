import React from 'react';
import { Input } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import * as moment from "moment";

export const Filter = ({ column }) => {
    return (
        <div style={{ marginTop: 5 }}>
            {column.canFilter && column.render('Filter')}
        </div>
    );
};

export const DefaultColumnFilter = ({
    column: {
        filterValue,
        setFilter,
        preFilteredRows: { length },
    },
}) => {
    return (
        <Input
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            placeholder={`search (${length}) ...`}
        />
    );
};

export const SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
}) => {
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);
    
    return (
        <select
            id="custom-select"
            className="form-select"
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.sort().map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
};

export const FlatpickrSingleColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows },
}) => {
    const handleValidDate = date => {
        const date1 = date !== undefined ? moment(new Date(date)).format("Y-MM-DD") : undefined;
        return date1;
    };

    return (
        <>
            <Flatpickr
                className="form-control bg-light border-light"
                id="custom-flatpickr"
                placeholder="Select a date"
                options={{
                    altInput: true,
                    altFormat: "Y-m-d",
                    mode: "single",
                    dateFormat: "Y-m-d",
                }}
                value={filterValue}
                onChange={(e) => {
                    setFilter(handleValidDate(e[0]) || undefined);
                }}
            />
        </>
    )
}