import { API_RESPONSE_ERROR, API_RESPONSE_SUCCESS, GET_INTERCHANGE } from "./actionType";

// common success
export const interchangeApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const interchangeApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getInterchange = (query) => ({
    type: GET_INTERCHANGE,
    payload: query
});