import { 
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    GET_ALLPRICES_DATA,
    POST_GLOBAL_PRICES,
    GET_USERS,
    POST_GLOBAL_MAX,
    POST_GLOBAL_DISCOUNT,
    GET_MONTH_TEST,
    GET_SALES_REPRESENTATIVES,
    POST_SALES_REPRESENTATIVE_SUCCESS,
    POST_SALES_REPRESENTATIVE_FAIL,
    DELETE_SALES_REPRESENTATIVE_SUCCESS,
    DELETE_SALES_REPRESENTATIVE_FAIL,
    UPDATE_SALES_REPRESENTATIVE_SUCCESS,
    UPDATE_SALES_REPRESENTATIVE_FAIL,
    GET_MONTH_TEST_MONTH_YEAR,
    GET_STATEMENT_OVERDUE_SUMMARY,
} from "./actionType";

const INIT_STATE = {
    prices: [],
}

const GlobalSettings = (state = INIT_STATE, action) => {
    switch(action.type) {
        case API_RESPONSE_SUCCESS:
            switch(action.payload.actionType) {
                case GET_ALLPRICES_DATA:
                    return{
                        ...state,
                        isPricesDownloaded: true,
                        isGlobalPricesUpdated: false,
                        prices: action.payload.data
                    }
                case POST_GLOBAL_PRICES:
                    return {
                        ...state,
                        isPricesDownloaded: false,
                        isGlobalPricesUpdated: true,
                    }
                case GET_USERS:
                    return {
                        ...state,
                        isUsersFetched: true,
                        users: action.payload.data,
                    }
                case POST_GLOBAL_MAX:
                    return {
                        ...state,
                        isGlobalMaxUpdated: true,
                    }
                case POST_GLOBAL_DISCOUNT:
                    return {
                        ...state,
                        isGlobalDiscountUpdated: true,
                    }
                case GET_SALES_REPRESENTATIVES:
                    return {
                        ...state,
                        salesRepresentatives: action.payload.data,
                        isPostSalesRepresentativeSuccess: false,
                        isPostSalesRepresentativeFail: false,
                        isDeleteSalesRepresentativeSuccess: false,
                        isDeleteSalesRepresentativeFail: false,
                        isUpdateSalesRepresentativeSuccess: false,
                        isUpdateSalesRepresentativeFail: false,
                    }
                case GET_MONTH_TEST:
                case GET_MONTH_TEST_MONTH_YEAR:
                case GET_STATEMENT_OVERDUE_SUMMARY:
                default:
                    return {
                        ...state,
                    }
            }
        case API_RESPONSE_ERROR:
            switch(action.payload.actionType) {
                case GET_ALLPRICES_DATA:
                    return{
                        ...state,
                        isPricesDownloaded: false,
                        error: action.payload.error
                    }
                case POST_GLOBAL_PRICES:
                    return{
                        ...state,
                        isGlobalPricesUpdated: false,
                        error: action.payload.error,
                    }
                case GET_USERS:
                    return {
                        ...state,
                        isUsersFetched: false,
                        error: action.payload.error,
                    }
                case POST_GLOBAL_MAX:
                    return {
                        ...state,
                        isGlobalMaxUpdated: false,
                        error: action.payload.error,
                    }
                case POST_GLOBAL_DISCOUNT:
                    return {
                        ...state,
                        isGlobalDiscountUpdated: false,
                        error: action.payload.error,
                    }
                case GET_SALES_REPRESENTATIVES:
                case GET_MONTH_TEST:
                case GET_MONTH_TEST_MONTH_YEAR:
                case GET_STATEMENT_OVERDUE_SUMMARY:
                    return {
                        ...state,
                        error: action.payload.error,
                    }
                default:
                    return {
                        ...state,
                    }
            }

        case POST_SALES_REPRESENTATIVE_SUCCESS:
            return {
                ...state,
                isPostSalesRepresentativeSuccess: true,
                isPostSalesRepresentativeFail: false,
            }

        case DELETE_SALES_REPRESENTATIVE_SUCCESS:
            return {
                ...state,
                isDeleteSalesRepresentativeSuccess: true,
                isDeleteSalesRepresentativeFail: false,
            }

        case UPDATE_SALES_REPRESENTATIVE_SUCCESS:
            return {
                ...state,
                isUpdateSalesRepresentativeSuccess: true,
                isUpdateSalesRepresentativeFail: false,
            }

        case POST_SALES_REPRESENTATIVE_FAIL:
            return {
                ...state,
                isPostSalesRepresentativeFail: true,
                isPostSalesRepresentativeSuccess: false,
                error: action.payload
            }

        case DELETE_SALES_REPRESENTATIVE_FAIL:
            return {
                ...state,
                isDeleteSalesRepresentativeFail: true,
                isDeleteSalesRepresentativeSuccess: false,
                error: action.payload
            }

        case UPDATE_SALES_REPRESENTATIVE_FAIL:
            return {
                ...state,
                isUpdateSalesRepresentativeFail: true,
                isUpdateSalesRepresentativeSuccess: false,
                error: action.payload,
            }
        
        default:
            return{
                ...state,
            }
    }
}
export default GlobalSettings;