import React from "react";
import { Redirect } from "react-router-dom";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import PasswordReset from "../pages/Authentication/PasswordReset";
import Logout from "../pages/Authentication/Logout";
import Application from "../pages/Authentication/Application";

// Pages
import Home from "../pages/Pages/Home/Home";

// Html
import ReturnPolicy from "../pages/Pages/Html/returnpolicy";

// Products
import PartSearch from "../pages/Pages/Products/PartSearch/PartSearch";
import Interchange from "../pages/Pages/Products/Interchange/Interchange";
import VehicleSearch from "../pages/Pages/Products/VehicleSearch/VehicleSearch";
import VehicleSearchMakeList from "../Components/Products/VehicleSearch/VehicleSearchMakeList";
import VehicleSearchModelList from "../Components/Products/VehicleSearch/VehicleSearchModelList";
import VehicleSearchCategoryList from "../Components/Products/VehicleSearch/VehicleSearchCategoriesList";
import VehicleSearchProductList from "../Components/Products/VehicleSearch/VehicleSearchProductList";

// New Order
import NewOrder from "../pages/Pages/NewOrder/NewOrder";
// import OrderDraft from "../pages/Pages/NewOrder/OrderDraft";
import ProperDraft from "../pages/Pages/NewOrder/ProperDraft";
import PreviewOrder from "../pages/Pages/NewOrder/PreviewOrder";
import Unshipped from "../pages/Pages/Unshipped/Unshipped";

// Returns
import BatchReturns from "../pages/Pages/Returns/BatchReturns";

// History
import AccountOverview from "../pages/Pages/AccountOverview/AccountOverview";
import OrderHistory from "../pages/Pages/History/OrderHistory/OrderHistory";
import OrderHistoryDetails from "../pages/Pages/History/OrderHistory/OrderHistoryDetails";
import ReturnHistory from "../pages/Pages/History/ReturnHistory/ReturnHistory";
import ReturnHistoryDetails from "../pages/Pages/History/ReturnHistory/ReturnHistoryDetails";
import CreditHistory from "../pages/Pages/History/CreditHistory/CreditHistory";
import CreditHistoryDetails from "../pages/Pages/History/CreditHistory/CreditHistoryDetails";
import Statements from "../pages/Pages/History/Statements/Statements";
import StatementDetails from "../pages/Pages/History/Statements/StatementDetails";

// Administrator
import AccountManagement from "../pages/Pages/Administrator/AccountManagment/AccountManagement";
import NewUser from "../pages/Pages/Administrator/AccountManagment/NewUser";
import Account from "../pages/Pages/Administrator/AccountManagment/Account";
import GlobalSettings from "../pages/Pages/Administrator/GlobalSetting/GlobalSettings";
import SystemMessages from "../pages/Pages/Administrator/SystemMessages/SystemMessage";

// Order Desk
import OrderApproval from "../pages/Pages/OrderDesk/OrderApproval/OrderApproval";
import OrderApprovalDetails from "../pages/Pages/OrderDesk/OrderApproval/OrderApprovalDetails";
import ReturnMaterialAuthorization from "../pages/Pages/OrderDesk/ReturnMaterialAuthorization/ReturnMaterialAuthorization";
import ReturnMaterialAuthorizationDetails from "../pages/Pages/OrderDesk/ReturnMaterialAuthorization/ReturnMaterialAuthorizationDetails";
import ReturnAdjustment from "../pages/Pages/OrderDesk/ReturnAdjustment/ReturnAdjustment";
import ReturnAdjustmentDetails from "../pages/Pages/OrderDesk/ReturnAdjustment/ReturnAdjustmentDetails";

// Warehouse
import Dispatch from "../pages/Pages/Warehouse/Dispatch/Dispatch";
import DispatchDetails from "../pages/Pages/Warehouse/Dispatch/DispatchDetails";
import QualityAssurance from "../pages/Pages/Warehouse/QuanlityAssurance/QualityAssurance";
import QualityAssuranceDetails from "../pages/Pages/Warehouse/QuanlityAssurance/QualityAssuranceDetails";

// Accounting
import IssueCredit from "../pages/Pages/Accounting/IssueCredit/IssueCredit";
import IssueCreditDetails from "../pages/Pages/Accounting/IssueCredit/IssueCreditDetails";
import OverdueStatements from "../pages/Pages/Accounting/OverdueStatements/OverdueStatements";

import NotAuthorized from "../pages/Errors/NotAuthorized";

const authProtectedRoutes = [
    // Pages
    { path: '/home', exact: true, component: Home },
    { path: '/returnpolicy', exact: true, component: ReturnPolicy },

    // Products
    { path: '/products/interchange', exact: true, component: Interchange },
    { path: '/products/part-search', exact: true, component: PartSearch },
    {
        path: '/products/vehicle-search', component: VehicleSearch, routes: [
            { path: '/products/vehicle-search/:year', component: VehicleSearchMakeList },
            { path: '/products/vehicle-search/:year/:make', component: VehicleSearchModelList },
            { path: '/products/vehicle-search/:year/:make/:model', component: VehicleSearchCategoryList },
            { path: '/products/vehicle-search/:year/:make/:model/:category', component: VehicleSearchProductList },
        ]
    },

    // New Order
    { path: '/new-order/:drafthash/preview-order', exact: true, component: PreviewOrder },
    {
        path: '/new-order', component: NewOrder, routes: [
            { path: '/new-order/:drafthash', component: ProperDraft },
        ]
    },

    // Unshipped
    { path: '/unshipped', exact: true, component: Unshipped },

    // Returns
    { path: '/returns', exact: true, component: BatchReturns },

    // History
    { path: '/account-overview', exact: true, component: AccountOverview },
    { path: '/history/order-history', exact: true, component: OrderHistory },
    { path: '/history/order-history/:order_id', exact: true, component: OrderHistoryDetails },
    { path: '/history/return-history', exact: true, component: ReturnHistory },
    { path: '/history/return-history/:return_id', exact: true, component: ReturnHistoryDetails },
    { path: '/history/credit-history', exact: true, component: CreditHistory },
    { path: '/history/credit-history/:credit_id', exact: true, component: CreditHistoryDetails },
    { path: '/history/statements', exact: true, component: Statements },
    { path: '/history/statements/:statement_id', exact: true, component: StatementDetails },

    // Administrator
    { path: '/administrator/account-management', exact: true, component: AccountManagement },
    { path: '/administrator/account-management/new-user', exact: true, component: NewUser },
    { path: '/administrator/account-management/:account', exact: true, component: Account },
    { path: '/administrator/globalsettings', exact: true, component: GlobalSettings },
    { path: '/administrator/system-messages', exact: true, component: SystemMessages },

    // Order Desk
    { path: '/order-desk/order-approval', exact: true, component: OrderApproval },
    { path: '/order-desk/order-approval/:orderId', exact: true, component: OrderApprovalDetails },
    { path: '/order-desk/return-material-authorization', exact: true, component: ReturnMaterialAuthorization },
    { path: '/order-desk/return-material-authorization/:returnid', exact: true, component: ReturnMaterialAuthorizationDetails },
    { path: '/order-desk/return-adjustment', exact: true, component: ReturnAdjustment },
    { path: '/order-desk/return-adjustment/:returnAdjustmentId', exact: true, component: ReturnAdjustmentDetails },

    // Warehouse
    { path: '/warehouse/dispatch', exact: true, component: Dispatch },
    { path: '/warehouse/dispatch/:order_id', exact: true, component: DispatchDetails },
    { path: '/warehouse/quality-assurance', exact: true, component: QualityAssurance },
    { path: '/warehouse/quality-assurance/:return_id', exact: true, component: QualityAssuranceDetails },

    // Accounting
    { path: '/accounting/issue-credit', exact: true, component: IssueCredit },
    { path: '/accounting/issue-credit/:return_id', exact: true, component: IssueCreditDetails },
    { path: '/accounting/overdue-statements', exact: true, component: OverdueStatements },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {
        path: "/",
        exact: true,
        component: () => <Redirect to="/home" />,
    },
];

const publicRoutes = [
    // Authentication Page
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/forgot-password", exact: true, component: ForgetPasswordPage },
    { path: "/forgot-password/reset/:adddresshash", exact: true, component: PasswordReset },
    { path: "/application", component: Application },
    { path: "/not-authorized", component: NotAuthorized },

];

export { authProtectedRoutes, publicRoutes };