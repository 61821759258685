function TableRows({ item, index }) {

    return (
        <tr key={index}>
            <td>{(item.s) ? (item.s) : (item.n)}</td>
            <td>{item.d}</td>
            <td>${(item.price).toFixed(2)}</td>
            <td>
                {(item.q > 0 && item.price > 0) ? ("Yes") : ("No")}
            </td>
        </tr>
    )
}

export default TableRows;