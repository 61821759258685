import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import { ToastContainer } from "react-toastify";
import * as moment from "moment";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import Loader from "../../../../Components/Common/Loader";
import TableContainer from "../../../../Components/Common/TableContainer";
import { FlatpickrSingleColumnFilter, SelectColumnFilter } from "../../../../Components/Common/filters";
import { downloadFiles } from "../../../../Components/Common/downloadFiles";
import { useProfile } from "../../../../Components/Hooks/UserHooks";

//Import actions
import {
    getInvoices as onGetInvoices,
    getFilteredInvoices as onGetFilteredInvoices,
    downloadInvoicePdf as onDownloadInvoicePdf,
    downloadPackingListPdf as onDownloadPackingListPdf,
} from "../../../../store/actions";

function OrderApproval() {
    const { userProfile } = useProfile();
    const dispatch = useDispatch();
    const [pdfOrderId, setPdfOrderId] = useState(null);
    const [invoice, setInvoice] = useState([]);

    const { invoices, isInvoiceSuccess, invoicePdf, isInvoicePdfDownloaded, packingListPdf, isPackingListPdfDownloaded, error } = useSelector((state) => ({
        invoices: state.Invoice.invoices,
        isInvoiceSuccess: state.Invoice.isInvoiceSuccess,
        invoicePdf: state.Invoice.invoicePdf,
        isInvoicePdfDownloaded: state.Invoice.isInvoicePdfDownloaded,
        packingListPdf: state.Invoice.packingListPdf,
        isPackingListPdfDownloaded: state.Invoice.isPackingListPdfDownloaded,
        error: state.Invoice.error,
    }));


    useEffect(() => {
        dispatch({ type: 'RESET_APP' });
    }, [dispatch])

    useEffect(() => {
        function getinvoices() {
            if (invoices && !invoices.length //&& ((Date.now() /1000) % 5==0)
    ) {
            dispatch(onGetFilteredInvoices({ status: 'pending' }));
        }
          }
          getinvoices()
        
        const interval = setInterval(() => getinvoices(), 5000)
        return () => {
          clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        // let temp = invoices.filter((item) => item.status === 'pending');
        setInvoice(invoices);
    }, [invoices]);

    useEffect(() => {
        if (isInvoicePdfDownloaded && pdfOrderId) {
            downloadFiles(invoicePdf, `Invoice ${pdfOrderId}.pdf`, 'application/pdf');
        }
    }, [invoicePdf, isInvoicePdfDownloaded, pdfOrderId])

    useEffect(() => {
        if (isPackingListPdfDownloaded && pdfOrderId) {
            downloadFiles(packingListPdf, `Packing List ${pdfOrderId}.pdf`, 'application/pdf');
        }
    }, [packingListPdf, isPackingListPdfDownloaded, pdfOrderId])

    const handleValidDate = date => {
        if (!date) return '';
        const date1 = moment(new Date(date)).format("Y-MM-DD");
        return date1;
    };

    // Checked All
    const checkedAll = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".invoiceCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    const handleDownloadInvoicePdf = useCallback((orderId) => {
        dispatch(onDownloadInvoicePdf(orderId));
        setPdfOrderId(orderId);
    }, [dispatch])

    const handleDownloadPackingListPdf = useCallback((orderId) => {
        dispatch(onDownloadPackingListPdf(orderId));
        setPdfOrderId(orderId);
    }, [dispatch])

    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="invoiceCheckBox" value={cellProps.row.original._id} />;
                },
                id: '#',
            },
            {
                Header: "ORDER ID",
                accessor: "order_id",
                filterable: true,
                Cell: (cell) => {
                    return <Link to={`/order-desk/order-approval/${cell.row.original.order_id}`} className="fw-medium link-primary">{cell.row.original.order_id}</Link>;
                },
            },
            {
                Header: "COMPANY NAME",
                accessor: (userProfile.access !== 5) ? ('company_name') : (null),
                Filter: SelectColumnFilter,
                Cell: (cell) => (
                    <>
                        {cell.row.original.company_name}
                    </>
                )
            },
            {
                Header: "DATE ORDERED",
                accessor: 'date_created',
                filterable: false,
                Filter: FlatpickrSingleColumnFilter,
                Cell: (invoice) => (
                    <>
                        {handleValidDate(invoice.row.original.date_created)}
                    </>
                ),
            },
            {
                Header: "TOTAL",
                filterable: false,
                Cell: (invoice) => (
                    <>
                        ${invoice.row.original.cost_final}
                    </>
                ),
            },
        ],
        [userProfile, handleDownloadInvoicePdf, handleDownloadPackingListPdf]
    );

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />
    if (!userProfile.permissions.view_order_history) return <Redirect to="/not-authorized" />

    document.title = "Order History | Max Advanced Brakes - Prozone";
    
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Order Approval" pageTitle="Order Approval" linkTo={'/order-desk/order-approval'} />
                    <Row>
                        <Col lg={12}>
                            <Card id="invoices">
                                <CardHeader className="border-0">
                                    <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-0 flex-grow-1">Order Approval</h5>
                                    </div>
                                </CardHeader>

                                <CardBody className="pt-0">
                                    <div>
                                        {isInvoiceSuccess && invoice ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(invoice || [])}
                                                isGlobalFilter={false}
                                                customPageSize={30}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="text-muted"
                                                thClass="text-muted"
                                            />
                                        ) : (<Loader error={error} />)}
                                        <ToastContainer closeButton={false} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default OrderApproval;