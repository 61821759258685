import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row, Table } from "reactstrap";

import { accessLabels } from "../constants/accountDetails";

import { 
    getCustomerUserGroup as onGetCustomerUserGroup, 
    updateUserGroup as onUpdateUserGroup,
} from "../../store/actions";

function GroupManagement({ username, userGroupId, companyName }) {
    const dispatch = useDispatch();
    const [userGroupArr, setUserGroupArr] = useState(null);
    const [tableInstance, setTableInstance] = useState(0);
    const { userGroup, isUpdateUserGroupSuccess, isUpdateUserGroupFail } = useSelector((state) => ({
        userGroup: state.Customer.userGroup,
        isUpdateUserGroupSuccess: state.Customer.isUpdateUserGroupSuccess,
        isUpdateUserGroupFail: state.Customer.isUpdateUserGroupFail,
    }))

    useEffect(() => {
        dispatch(onGetCustomerUserGroup(userGroupId));
    }, [dispatch, userGroupId, username])

    useEffect(() => {
        if (userGroup) {
            setUserGroupArr(userGroup)
        }
    }, [userGroup])

    useEffect(() => {
        if (isUpdateUserGroupSuccess) {
            alert('Changes applied')
            dispatch(onGetCustomerUserGroup(userGroupId));
        }
        
        if (isUpdateUserGroupFail) alert("Failed to apply permission changes");
    }, [dispatch, userGroupId, isUpdateUserGroupSuccess, isUpdateUserGroupFail])

    function handlePermissionChange(permission, value, arrIndex) {
        let tempUserGroupArr = userGroupArr;

        tempUserGroupArr[arrIndex].permissions[permission] = (value) ? (0) : (1);
        
        setUserGroupArr(tempUserGroupArr);
        setTableInstance(prev => prev + 1);
    }

    function handleApplyPermissionChanges() {
        dispatch(onUpdateUserGroup(userGroupArr))
    }

    return (
        <Card className="mt-3">
            <CardHeader tag="h5">
                {companyName}
                <Button className="float-end" color="max" onClick={handleApplyPermissionChanges}>Apply Changes</Button>
            </CardHeader>
            <CardBody>
                <Table key={tableInstance} striped bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Username</th>
                            <th>Name</th>
                            <th>Permissions</th>
                            <th>Account</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(userGroupArr) ? (
                            userGroupArr.map((item, arrIndex) => {
                                return (
                                    <tr key={arrIndex}>
                                        <td>{arrIndex + 1}</td>
                                        <td>{item.username}</td>
                                        <td>{item.firstname} {item.lastname}</td>
                                        <td>
                                            {Object.entries(item.permissions).map(([key, value], perIndex) => {
                                                return (
                                                    <FormGroup key={perIndex} check>
                                                        <Input type="checkbox" value={value} checked={(value) ? (true) : (false)} onChange={() => handlePermissionChange(key, value, arrIndex)} />
                                                        <Label className="ms-3" check>{key}</Label>
                                                    </FormGroup>
                                                )
                                            })}
                                        </td>
                                        <td>{accessLabels[item.access]}</td>
                                    </tr>
                                )
                            }
                        )) : (null)}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    )
}

export default GroupManagement;