import { all, call, fork, put, takeEvery } from "redux-saga/effects";

// Home Redux Statee 
import { GET_INTERCHANGE } from "./actionType";

import { 
    interchangeApiResponseError, 
    interchangeApiResponseSuccess 
} from "./action";

//Include Both Helper File with needed methods
import {
    getInterchange as getInterchangeApi,
} from "../../../helpers/backend_helper";

function* getInterchange({ payload: obj }) {
    try {
        const response = yield call(getInterchangeApi, obj.query, obj.method);
        yield put(interchangeApiResponseSuccess(GET_INTERCHANGE, response));
    } catch (error) {
        yield put(interchangeApiResponseError(GET_INTERCHANGE, error));
    }
}

export function* watchGetInterchange() {
    yield takeEvery(GET_INTERCHANGE, getInterchange);
}

function* interchangeSaga() {
    yield all([
      fork(watchGetInterchange),
    ]);
}

export default interchangeSaga;