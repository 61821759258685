import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Container, Form, Label } from "reactstrap";
import 'react-toastify/dist/ReactToastify.css';

import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";

// action
import { postRegistration as onPostRegistration } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

//import images 
import logoDark from "../../assets/images/BLACKLOGO1.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import RegistrationInput from "../../Components/Account/RegistrationInput";
import RegistrationOptionalInput from "../../Components/Account/RegistrationOptionalInput";
import RegistrationSelectInput from "../../Components/Account/RegistrationSelectInput";


const Application = () => {
    const dispatch = useDispatch();
    const [newUser, setNewUser] = useState({ firstName: '', lastName: '', company: '', phone: '', title: '', email: '', currentSupplier: '', partExp: '', stockInv: '', estimateVol: '', yearEstablished: '', state: 'ON', confirmEmail: '', terms: false });
    const requestParams = { newUser }; 

    const {isRegistrationPosted} = useSelector (state => ({
        isRegistrationPosted: state.Registration.isRegistrationPosted,
    }));

    useEffect(() => {
        dispatch({ type: "RESET_APP" })
    }, [dispatch])

    
    function postRegistration(){
        if ((newUser.email) && (newUser.email === newUser.confirmEmail)) {
            delete requestParams.terms;
            dispatch(onPostRegistration(newUser))
        } else {
            if (newUser.email !== newUser.confirmEmail) {
                alert("ERROR: Email do not match");
            }
        }
    }

    function onPhoneChange(e) {
        console.log(e)
        setNewUser({ newUser, 'phone': e.target.rawValue });
    }

    useEffect(() => {
        if (isRegistrationPosted) {
            alert("Registration Request Sent!");
        }    
    }, [isRegistrationPosted]);
    
    document.title = "Application | Max Advanced Brakes Wholesale";
    
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoDark} alt="" height="150" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">MAX PRO SUPERIOR QUALITY BRAKES FOR DISTRIBUTORS</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={10} lg={6} xl={10}>
                                <Card className="mt-4">

                                    <CardBody className="p-3">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Apply for an Account</h5>
                                        </div>
                                        <div className="p-2 mt-4">

                                            <Form 
                                                className="needs-validation" 
                                                action="#"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    postRegistration();
                                                    return false;
                                                }}
                                            >
                                                <Row>
                                                    <Row>
                                                        <Col>
                                                            <div className="mb-3">
                                                                <RegistrationInput type={"text"} name={"firstName"} newUser={newUser} value={newUser.firstName} setNewUser={setNewUser} label={"First Name"} />
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="mb-3">
                                                                <RegistrationInput type={"text"} name={"lastName"} newUser={newUser} value={newUser.lastName} setNewUser={setNewUser} label={"Last Name"} />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <div className="mb-3">
                                                            <RegistrationInput type={"text"} name={"company"} newUser={newUser} value={newUser.company} setNewUser={setNewUser} label={"Company Name"} />
                                                        </div>

                                                        <Col>
                                                            <div className="mb-3">
                                                                <Label>{"Business Phone Number"} <span className="text-danger">*</span></Label>
                                                                <Cleave
                                                                    placeholder="xxx xxx xxxx"
                                                                    options={{
                                                                        phone: true,
                                                                        phoneRegionCode: "CA"
                                                                    }}
                                                                    value={newUser.phone}
                                                                    onChange={onPhoneChange}
                                                                    className="form-control"
                                                                    required
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="mb-3">
                                                                <RegistrationInput type={"text"} name={"title"} newUser={newUser} value={newUser.title} setNewUser={setNewUser} label={"Title"} />
                                                            </div>
                                                        </Col>
                                                        <Row>
                                                            <Col>
                                                                <div className="mb-3">
                                                                    <RegistrationInput type={"text"} name={"state"} newUser={newUser} value={newUser.state} setNewUser={setNewUser} label={"Province/State"} />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="mb-3">
                                                                    <RegistrationOptionalInput type={"number"} name={"yearEstablished"} newUser={newUser} value={newUser.yearEstablished} setNewUser={setNewUser} label={"Year of Establishment"} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                    <Row className="p-3">
                                                        <Col>
                                                            <div className="mb-3">
                                                                <RegistrationOptionalInput type={"text"} name={"currentSupplier"} newUser={newUser} value={newUser.currentSupplier} setNewUser={setNewUser} label={"Who are your current suppliers for parts? (WorldPAC, World Automotive, etc."} />
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="mb-3">
                                                                <RegistrationSelectInput name={"partExp"}  newUser={newUser} value={newUser.partExp} setNewUser={setNewUser} label={"Are you experienced with part numbers from the following companies: (Aimco, Raybestos, Centric, MOOG)?"} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div className="mb-3">
                                                                <RegistrationOptionalInput type={"number"} name={"estimateVol"} newUser={newUser} value={newUser.estimateVol} setNewUser={setNewUser} label={"What is the estimated volume of orders per month?"} />
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="mb-3">
                                                                <RegistrationSelectInput name={"stockInv"}  newUser={newUser} value={newUser.stockInv} setNewUser={setNewUser} label={"Are you able to stock inventory at your location?"} />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <div className="mb-3">
                                                        <RegistrationInput type={"text"} name={"email"} newUser={newUser} value={newUser.email} setNewUser={setNewUser} label={"Email"} />
                                                    </div>
                                                    <div className="mb-2">
                                                        <RegistrationInput type={"text"} name={"confirmEmail"} newUser={newUser} value={newUser.confirmEmail} setNewUser={setNewUser} label={"Confirm Email"} />
                                                    </div>

                                                    <div className="mb-4">
                                                        <p className="mb-0 fs-12 text-muted fst-italic">By registering you agree to the Max Advanced Brakes
                                                            <Link to="#" className="text-primary text-decoration-underline fst-normal fw-medium"> Terms of Use</Link></p>
                                                    </div>

                                                    <div className="mt-4">
                                                        <button className="btn btn-success w-100" type="submit">Sign Up</button>
                                                    </div>
                                                </Row>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">Already have an account ? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Signin </Link> </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Application;

