import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Input, Row, Table } from "reactstrap";

import { postStatementPayment as onPostStatementPayment } from "../../../store/actions";

function StatementDetailsAccountingPayment({ statement }) {
    let dispatch = useDispatch()
    const [payment, setPayment] = useState(0.00);

    function handleStatementPayment(type) {
        if (!payment) {
            alert("A payment value must be provided");
            return;
        }

        if (type === 'discount' && (Number(payment) !== Number(statement.balance_discounted))) {
            alert('To enter discounted payment the payment value must match "Pay Within 10 Days Balance".');
            return;
        }

        if (type === 'multi-term' && Number(payment) > Number(statement.multi_term_orders_total)) {
            alert('To enter multi-term payment the payment value must be exact or lower than "Multi Term Orders Total".');
            return;
        }

        dispatch(onPostStatementPayment(statement.statement_id, payment, type))
    }

    return (
        <div className="mt-4">
            <div className="alert alert-success">
                <Row>
                    <Col md={9} className="text-dark">
                        <Row>
                            <Col>
                                {(statement.fully_paid === 1) ? (
                                    <>
                                        <p>Statement has been paid! <strong>${statement.paid_amount}</strong></p>
                                        <p>Last payment received on <strong>[{statement.date_paid?.slice(0, 10)}]</strong> by user <strong>[{statement.markpaid_user}]</strong></p>
                                    </>
                                ) : (null)}
                                <Table className="border-dark tableLayoutFixed" bordered>
                                    <thead className="align-top">
                                        <tr>
                                            {(statement.fully_paid !== 1) ? (
                                                <>
                                                    <th className="fs-4">Previous payment(s) for this statement:</th>
                                                    <th className="fs-4">Remaining balance:</th>
                                                </>
                                            ) : (null)}
                                            {(statement.multi_term_orders_total > 0 && statement.multi_term_order_payment !== statement.multi_term_orders_total) ? (
                                                <>
                                                    <th className="fs-4">Previous payment(s) for multi term orders for this statement:</th>
                                                    <th className="fs-4">Remaining multi term order total:</th>
                                                </>
                                            ) : (null)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {(statement.fully_paid !== 1) ? (
                                                <>
                                                    <td>
                                                        <strong className="fs-3">${statement.paid_amount}</strong>
                                                    </td>
                                                    <td>
                                                        <strong className="fs-3">${(Number(statement.balance) - Number(statement.paid_amount)).toFixed(2)}</strong> <br />(or <strong>${(Number(statement.balance_discounted) - Number(statement.paid_amount)).toFixed(2)}</strong> if paid within 10 days)
                                                    </td>
                                                </>
                                            ) : (null)}
                                            {(statement.multi_term_orders_total > 0 && statement.multi_term_order_payment !== statement.multi_term_orders_total) ? (
                                                <>
                                                    <td>
                                                        <strong className="fs-3">${statement.multi_term_order_payment}</strong>
                                                    </td>
                                                    <td>
                                                        <strong className="fs-3">${(Number(statement.multi_term_orders_total) - Number(statement.multi_term_order_payment)).toFixed(2)}</strong>
                                                    </td>
                                                </>
                                            ) : (null)}
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        {(statement.fully_paid !== 1 || statement.multi_term_orders_total !== statement.multi_term_order_payment) ? (
                            <>
                                <Col>
                                    <p>Enter the new amount paid. If there is a previous payment on record, it will be added to the current payment automatically.</p>
                                    <p>Amount Paid ($):</p>
                                </Col>
                                <Col>
                                    <Input type="number" className="form-control" value={payment} placeholder="0.00" maxLength={100} onChange={(e) => setPayment(e.target.value)} />
                                </Col>
                            </>
                        ) : (null)}
                    </Col>
                    <Col className="d-grid"> 
                        <Button color={'primary'} className={`btn btn-primary mb-3 rounded-pill ${(statement.fully_paid === 1) ? ('invisible') : ('')}`} onClick={() => handleStatementPayment('normal')}>Paid</Button>
                        <Button color={'success'} className={`btn btn-primary mb-3 rounded-pill ${(statement.fully_paid === 1) ? ('invisible') : ('')}`} onClick={() => handleStatementPayment('discount')}>Paid within 10 Days</Button>
                        {(statement.multi_term_orders_total > 0 && statement.multi_term_order_payment !== statement.multi_term_orders_total && statement.newest) ? (
                            <Button color={'warning'} className="btn btn-success rounded-pill" onClick={() => handleStatementPayment('multi-term')}>Paid Statement with Multi Term Orders</Button>
                        ) : (null)}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default StatementDetailsAccountingPayment;