import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import { APIClient } from '../../../helpers/api_helper';

// components
// User info
import { useProfile } from '../../../Components/Hooks/UserHooks';
import OrderDraftInformation from '../../../Components/NewOrder/OrderDraftInformation';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// actions
import {
    getCustomers as onGetCustomers,
    getDraft as onGetDraft,
    postDraftUpdate as onPostDraftUpdate,
    postProductAndPriceArray as onPostProductAndPriceArray,
    previewOrderDraft as onPreviewOrderDraft,
    previewOrderUsername as onPreviewOrderUsername,
} from "../../../store/actions";
import ProperDraftTable from '../../../Components/NewOrder/ProperDraftTable';
import { toast } from 'react-toastify';

const ProperDraft = ({ match }) => {
    let typingTimer; // setTimeout ID value
    let doneTypingInterval = 1000; // Typing interval
    let history = useHistory();
    const drafthash = match.params.drafthash;
    const previewOrderButtonRef = useRef();
    const dispatch = useDispatch();
    const { userProfile } = useProfile();
    const [username, setUsername] = useState(null);
    const [draftInfo, setDraftInfo] = useState({ user: (userProfile.username) ? (userProfile.username) : (''), shipto: (userProfile.address) ? (userProfile.address) : (""), ship_method: "Shipping", po: '', draft_name: "", maxnotes: '', mynotes: '', draftTotal: 0.00, is_promotion: 0, credit_terms: 30 });
    const [draftRowItems, setDraftRowItems] = useState(null);
    const [draftProductArr, setDraftProductArr] = useState([]);
    const [draftTotal, setDraftTotal] = useState(0.00);
    const [isLoading, setIsLoading] = useState(true);
    const [invalidSCBAndCNGRows, setInvalidSCBAndCNGRows] = useState([]);

    const API = new APIClient();
    const { draft, products, isDraftItemsDelete } = useSelector((state) => ({
        draft: state.Drafts.draft,
        products: state.Products.draftProducts,
        isDraftItemsDelete: state.Drafts.isDraftItemsDelete,
    }));

    useEffect(() => {
        // previewOrderButtonRef.current.disabled = true;
        dispatch(onGetDraft(drafthash));
    }, [dispatch, drafthash, isDraftItemsDelete])
    // useEffect(()=>{
    //     console.log(draftRowItems);
    // },[draftRowItems])
    useEffect(() => {
        if (userProfile.access !== 5) dispatch(onGetCustomers());
    }, [dispatch, userProfile])

    useEffect(() => {
        if (!draft || draft.drafthash !== drafthash) return;
        // console.log(draft);
        let tempShipMethod = 'shipping';
        let draftUser = (draft.user) ? (draft.user) : ('');
        let draftShipTo = (userProfile.address) ? (userProfile.address) : ("");
        let draftPo = (draft.po) ? (draft.po) : ("");
        let draftName = draft.draft_name;
        let draftMaxNotes = (draft.maxnotes) ? (draft.maxnotes) : ('');
        let draftMyNotes = (draft.mynotes) ? (draft.mynotes) : ('');
        let draftIsPromotion = (draft.is_promotion) ? (draft.is_promotion) : (0);
        let draftCreditTerms = (draft.credit_terms) ? (draft.credit_terms) : (30);

        if (draft.ship_method) {
            tempShipMethod = draft.ship_method;
        } else if (userProfile.default_ship_method) {
            tempShipMethod = (userProfile.default_ship_method === 1) ? ('shipping') : ('pickup');
        } else {
            tempShipMethod = 'shipping';
        }

        setDraftInfo({ user: draftUser, shipto: draftShipTo, ship_method: tempShipMethod, po: draftPo, draft_name: draftName, maxnotes: draftMaxNotes, mynotes: draftMyNotes, is_promotion: draftIsPromotion, credit_terms: draftCreditTerms });

        if (draft.draftitems && draft.draftitems.length) {

            let tempDraftRowProducts = draft.draftitems.map((item) => item.product);
            // dispatch(onPostProductAndPriceArray(tempDraftRowProducts, username, drafthash));

            setDraftRowItems(draft.draftitems);
            previewOrderButtonRef.current.disabled = false;
            setIsLoading(false);
        } else {
            setIsLoading(false);
            setDraftRowItems([]);
            setDraftProductArr([]);
            // previewOrderButtonRef.current.disabled = true;
        }
    }, [dispatch, draft, drafthash, userProfile, username])
    useEffect(() => {
        if (Object.keys(products).length && products[drafthash]) {
            // console.log(products);
            setDraftProductArr(products[drafthash]);
            setIsLoading(false);
        }
    }, [products, drafthash])
    function handleSelectUsername(username) {
        setUsername(username);
    }

    function handlePostDraftUpdate(draftitems) {
        dispatch(onPostDraftUpdate({ drafthash: drafthash, ...draftInfo, coupon: [], draft_total: draftTotal, draftitems: [] }));
    }

    function postChange() {
        clearTimeout(typingTimer);
        // let tempDraftRowItems = [];
        // if (draftRowItems) tempDraftRowItems = draftRowItems.filter((item) => item.product);
        // if(tempDraftRowItems.length)
        handlePostDraftUpdate(draftRowItems);
    }

    // Clear typing timer if the user is still typing
    function clearCount() {
        clearTimeout(typingTimer);
    }

    // Check if the user finished typing 
    function checkTyping() {
        clearTimeout(typingTimer);
        if (isLoading) return;
        typingTimer = setTimeout(postChange, doneTypingInterval);
        // postChange();
    }

    function previewOrderDraft() {
        if (invalidSCBAndCNGRows && invalidSCBAndCNGRows.length > 0) {
            toast.error('Error in quantity. Please make sure to add even quantity for SCB or CNZ');
            return;
        }

        let tempDraftRowItems = draftRowItems.filter((item) => item.product && item.max_product && item.description && item.qty > 0 && item.total > 0);
        console.log(tempDraftRowItems, draftRowItems);
        let tempUsername = (username) ? (username.value) : ('');

        if (!tempDraftRowItems.length) return;

        handlePostDraftUpdate(tempDraftRowItems);
        dispatch(onPreviewOrderDraft(tempDraftRowItems));
        dispatch(onPreviewOrderUsername(tempUsername));
        history.push(`/new-order/${drafthash}/preview-order`)
    }

    document.title = "Order Drafts | Max Advanced Brakes - Prozone";

    return (
        <>
            <Row key={drafthash} >
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <OrderDraftInformation userProfile={userProfile} draftInfo={draftInfo} setDraftInfo={setDraftInfo} username={username} handleSelectUsername={handleSelectUsername} checkTyping={checkTyping} clearCount={clearCount} />
                            {(draft && draftRowItems && draftProductArr && !isLoading) ? (
                                <ProperDraftTable draft={draft} API={API} draftid={draft.id} drafthash={drafthash} setUsername={setUsername} username={username} draftRowItems={draftRowItems} setDraftRowItems={setDraftRowItems} draftProductArr={draftProductArr} setDraftProductArr={setDraftProductArr} draftTotal={draftTotal} setDraftTotal={setDraftTotal} checkTyping={checkTyping} clearCount={clearCount} previewOrderButtonRef={previewOrderButtonRef} setInvalidSCBAndCNGRows={setInvalidSCBAndCNGRows} />
                            ) : (
                                <div className="d-flex justify-content-center mx-2 mt-2 mb-3">
                                    <Spinner className="spinner-size-4" color="info" />
                                </div>
                            )}
                            <Row>
                                <Col className="text-center">
                                    <Button innerRef={previewOrderButtonRef} color="max" className="btn btn-max" onClick={previewOrderDraft}>Preview Order</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ProperDraft;