import { all, call, fork, put, takeEvery } from "redux-saga/effects";

// Home Redux Statee 
import { 
    GET_ALLPRICES_DATA,
    POST_GLOBAL_PRICES,
    GET_USERS,
    POST_GLOBAL_MAX,
    POST_GLOBAL_DISCOUNT,
    GET_MONTH_TEST,
    GET_SALES_REPRESENTATIVES,
    POST_SALES_REPRESENTATIVE,
    DELETE_SALES_REPRESENTATIVE,
    UPDATE_SALES_REPRESENTATIVE,
    GET_MONTH_TEST_MONTH_YEAR,
    GET_STATEMENT_OVERDUE_SUMMARY,
} from "./actionType";

import { 
    globalSettingsApiResponseSuccess,
    globalSettingsApiResponseError,
    postSalesRepresentativeFail,
    postSalesRepresentativeSuccess,
    deleteSalesPresentativeFail,
    deleteSalesPresentativeSuccess,
    updateSalesRepresentativeFail,
    updateSalesRepresentativeSuccess,
} from "./action";

//Include Both Helper File with needed methods
import {
    getGlobalPrices as getGlobalPricesApi,
    updateGlobalPrices as updateGlobalPricesApi,
    getUsers as getUsersApi,
    postGlobalMax as postGlobalMaxApi,
    postGlobalDiscount as postGlobalDiscountApi,
    getMonthTest as getMonthTestApi,
    getMonthTestMonthYear as getMonthTestMonthYearApi,
    getSalesRepresentatives as getSalesRepresentativesApi,
    postSalesRepresentative as postSalesRepresentativeApi,
    deleteSalesRepresentative as deleteSalesRepresentativeApi,
    updateSalesRepresentative as updateSalesRepresentativeApi,
    getStatementOverdueSummary as getStatementOverdueSummaryApi,
} from "../../../helpers/backend_helper";

function* getGlobalPrices() {
    try {
        const response = yield call(getGlobalPricesApi); 
        yield put(globalSettingsApiResponseSuccess(GET_ALLPRICES_DATA, response));
    } catch(error) {
        yield put(globalSettingsApiResponseError(GET_ALLPRICES_DATA, error));
    }
}

function* updateGlobalPrices({payload: prices}) {
    try {
        const response = yield call(updateGlobalPricesApi, prices)
        yield put(globalSettingsApiResponseSuccess(POST_GLOBAL_PRICES, response));
    } catch(error) {
        yield put(globalSettingsApiResponseError(POST_GLOBAL_PRICES, error));
    }
}

function* getUsers() {
    try {
        const response = yield call(getUsersApi)
        yield put(globalSettingsApiResponseSuccess(GET_USERS, response));
    } catch (error) {
        yield put(globalSettingsApiResponseError(GET_USERS, error));
    }
}

function* postGlobalMax({payload: globalParam}) {
    try {
        const response = yield call(postGlobalMaxApi, globalParam)
        yield put(globalSettingsApiResponseSuccess(POST_GLOBAL_MAX, response));
    } catch (error) {
        yield put(globalSettingsApiResponseError(POST_GLOBAL_MAX, error));
    }
}

function* postGlobalDiscount({payload: globalParam}) {
    try {
        const response = yield call(postGlobalDiscountApi, globalParam)
        yield put(globalSettingsApiResponseSuccess(POST_GLOBAL_DISCOUNT, response));
    } catch (error) {
        yield put(globalSettingsApiResponseError(POST_GLOBAL_DISCOUNT, error));
    }
}

function* getMonthTest() {
    try {
        yield call(getMonthTestApi);
        yield put(globalSettingsApiResponseSuccess(GET_MONTH_TEST))
    } catch (error) {
        yield put(globalSettingsApiResponseError(GET_MONTH_TEST, error))
    }
}

function* getSalesRepresentatives() {
    try {
        const response = yield call(getSalesRepresentativesApi);
        yield put(globalSettingsApiResponseSuccess(GET_SALES_REPRESENTATIVES, response));
    } catch (error) {
        yield put(globalSettingsApiResponseError(GET_SALES_REPRESENTATIVES, error));
    }
}

function* postSalesRepresentative({ payload: username }) {
    try {
        yield call(postSalesRepresentativeApi, username);
        yield put(postSalesRepresentativeSuccess());
    } catch (error) {
        yield put(postSalesRepresentativeFail(error))
    }
}

function* deleteSalesPresentative({ payload: id }) {
    try {
        yield call(deleteSalesRepresentativeApi, id);
        yield put(deleteSalesPresentativeSuccess());
    } catch (error) {
        yield put(deleteSalesPresentativeFail(error));
    }
}

function* updateSalesRepresentative({ payload: obj }) {
    try {
        yield call(updateSalesRepresentativeApi, obj.replaceRep, obj.newRep);
        yield put(updateSalesRepresentativeSuccess());
    } catch (error) {
        yield put(updateSalesRepresentativeFail(error));
    }
}

function* getMonthTestMonthYear({ payload: monthYear }) {
    try {
        yield call(getMonthTestMonthYearApi, monthYear);
        yield put(globalSettingsApiResponseSuccess(GET_MONTH_TEST_MONTH_YEAR));
    } catch (error) {
        yield put(globalSettingsApiResponseError(GET_MONTH_TEST_MONTH_YEAR, error));
    }
}

function* getStatementOverdueSummary() {
    try {
        yield call(getStatementOverdueSummaryApi);
        yield put(globalSettingsApiResponseSuccess(GET_STATEMENT_OVERDUE_SUMMARY));
    } catch (error) {
        yield put(globalSettingsApiResponseError(GET_STATEMENT_OVERDUE_SUMMARY, error));
    }
}

export function* watchGetGlobalPrices() {
    yield takeEvery(GET_ALLPRICES_DATA, getGlobalPrices)
}

export function* watchUpdateGlobalPrices() {
    yield takeEvery(POST_GLOBAL_PRICES, updateGlobalPrices)
}

export function* watchGetUsers() {
    yield takeEvery(GET_USERS, getUsers)
}

export function* watchPostGlobalMax() {
    yield takeEvery(POST_GLOBAL_MAX, postGlobalMax)
}

export function* watchPostGlobalDiscount() {
    yield takeEvery(POST_GLOBAL_DISCOUNT, postGlobalDiscount)
}

export function* watchGetMonthTest() {
    yield takeEvery(GET_MONTH_TEST, getMonthTest);
}

export function* watchGetSalesRepresentatives() {
    yield takeEvery(GET_SALES_REPRESENTATIVES, getSalesRepresentatives);
}

export function* watchPostSalesRepresentative() {
    yield takeEvery(POST_SALES_REPRESENTATIVE, postSalesRepresentative);
}

export function* watchDeleteSalesPresentative() {
    yield takeEvery(DELETE_SALES_REPRESENTATIVE, deleteSalesPresentative);
}

export function* watchUpdateSalesRepresentative() {
    yield takeEvery(UPDATE_SALES_REPRESENTATIVE, updateSalesRepresentative);
}

export function* watchGetMonthTestMonthYear() {
    yield takeEvery(GET_MONTH_TEST_MONTH_YEAR, getMonthTestMonthYear);
}

export function* watchGetStatementOverdueSummary() {
    yield takeEvery(GET_STATEMENT_OVERDUE_SUMMARY, getStatementOverdueSummary)
}

function* globalSettingsSaga() {
    yield all([
        fork(watchGetGlobalPrices),
        fork(watchUpdateGlobalPrices),
        fork(watchGetUsers),
        fork(watchPostGlobalMax),
        fork(watchPostGlobalDiscount),
        fork(watchGetMonthTest),
        fork(watchGetSalesRepresentatives),
        fork(watchPostSalesRepresentative),
        fork(watchDeleteSalesPresentative),
        fork(watchUpdateSalesRepresentative),
        fork(watchGetMonthTestMonthYear),
        fork(watchGetStatementOverdueSummary)
    ]);
}

export default globalSettingsSaga;