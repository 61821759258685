import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { 
    postProductArrayFail,
    postProductArraySuccess,
    postProductsWholesaleInventoryFail,
    postProductsWholesaleInventorySuccess,
    vehicleApiResponseError,
    vehicleApiResponseSuccess 
} from "./action";

import { 
    GET_CATEGORIES,
    GET_MAKES,
    GET_MODELS,
    GET_YEARS, 
    POST_PRODUCTS_WHOLESALE_INVENTORY, 
    POST_PRODUCT_ARRAY
} from "./actionType";

import {
    getCategories as getCategoriesApi,
    getMakes as getMakesApi,
    getModels as getModelsApi,
    getYears as getYearsApi,
    postProductArray as postProductArrayApi,
    postProductsWholesaleInventory as postProductsWholesaleInventoryApi,
} from '../../../helpers/backend_helper';

function* getYears() {
    try {
        const response = yield call(getYearsApi); 
        yield put(vehicleApiResponseSuccess(GET_YEARS, response));
    } catch (error) {
        yield put(vehicleApiResponseError(GET_YEARS, error));
    }
}

function* getMakes({ payload: year }) {
    try {
        const response = yield call(getMakesApi, year); 
        yield put(vehicleApiResponseSuccess(GET_MAKES, response));
    } catch (error) {
        yield put(vehicleApiResponseError(GET_MAKES, error));
    }
}

function* getModels({ payload: obj }) {
    try {
        const response = yield call(getModelsApi, obj.year, obj.make); 
        yield put(vehicleApiResponseSuccess(GET_MODELS, response));
    } catch (error) {
        yield put(vehicleApiResponseError(GET_MODELS, error));
    }
}

function* getCategories({ payload: obj }) {
    try {
        const response = yield call(getCategoriesApi, obj.year, obj.make, obj.model); 
        yield put(vehicleApiResponseSuccess(GET_CATEGORIES, response));
    } catch (error) {
        yield put(vehicleApiResponseError(GET_CATEGORIES, error));
    }
}

function* postProductArray({ payload: productArr }) {
    try {
        const response = yield call(postProductArrayApi, productArr);
        yield put(postProductArraySuccess(response));
    } catch (error) {
        yield put(postProductArrayFail(error));
    }
}

function* postProductsWholesaleInventory({ payload: products }) {
    try {
        const response = yield call(postProductsWholesaleInventoryApi, products);
        yield put(postProductsWholesaleInventorySuccess(response));
    } catch (error) {
        yield put(postProductsWholesaleInventoryFail(error));
    }
}

export function* watchGetYears() {
    yield takeEvery(GET_YEARS, getYears);
}

export function* watchGetMakes() {
    yield takeEvery(GET_MAKES, getMakes);
}

export function* watchGetModels() {
    yield takeEvery(GET_MODELS, getModels);
}

export function* watchGetCategories() {
    yield takeEvery(GET_CATEGORIES, getCategories);
}

export function* watchPostProductArray() {
    yield takeEvery(POST_PRODUCT_ARRAY, postProductArray);
}

export function* watchPostProductsWholesaleInventory() {
    yield takeEvery(POST_PRODUCTS_WHOLESALE_INVENTORY, postProductsWholesaleInventory);
}

function* vehicleSaga() {
    yield all([
      fork(watchGetYears),
      fork(watchGetMakes),
      fork(watchGetModels),
      fork(watchGetCategories),
      fork(watchPostProductArray),
      fork(watchPostProductsWholesaleInventory),
    ]);
}

export default vehicleSaga;