import { 
    API_RESPONSE_ERROR,
    API_RESPONSE_SUCCESS,
    GET_CATEGORIES,
    GET_MAKES,
    GET_MODELS,
    GET_YEARS,
    RESET_VEHICLE_MAKES,
    RESET_VEHICLE_MODELS,
    RESET_VEHICLE_CATEGORIES,
    RESET_VEHICLE_PRODUCTS,
    POST_PRODUCTS_WHOLESALE_INVENTORY_FAIL, 
    POST_PRODUCTS_WHOLESALE_INVENTORY_SUCCESS, 
    POST_PRODUCT_ARRAY_FAIL, 
    POST_PRODUCT_ARRAY_SUCCESS, 
    RESET_APP, 
} from "./actionType";

const INIT_STATE = {
    years: [],
    makes: [],
    models: [],
    categories: [],
    productsInventory: [],
    product: [],
    error: {}
}

const Vehicle = (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_APP:
            return INIT_STATE;
        case RESET_VEHICLE_MAKES:
            return {
                ...state,
                makes: [],
                models: [],
                categories: [],
                productsInventory: [],
                product: [],
            };
        case RESET_VEHICLE_MODELS:
            return {
                ...state,
                models: [],
                categories: [],
                productsInventory: [],
                product: [],
            };
        case RESET_VEHICLE_CATEGORIES:
            return {
                ...state,
                categories: [],
                productsInventory: [],
                product: [],
            };
        case RESET_VEHICLE_PRODUCTS:
            return {
                ...state,
                productsInventory: [],
                product: [],
            };
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_YEARS:
                    return {
                        ...state,
                        years: action.payload.data,
                        productsInventory: [],
                        postProductWholesaleInventorySuccess: false,
                    };
                case GET_MAKES:
                    return {
                        ...state,
                        makes: action.payload.data,
                        productsInventory: [],
                        postProductWholesaleInventorySuccess: false,
                    };
                case GET_MODELS:
                    return {
                        ...state,
                        models: action.payload.data,
                        productsInventory: [],
                        postProductWholesaleInventorySuccess: false,
                    };
                case GET_CATEGORIES:
                    return {
                        ...state,
                        categories: action.payload.data,
                        productsInventory: [],
                        postProductWholesaleInventorySuccess: false,
                    };
                default:
                    return { ...state };
            }
        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_YEARS:
                case GET_MAKES:
                case GET_MODELS:
                case GET_CATEGORIES:
                    return {
                        ...state,
                        error: action.payload.error,
                    }
                default:
                    return { ...state };
            }

        case POST_PRODUCT_ARRAY_SUCCESS:
            return {
                ...state,
                products: action.payload
            }

        case POST_PRODUCTS_WHOLESALE_INVENTORY_SUCCESS:
            return {
                ...state,
                productsInventory: action.payload,
                postProductWholesaleInventorySuccess: true,
            }

        case POST_PRODUCT_ARRAY_FAIL:
        case POST_PRODUCTS_WHOLESALE_INVENTORY_FAIL:
            return {
                ...state,
                error: action.payload.error
            }

        default:
            return { ...state };
    }
}

export default Vehicle;