import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Card, CardBody, Col, Label, ListGroup, ListGroupItem, Row } from "reactstrap";

import {
    deleteProductGroupFromUser as onDeleteProductGroupFromUser,
    getProductGroups as onGetProductGroups,
    getUserProductGroups as onGetUserProductGroups,
    postNewProductGroupToUser as onPostNewProductGroupToUser
} from '../../store/actions';

function ProductGroupsAccountPanel({ userInfo }) {
    const dispatch = useDispatch();
    const [selectedProductGroup, setSelectedProductGroup] = useState(null);

    const { productGroups, userProductGroups, isPostNewProductToUserSuccess, isPostNewProductToUserFail, isDeleteProductGroupFromUserSuccess, isDeleteProductGroupFromUserFail } = useSelector((state) => ({
        productGroups: state.Customer.productGroups,
        userProductGroups: state.Customer.userProductGroups,
        isPostNewProductToUserSuccess: state.Customer.isPostNewProductToUserSuccess,
        isPostNewProductToUserFail: state.Customer.isPostNewProductToUserFail,
        isDeleteProductGroupFromUserSuccess: state.Customer.isDeleteProductGroupFromUserSuccess,
        isDeleteProductGroupFromUserFail: state.Customer.isDeleteProductGroupFromUserFail,
    }))

    useEffect(() => {
        dispatch(onGetProductGroups());
        dispatch(onGetUserProductGroups(userInfo.username));
    }, [dispatch])

    useEffect(() => {
        if (isPostNewProductToUserSuccess || isDeleteProductGroupFromUserSuccess) {
            dispatch(onGetUserProductGroups(userInfo.username));
        }

        if (isPostNewProductToUserFail || isDeleteProductGroupFromUserFail) {
            alert('Failed to update user product groups');
        }
    }, [dispatch, isPostNewProductToUserSuccess, isPostNewProductToUserFail, isDeleteProductGroupFromUserSuccess, isDeleteProductGroupFromUserFail])

    function getGroupName(groupId) {
        let groupName = groupId;
        
        if (groupId) {
            groupName = productGroups.find((item) => item.id === groupId)
        }

        return (groupName && groupName.description) ? (groupName.description) : (groupName);
    }

    function postNewProductGroupToUser() {
        if (selectedProductGroup) {
            dispatch(onPostNewProductGroupToUser(userInfo.username, selectedProductGroup));
        } else {
            alert('Select a group')
        }
    }

    function deleteProductGroupFromUser(groupId) {
        dispatch(onDeleteProductGroupFromUser(userInfo.username, groupId));
    }

    return (
        <Card className="mt-3">
            <CardBody>
                <Row>
                    <Col>
                        {(productGroups && productGroups.length) ? (
                            <>
                                <Label>Group Select:</Label>
                                <Select
                                    options={productGroups.map((item) => ({ value: item.id, label: item.description }))}
                                    onChange={(e) => setSelectedProductGroup(e.value)}
                                />
                                <Button color={'max'} className="mt-3" onClick={postNewProductGroupToUser}>Add Group Item</Button>
                            </>
                        ) : (null)}
                    </Col>
                    <Col>
                        <ListGroup>
                            {(userProductGroups && userProductGroups.length) ? (
                                userProductGroups.map((item, index) => 
                                    <ListGroupItem key={index}>
                                        {getGroupName(item.gid)}
                                        <Button color={'danger'} className="float-end" onClick={() => deleteProductGroupFromUser(item.id)}>X</Button>
                                    </ListGroupItem>
                                )
                            ) : (null)}
                        </ListGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default ProductGroupsAccountPanel;