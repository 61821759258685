import { productPatternRotor } from "../../constants/products";

function OrderHistoryDetailsTableRows({ index, item, userProfile, invoiceStatus }) {

    function displayRowAmount() {
        if (invoiceStatus === 'shipped' && item.qty_shipped) return (Number(item.price_discounted) * Number(item.qty_shipped)).toFixed(2);
        else return (Number(item.price_discounted) * Number(item.qty)).toFixed(2);
    }

    function getAmicoRaybestosCode(productCode) {
        let amicoRaybestosCode = '';

        if (productCode && productCode.match(productPatternRotor)) {
            let productLine = productCode.match(productPatternRotor);
            let tempCode = productCode.replace(productLine, '');
            amicoRaybestosCode = tempCode.substring(1);
        }

        return amicoRaybestosCode;
    }

    return (
        <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td>
                <span className="fw-medium">{(item.pcode) ? (item.pcode) : (item.product)}</span>
            </td>
            <td>
                <span className="fw-medium">{item.max_product}</span>
            </td>
            <td>
                <span className="fw-medium">{getAmicoRaybestosCode(item.product)}</span>
            </td>
            <td>
                <div className="table-cell-overflow">
                    <span className="fw-medium">{item.description}</span>
                </div>
            </td>
            <td>{item.qty}</td>
            {(userProfile.access !== 5) ? (<td>{item.forsale}</td>) : (null)}
            <td>
                {(item.qty_shipped || item.qty_shipped === 0) ? (item.qty_shipped) : (item.qty)}
            </td>
            <td className="noPrint">{item.qty_returned}</td>
            <td className="noPrint">${item.price}</td>
            <td>${item.price_discounted}</td>
            <td className="text-end">${displayRowAmount()}</td>
        </tr>
    )
}

export default OrderHistoryDetailsTableRows;