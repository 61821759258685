import { all, call, fork, put, takeEvery } from "redux-saga/effects";

// Home Redux Statee 
import { 
    DOWNLOAD_GROUP_TEMPLATE,
    GET_GROUPS,
    GET_GROUP_ITEMS,
    DELETE_GROUP,
    POST_GROUPS,
    GET_ACCESSORY,
    POST_ACCESSORY,
    POST_ADD_ACCESSORY,
    DELETE_ACCESSORY,
} from "./actionType";

import { 
    promotionsCouponsApiResponseSuccess,
    promotionsCouponsApiResponseError,
} from "./action";

import {
    getGroupTemplate as getGroupTemplateApi,
    getGroups as getGroupsApi,
    getGroupItems as getGroupItemsApi,
    deleteGroup as deleteGroupApi,
    postGroups as postGroupsApi,
    getAccessory as getAccessoryApi,
    postAccessory as postAccessoryApi,
    postAddAccessory as postAddAccessoryApi,
    deleteAccessory as deleteAccessoryApi,
} from "../../../helpers/backend_helper";

function* getGroupTemplate() {
    try {
        const response = yield call(getGroupTemplateApi)
        yield put(promotionsCouponsApiResponseSuccess(DOWNLOAD_GROUP_TEMPLATE, response));
    } catch(error) {
        yield put(promotionsCouponsApiResponseError(DOWNLOAD_GROUP_TEMPLATE, error));
    }
}

function* watchGetGroupTemplate() {
    yield takeEvery(DOWNLOAD_GROUP_TEMPLATE, getGroupTemplate)
}

function* getGroups() {
    try {
        const response = yield call(getGroupsApi)
        yield put(promotionsCouponsApiResponseSuccess(GET_GROUPS, response));
    } catch(error) {
        yield put(promotionsCouponsApiResponseError(GET_GROUPS, error));
    }
}

function* watchGetGroups() {
    yield takeEvery(GET_GROUPS, getGroups)
}

function* getGroupItems({payload:id}) {
    try {
        const response = yield call(getGroupItemsApi, id)
        yield put(promotionsCouponsApiResponseSuccess(GET_GROUP_ITEMS, response));
    } catch(error) {
        yield put(promotionsCouponsApiResponseError(GET_GROUP_ITEMS, error));
    }
}

function* watchGetGroupItems() {
    yield takeEvery(GET_GROUP_ITEMS, getGroupItems)
}

function* deleteGroup({payload:deleteid}) {
    try {
        const response = yield call(deleteGroupApi, deleteid)
        yield put(promotionsCouponsApiResponseSuccess(DELETE_GROUP, response));
    } catch(error) {
        yield put(promotionsCouponsApiResponseError(DELETE_GROUP, error));
    }
}

function* watchDeleteGroup(){
    yield takeEvery(DELETE_GROUP, deleteGroup)
}

function* postGroups({payload: groupParam}) {
    try {
        const response = yield call(postGroupsApi, groupParam)
        yield put(promotionsCouponsApiResponseSuccess(POST_GROUPS, response));
    } catch(error) {
        yield put(promotionsCouponsApiResponseError(POST_GROUPS, error));
    }
}

function* watchPostGroups() {
    yield takeEvery(POST_GROUPS, postGroups)
}

function* getAccessory() {
    try {
        const response = yield call(getAccessoryApi)
        yield put(promotionsCouponsApiResponseSuccess(GET_ACCESSORY, response));
    } catch(error) {
        yield put(promotionsCouponsApiResponseError(GET_ACCESSORY, error));
    }
}

function* watchGetAccessory() {
    yield takeEvery(GET_ACCESSORY, getAccessory)
}

function* postAccessory({payload: accessoryParam}) {
    try {
        const response = yield call(postAccessoryApi, accessoryParam)
        yield put(promotionsCouponsApiResponseSuccess(POST_ACCESSORY, response));
    } catch (error) {
        yield put(promotionsCouponsApiResponseError(POST_ACCESSORY, error));
    }
}

function* watchPostAccessory() {
    yield takeEvery(POST_ACCESSORY, postAccessory)
}

function* postAddAccessory({payload: addAccessoryParam}) {
    try {
        const response = yield call(postAddAccessoryApi, addAccessoryParam)
        yield put(promotionsCouponsApiResponseSuccess(POST_ADD_ACCESSORY, response));
    } catch (error) {
        yield put(promotionsCouponsApiResponseError(POST_ADD_ACCESSORY, error));
    }
}

function* watchPostAddAccessory() {
    yield takeEvery(POST_ADD_ACCESSORY, postAddAccessory)
}

function* deleteAccessory({payload: {accessoryBucket, accessoryPart}}) {
    try {
        const response = yield call(deleteAccessoryApi, accessoryBucket, accessoryPart)
        yield put(promotionsCouponsApiResponseSuccess(DELETE_ACCESSORY, response));
    } catch (error) {
        yield put(promotionsCouponsApiResponseError(DELETE_ACCESSORY, error));
    }
}

function* watchDeleteAccessory() {
    yield takeEvery(DELETE_ACCESSORY, deleteAccessory)
}

function* promotionsCouponsSaga() {
    yield all([
        fork(watchGetGroupTemplate),
        fork(watchGetGroups),
        fork(watchGetGroupItems),
        fork(watchDeleteGroup),
        fork(watchPostGroups),
        fork(watchGetAccessory),
        fork(watchPostAccessory),
        fork(watchPostAddAccessory),
        fork(watchDeleteAccessory),
    ]);
}                          

export default promotionsCouponsSaga;