import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";

import { 
    getInvoiceDetailsProductInterchange as onGetInvoiceDetailsProductInterchange
} from '../../../store/actions';

function DispatchDetailsTableRows({ index, item, userProfile, invoiceStatus, changeItemShippedQty }) {
    const [shipped, setShipped] = useState(item.qty_shipped ? item.qty_shipped : item.qty)

    // useEffect(() => {
    //     let tempMaxProduct = item.max_product;
    //     let tempNotMaxCode = item.product;
    //     let productToSearch = '';
        
    //     if (Object.hasOwn(item, 'maxCode')) return;
        
    //     productToSearch = tempNotMaxCode;
    //     if (tempMaxProduct && tempMaxProduct.match(/^OCG/i)) {
    //         productToSearch = tempMaxProduct;
    //     }
        
    //     dispatch(onGetInvoiceDetailsProductInterchange(productToSearch));
    // }, [dispatch])

    useEffect(() => {
        let shippedLine = { id: item.id, qty: Number(shipped) }
        changeItemShippedQty(index, shippedLine);
    }, [index, item.id, shipped, changeItemShippedQty])

    function handleChangeShippedQty(value) {
        setShipped(value);
    }

    function inputShippedQty() {
        return <Input type="number" max={item.qty} min="0" value={shipped} onChange={(e) => handleChangeShippedQty(e.target.value)} />
    }

    function displayShippedQty() {
        return (item.qty_shipped || item.qty_shipped === 0) ? (item.qty_shipped) : (item.qty);
    }

    function displayRowAmount() {
        if (invoiceStatus === 'shipped' && item.qty_shipped) return (Number(item.price_discounted) * Number(item.qty_shipped)).toFixed(2);
        else return (Number(item.price_discounted) * Number(item.qty)).toFixed(2);
    }
    
    return (
        <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td>
                <span className="fw-medium">{(item.pcode) ? (item.pcode) : (item.product)}</span>
            </td>
            <td>
                <span className="fw-medium">{item.max_product}</span>
            </td>
            <td>
                <div className="table-cell-overflow">
                    <span className="fw-medium">{item.description}</span>
                </div>
            </td>
            <td>{item.qty}</td>
            {(userProfile.access !== 5) ? (<td>{item.forsale}</td>) : (null)}
            <td>
                {((userProfile.access === 9 || userProfile.access === 1) && (invoiceStatus === 'unshipped' || invoiceStatus === 'processing')) ? (inputShippedQty()) : (displayShippedQty())}
            </td>
            <td className="noPrint">{item.qty_returned}</td>
            <td className="noPrint">${item.price}</td>
            <td>${item.price_discounted}</td>
            <td className="text-end">${displayRowAmount()}</td>
        </tr>
    )
}

export default DispatchDetailsTableRows;