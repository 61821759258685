import { all, fork } from "redux-saga/effects";

// layout
import LayoutSaga from "./layouts/saga";

// Auth
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import registrationSaga from "./auth/register/saga";

// IdleTimer
import idleTimerSaga from "./idleTimer/saga";

// Products
import productsSaga from "./products/part-search/saga";
import interchangeSaga from "./products/interchange/saga";
import vehicleSaga from "./products/vehicle-search/saga";

// drafts
import draftsSaga from "./drafts/saga";

// customer
import customerSaga from "./customer/saga";

// History
import invoiceSaga from "./history/order-history/saga";
import returnsSaga from "./history/return-history/saga";
import creditSaga from "./history/credit-history/saga";
import statementSaga from "./history/statements/saga";

// globalSettings
import globalSettingsSaga from "./administrator/globalSettings/saga";

// promotionsCoupons
import promotionsCouponsSaga from "./administrator/promotionsCoupons/saga";

// systemMessages
import systemMessagesSaga from "./administrator/systemMessages/saga";

export default function* rootSaga() {
    yield all([
        //public
        fork(LayoutSaga),
        fork(AuthSaga),
        fork(ForgetSaga),
        fork(ProfileSaga),
        fork(invoiceSaga),
        fork(productsSaga),
        fork(interchangeSaga),
        fork(vehicleSaga),
        fork(draftsSaga),
        fork(returnsSaga),
        fork(creditSaga),
        fork(statementSaga),
        fork(customerSaga),
        fork(globalSettingsSaga),
        fork(promotionsCouponsSaga),
        fork(registrationSaga),
        fork(idleTimerSaga),
        fork(systemMessagesSaga),
    ]);
}
