import {
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_ERROR,
    POST_RESET_PASSWORD,
    POST_RESET_PASSWORD_SUCCESS,
    POST_RESET_PASSWORD_FAIL,
} from "./actionTypes";

const initialState = {
    forgetSuccessMsg: null,
    forgetError: null,
    resetSuccessMsg: null,
    resetError: null,
};

const forgetPassword = (state = initialState, action) => {
    switch (action.type) {
        case FORGET_PASSWORD:
            return {
                ...state,
                forgetSuccessMsg: null,
                forgetError: null,
            };
        
        case POST_RESET_PASSWORD:
            return {
                ...state,
                resetSuccessMsg: null,
                resetError: null,
            };
        
        case FORGET_PASSWORD_SUCCESS:
            return {
                ...state,
                forgetSuccessMsg: action.payload,
            };
        
        case POST_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetSuccessMsg: action.payload,
            };
        
        case FORGET_PASSWORD_ERROR:
            return { 
                ...state, 
                forgetError: action.payload
            };
        
        case POST_RESET_PASSWORD_FAIL:
            return { 
                ...state, 
                resetError: action.payload
            };
        default:
            return { ...state };
    }
};

export default forgetPassword;
