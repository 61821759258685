import { 
    API_RESPONSE_ERROR, 
    API_RESPONSE_SUCCESS, 
    DOWNLOAD_CREDIT_INVOICE, 
    DOWNLOAD_CREDIT_INVOICE_FAIL, 
    DOWNLOAD_CREDIT_INVOICE_SUCCESS, 
    GET_CREDIT, 
    GET_CREDITS, 
    GET_CREDITS_FILTERED, 
    GET_CREDIT_DETAILS,
} from "./actionType";

// common success
export const creditApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const creditApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getCredits = () => ({
    type: GET_CREDITS,
});

export const getCreditsFiltered = (filter) => ({
    type: GET_CREDITS_FILTERED,
    payload: filter,
});

export const getCredit = (credit_id) => ({
    type: GET_CREDIT,
    payload: credit_id,
});

export const getCreditDetails = (credit_id) => ({
    type: GET_CREDIT_DETAILS,
    payload: credit_id,
});

export const downloadCreditInvoice = (creditId) => ({
    type: DOWNLOAD_CREDIT_INVOICE,
    payload: creditId
});