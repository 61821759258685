import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import { AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane, UncontrolledAccordion } from "reactstrap";
import * as XLSX from "xlsx";
import Select from "react-select";
import classnames from "classnames";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useProfile } from "../../../Components/Hooks/UserHooks";
import BatchReturnTableRow from "../../../Components/Returns/BatchReturnTableRow";
import { downloadFiles } from "../../../Components/Common/downloadFiles";
import { reasonOptions, typeOptions } from "../../../Components/constants/rgrform";
import PreviewRGRTableRow from "../../../Components/Returns/PreviewRGRTableRow";
import Loader from "../../../Components/Common/Loader";
import removeButton from '../../../assets/images/close.png';

import {
    postReturnBucket as onPostReturnBucket,
    getCustomers as onGetCustomers,
    getCustomerInformation as onGetCustomerInformation,
    getReturnBucket as onGetReturnBucket,
    postReturnInvoiceCreate as onPostReturnInvoiceCreate,
    deleteReturnBucketItem as onDeleteReturnBucketItem,
    updateReturnBucketItems as onUpdateReturnBucketItems,
    updateReturnBucket as onUpdateReturnBucket,
    donwloadReturnBatchTemplate as onDonwloadReturnBatchTemplate,
} from "../../../store/actions";

const BatchReturns = () => {
    const { userProfile } = useProfile();
    const dispatch = useDispatch();
    const [numRows, setNumRows] = useState(5);
    const [username, setUsername] = useState(null);
    const [customerProductsArr, setCustomerProductsArr] = useState({});
    const [fileItems, setFileItems] = useState([]);
    const [productsToReturnArray, setProductsToReturnArray] = useState([]);
    const [tableKey, setTableKey] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [globalType, setGlobalType] = useState('');
    const [globalReason, setGlobalReason] = useState('');
    const [returnBucketFormatted, setReturnBucketFormatted] = useState(null);
    const [totalReturnQty, setTotalReturnQty] = useState(0);
    const [totalReturnPrice, setTotalReturnPrice] = useState(0.00);
    const [instanceKey, setInstanceKey] = useState(0);
    let rows = [];
    let history = useHistory();

    const { customers, customerProducts, postBucketResponse, userInfo, returnError, customerError, returnBucket, isCreateReturnInvoiceSuccess, isCreateReturnInvoiceFail, isDeleteBucketItemSuccess, returnBatchTemplate, isReturnBatchTemplateDownloaded } = useSelector((state) => ({
        customers: state.Customer.customers,
        customerProducts: state.Returns.customerProducts,
        postBucketResponse: state.Returns.postBucketResponse,
        userInfo: state.Customer.userInfo,
        returnBucket: state.Returns.returnBucket,
        isCreateReturnInvoiceSuccess: state.Returns.isCreateReturnInvoiceSuccess,
        isCreateReturnInvoiceFail: state.Returns.isCreateReturnInvoiceFail,
        isDeleteBucketItemSuccess: state.Returns.isDeleteBucketItemSuccess,
        returnBatchTemplate: state.Returns.returnBatchTemplate,
        isReturnBatchTemplateDownloaded: state.Returns.isReturnBatchTemplateDownloaded,
        returnError: state.Returns.error,
        customerError: state.Customer.error,
    }));

    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    const [activeArrowTab, setactiveArrowTab] = useState(1);

    function toggleArrowTab(tab) {
        if (activeArrowTab !== tab) {
            let modifiedSteps = [...passedarrowSteps, tab];

            if (tab >= 1 && tab <= 4) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    }

    useEffect(() => {
        if (postBucketResponse) {
            let tempUsername = (username) ? (username) : (userProfile.username);
            dispatch(onGetReturnBucket(tempUsername));
        }
    }, [postBucketResponse, dispatch, userProfile]);

    useEffect(() => {
        if (isDeleteBucketItemSuccess) {
            let tempUsername = (username) ? (username) : (userProfile.username);
            alert('Deleted item from bucket');
            dispatch(onGetReturnBucket(tempUsername));
        }
    }, [dispatch, userProfile, isDeleteBucketItemSuccess]);
    
    useEffect(() => {
        if (returnBucket) {
            let products = [];
            let tempObj = {};
            let tempQty = returnBucket.reduce((total, item) => Number(total) + Number(item.quantity_to_return), 0);
            let tempTotal = returnBucket.reduce((total, item) => Number(total) + (Number(item.price_at_purchase) * Number(item.quantity_to_return)), 0);

            returnBucket.map((item) => {
                if (!products.includes(item.product)) {
                    products.push(item.product);
                    tempObj[item.product] = [item];
                    tempObj.return_bucket_id = item.return_bucket_id;
                } else {
                    tempObj[item.product].push(item);
                }
                return item;
            })
            
            setReturnBucketFormatted(tempObj);
            setTotalReturnQty(tempQty);
            setTotalReturnPrice(tempTotal);
            setIsLoading(false);
        } else {
            setReturnBucketFormatted({})
        }
    }, [returnBucket])

    useEffect(() => {
        // if (isCreateReturnInvoiceSuccess) {
        //     alert('Return Invoice Created');
        // }

        if (isCreateReturnInvoiceFail) alert('Return Request failed: ' + returnError);
    }, [isCreateReturnInvoiceSuccess, isCreateReturnInvoiceFail, returnError, history]);

    useEffect(() => {
        if (userProfile.access !== 5) dispatch(onGetCustomers());
    }, [dispatch, userProfile])

    useEffect(() => {
        if (customerProducts && customerProducts.length !== 0) setCustomerProductsArr(customerProducts);
    }, [customerProducts])

    useEffect(() => {
        if (postBucketResponse) {
            setIsLoading(true);
            // alert(`Status:  ${postBucketResponse.status} | message: ${postBucketResponse.message}`);
        }
    }, [postBucketResponse, history, userProfile, username])

    useEffect(() => {
        if (username) dispatch(onGetCustomerInformation(username)); 
        else dispatch(onGetCustomerInformation(userProfile.username));
    }, [dispatch, username])

    useEffect(() => {
        if (isReturnBatchTemplateDownloaded) {
            downloadFiles(returnBatchTemplate, 'return-batch-template.xlsx', 'application/json')
        }
    }, [isReturnBatchTemplateDownloaded, returnBatchTemplate])

    function handleRemoveBucketItem(product) {
        dispatch(onDeleteReturnBucketItem(product, returnBucketFormatted.return_bucket_id));
    }

    function handleSelectTypeAndReason(value, product, column) {
        let newReturnBucketFormatted = returnBucketFormatted;
        newReturnBucketFormatted[product].map((item) => item[column] = value);
        
        dispatch(onUpdateReturnBucketItems(value, product, newReturnBucketFormatted.return_bucket_id, column));
        setReturnBucketFormatted(newReturnBucketFormatted);
    }

    function handleSubmitReturnRequest() {
        let tempUsername = (username) ? (username) : (userProfile.username);
        let typeAndReasonCheck = returnBucket.every((item) => item.type && item.reason)

        if (typeAndReasonCheck) {
            dispatch(onPostReturnInvoiceCreate(tempUsername, returnBucket, totalReturnPrice, totalReturnQty));
            toggleArrowTab(activeArrowTab + 1);
        } else {
            alert('A type or reason was not given for product(s)');
        }
    }

    function handleSelectUsername(item) {
        setUsername(item.value);
    }

    function handleFileUpload(e) {
        let file = e.target.files[0];
        let filetype = 'other';

        if (!file) return;
        if (file.name.toLowerCase().endsWith('xlsx')) filetype = 'xlsx';

        let reader = new FileReader();
        reader.onload = function(evt) {
            let data = evt.target.result;
            let parsed = '';
            if (filetype === 'xlsx') {
                let workbook = XLSX.read(data, { type:'binary' });
                parsed = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
            } else {
                parsed = data.trim().split("\n");
                for (let i in parsed) {
                    parsed[i] = parsed[i].split(",");
                }

                for (let i = 1; i < parsed.length; i++) {
                    parsed[i][1] = Number(parsed[i][1]);
                }
            }
            
            handleParsedUploadFile(parsed);
        }

        if (filetype === 'xlsx') reader.readAsBinaryString(file);
        else reader.readAsText(file);
    }

    function handleParsedUploadFile(parsedFileItems) {
        if (!parsedFileItems && parsedFileItems.length !== 0) return;
        let tempFileItems = fileItems; // change
        for (let fileItem in parsedFileItems) {
            if (fileItem === 0 || fileItem === '0') continue;
            let tempRowItem = { product: parsedFileItems[fileItem][0], qty: parsedFileItems[fileItem][1] };
            tempFileItems.push(tempRowItem); // change
        }

        tempFileItems = tempFileItems.filter((value, index, self) => value !== undefined && index === self.findIndex((t) => t.product === value.product));

        setNumRows(prevState => Number(prevState) + tempFileItems.length);
        setFileItems(tempFileItems); // change
    }

    function handleDownloadReturnBatchTemplate() {
        dispatch(onDonwloadReturnBatchTemplate())
    }

    function handleClearTable() {
        dispatch({ type: 'RESET_APP' });
        setNumRows(5);
        setProductsToReturnArray([]);
        setCustomerProductsArr({});
        setFileItems([]);
        setUsername(null);
        setTableKey(i => i + 1);
    }

    function handlePreviewReturns() {
        if (!productsToReturnArray && !productsToReturnArray.length) return;
        let checkZeroQty = productsToReturnArray.every((item) => Number(item.qty) > 0)

        if (!checkZeroQty) {
            alert('A product does not have qty');
            return;
        }

        let tempUsername = (username) ? (username) : (userProfile.username)
        dispatch(onPostReturnBucket(productsToReturnArray, tempUsername));
        toggleArrowTab(activeArrowTab + 1);
    }

    function handleGlobalTypeReason(e) {
        e.preventDefault();
        setIsLoading(true);
        let temp = returnBucketFormatted;
        
        for (let key in temp) {
            if (key === 'return_bucket_id') continue;
            for (let item in temp[key]) {
                temp[key][item].type = globalType;
                temp[key][item].reason = globalReason;
            }
        }
        
        setReturnBucketFormatted(temp);
        setInstanceKey(instanceKey + 1);
        setIsLoading(false);
    }

    for (let i = 0; i < numRows; i++) {
        rows[i] = <BatchReturnTableRow key={i} rowId={i} customerProducts={customerProductsArr[i]} productsToReturnArray={productsToReturnArray} setProductsToReturnArray={setProductsToReturnArray} fileItem={fileItems[i]} username={username} />;
    }

    if ((typeof customerError === 'string' && customerError.match("401")) || (typeof returnError === 'string' && returnError.match("401"))) return <Redirect to="/login" />
    if (!userProfile.permissions.return_creation) return <Redirect to="/not-authorized" />

    document.title = "Batch Returns | Max Advanced Brakes - Prozone";

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Batch Returns" pageTitle="Returns" linkTo={`/returns`} />
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    {(userProfile.access !== 5) ? (
                                        <>
                                            <Label className="form-label">Placing Return As: {(username) ? (username) : (null)}</Label>
                                            <Select
                                                value={username}
                                                isClearable={true}
                                                onChange={(e) => {
                                                    handleSelectUsername(e);
                                                }}
                                                options={
                                                    customers.map((item) => {
                                                        return { value: item.username, label: `${item.company_name} (${item.username})` }
                                                    }
                                                )}
                                            />
                                        </>
                                    ) : (null)}
                                </Col>
                                <Col>
                                    <Label>Upload a file:</Label>
                                    <Input type="file" onChange={(e) => handleFileUpload(e)} />
                                </Col>
                                <Col lg={2} className="text-center">
                                    <Button onClick={handleDownloadReturnBatchTemplate}>Download Template</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Batch Returns</h4>
                                </CardHeader>
                                <CardBody className="form-steps">
                                    <Card>
                                        <CardHeader className="bg-light border">
                                            <h5>CUSTOMER INFORMATION</h5>
                                        </CardHeader>
                                        <CardBody>
                                            {(userInfo) ? (
                                                <Table bordered>
                                                    <tbody>
                                                        <tr>
                                                            <th>COMPANY NAME</th>
                                                            <td>{userInfo.company_name}</td>
                                                            <th>DATE (YYYY-MM-DD)</th>
                                                            <td>{new Date().toISOString().split('T')[0]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>CONTACT PERSON</th>
                                                            <td>{userInfo.firstname} {userInfo.lastname}</td>
                                                            <th>PHONE NUMBER</th>
                                                            <td>{userInfo.phone_primary}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>EMAIL</th>
                                                            <td colSpan={3}>{userInfo.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>ADDRESS</th>
                                                            <td colSpan={3}>{userInfo.address}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            ) : (null)}
                                        </CardBody>
                                    </Card>
                                    <div className="step-arrow-nav mb-4">
                                        <Nav className="nav-pills custom-nav nav-justified" role="tablist" >
                                            <NavItem>
                                                <NavLink href="#" id="steparrow-gen-info-tab" className={classnames({ active: activeArrowTab === 1, done: activeArrowTab <= 6 && activeArrowTab > 3, })} onClick={() => toggleArrowTab(1)}>Return Table</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#" id="steparrow-gen-info-tab" className={classnames({ active: activeArrowTab === 2, done: activeArrowTab <= 6 && activeArrowTab > 4, })} onClick={() => toggleArrowTab(2)}>Preview Returns</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="#" id="steparrow-gen-info-tab" className={classnames({ active: activeArrowTab === 3, done: activeArrowTab <= 6 && activeArrowTab > 5, })} onClick={() => toggleArrowTab(3)}>Finish</NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>

                                    <TabContent activeTab={activeArrowTab}>
                                        <TabPane id="steparrow-gen-info" tabId={1}>
                                            <div>
                                                <div className="mb-3">
                                                    <Table key={tableKey} className="mobile-inherit" bordered striped>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Query</th>
                                                                <th>Product</th>
                                                                <th>Shipped</th>
                                                                <th>Available To Return</th>
                                                                <th>Return</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {rows}
                                                        </tbody>
                                                    </Table>
                                                    <Button className="me-3" onClick={handleClearTable}>Clear Table</Button>
                                                    <Button onClick={() => setNumRows(prevState => prevState + 5)}>Add lines</Button>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-start gap-3 mt-4">
                                                <button className="btn btn-success btn-label right ms-auto nexttab nexttab" type="button" onClick={handlePreviewReturns}>
                                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                    Preview Returns
                                                </button>
                                            </div>
                                        </TabPane>

                                        <TabPane id="steparrow-description-info" tabId={2}>
                                            <Row>
                                                <Col lg={12}>
                                                    <Card id="batch-returns">
                                                        <CardBody>
                                                            <CardHeader>
                                                                <Row>
                                                                    <Col>
                                                                        <h4>Apply Type And Reason For multiple products</h4>
                                                                    </Col>
                                                                </Row>
                                                                <form onSubmit={(e) => handleGlobalTypeReason(e)}>
                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <Label>Type:</Label>
                                                                            <Select
                                                                                className="enable-click-event"
                                                                                isClearable={true}
                                                                                options={typeOptions}
                                                                                onChange={(e) => setGlobalType(e.value)}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Label>Reason:</Label>
                                                                            <Select
                                                                                className="enable-click-event"
                                                                                isClearable={true}
                                                                                options={reasonOptions}
                                                                                onChange={(e) => setGlobalReason(e.value)}
                                                                            />
                                                                        </Col>
                                                                        <Col md="1">
                                                                            <Button color={'max'} type="submit">Update</Button>
                                                                        </Col>
                                                                    </Row>
                                                                </form>
                                                                <Row className="border-top">
                                                                    <Col md={1}></Col>
                                                                    <Col md={1}>#</Col>
                                                                    <Col md={1}>Product</Col>
                                                                    <Col>Quantity</Col>
                                                                    <Col>Type</Col>
                                                                    <Col>Reason</Col>
                                                                </Row>
                                                            </CardHeader>
                                                            <UncontrolledAccordion flush key={instanceKey}>
                                                                {(!isLoading && returnBucketFormatted && Object.keys(returnBucketFormatted).length) ? (
                                                                    Object.keys(returnBucketFormatted).map((key, index) => {
                                                                        let tempReturnQty = 0;
                                                                        let tempReturnPrice = 0.00;
                                                                        let tempReason = null;
                                                                        let tempType = null;
                                                                        
                                                                        if (key === 'return_bucket_id') return null;
                                                                        
                                                                        tempReturnQty = returnBucketFormatted[key].reduce((total, item) => Number(total) + Number(item.quantity_to_return), 0);
                                                                        tempReturnPrice = returnBucketFormatted[key].reduce((total, item) => Number(total) + (Number(item.price_at_purchase) * Number(item.quantity_to_return)), 0);
                                                                        tempReason = returnBucketFormatted[key][0].reason;
                                                                        tempType = returnBucketFormatted[key][0].type;
                                                                        
                                                                        return (
                                                                            <AccordionItem key={index}>
                                                                                <AccordionHeader className="disable-click-event" targetId={index.toString()}>
                                                                                    <Row className="returnBucketAccordianHeaderRow">
                                                                                        <Col md={1}>
                                                                                            <img className="enable-click-event cursorPointer" src={removeButton} alt="remove button" onClick={() => handleRemoveBucketItem(key)} />
                                                                                        </Col>
                                                                                        <Col md={1}>{index}</Col>
                                                                                        <Col>{key}</Col>
                                                                                        <Col>{tempReturnQty}</Col>
                                                                                        <Col md={4}>
                                                                                            <Select
                                                                                                defaultValue={typeOptions.find((option) => option.value === tempType)}
                                                                                                className="enable-click-event"
                                                                                                isClearable={true}
                                                                                                options={typeOptions}
                                                                                                onChange={(e) => (e) ? (handleSelectTypeAndReason(e.value, key, 'type')) : (null)}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col md={4}>
                                                                                            <Select
                                                                                                defaultValue={reasonOptions.find((option) => option.value === tempReason)}
                                                                                                className="enable-click-event"
                                                                                                isClearable={true}
                                                                                                options={reasonOptions}
                                                                                                onChange={(e) => (e) ? (handleSelectTypeAndReason(e.value, key, 'reason')) : (null)}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </AccordionHeader>
                                                                                <AccordionBody accordionId={index.toString()}>
                                                                                    <Table bordered striped>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>#</th>
                                                                                                    <th>Product</th>
                                                                                                    <th>QTY</th>
                                                                                                    <th>INVOICE NO.</th>
                                                                                                    <th>SALES DATE</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {returnBucketFormatted[key].map((item, index) => <PreviewRGRTableRow key={index} item={item} index={index} />)}
                                                                                            </tbody>
                                                                                        </Table>
                                                                                </AccordionBody>
                                                                            </AccordionItem>
                                                                        )
                                                                    })
                                                                ) : (<Loader />)}
                                                            </UncontrolledAccordion>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <div className="d-flex align-items-start gap-3 mt-4">
                                                <button type="button" className="btn btn-light btn-label previestab" onClick={() => toggleArrowTab(activeArrowTab - 1)}>
                                                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                                    Back to Return Table
                                                </button>
                                                <button type="button" className="btn btn-success btn-label right ms-auto nexttab nexttab" onClick={() => {
                                                    handleSubmitReturnRequest();
                                                }}>
                                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                    Submit
                                                </button>
                                            </div>
                                        </TabPane>

                                        <TabPane id="pills-experience" tabId={3}>
                                            <div className="text-center">
                                                {(isCreateReturnInvoiceSuccess) ? (
                                                    <>
                                                        <div className="avatar-md mt-5 mb-4 mx-auto">
                                                            <div className="avatar-title bg-light text-success display-4 rounded-circle">
                                                                <i className="ri-checkbox-circle-fill"></i>
                                                            </div>
                                                        </div>
                                                        <h5>Return Request Placed !</h5>
                                                        <p className="text-muted">Your Return Request has been submitted</p>
                                                    </>
                                                ) : (null)}
                                                {(isCreateReturnInvoiceFail) ? (
                                                    <>
                                                        <div className="avatar-md mt-5 mb-4 mx-auto">
                                                            <div className="avatar-title bg-light text-danger display-4 rounded-circle">
                                                                <i className="ri-close-circle-fill"></i>
                                                            </div>
                                                        </div>
                                                        <h5>Return Request Failed !</h5>
                                                        <p className="text-muted">Your Return Request failed to submit</p>
                                                    </>
                                                ) : (null)}
                                            </div>
                                            <div className="d-flex align-items-start gap-3 mt-4">
                                                <button type="button" className="btn btn-light btn-label previestab" 
                                                    onClick={() => {
                                                        toggleArrowTab(1);
                                                        handleClearTable();
                                                    }}
                                                >
                                                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                                    Back to Return Table
                                                </button>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default BatchReturns;