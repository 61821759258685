import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
    const user = localStorage.getItem("user");
    if (user) return JSON.parse(user);
    return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
};

// Login Method
export const postLogin = data => api.create(url.POST_MAX_LOGIN, data);

// Forgot Password
export const postForgotPassword = (email) => api.create(url.POST_PASSWORD_FORGET, email);

// Reset Password
export const postResetPassword = (code, hash, password) => api.create(url.POST_RESET_PASSWORD, { code, hash, password });

// Registration
export const postRegistration = requestParams => api.create(url.POST_REGISTRATION, requestParams)

// Register Method
export const postJwtRegister = (url, data) => {
    return api.create(url, data)
        .catch(err => {
            let message;
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = "Sorry! the page you are looking for could not be found";
                        break;
                    case 500:
                        message = "Sorry! something went wrong, please contact our support team";
                        break;
                    case 401:
                        message = "Invalid credentials";
                        break;
                    default:
                        message = err[1];
                        break;
                }
            }
            throw message;
        });
};

export const logout = () => api.get(url.GET_MAX_LOGOUT);

// IdleTimer
// ping
export const ping = () => api.get(url.GET_PING);

// Products
// get Products
export const getProducts = query => api.get(url.GET_PRODUCTS + '/' + query);

// get Product with prices
export const postProductWithPrices = (query, username) => api.create(url.POST_PRODUCT_WITH_PRICES + '/' + query, { username });

// get Price List
export const postPriceList = products => api.create(url.POST_PRICELIST, products);

// post Product Select log
export const postProductSelectLog = (logId, product) => api.create(url.POST_PRODUCT_SELECT_LOG, { logId, product })

// post Product And Price array
export const postProductAndPriceArray = (productArr, username) => api.create(url.POST_PRODUCT_AND_PRICE_ARRAY, { productArr, username });

// post Product array
export const postProductArray = (productArr) => api.create(url.POST_PRODUCT_ARRAY, { productArr })

// Interchange
// get Interchange
export const getInterchange = (query, method) => api.get(url.GET_INTERCHANGE + '/' + query + '/' + method);

// Vehicle Search
// get Years
export const getYears = () => api.get(url.GET_YEARS);

// get Makes
export const getMakes = (year) => api.get(url.GET_MAKES, { year });

// get Models
export const getModels = (year, make) => api.get(url.GET_MODELS, { year, make });

// get Categories
export const getCategories = (year, make, model) => api.get(url.GET_CATEGORIES, { year, make, model });

// post Products for wholesale inventory
export const postProductsWholesaleInventory = (products) => api.create(url.POST_PRODUCTS_WHOLESALE_INVENTORY, { data: products });

// New Order
// get Drafts
export const getDrafts = () => api.get(url.GET_DRAFTS);

// get Draft
export const getDraft = (drafthash) => api.get(url.GET_DRAFT + '/' + drafthash);

// post New Order
export const createNewOrder = (order) => api.create(url.CREATE_NEW_ORDER, order);

// post New draft
export const addDraft = (params) => api.create(url.ADD_DRAFT, params);

// post Update to draft
export const postDraftUpdate = (fields) => api.create(url.POST_DRAFT_UPDATE, { ...fields });

// delete draft
export const deleteDraft = (drafthash) => api.delete(url.DELETE_DRAFT + '/' + drafthash);

// delete draft items
export const deleteDraftItems = (drafthash) => api.delete(url.DELETE_DRAFT_ITEMS + '/' + drafthash);

// download draft template
export const downloadDraftTemplate = () => api.download(url.DOWNLOAD_DRAFT_TEMPLATE);

// Returns
// get Returns
export const getReturns = () => api.get(url.GET_RETURNS);

// get Returns filtered
export const getReturnsFiltered = (filter) => api.get(url.GET_RETURNS, { ...filter });

// get Return
export const getReturn = (return_id) => api.get(url.GET_RETURN, { return_id: return_id });

// get Return details
export const getReturnDetails = (return_id) => api.get(url.GET_RETURN_DETAILS, { return_id: return_id });

// get Return details extra
export const getReturnDetailsExtra = (return_id) => api.get(url.GET_RETURN_DETAILS_EXTRA, { return_id: return_id });

// get Customer Products
export const getCustomerProducts = (username, query, line) => api.get(url.GET_CUSTOMER_PRODUCTS, { username, product: query, line });

// download RGR Form
export const getRgrForm = () => api.download(url.GET_RGR_FORM, 'application/pdf');

// post Return Bucket
export const postReturnBucket = (productsToReturn, username) => api.create(url.POST_RETURN_BUCKET, { returns: productsToReturn, username });

// update Return bucket
export const updateReturnBucket = (returnBucketId, value, column) => api.update(url.UPDATE_RETURN_BUCKET, {  returnBucketId, value, column });

// get Return Bucket
export const getReturnBucket = (username) => api.get(url.GET_RETURN_BUCKET, { username });

// get Return Adjustments
export const getReturnAdjustments = () => api.get(url.GET_RETURN_ADJUSTMENTS);

// get Return Adjustment
export const getReturnAdjustment = (return_adjustment_id) => api.get(url.GET_RETURN_ADJUSTMENT, { return_adjustment_id });

// get Return Adjustment Items
export const getReturnAdjustmentDetails = (return_adjustment_id) => api.get(url.GET_RETURN_ADJUSTMENT_DETAILS, { return_adjustment_id });

// update Return bucket items
export const updateReturnBucketItems = (value, product, return_bucket_id, column) => api.update(url.UPDATE_RETURN_BUCKET_ITEMS, { value, product, return_bucket_id, column });

// delete return bucket items
export const deleteReturnBucketItem = (product, returnBucketId) => api.delete(url.DELETE_RETURN_BUCKET_ITEM, { product, returnBucketId });

// post return invoice
export const postReturnInvoiceCreate = (username, returnBucketItems, returnTotal, returnQty) => api.create(url.POST_RETURN_INVOICE_CREATE, { username, returnBucketItems, returnTotal, returnQty });

// post return invoice approval
export const postReturnInvoiceApproval = (status, reject_reason, ship_method, admin_fee, returnId) => api.create(url.POST_RETURN_INVOICE_APPROVAL, { status, reject_reason, ship_method, admin_fee, returnId});

// download Return invoice
export const downloadReturnInvoice = (returnId) => api.download(url.DOWNLOAD_RETURN_INVOICE + '/' + returnId, 'application/pdf');

// update Return Invoice items worksheet
export const updateReturnInvoiceItemsWorksheet = (items, extra, returnId) => api.update(url.UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET, { items, extra, returnId });

// update Return adjustment items approval
export const updateReturnAdjustmentItemsApproval = (returnAdjustmentItems, status, returnAdjustmentId) => api.update(url.UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL, { returnAdjustmentItems, status, returnAdjustmentId });

// update issue credit for return invoice
export const updateReturnInvoiceCreditIssue = (status, returnId) => api.update(url.UPDATE_RETURN_INVOICE_CREDIT_ISSUE, { status, returnId });

// download return batch template
export const downloadReturnBatchTemplate = () => api.download(url.DOWNLOAD_RETURN_BATCH_TEMPLATE);

// Credit
// get Credits
export const getCredits = () => api.get(url.GET_CREDITS);

// get Credits filtered
export const getCreditsFiltered = (filter) => api.get(url.GET_CREDITS, { ...filter });

// get Credit
export const getCredit = (credit_id) => api.get(url.GET_CREDIT, { credit_id: credit_id });

export const getCreditDetails = (credit_id) => api.get(url.GET_CREDIT_DETAILS + '/' + credit_id);

// download Credit invoice
export const downloadCreditInvoice = (creditId) => api.download(url.DOWNLOAD_CREDIT_INVOICE + '/' + creditId, 'application/pdf');

// Statement
// get Statements
export const getStatements = () => api.get(url.GET_STATEMENTS);

// get Statements with username
export const getStatementsWithUsername = (username) => api.get(url.GET_STATEMENTS, { username: username });

// get Statement
export const getStatement = (statement_id) => api.get(url.GET_STATEMENT + '/' + statement_id);

// download Statement
export const downloadStatement = (statementId) => api.download(url.DOWNLOAD_STATEMENT + '/' + statementId, 'application/pdf');

// post Statement Payment
export const postStatementPayment = (statement_id, paid_amount, type) => api.create(url.POST_STATEMENT_PAYMENT, { statement_id, paid_amount, type });

// Customer
// get Customers
export const getCustomers = () => api.get(url.GET_CUSTOMERS);

// get Customer Information
export const getCustomerInformation = (username) => api.get(url.GET_CUSTOMER_INFO + '/' + username);

// get Customer Product Exclusions
export const getCustomerProductExclusion = (username) => api.get(url.GET_CUSTOMER_PRODUCT_EXCLUSION + '/' + username);

// post New Customer Exclusion
export const postNewCustomerExclusion = (username, type, line) => api.create(url.POST_NEW_CUSTOMER_EXCLUSION, { username, type, line });

// delete Customer Exclusion
export const deleteCustomerExclusion = (id) => api.delete(url.DELETE_CUSTOMER_EXCLUSION + '/' + id);

// get Customer User Group
export const getCustomerUserGroup = (userGroupId) => api.get(url.GET_CUSTOMER_USER_GROUP + '/' + userGroupId);

// update Customer Information
export const updateCustomerInformation = (userInfo) => api.create(url.UPDATE_CUSTOMER_INFO, { ...userInfo });

// delete Customer
export const deleteCustomer = (user) => api.delete(url.DELETE_CUSTOMER + '/' + user);

// update User Group
export const updateUserGroup = (userGroupArr) => api.update(url.UPDATE_USER_GROUP, userGroupArr);

// post New Customer
export const postNewCustomer = (newUserInfo) => api.create(url.POST_NEW_CUSTOMER, { ...newUserInfo });

// get User Groups
export const getUserGroups = () => api.get(url.GET_USER_GROUPS);

// post User Password Reset
export const postUserPasswordReset = (username, password) => api.create(url.POST_USER_PASSWORD_RESET, { username, password });

// get Update User Credit Balances
export const getUpdateUserCreditBalances = () => api.get(url.GET_UPDATE_USER_CREDIT_BALANCES);

// get Product Groups
export const getProductGroups = () => api.get(url.GET_PRODUCT_GROUPS);

// get Product Groups by User
export const getUserProductGroups = (user) => api.get(url.GET_USER_PRODUCT_GROUPS, { user });

// post New Product Group To User
export const postNewProductGroupToUser = (username, gid) => api.create(url.POST_NEW_PRODUCT_GROUP_TO_USER, { username, gid });

// delete Product Group From User
export const deleteProductGroupFromUser = (id) => api.delete(url.DELETE_PRODUCT_GROUP_FROM_USER + '/' + id);

// Invoice
// get Invoice
export const getInvoices = () => api.get(url.GET_INVOICES);

// get Filtered invoices
export const getFilteredInvoices = (filter) => api.get(url.GET_FILTERED_INVOICES, { ...filter });

// get Archived Invoice
export const getArchivedInvoices = () => api.get(url.GET_ARCHIVED_INVOICES);

// get Filtered archived invoices
export const getFilteredArchivedInvoices = (filter) => api.get(url.GET_FILTERED_ARCHIVED_INVOICES, { ...filter });

// get Invoice
export const getInvoice = (order_id) => api.get(url.GET_INVOICE, { order_id: order_id });

// get Invoice details
export const getInvoiceDetails = (order_id) => api.get(url.GET_INVOICE_DETAILS + '/' + order_id);

// get Invoice details product qty
export const postInvoiceDetailsProductQuantity = (data) => api.create(url.POST_INVOICE_DETAILS_PRODUCT_QUANTITY, { data });

// download invoice
export const downloadInvoicePdf = (orderId) => api.download(url.DOWNLOAD_INVOICE_PDF + '/' + orderId, 'application/pdf');

// download packing list
export const downloadPackingListPdf = (orderId) => api.download(url.DOWNLOAD_PACKING_LIST_PDF + '/' + orderId, 'application/pdf');

// post Ship Order
export const postShipOrder = (params) => api.create(url.POST_SHIP_ORDER, { ...params });

// update Order Status
export const updateOrderStatus = (status, order_id, requested_shipped_date) => api.update(url.UPDATE_ORDER_STATUS, { status, order_id, requested_shipped_date });

// post Cancel Order
export const postCancelOrder = (order_id) => api.create(url.POST_CANCEL_ORDER, { order_id });

// get Old Orders Order Items
export const getOldOrdersOrderitems = () => api.get(url.GET_OLD_ORDERS_ORDER_ITEMS);

// get Reformat Order Items
export const getReformatOrderItems = () => api.get(url.GET_REFORMAT_ORDERITEMS);

// Global Settings
// get Global Prices
export const getGlobalPrices = () => api.get(url.GET_ALLPRICES_DATA);
// post Updated Global Prices
export const updateGlobalPrices = prices => api.create(url.POST_GLOBAL_PRICES, prices)
// get Users
export const getUsers = () => api.get(url.GET_USERS);
// post Global Max
export const postGlobalMax = (globalParam) => api.create(url.POST_GLOBAL_MAX, globalParam);
// post Global Discount
export const postGlobalDiscount = (globalParam) => api.create(url.POST_GLOBAL_DISCOUNT, globalParam);
// get Monthly Statement
export const getMonthTest = () => api.get(url.GET_MONTH_TEST);
//get Monthly Statement based on month and year
export const getMonthTestMonthYear = (monthYear) => api.get(url.GET_MONTH_TEST_MONTH_YEAR, { ...monthYear })
// get Sales Representatives
export const getSalesRepresentatives = () => api.get(url.GET_SALES_REPRESENTATIVES);
// post Sales Representatice
export const postSalesRepresentative = (username) => api.create(url.POST_SALES_REPRESENTATIVE, { username });
// delete Sales Representative
export const deleteSalesRepresentative = (id) => api.delete(url.DELETE_SALES_REPRESENTATIVE + '/' + id);
// update Sales Representative
export const updateSalesRepresentative = (replaceRep, newRep) => api.update(url.UPDATE_SALES_REPRESENTATIVE, { replaceRep, newRep });
// get Statements Overdue Summary
export const getStatementOverdueSummary = () => api.get(url.GET_STATEMENT_OVERDUE_SUMMARY);

// Promotions and Coupons
// get Group Template
export const getGroupTemplate = () => api.download(url.DOWNLOAD_GROUP_TEMPLATE, 'text/csv');
// get Groups
export const getGroups = () => api.get(url.GET_GROUPS);
// get Group Items
export const getGroupItems = (id) => api.get(url.GET_GROUP_ITEMS + '/' + id);
// delete Group
export const deleteGroup = (deleteid) => api.delete(url.DELETE_GROUP + '/' + deleteid);
// post Groups
export const postGroups = (groupParam) => api.create(url.POST_GROUPS, groupParam);
// get Accessory
export const getAccessory = () => api.get(url.GET_ACCESSORY);
// post Accessory
export const postAccessory = (accessoryParam) => api.create(url.POST_ACCESSORY, accessoryParam); 
// post Add Accessory
export const postAddAccessory = (addAccessoryParam) => api.create(url.POST_ADD_ACCESSORY, addAccessoryParam);
// delete Accessory
export const deleteAccessory = (accessoryBucket, accessoryPart) => api.delete(url.DELETE_ACCESSORY + '/' + accessoryBucket + '/' + accessoryPart);

// System Messages
// get System Messages
export const getSystemMessages = () => api.get(url.GET_SYSTEM_MESSAGES);
// get System Messages Today
export const getSystemMessagesToday = () => api.get(url.GET_SYSTEM_MESSAGES_TODAY);
// post System Message
export const postSystemMessage = (system_message) => api.create(url.POST_SYSTEM_MESSAGE, { ...system_message });