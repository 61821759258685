import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";

import Loader from "../Common/Loader";
import { maxPattern, order, pattern } from "../../Components/constants/products";
import { getProductLineFromProduct } from "../../helpers/globalfunctions_helper";

import removeButton from '../../assets/images/close.png';
import { toast, ToastContainer } from 'react-toastify';
import {
    deleteDraftProductsAndPriceListWithRowId as onDeleteDraftProductsAndPriceListWithRowId,
    getProductsWithRowId as onGetProductsWithRowId,
    postProductSelectLog as onPostProductSelectLog,
} from "../../store/actions";

function ProperDraftTableRow({ row, rowId, API, draftid, rowproduct, productRowQuantity, changerow, setDraftRowItems, currentRow, setCurrentRow, setDraftProductArr, removedup, selectedItemsCode, setInvalidSCBAndCNGRows }) {
    const dispatch = useDispatch();

    const [productsArr, setProductsArr] = useState(null);
    const [query, setQuery] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [qty, setQty] = useState(0);

    const [total, setTotal] = useState(0.00);
    const [isLoading, setIsLoading] = useState(true);
    const [isTyping, setIsTyping] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);
    const tableRowRef = useRef();
    const inputQueryRef = useRef();
    const inputQtyRef = useRef();
    const dropDownSelectProductRef = useRef();
    const availabiltyDisplayRef = useRef();

    const toggle = () => setIsDropdownOpen(prevState => !prevState);

    useEffect(() => {
        console.log(row, rowproduct, productsArr, selectedProduct);
        if (!isSearching && !isTyping && ((!row && (!productsArr && !rowproduct)) || (row && !row.pcode))) {
            console.log('deleted row')
            if (productsArr) setProductsArr(null);
            if (selectedProduct) setSelectedProduct(null);
            if (query) setQuery('');
            if (qty) setQty(0);
            if (total) setTotal(0.00);
            setIsLoading(false);
            tableRowRef.current.style.backgroundColor = 'initial';
            availabiltyDisplayRef.current.style.backgroundColor = 'initial';
            if (currentRow === rowId)
                inputQueryRef.current.focus();

            return;
        }
        // if((row && !row.pcode)){
        //     return;
        // }

        // if(row.id){
        //     console.log(row);
        // setRowid(row.id);
        // }
        //if (!draftRowItems || !draftRowItems[rowId]) return;

        // setIsLoading(true);
        // setSelectedProduct(null);
        // setIsDropdownOpen(true);
        if (!row && !productsArr && !rowproduct && !isTyping && !isSearching) {
            console.log('empty row')
            setQuery('');
            setQty(0);
            setSelectedProduct(null);
            // setProductsArr([]);
            return;
        }
        if (isTyping) {
            setProductsArr(null);
            setSelectedProduct(null);
            setQty(0);
            setTotal(0.00);
            // setIsTyping(false);
            return;
        }

        // console.log(rowproduct);
        if (row && row.pcode && (!productsArr) && !isTyping) {
            console.log('load saved');
            if (!query)
                setQuery(row.pcode || row.product || row.max_product || '');

            if (rowproduct) {
                setProductsArr(rowproduct);
            } else {
                handleQueryEnter(row.pcode || row.max_product || row.product, 1);
                return;
            }
        } else if (!productsArr && rowproduct && !isTyping) {
            console.log('new search')
            setProductsArr(rowproduct);
            setIsSearching(false);
        }
        // if(){

        // }
        if (currentRow === rowId && selectedProduct //&& document.activeElement !== inputQueryRef.current
        )
            inputQtyRef.current.focus();
        if (currentRow !== rowId)
            setIsDropdownOpen(false);
        //inputQueryRef.current.value = (draftRowItems[rowId].pcode || draftRowItems[rowId].product || draftRowItems[rowId].max_product || null);
        //inputQtyRef.current.value = (draftRowItems[rowId].qty || 0);
    }, [row, rowproduct, selectedProduct, productsArr, isTyping])

    useEffect(() => {
        console.log(row, productsArr)
        if (productsArr) {

            setIsLoading(false);
            setIsTyping(false);
            if (productsArr.length) {
                if (row && row.pcode && !selectedProduct) {
                    for (let i in productsArr) {
                        if (productsArr[i].n === row.product) {
                            // console.log(rowproduct[i]);
                            setSelectedProduct(productsArr[i]);


                            break;
                        }
                    }
                }
            } else if (row && rowId) {
                changerow(rowId, { ...row, bulk: false }, true);
            }
        }
    }, [productsArr]);

    useEffect(() => {
        if (!row) return;

        let tmp = validateQuantity(row.qty || 0);
        if (tmp === '') tmp = 0;
        setQty(tmp);
    }, [selectedProduct])

    useEffect(() => {
        if (row && !row.pcode) return;
        console.log(row, selectedProduct);
        // if(isDelete){
        //     return;
        // }
        let tempQuantity = 0;
        let display_value = '';
        let selectedProductPrice = 0.00;
        let tempTotal = '';

        if (qty === 0 || !selectedProduct) {
            setTotal(0.00);
            return;
        }
        if (qty > 0) {
            tempQuantity = qty;
        }
        inputQtyRef.current.style.backgroundColor = 'initial';
        if (selectedProduct) {
            if (selectedProduct.price) {
                if (selectedProduct.price.price)
                    selectedProductPrice = selectedProduct.price.price;
                else
                    selectedProductPrice = selectedProduct.price;
            }


            if ((selectedProduct.n.search(/SCB|CNZ/) !== -1) && qty > 0 && ((qty % 2) !== 0)) {
                inputQtyRef.current.style.backgroundColor = 'red';
                toast.error('SCB/CNZ quantity must be even!');

                updateInvalidSCBAndCNGRowsForCurrentRow(true);

                return;
            } else if (selectedProduct.n.search(/SCB|CNZ/) !== -1 && qty > 0 && qty % 2 === 0) {
                updateInvalidSCBAndCNGRowsForCurrentRow(false);
            }
        }

        // console.log(row,)
        // display_value = showAvailability();
        // availabiltyDisplayRef.current.style.backgroundColor = 'initial';
        // if (tempQuantity !== 0 && tempQuantity >= display_value) {
        //     tempQuantity = Number(display_value);
        //     availabiltyDisplayRef.current.style.backgroundColor = 'lightcoral';
        // }

        if (selectedProduct) {
            // tempQuantity= validateQuantity(tempQuantity);
            // setDraftRowItems(prevState => {
            //     return prevState.map((item, index) => {
            //         return index === rowId ? { ...item, qty: Number(tempQuantity) } : item;
            //     })
            // });
            // setQty(tempQuantity);
            tempTotal = (tempQuantity * Number(selectedProductPrice)).toFixed(2)
        }


        // if(qty!==tempQuantity)
        //     setQty(tempQuantity);
        // console.log(selectedProduct,row);
        if (selectedProduct && qty && !isLoading) {
            let data = {
                draftid: draftid, pcode: query,
                qty: tempQuantity, product: selectedProduct.n,
                max_product: selectedProduct.s, description: selectedProduct.d,
                price: selectedProduct.price, total: tempTotal, bulk: false, clear: false
            };
            if (row && row.id) {
                data.id = row.id;
            }
            changerow(rowId, data);
        }
        else if (row && row.pcode && !row.price) {
            changerow(rowId, { ...row, price: selectedProduct.price, total: tempTotal, clear: false, bulk: false }, true);
        }

        setTotal(tempTotal);
        if (currentRow === rowId && selectedProduct) inputQtyRef.current.focus();
        // inputQtyRef.current.focus();
    }, [qty, selectedProduct]) //, rowid

    function deleterow(mode = 0) {
        if (selectedProduct && selectedProduct.n.search(/SCB|CNZ/) !== -1) {
            updateInvalidSCBAndCNGRowsForCurrentRow(false);
        }

        if (!row) {
            return;
        }
        // setIsDelete(true);
        let newrow = { pcode: '', product: '', max_product: '', description: '', qty: 0, price: '', total: '' };

        if (mode == 1) {
            newrow.pcode = query;
            newrow.clear = true;
        } else {
            //newrow.clear = true;
        }


        setDraftRowItems(prevState => {
            // delete prevState[rowId];
            prevState[rowId] = { ...prevState[rowId], ...newrow };
            return [...prevState];
        });
    }

    function handleImgClickClear() {
        // inputQueryRef.current.value = '';

        // dispatch(onDeleteDraftProductsAndPriceListWithRowId({ drafthash: drafthash, rowId: rowId }));
        // setProductsArr(null);
        // setSelectedProduct(null);
        // setQuery('');
        // setQty(0);
        // setTotal(0.00);

        tableRowRef.current.style.backgroundColor = 'initial';
        availabiltyDisplayRef.current.style.backgroundColor = 'initial';

        // setCurrentRow(rowId);
        setIsTyping(true);
        deleterow(0);
        setSelectedProduct(null);
        setProductsArr(null);
        setQuery('');
        setQty(0);


        if (row && row.id)
            API.delete('/draftitem/' + row.id);
    }

    function handleselectchange(e) {

        if (e.key === 'Enter') {
            // console.log('select change?');
            // inputQtyRef.current.focus();
        }
    }

    function processproductarr(data) {

        if (!data) return;
        let tempProducts = [];
        let items = [];

        if (data.int) {
            for (let i of data.int) {
                items.push(i);
            }
        }
        if (data.sub) {
            for (let i of data.sub) {
                let found = false;
                for (let j of items) {
                    if (j.n === i.n) {
                        found = true;
                        break;
                    }
                }
                if (!found)
                    items.push(i);
            }
        }
        if (data.length) {
            for (let i of data) {
                items.push(i);
            }
        }
        for (let item of items) {
            let productLine = getProductLineFromProduct(item.n);
            if (productLine[0] === "8100" || productLine[0] === "9100") productLine = "Other";

            let tempOrder = order[productLine];

            let tempPrice = '';
            if (item && item.price && item.price.status === "OK") {
                tempPrice = Number(item.price.price).toFixed(2);
            } else if (item && item.price) {
                tempPrice = Number(item.price).toFixed(2);
            } else {
                tempPrice = "N/A"
            }

            if (!Object.hasOwn(item, 'order')) {
                Object.defineProperties(item, {
                    'order': { value: tempOrder, writable: true },
                })
                item.price = tempPrice;
            } else {
                item.order = tempOrder;
                item.price = tempPrice;
            }
            if (tempPrice !== "N/A")
                tempProducts.push(item);
        }
        removedup(rowId, tempProducts);
    }

    function handleQueryEnter(productQuery, mode = 0) {
        let tempUsername = '';
        setIsLoading(true);

        setProductsArr(null);
        setSelectedProduct(null);
        if (mode === 0)
            setQty(0);

        setIsDropdownOpen(true);

        if (tableRowRef.current) tableRowRef.current.style.backgroundColor = 'initial';
        if (availabiltyDisplayRef.current) availabiltyDisplayRef.current.style.backgroundColor = 'initial';

        const res = API.create('/product-price/' + productQuery).then((data) => {
            if (!data || !data.int) {
                console.log('no data');
                return;
            }
            processproductarr(data);
        });
    }

    function handleRowInputOnBlur(e) {
        if (isLoading || !isTyping) return;
        setCurrentRow(rowId);
        if (e.target.value !== '') {
            setIsSearching(true);
            setIsTyping(false);
            handleQueryEnter(e.target.value);
        }
    }

    function checkMatch(product, tempProductsArr) {
        let matchRegularExpression = '';
        matchRegularExpression = new RegExp(`/\b${product}\b/`);

        return tempProductsArr.find((item) => {
            if (item.n && item.n.match(matchRegularExpression)) {
                return item.n.match(matchRegularExpression);
            } else if (item.s && item.s.match(matchRegularExpression)) {
                return item.s.match(matchRegularExpression);
            } else if ((item.n.match(pattern) || item.s.match(pattern)) && product.match(pattern)) {
                return item.n.match(pattern).toString() === product.match(pattern).toString() || (item.s && item.s.match(pattern) === product.match(pattern).toString());
            }
            return false;
        });
    }

    function descriptionColumn() {
        if (productsArr && productsArr.length > 1) {
            tableRowRef.current.style.backgroundColor = 'initial';

            if (selectedProduct && (selectedProduct.q === 0 || selectedProduct.q === '0')) tableRowRef.current.style.backgroundColor = 'yellow';

            return (
                <ButtonGroup className="order-draft-table-row-dropdown">
                    <Dropdown isOpen={isDropdownOpen} toggle={toggle} onKeyUp={handleselectchange}>
                        {/* tabIndex={(rowId * 3) + 2} */}
                        <DropdownToggle innerRef={dropDownSelectProductRef} tag="button" className="btn btn-max" >
                            {(selectedProduct && (selectedProduct.q === 0 || selectedProduct.q === '0')) ? (`${selectedProduct.n} | ${selectedProduct.d} | Product Out of Stock`) : (selectedProduct) ? (`${selectedProduct.n} | ${selectedProduct.d}`) : ('')}<i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            {productsArr.filter(p => (selectedProduct && p.n == selectedProduct.n) || selectedItemsCode.indexOf(p.n) === -1).map((product, index) => {
                                return <DropdownItem autoFocus={(index === 0 && currentRow === rowId)} key={'item-' + index} onClick={() => handleDropDownSelectProductClick(product)}>{product.n} | {product.d}</DropdownItem>
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </ButtonGroup>
            )
        } else if (productsArr && productsArr.length && (selectedProduct || productsArr.length === 1)) { //productsArr.length === 1 
            tableRowRef.current.style.backgroundColor = 'initial';
            if ((selectedProduct && (selectedProduct.q === 0 || selectedProduct.q === '0')) || (productsArr[0].q === 0 || productsArr[0].q === '0')) {
                tableRowRef.current.style.backgroundColor = 'yellow';
                return 'Product out of Stock';
            } else if (selectedProduct) { //&& (products && products.length)
                return `${selectedProduct.n} - ${selectedProduct.d}`;
            } else {
                // return `${productsArr[0].n} - ${productsArr[0].d}`;
                handleDropDownSelectProductClick(productsArr[0]);
            }
        } else if (productsArr && !productsArr.length) {
            tableRowRef.current.style.backgroundColor = 'yellow';
            return 'Product Not Found';
        } else {
            return '';
        }
    }

    function handleDropDownSelectProductClick(dropDownSelectedProduct) {
        setSelectedProduct(dropDownSelectedProduct);
        setQty(0);
        setCurrentRow(rowId);
        inputQtyRef.current.focus();
    }

    function handleInputQtyFocus() {
        if (Number(qty) === 0)
            inputQtyRef.current.select();
    }

    function validateQuantity(tovalidate) {
        let tempQuantity = 0;
        let display_value = '';
        let selectedProductPrice = 0.00;
        let quantity = Number(tovalidate);
        if (quantity > 0) {
            tempQuantity = quantity;
        }
        inputQtyRef.current.style.backgroundColor = 'initial';

        display_value = showAvailability();
        availabiltyDisplayRef.current.style.backgroundColor = 'initial';
        if (tempQuantity !== 0 && tempQuantity >= display_value && qty !== 0) {
            tempQuantity = Number(display_value);
            availabiltyDisplayRef.current.style.backgroundColor = 'lightcoral';
        }
        return tempQuantity;
    }

    function handleInputQuantityChange(e) {
        let tempQuantity = validateQuantity(e.target.value);
        setQty(tempQuantity);
    }

    function showAvailability() {
        let display_value = '';
        let selectedProductProductLine = '';
        let selectedProductQty = 0;

        if (qty) {
            display_value = qty;
        }

        if (selectedProduct) {
            selectedProductProductLine = getProductLineFromProduct(selectedProduct.n);
            selectedProductQty = Number(selectedProduct.q);

            if (selectedProductQty > productRowQuantity[selectedProductProductLine]) {
                display_value = productRowQuantity[selectedProductProductLine];
                inputQtyRef.current.max = productRowQuantity[selectedProductProductLine];
            } else if (selectedProductQty < productRowQuantity[selectedProductProductLine]) {
                display_value = selectedProductQty;
                inputQtyRef.current.max = selectedProductQty;
            } else {
                display_value = '';
            }
        }

        return display_value;
    }

    function handleQtyChangeFocusToQuery(e) {
        if (e.key === 'Enter') {
            let x = e.target.parentElement.parentElement.nextElementSibling;
            if (x)
                x.querySelector('input[name="query"]').focus();
        } else {
            setCurrentRow(rowId);
        }
    }

    function handleRowInputKeyDownQuery(e) {
        setCurrentRow(rowId);
        if ((e.key === 'Enter' || e.key === 'Tab') && e.target.value !== '') {
            setIsSearching(true);
            setIsTyping(false);
            handleQueryEnter(e.target.value);
        } else {
            deleterow(1);
            if (row && row.id && selectedProduct)
                API.delete('/draftitem/' + row.id);
            setIsTyping(true);
        }
    }

    function handlequerychange(e) {
        // if (selectedProduct !== null && row !== null && row !== undefined && (e.key !== 'Enter' || e.key !== 'Tab')) {
        //     console.log(`Row slected ${selectedProduct}, Row ${row}`);
        //     tableRowRef.current.style.backgroundColor = 'initial';
        //     availabiltyDisplayRef.current.style.backgroundColor = 'initial';

        //     let newrow = { pcode: '', product: '', max_product: '', description: '', qty: 0, price: '', total: '' };
        //     newrow.pcode = query;
        //     newrow.clear = true;
        //     setDraftRowItems(prevState => {
        //         // delete prevState[rowId];
        //         prevState[rowId] = { ...prevState[rowId], ...newrow };
        //         return [...prevState];
        //     });

        //     if (row && row.id)
        //         API.delete('/draftitem/' + row.id);
        // }
        // else {
        //     setQuery(e.target.value);
        // }

        setQuery(e.target.value);

    }

    function updateInvalidSCBAndCNGRowsForCurrentRow(isInsertInvalidRow = true) {

        setInvalidSCBAndCNGRows((prevState) => {
            let invalidRows = [...prevState];
            let indexOfCurrentInvalidRow = invalidRows.indexOf(rowId);

            if (isInsertInvalidRow) {
                if (indexOfCurrentInvalidRow === -1) {
                    invalidRows.push(rowId);
                }
            } else {
                if (indexOfCurrentInvalidRow !== -1) {
                    invalidRows.splice(indexOfCurrentInvalidRow, 1);
                }
            }

            return [...invalidRows];
        });
    }

    return (
        <tr key={(row) ? row.id : 0} ref={tableRowRef}>
            <td>

                <img className="cursorPointer" src={removeButton} alt="remove button" onClick={handleImgClickClear} />
            </td>
            <td>
                {/* tabIndex={(rowId * 3) + 1} */}
                <Input innerRef={inputQueryRef} type="text" name="query" value={query} onChange={handlequerychange} placeholder="3295" onKeyDown={(e) => handleRowInputKeyDownQuery(e)} onBlur={(e) => handleRowInputOnBlur(e)} />
            </td>
            <td>
                {(isLoading) ? (<Loader />) : (descriptionColumn())}
            </td>
            <td>
                {/* tabIndex={(rowId * 3) + 3} */}
                <Input innerRef={inputQtyRef} type="number" name="qty" min="0" placeholder="100" value={qty} onChange={handleInputQuantityChange} onKeyDown={(e) => handleQtyChangeFocusToQuery(e)} onWheel={(e) => e.target.blur()} onFocus={handleInputQtyFocus} />

            </td>
            <td ref={availabiltyDisplayRef}>{(showAvailability() > 10) ? ('10+') : (showAvailability())}</td>
            <td>{(selectedProduct) ? ('$' + selectedProduct.price) : (null)}</td>
            <td>{(total) ? ('$' + Number(total).toFixed(2)) : (null)}</td>
        </tr>
    )
}

export default ProperDraftTableRow;