import { Input, Label } from "reactstrap";

function NewUserInputFields({ labelName, type, field, value, newUserInfo, setNewUserInfo }) {
    return (
        <>
            <Label>{labelName} <span className="text-danger">*</span></Label>
            <Input type={type} value={(value) ? (value) : ('')} onChange={(e) => setNewUserInfo({ ...newUserInfo, [field]: e.target.value })} />
        </>
    )
}

export default NewUserInputFields;