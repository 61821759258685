import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Credit Redux States
import { 
    ADD_DRAFT,
    CREATE_NEW_ORDER,
    DELETE_DRAFT,
    DELETE_DRAFT_ITEMS,
    DOWNLOAD_DRAFT_TEMPLATE,
    GET_DRAFT,
    GET_DRAFTS,
    POST_DRAFT_UPDATE,
} from "./actionType";

import { 
    addDraftFail,
    addDraftSuccess,
    createNewOrderFail,
    createNewOrderSuccess,
    deleteDraftFail,
    deleteDraftItemsFail,
    deleteDraftItemsSuccess,
    deleteDraftSuccess,
    draftsApiResponseError,
    draftsApiResponseSuccess,
    postDraftUpdateFail,
    postDraftUpdateSuccess,
} from "./action";

//Include Both Helper File with needed methods
import {
    getDrafts as getDraftsApi,
    getDraft as getDraftApi,
    createNewOrder as createNewOrderApi,
    addDraft as addDraftApi,
    postDraftUpdate as postDraftUpdateApi,
    deleteDraft as deleteDraftApi,
    deleteDraftItems as deleteDraftItemsApi,
    downloadDraftTemplate as downloadDraftTemplateApi
} from "../../helpers/backend_helper";

function* getDrafts() {
    try {
        const response = yield call(getDraftsApi);
        yield put(draftsApiResponseSuccess(GET_DRAFTS, response));
    } catch (error) {
        yield put(draftsApiResponseError(GET_DRAFTS, error));
    }
}

function* getDraft({ payload: drafthash }) {
    try {
        const response = yield call(getDraftApi, drafthash);
        yield put(draftsApiResponseSuccess(GET_DRAFT, response));
    } catch (error) {
        yield put(draftsApiResponseError(GET_DRAFT, error));
    }
}

function* addDraft({ payload: fields }) {
    try {
        const response = yield call(addDraftApi, fields);
        yield put(addDraftSuccess(ADD_DRAFT, response));
    } catch (error) {
        yield put(addDraftFail(ADD_DRAFT, error));
    }
}

function* createNewOrder({ payload: order }) {
    try {
        const response = yield call(createNewOrderApi, order);
        yield put(createNewOrderSuccess(CREATE_NEW_ORDER, response));
    } catch (error) {
        yield put(createNewOrderFail(CREATE_NEW_ORDER, error));
    }
}

function* postDraftUpdate({ payload: fields }) {
    try {
        yield call(postDraftUpdateApi, fields);
        yield put (postDraftUpdateSuccess(POST_DRAFT_UPDATE, fields));
    } catch (error) {
        yield put(postDraftUpdateFail(POST_DRAFT_UPDATE, error))
    }
}

function* deleteDraft({ payload: drafthash }) {
    try {
        yield call(deleteDraftApi, drafthash);
        yield put(deleteDraftSuccess(DELETE_DRAFT, drafthash));
    } catch (error) {
        yield put(deleteDraftFail(DELETE_DRAFT, error));
    }
}

function* deleteDraftItems({ payload: drafthash }) {
    try {
        yield call(deleteDraftItemsApi, drafthash);
        yield put(deleteDraftItemsSuccess(DELETE_DRAFT_ITEMS, drafthash));
    } catch (error) {
        yield put(deleteDraftItemsFail(DELETE_DRAFT_ITEMS, drafthash));
    }
}

function* downloadDraftTemplate() {
    try {
        const response = yield call(downloadDraftTemplateApi);
        yield put(draftsApiResponseSuccess(DOWNLOAD_DRAFT_TEMPLATE, response));
    } catch (error) {
        yield put(draftsApiResponseError(DOWNLOAD_DRAFT_TEMPLATE, error));
    }
}

export function* watchGetDrafts() {
    yield takeEvery(GET_DRAFTS, getDrafts);
}

export function* watchGetDraft() {
    yield takeEvery(GET_DRAFT, getDraft);
}

export function* watchCreateNewOrder() {
    yield takeEvery(CREATE_NEW_ORDER, createNewOrder);
}

export function* watchAddDraft() {
    yield takeEvery(ADD_DRAFT, addDraft);
}

export function* watchPostDraftUpdate() {
    yield takeEvery(POST_DRAFT_UPDATE, postDraftUpdate);
}

export function* watchDeleteDraft() {
    yield takeEvery(DELETE_DRAFT, deleteDraft);
}

export function* watchDeleteDraftItems() {
    yield takeEvery(DELETE_DRAFT_ITEMS, deleteDraftItems);
}

export function* watchDownloadDraftTemplate() {
    yield takeEvery(DOWNLOAD_DRAFT_TEMPLATE, downloadDraftTemplate);
}

function* draftsSaga() {
    yield all([
        fork(watchGetDrafts),
        fork(watchGetDraft),
        fork(watchCreateNewOrder),
        fork(watchAddDraft),
        fork(watchPostDraftUpdate),
        fork(watchDeleteDraft),
        fork(watchDeleteDraftItems),
        fork(watchDownloadDraftTemplate)
    ]);
}

export default draftsSaga;