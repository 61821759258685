import { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { CardBody, Row, Col, Card, Container, Input, Label, Button, CardHeader, } from "reactstrap";
import * as moment from "moment";
import Flatpickr from "react-flatpickr";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useProfile } from '../../../../Components/Hooks/UserHooks';
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../../../Components/Common/TableContainer";
import { SelectColumnFilter } from "../../../../Components/Common/filters";
import PDFRenderer from "../../../../Components/Common/PDFRenderer";
import Loader from "../../../../Components/Common/Loader";
import DownloadImage from '../../../../assets/images/download.png';

//Import actions
import {
    getInvoices as onGetInvoices,
    getFilteredInvoices as onGetFilteredInvoices,
    downloadInvoicePdf as onDownloadInvoicePdf,
    downloadPackingListPdf as onDownloadPackingListPdf,
} from "../../../../store/actions";

const OrderHistory = () => {
    const { userProfile } = useProfile();
    const dispatch = useDispatch();
    const [pdfOrderId, setPdfOrderId] = useState(null);
    const [pdfPackingOrderId, setPdfPackingOrderId] = useState(null);
    const [invoice, setInvoice] = useState([]);
    const [invoiceFilter, setInvoiceFilter] = useState({ product: '', sdate: '', edate: '', po: '', order_id: '' });
    const [isLoading, setIsLoading] = useState(false);

    // pdf modal
    const [openInvoicePdfModal, setOpenInvoicePdfModal] = useState(false);
    const closeInvoicePdfModal = () => setOpenInvoicePdfModal(false);

    const [openPackingListPdfModal, setOpenPackingListPdfModal] = useState(false);
    const closePackingListPdfModal = () => setOpenPackingListPdfModal(false);

    const { invoices, isInvoiceSuccess, invoicePdf, isInvoicePdfDownloaded, packingListPdf, isPackingListPdfDownloaded, error } = useSelector((state) => ({
        invoices: state.Invoice.invoices,
        isInvoiceSuccess: state.Invoice.isInvoiceSuccess,
        invoicePdf: state.Invoice.invoicePdf,
        isInvoicePdfDownloaded: state.Invoice.isInvoicePdfDownloaded,
        packingListPdf: state.Invoice.packingListPdf,
        isPackingListPdfDownloaded: state.Invoice.isPackingListPdfDownloaded,
        error: state.Invoice.error,
    }));


    useEffect(() => {
        dispatch({ type: 'RESET_APP' });
    }, [dispatch])

    useEffect(() => {
        setIsLoading(true);
        dispatch(onGetInvoices());
    }, [dispatch]);

    useEffect(() => {
        setInvoice(invoices);
        setIsLoading(false);
    }, [invoices]);

    useEffect(() => {
        if (isInvoicePdfDownloaded && pdfOrderId) {
            setOpenInvoicePdfModal(true);
        }
    }, [invoicePdf, isInvoicePdfDownloaded, pdfOrderId])

    useEffect(() => {
        if (isPackingListPdfDownloaded && pdfPackingOrderId) {
            setOpenPackingListPdfModal(true);
        }
    }, [packingListPdf, isPackingListPdfDownloaded, pdfPackingOrderId])

    const handleValidDate = date => {
        if (!date) return '';
        const date1 = moment(new Date(date)).format("Y-MM-DD");
        return date1;
    };

    const handleValidDateColumn = (date, time, column) => {
        const date1 = moment(new Date(date)).format(`Y-MM-DD ${time}`);
        setInvoiceFilter({ ...invoiceFilter, [column]: date1 });
    };

    function handleFilterInvoices(e) {
        e.preventDefault();
        setIsLoading(true);
        dispatch(onGetFilteredInvoices(invoiceFilter));
    }

    function handleClearFilter() {
        setIsLoading(true);
        setInvoiceFilter({ product: '', sdate: '', edate: '', po: '', order_id: '' });
        dispatch(onGetInvoices());
    }

    // Checked All
    const checkedAll = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".invoiceCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    const handleDownloadInvoicePdf = useCallback((orderId) => {
        dispatch(onDownloadInvoicePdf(orderId));
        setPdfOrderId(orderId);
    }, [dispatch])

    const handleDownloadPackingListPdf = useCallback((orderId) => {
        dispatch(onDownloadPackingListPdf(orderId));
        setPdfPackingOrderId(orderId);
    }, [dispatch])

    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="invoiceCheckBox" value={cellProps.row.original._id} />;
                },
                id: '#',
            },
            {
                Header: "ORDER ID",
                accessor: "order_id",
                filterable: true,
                Cell: (cell) => {
                    return <Link to={`/history/order-history/${cell.row.original.order_id}`} className="fw-medium link-primary">{cell.row.original.order_id}</Link>;
                },
            },
            {
                Header: 'INVOICE',
                filterable: false,
                Cell: (cell) => (
                    <>
                        <img className="cursorPointer" width="30" height="30" src={DownloadImage} alt="Invoice" onClick={() => handleDownloadInvoicePdf(cell.row.original.order_id)} />
                    </>
                )
            },
            {
                Header: 'PACKING LIST',
                filterable: false,
                Cell: (cell) => (
                    <>
                        <img className="cursorPointer" width="30" height="30" src={DownloadImage} alt="Packing List" onClick={() => handleDownloadPackingListPdf(cell.row.original.order_id)} />
                    </>
                )
            },
            {
                Header: "COMPANY NAME",
                accessor: (userProfile.access !== 5) ? ('company_name') : (null),
                Filter: SelectColumnFilter,
                Cell: (cell) => (
                    <>
                        {cell.row.original.company_name}
                    </>
                )
            },
            {
                Header: "PO",
                accessor: "custom_po",
                // filterable: true,
                Cell: (invoice) => (
                    <>
                        {invoice.row.original.custom_po}
                    </>
                ),
            },
            {
                Header: "USERNAME",
                accessor: 'username',
                show: (userProfile.access !== 5) ? (true) : (false),
                Cell: (invoice) => (
                    <>
                        <div className="d-flex align-items-center">
                            {invoice.row.original.username}
                        </div>
                    </>
                ),
            },
            {
                Header: "DATE ORDERED",
                accessor: 'date_created',
                filterable: false,
                Filter: '',
                Cell: (invoice) => (
                    <>
                        {handleValidDate(invoice.row.original.date_created)}
                    </>
                ),
            },
            {
                Header: "DATE SHIP BY",
                accessor: 'requested_shipped_date',
                filterable: false,
                Filter: '',
                show: (userProfile.access !== 5) ? (true) : (false),
                Cell: (invoice) => (
                    <>
                        {handleValidDate(invoice.row.original.requested_shipped_date)}
                    </>
                ),
            },
            {
                Header: "METHOD",
                accessor: "ship_method",
                filterable: false,
                Filter: SelectColumnFilter,
                Cell: (invoice) => (
                    <>
                        {invoice.row.original.ship_method}
                    </>
                ),
            },
            {
                Header: "STATUS",
                accessor: 'status',
                Filter: SelectColumnFilter,
                Cell: (cell) => (
                    <>
                        {cell.row.original.status}
                    </>
                )
            },
            {
                Header: "PROMOTION",
                accessor: 'is_promotion',
                Filter: SelectColumnFilter,
                show: (userProfile.access !== 5) ? (true) : (false),
                Cell: (cell) => (
                    <>
                        {(cell.row.original.is_promotion) ? (cell.row.original.is_promotion) : ('')}
                    </>
                )
            },
            {
                Header: "TOTAL",
                filterable: false,
                Cell: (invoice) => (
                    <>
                        ${invoice.row.original.cost_final}
                    </>
                ),
            },
        ],
        [userProfile, handleDownloadInvoicePdf, handleDownloadPackingListPdf]
    );

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />
    if (!userProfile.permissions.view_order_history) return <Redirect to="/not-authorized" />

    document.title = "Order History | Max Advanced Brakes - Prozone";

    // TODO: Make a button that will take the user to archived orders

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Order History" pageTitle="Order History" linkTo={'/history/order-history'} />
                    {
                        openInvoicePdfModal && <PDFRenderer
                            fileUrl={URL.createObjectURL(invoicePdf)}
                            modalHeaderText={`Invoice ${pdfOrderId}`}
                            openPdfModal={openInvoicePdfModal}
                            closePdfModal={closeInvoicePdfModal} />
                    }
                    {
                        openPackingListPdfModal && <PDFRenderer
                            fileUrl={URL.createObjectURL(packingListPdf)}
                            modalHeaderText={`Packing List ${pdfPackingOrderId}`}
                            openPdfModal={openPackingListPdfModal}
                            closePdfModal={closePackingListPdfModal} />
                    }
                    <Row>
                        <Col lg={12}>
                            <Card id="invoices">
                                <CardBody className="mt-3 pt-0">
                                    <form onSubmit={(e) => handleFilterInvoices(e)}>
                                        <Row>
                                            <Col>
                                                <Label>Start Date</Label>
                                                <Flatpickr className="form-control" value={invoiceFilter.sdate} options={{ dateFormat: "Y-m-d" }} onChange={(e) => handleValidDateColumn(e[0], '00:00:00', 'sdate')} />
                                            </Col>
                                            <Col>
                                                <Label>End Date</Label>
                                                <Flatpickr className="form-control" value={invoiceFilter.edate} options={{ dateFormat: "Y-m-d" }} onChange={(e) => handleValidDateColumn(e[0], '23:59:59', 'edate')} />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <Label>Product</Label>
                                                <Input type="text" value={invoiceFilter.product} onChange={(e) => setInvoiceFilter({ ...invoiceFilter, product: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Label>PO</Label>
                                                <Input type="text" value={invoiceFilter.po} onChange={(e) => setInvoiceFilter({ ...invoiceFilter, po: e.target.value })} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Label>Order Id</Label>
                                                <Input className="hide-number-arrows" type="number" value={invoiceFilter.order_id} min="1" onChange={(e) => setInvoiceFilter({ ...invoiceFilter, order_id: e.target.value })} />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                <Button color={'max'} type="submit">Filter</Button>
                                            </Col>
                                            <Col className="text-end">
                                                <Button color={'secondary'} onClick={handleClearFilter}>Clear</Button>
                                            </Col>
                                        </Row>
                                    </form>
                                    <Row className="mt-3 border-top">
                                        <Col>
                                            {(!isLoading && isInvoiceSuccess && invoices) ? (
                                                <TableContainer
                                                    columns={columns}
                                                    data={(invoices || [])}
                                                    isGlobalFilter={false}
                                                    customPageSize={30}
                                                    className="custom-header-css"
                                                    divClass="table-responsive table-card"
                                                    tableClass="align-middle table-nowrap"
                                                    theadClass="text-muted"
                                                    thClass="text-muted"
                                                />
                                            ) : (<Loader error={error} />)}
                                            <ToastContainer closeButton={false} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default OrderHistory;