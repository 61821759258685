import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row className="text-center">
                        <Col md={6}>
                            <h5>Max Advanced Brakes</h5>
                            <p className="mb-0">280 Hillmount Road, Unit 5</p>
                            <p>Markham, ON L6C3A1</p>
                            <p className="mb-0">Operation Hours</p>
                            <p className="mb-0">Mon-Friday: 9AM-6PM EST</p>
                            <p>Sat-Sunday: CLOSED</p>
                        </Col>
                        <Col md={6}>
                            <h5>Contact List</h5>
                            <p className="mb-0">General Inquiry</p>
                            <p>1-888-835-2399 ext 124</p>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col sm={12}>
                            © {new Date().getFullYear()} Max Advanced Brakes.
                        </Col>
                        <Col sm={6}>
                            {/* <div className="text-sm-end d-none d-sm-block">
                                Design & Develop by Themesbrand
                            </div> */}
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;