import {
    GET_INVOICES,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    GET_INVOICE_DETAILS,
    GET_INVOICE,
    POST_INVOICE_DETAILS_PRODUCT_QUANTITY_SUCCESS,
    POST_INVOICE_DETAILS_PRODUCT_QUANTITY_FAIL,
    GET_INVOICE_DETAILS_PRODUCT_INTERCHANGE,
    DOWNLOAD_INVOICE_PDF,
    DOWNLOAD_PACKING_LIST_PDF,
    POST_SHIP_ORDER_SUCCESS,
    POST_SHIP_ORDER_FAIL,
    UPDATE_ORDER_STATUS_FAIL,
    UPDATE_ORDER_STATUS_SUCCESS,
    POST_CANCEL_ORDER_SUCCESS,
    POST_CANCEL_ORDER_FAIL,
    GET_FILTERED_INVOICES,
    GET_ARCHIVED_INVOICES,
    GET_FILTERED_ARCHIVED_INVOICES,
    GET_OLD_ORDERS_ORDER_ITEMS,
    GET_REFORMAT_ORDERITEMS,
} from "./actionType";

const INIT_STATE = {
    invoices: [],
    invoice: {},
    invoiceDetails: [],
    error: {},
};

const Invoice = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_INVOICES:
                case GET_FILTERED_INVOICES:
                case GET_ARCHIVED_INVOICES:
                case GET_FILTERED_ARCHIVED_INVOICES:
                    return {
                        ...state,
                        invoices: action.payload.data,
                        invoice: {},
                        invoiceDetails: [],
                        isInvoiceCreated: false,
                        isInvoiceSuccess: true,
                        isInvoiceDetailsSuccess: false,
                        isInvoicePdfDownloaded: false,
                        isPackingListPdfDownloaded: false,
                        invoicePdf: null,
                    };
                case GET_INVOICE:
                    return {
                        ...state,
                        invoice: action.payload.data[0],
                        isInvoiceCreated: false,
                        isInvoiceSuccess: true,
                        isOrderShippedSuccess: false, 
                        isOrderShippedFalse: false,
                        isOrderStatusChangeSuccess: false,
                        isOrderStatusChangeFail: false,
                    };
                case GET_INVOICE_DETAILS:
                    return {
                        ...state,
                        invoiceDetails: action.payload.data,
                        isInvoiceCreated: false,
                        isInvoiceDetailsSuccess: true,
                        isOrderShippedSuccess: false, 
                        isOrderShippedFalse: false,
                        isOrderStatusChangeSuccess: false,
                        isOrderStatusChangeFail: false,
                    }
                case GET_INVOICE_DETAILS_PRODUCT_INTERCHANGE:
                    return {
                        ...state,
                        invoiceDetails: state.invoiceDetails.map((item) => {
                            let tempMaxCode = '';
                            if (action.payload.data.int.length) {
                                tempMaxCode = action.payload.data.int.find((part) => part.n === item.product);
                                if (tempMaxCode) {
                                    item.maxCode = tempMaxCode.s;
                                    item.notMaxCode = tempMaxCode.n;
                                }
                            } else {
                                item.maxCode = '';
                                item.notMaxCode = '';
                            }
                            return item;
                        })
                    }
                case DOWNLOAD_INVOICE_PDF:
                    return {
                        ...state,
                        invoicePdf: action.payload.data,
                        isInvoicePdfDownloaded: true,
                    }
                case DOWNLOAD_PACKING_LIST_PDF:
                    return {
                        ...state,
                        packingListPdf: action.payload.data,
                        isPackingListPdfDownloaded: true,
                    }
                case GET_OLD_ORDERS_ORDER_ITEMS:
                case GET_REFORMAT_ORDERITEMS:
                default:
                    return { ...state };
            }
        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_INVOICES:
                case GET_FILTERED_INVOICES:
                case GET_ARCHIVED_INVOICES:
                case GET_FILTERED_ARCHIVED_INVOICES:
                    return {
                        ...state,
                        error: action.payload.error,
                        isInvoiceCreated: false,
                        isInvoiceSuccess: false
                    };
                case GET_INVOICE:
                    return {
                        ...state,
                        error: action.payload.error,
                        isInvoiceCreated: false,
                        isInvoiceSuccess: false,
                    };
                case GET_INVOICE_DETAILS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isInvoiceCreated: false,
                        isInvoiceDetailsSuccess: false,
                    }
                case GET_INVOICE_DETAILS_PRODUCT_INTERCHANGE: 
                    return {
                        ...state,
                        error: action.payload.error
                    }
                case DOWNLOAD_INVOICE_PDF:
                    return {
                        ...state,
                        error: action.payload.error,
                        isInvoicePdfDownloaded: false,
                    }
                case DOWNLOAD_PACKING_LIST_PDF:
                    return {
                        ...state,
                        error: action.payload.error,
                        isPackingListPdfDownloaded: false,
                    }
                case GET_OLD_ORDERS_ORDER_ITEMS:
                case GET_REFORMAT_ORDERITEMS:
                default:
                    return { ...state };
            }

        case POST_INVOICE_DETAILS_PRODUCT_QUANTITY_SUCCESS: {
            return {
                ...state,
                invoiceDetails: state.invoiceDetails.map((item) => {
                    let tempForSale = action.payload.find((inv) => inv.part.toString() === item.product || inv.part.toString() === item.max_product)
                    if (tempForSale) {
                        item.forsale = tempForSale.qty;
                    } else {
                        item.forsale = 'N/A'
                    }
                    return item;
                })
            }
        }

        case POST_SHIP_ORDER_SUCCESS:
            return {
                ...state,
                isOrderShippedSuccess: true, 
                isOrderShippedFalse: false, 
            }

        case UPDATE_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                isOrderStatusChangeSuccess: true,
                isOrderStatusChangeFail: false
            }

        case POST_CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                isOrderStatusChangeSuccess: true,
                isOrderStatusChangeFail: false
            }

        case POST_INVOICE_DETAILS_PRODUCT_QUANTITY_FAIL: {
            return {
                ...state,
                error: action.payload.error
            }
        }

        case POST_SHIP_ORDER_FAIL:
            return {
                ...state,
                error: action.payload.error,
                isOrderShippedSuccess: false, 
                isOrderShippedFalse: true, 
            }

        case UPDATE_ORDER_STATUS_FAIL:
            return {
                ...state,
                error: action.payload.error,
                isOrderStatusChangeSuccess: false,
                isOrderStatusChangeFail: true
            }

        case POST_CANCEL_ORDER_FAIL:
            return {
                ...state,
                isOrderStatusChangeSuccess: false,
                isOrderStatusChangeFail: true
            }

        default:
            return { ...state };
    }
};

export default Invoice;