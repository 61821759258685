import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Label } from "reactstrap";

import {
    postUserPasswordReset as onPostUserPasswordReset,    
} from "../../store/actions";

function AccountOverviewPasswordReset({ username }) {
    const dispatch = useDispatch();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const { isUserPasswordResetSuccess, isUserPasswordResetFail, error } = useSelector((state) => ({
        isUserPasswordResetSuccess: state.Customer.isUserPasswordResetSuccess,
        isUserPasswordResetFail: state.Customer.isUserPasswordResetFail,
        error: state.Customer.error
    }))

    useEffect(() => {
        if (isUserPasswordResetSuccess) alert("Password Reset");
        if (isUserPasswordResetFail) alert("An error occured while resetting password");
    }, [isUserPasswordResetSuccess, isUserPasswordResetFail]);

    function handleResetPassword(e) {
        e.preventDefault()
        let tempNewPassword = (newPassword === confirmPassword) ? (newPassword) : ('');
        
        if (!tempNewPassword) {
            alert('Passwords do not match');
            return;
        }

        dispatch(onPostUserPasswordReset(username, tempNewPassword));
    }
    
    return (
        <tr>
            <td>Password Reset</td>
            <td className="text-start">
                <Form onSubmit={(e) => handleResetPassword(e)}>
                    <Label>New Password:</Label>
                    <Input className="mb-3" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                    <Label>Confirm Password:</Label>
                    <Input className="mb-3" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                    <Button className="float-end" color={'max'} type="submit">Update Password</Button>
                </Form>
            </td>
        </tr>
    )
}

export default AccountOverviewPasswordReset;