import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Row, Table, Label, Col, Input, Button, CardHeader } from "reactstrap";
import Select from 'react-select';
import * as XLSX from "xlsx";

import { 
    productExclusionTypeOptions 
} from "../constants/administratorDetails";

import removeButton from '../../assets/images/close.png';

import { 
    getCustomerProductExclusion as onGetCustomerProductExclusion, 
    postNewCustomerExclusion as onPostNewCustomerExclusion,
    deleteCustomerExclusion as onDeleteCustomerExclusion,
} from "../../store/actions";
import Loader from "../Common/Loader";

function GlobalSettingsExclusions({ username }) {
    const dispatch = useDispatch();
    const [exType, setExType] = useState(null);
    const [exProduct, setExProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const exTypeSelectRef = useRef();
    const exProductInputRef = useRef();

    const { customerProductExclusion, isPostNewExclusionSuccess, isPostNewExclusionFail, isDeleteExclusionSuccess, isDeleteExclusionFail } = useSelector((state) => ({
        customerProductExclusion: state.Customer.customerProductExclusion,
        isPostNewExclusionSuccess: state.Customer.isPostNewExclusionSuccess,
        isPostNewExclusionFail: state.Customer.isPostNewExclusionFail,
        isDeleteExclusionSuccess: state.Customer.isDeleteExclusionSuccess,
        isDeleteExclusionFail: state.Customer.isDeleteExclusionFail,
    }))

    useEffect(() => {
        dispatch(onGetCustomerProductExclusion(username));
    }, [dispatch, username])

    useEffect(() => {
        if (isPostNewExclusionSuccess || isDeleteExclusionSuccess) {
            dispatch(onGetCustomerProductExclusion(username));
        }
        
        if (isPostNewExclusionFail) alert("Failed to add exclusion");
        if (isDeleteExclusionFail) alert("Failed to delete exclusion");
        setIsLoading(false);
    }, [dispatch, username, isPostNewExclusionSuccess, isPostNewExclusionFail, isDeleteExclusionSuccess, isDeleteExclusionFail])

    function handleExclusionFileUpload(e) {
        let file = e.target.files[0];
        let filetype = 'other';

        if (!file) return;
        if (file.name.toLowerCase().endsWith('xlsx')) filetype = 'xlsx';

        let reader = new FileReader();
        reader.onload = function(evt) {
            let data = evt.target.result;
            let parsed = '';
            if (filetype === 'xlsx') {
                let workbook = XLSX.read(data, { type:'binary' });
                parsed = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
            } else {
                parsed = data.trim().split("\n");
                for (let i in parsed) {
                    parsed[i] = parsed[i].split(",");
                }

                for (let i = 1; i < parsed.length; i++) {
                    parsed[i][1] = Number(parsed[i][1]);
                }
            }
            
            handleParsedUploadFile(parsed);
        }

        if (filetype === 'xlsx') reader.readAsBinaryString(file);
        else reader.readAsText(file);
    }

    function handleParsedUploadFile(parsedFileItems) {
        if (!parsedFileItems && parsedFileItems.length !== 0) return;
        for (let fileItem in parsedFileItems) {
            if (fileItem === 0 || fileItem === '0') continue;
            dispatch(onPostNewCustomerExclusion(username, parsedFileItems[fileItem][0], parsedFileItems[fileItem][1]));
        }
    }

    function handleSubmitNewExclusions() {
        setIsLoading(true);
        if (exType && exProduct) {
            dispatch(onPostNewCustomerExclusion(username, exType, exProduct));
            exProductInputRef.current.value = null;
            exTypeSelectRef.current.value = null;
            setExProduct(null);
            setExType(null);
        } else {
            alert('Type and Product must be filled');
            setIsLoading(false);
        }
    }

    function handleDeleteCustomerExclusion(id) {
        setIsLoading(true);
        dispatch(onDeleteCustomerExclusion(id))
    }

    return (
        <>
            <h4>Exclusions</h4>
            <Row className="mt-3">
                <Col>
                    <Card color={'light'} outline>
                        <CardBody>
                            {(isLoading) ? (
                                <Loader />
                            ) : (
                                <Table bordered striped>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Type</th>
                                            <th>Line</th>
                                            <th>Item</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody className="scrollable-table">
                                        {(customerProductExclusion) ? (
                                            customerProductExclusion.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.type}</td>
                                                        <td>{item.line}</td>
                                                        <td>{item.item}</td>
                                                        <td>
                                                            <img className="cursorPointer" src={removeButton} alt="delete" onClick={() => handleDeleteCustomerExclusion(item.id)} />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ) : (null)}
                                    </tbody>
                                </Table>
                            )}
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Label>Type:</Label>
                                    <Select
                                        innerRef={exTypeSelectRef}
                                        isClearable={true}
                                        options={productExclusionTypeOptions}
                                        onChange={(e) => (e) ? setExType(e.value) : setExType(e)}
                                    />
                                </Col>
                                <Col>
                                    <Label>Product:</Label>
                                    <Input innerRef={exProductInputRef} type="text" onChange={(e) => setExProduct(e.target.value)} />
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col className="text-center">
                                    <Button color={'max'} onClick={handleSubmitNewExclusions}>Submit</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Input type="file" onChange={(e) => handleExclusionFileUpload(e)} />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default GlobalSettingsExclusions;