import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, FormGroup, FormText, Input, Label, List, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import * as XLSX from 'xlsx';

import { downloadFiles } from "../Common/downloadFiles";

import { 
    getGroupTemplate,
    getGroups as onGetGroups,
    getGroupItems,
    deleteGroup,
    postGroups,
} from "../../store/actions";

function PromotionsAndCouponGroupManager() {
    const dispatch = useDispatch();
    const [group, setGroups] = useState([]);
    const [deleteId, setDeleteId] = useState();
    const [groupItems, setGroupItems] = useState([]);
    const [groupDescription, setGroupDescription] = useState('');
    const [srcDoc, setSrcDoc] = useState("");
    const [groupId, setGroupId] = useState("");

    const { isGroupTemplateDownloaded, groupTemplate, isGroupsFetched, groups, groupItemsCheck, isGroupItemsFetched, isGroupDeleted } = useSelector(state => ({
        groupTemplate: state.PromotionsCoupons.groupTemplate,
        isGroupTemplateDownloaded: state.PromotionsCoupons.isGroupTemplateDownloaded,
        groups: state.PromotionsCoupons.groups,
        isGroupsFetched: state.PromotionsCoupons.isGroupsFetched,
        groupItemsCheck: state.PromotionsCoupons.groupItems,
        isGroupItemsFetched: state.PromotionsCoupons.isGroupItemsFetched,
        isGroupDeleted: state.PromotionsCoupons.isGroupDeleted,
    }));

    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);

    useEffect(() => {
        if (isGroupTemplateDownloaded) {
            downloadFiles(groupTemplate, `GroupTemplate.xlsx`, 'text/csv');
        }

    }, [isGroupTemplateDownloaded]);

    useEffect(() => {
        if (isGroupItemsFetched) {
            let temp = '<ul>';
            groupItemsCheck.map(item => temp += `<li>${item.gproduct}</li>`)
            temp += '</ul>';

            setSrcDoc(temp);
            // setGroupId(id);
        }
    }, [isGroupItemsFetched]);
    
    useEffect(() => {
        if (isGroupDeleted) alert("Group deleted")
    }, [isGroupDeleted])

    function handleDownloadGroupTemplate() {
        dispatch(getGroupTemplate());
    }

    function handleGetGroups() {
        dispatch(onGetGroups());
    }

    function handleGetGroupItems(id) {
        dispatch(getGroupItems(id));
        setDeleteId(id);
        setModal(true);
    }
    
    function handleGroupUpload(event) {
        let file = event.target.files[0];
        let filetype = 'other';
        
        if (!file) return;
        if (file.name.toLowerCase().endsWith('xlsx')) filetype = 'xlsx';

        let reader = new FileReader();
        reader.onload = function (evt) {
            let data = evt.target.result;
            let parsed = '';
            let tempArr = [];
            let tempObj = {};
            
            if (filetype === 'xlsx') {
                let workbook = XLSX.read(data, { type: 'binary' });
                parsed = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
            } else {
                parsed = data.split(/\r\n|\n/);
                parsed = parsed.map((item) => {
                    return item.split(',');
                })
            }
            for (let i in parsed) {
                if (i === '0' || parsed[i].length === 0 || !parsed[i][0] || !parsed[i][1]) continue;

                tempObj = { "gproduct": parsed[i][0].trim(), "gprice": parsed[i][1].toString().trim() };
                tempArr.push(tempObj);
            }
            if (tempArr.length > 0) setGroupItems(tempArr);
            else alert("There are no items to upload.");
        }
        if (filetype === 'xlsx') reader.readAsBinaryString(file);
        else reader.readAsText(file);
    }
    
    function handleSubmitGroups() {
        let groupParams = "";
        
        if (groupId !== '' && groupDescription !== '') {
            alert('Either overwrite an existing group or make a new group.');
        } else if (groupItems.length === 0) {
            alert('No items for group');
        } else {
            if (groupId !== '') groupParams = { id: groupId, items: groupItems };
            if (groupDescription !== '') groupParams = { description: groupDescription, items: groupItems };
            
            try {
                dispatch(postGroups(groupParams));
                setGroupId('');
                alert('Group Created')
            } catch (error) {
                console.log("handleSubmitGroups Failed: " + error.message);
            }
        }
    }

    const clearGroups = () => {
        setGroupId('');
        setGroupDescription('');
        setGroupItems([]);
        document.getElementById('selectGroup').value = '';
        document.getElementById('nameGroup').value = '';
    }

    function handleDeleteGroup() {
        dispatch(deleteGroup(deleteId));
        alert("Group deleted")
    }

    return (
        <>
            <h4>Manage Groups</h4>
            <Row xxl="auto" className="p-3">
                <Col>
                    <Button type="button" color="warning" onClick={handleDownloadGroupTemplate}>Download Groups Template</Button>
                </Col>
            </Row>
            <Row xxl="auto" className="p-3">
                <Col>
                    <Button type="button" color="warning" onClick={handleGetGroups}>Get Groups</Button> {" "}
                    {(srcDoc !== "") ? (
                        <>
                            <Button type="button" color="info" onClick={() => setModal(true)}>Check Group</Button> {" "}
                            <Button type="button" color="danger" onClick={handleDeleteGroup}>Delete Selected Group</Button>
                        </>
                    ) : (null)}
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="p-3">
                        <Label for="groupSelect">Select a Group</Label>
                        <Input type="select" name="selectGroup" id="selectGroup" onChange={e => { handleGetGroupItems(e.target.value); }}>
                            <option value=""></option>
                            {(isGroupsFetched && groups) ? (groups.map((item, index) => <option key={index} value={item.id}>{item.description}</option>)) : (null)}
                        </Input>
                    </FormGroup>
                    <Modal isOpen={modal} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Products in Group</ModalHeader>
                        <ModalBody>
                            <List>
                                {(groupItemsCheck) ? (
                                    groupItemsCheck.map((item, index) => <li key={index}>{item.gproduct}</li>)
                                ) : (null)}
                            </List>
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="p-3">
                        <Label for="nameGroup">Name New Group:</Label>
                        <Input type="text" name="nameGroup" id="nameGroup" onChange={e => setGroupDescription(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="p-3">
                        <Label for="file">Upload File</Label>
                        <Input type="file" name="pricefile" id="priceFile" onChange={(e) => handleGroupUpload(e)} />
                        <FormText color="muted">
                            File containing products and prices.
                        </FormText>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="align-items-center justify-content-center mb-3">
                <Col md="auto">
                    <Button type="button" onClick={clearGroups}>Clear</Button>
                </Col>
                <Col lg="1">
                    <Button type="button" color="success" onClick={handleSubmitGroups}>Submit</Button>
                </Col>
            </Row>
                
        </>
    )
}

export default PromotionsAndCouponGroupManager;