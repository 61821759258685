import {
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_ERROR,
    POST_RESET_PASSWORD,
    POST_RESET_PASSWORD_SUCCESS,
    POST_RESET_PASSWORD_FAIL,
} from "./actionTypes";

export const userForgetPassword = (user, history) => ({
    type: FORGET_PASSWORD,
    payload: { user, history },
});

export const userForgetPasswordSuccess = message => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
});

export const userForgetPasswordError = message => ({
    type: FORGET_PASSWORD_ERROR,
    payload: message,
});

export const postResetPassword = (code, hash, password) => ({
    type: POST_RESET_PASSWORD,
    payload: { code, hash, password },
});

export const postResetPasswordSuccess = (message) => ({
    type: POST_RESET_PASSWORD_SUCCESS,
    payload: message,
});

export const postResetPasswordFail = (message) => ({
    type: POST_RESET_PASSWORD_FAIL,
    payload: message,
});
