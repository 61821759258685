// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";

// Get Credit
export const GET_CREDITS = "GET_CREDITS";
export const GET_CREDITS_FILTERED = "GET_CREDITS_FILTERED";
export const GET_CREDIT = "GET_CREDIT";
export const GET_CREDIT_DETAILS = "GET_CREDIT_DETAILS";

// Download Credit Invoice
export const DOWNLOAD_CREDIT_INVOICE = "DOWNLOAD_CREDIT_INVOICE";
export const DOWNLOAD_CREDIT_INVOICE_SUCCESS = "DOWNLOAD_CREDIT_INVOICE_SUCCESS";
export const DOWNLOAD_CREDIT_INVOICE_FAIL = "DOWNLOAD_CREDIT_INVOICE_FAIL";