import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import avatar1 from "../../assets/images/users/user-dummy-img.jpg";

const ProfileDropdown = () => {
    const { user } = useSelector(state => ({
        user: state.Profile.user,
    }));

    const [userName, setUserName] = useState("Admin");
    const [userAccess, setUserAccess] = useState('Customer');

    useEffect(() => {
        if (sessionStorage.getItem("authUser")) {
            const obj = JSON.parse(sessionStorage.getItem("authUser"));
            setUserName(user.username || obj.username || "Admin");
            switch(obj.access) {
                case 1:
                    setUserAccess('Admin');
                    break;
                case 3:
                    setUserAccess('Sales Team');
                    break;
                case 5:
                    setUserAccess('Customer');
                    break;
                case 7:
                    setUserAccess('Accounting');
                    break;
                case 9:
                    setUserAccess('Warehouse');
                    break;
                default:
                    setUserAccess('Customer');
                    break;
            }
        }
    }, [userName, user]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    
    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={avatar1} alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{userAccess}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Welcome {userName}!</h6>
                    <DropdownItem>
                        <Link className="text-dark" to={"/account-overview"}>
                            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Account Overview</span>
                        </Link>
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    {/* <DropdownItem href="/pages-profile">
                        <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> 
                        <span className="align-middle">Balance : <b>$0.00</b></span>
                    </DropdownItem> */}
                    {/* <DropdownItem>
                        <Link className="text-dark" to={`/history/${userName}`}>
                            <span className="badge bg-soft-success text-success mt-1 float-end">New</span>
                            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> 
                            <span className="align-middle">Settings</span>
                        </Link>
                    </DropdownItem> */}
                    <DropdownItem>
                        <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> 
                        <span className="align-middle" data-key="t-logout">
                            <Link to={"/logout"}>Logout</Link>
                        </span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;