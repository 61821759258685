import React, { useState, useEffect, useMemo, useCallback } from "react";
import { CardBody, Row, Col, Card, Container, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap";
import { Link, Redirect } from "react-router-dom";

// User info
import { useProfile } from '../../../Components/Hooks/UserHooks';

import * as moment from "moment";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import { FlatpickrSingleColumnFilter, SelectColumnFilter } from "../../../Components/Common/filters";
import { downloadFiles } from "../../../Components/Common/downloadFiles";

//Import actions
import {
    getInvoices as onGetInvoices,
    downloadInvoicePdf as onDownloadInvoicePdf,
    downloadPackingListPdf as onDownloadPackingListPdf,
} from "../../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import Loader from "../../../Components/Common/Loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Unshipped = () => {
    const { userProfile } = useProfile();
    const dispatch = useDispatch();
    const [pdfOrderId, setPdfOrderId] = useState(null);
    const [invoice, setInvoice] = useState(null);

    const { invoices, isInvoiceSuccess, invoicePdf, isInvoicePdfDownloaded, packingListPdf, isPackingListPdfDownloaded, error } = useSelector((state) => ({
        invoices: state.Invoice.invoices,
        isInvoiceSuccess: state.Invoice.isInvoiceSuccess,
        invoicePdf: state.Invoice.invoicePdf,
        isInvoicePdfDownloaded: state.Invoice.isInvoicePdfDownloaded,
        packingListPdf: state.Invoice.packingListPdf,
        isPackingListPdfDownloaded: state.Invoice.isPackingListPdfDownloaded,
        error: state.Invoice.error,
    }));

    useEffect(() => {
        dispatch({ type: 'RESET_APP' });
    }, [dispatch])

    useEffect(() => {
        if (invoices && !invoices.length) {
            dispatch(onGetInvoices());
        }
    }, [dispatch, invoices]);

    useEffect(() => {
        setInvoice(invoices);
    }, [invoices]);

    useEffect(() => {
        if (isInvoicePdfDownloaded && pdfOrderId) {
            downloadFiles(invoicePdf, `Invoice ${pdfOrderId}.pdf`, 'application/pdf');
        }
    }, [invoicePdf, isInvoicePdfDownloaded, pdfOrderId])

    useEffect(() => {
        if (isPackingListPdfDownloaded && pdfOrderId) {
            downloadFiles(packingListPdf, `Packing List ${pdfOrderId}.pdf`, 'application/pdf');
        }
    }, [packingListPdf, isPackingListPdfDownloaded, pdfOrderId])

    const handleValidDate = date => {
        if (!date) return date
        const date1 = moment(new Date(date)).format("Y-MM-DD");
        return date1;
    };

    // Checked All
    const checkedAll = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".invoiceCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    const handleDownloadInvoicePdf = useCallback((orderId) => {
        dispatch(onDownloadInvoicePdf(orderId));
        setPdfOrderId(orderId);
    }, [dispatch])

    const handleDownloadPackingListPdf = useCallback((orderId) => {
        dispatch(onDownloadPackingListPdf(orderId));
        setPdfOrderId(orderId);
    }, [dispatch])

    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="invoiceCheckBox" value={cellProps.row.original._id} />;
                },
                id: '#',
            },
            {
                Header: "ORDER ID",
                accessor: "order_id",
                filterable: true,
                Cell: (cell) => {
                    return <Link to={`/history/order-history/${cell.row.original.order_id}`} className="fw-medium link-primary">{cell.row.original.order_id}</Link>;
                },
            },
            {
                Header: "PO",
                accessor: "custom_po",
                filterable: true,
                Cell: (invoice) => (
                    <>
                        {invoice.row.original.custom_po}
                    </>
                ),
            },
            {
                Header: "USERNAME",
                accessor: (userProfile.access !== 5) ? ('username') : (null),
                Cell: (invoice) => (
                    <>
                        <div className="d-flex align-items-center">
                            {invoice.row.original.username}
                        </div>
                    </>
                ),
            },
            {
                Header: "DATE ORDERED",
                accessor: 'date_created',
                filterable: false,
                Filter: FlatpickrSingleColumnFilter,
                Cell: (invoice) => (
                    <>
                        {handleValidDate(invoice.row.original.date_created)}
                    </>
                ),
            },
            {
                Header: "DATE SHIPPED",
                accessor: 'date_shipped',
                filterable: false,
                Filter: FlatpickrSingleColumnFilter,
                // filter: 'includes',
                Cell: (invoice) => (
                    (invoice.row.original.date_shipped === null) ? (
                        <>
                            {handleValidDate(invoice.row.original.date_shipped)}
                            {/* ,{" "} */}
                            {/* <small className="text-muted">{handleValidTime(invoice.row.original.date_shipped)}</small> */}
                        </>
                    ) : (null)
                ),
            },
            {
                Header: "METHOD",
                accessor: "ship_method",
                filterable: false,
                Filter: SelectColumnFilter,
                Cell: (invoice) => (
                    <>
                        {invoice.row.original.ship_method}
                    </>
                ),
            },
            {
                Header: "TOTAL",
                filterable: false,
                Cell: (invoice) => (
                    <>
                        ${invoice.row.original.cost_final}
                    </>
                ),
            },
            {
                Header: "COMPANY NAME",
                accessor: (userProfile.access !== 5) ? ('company_name') : (null),
                Filter: SelectColumnFilter,
                Cell: (cell) => (
                    <>
                        {cell.row.original.company_name}
                    </>
                )
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <UncontrolledDropdown >
                            <DropdownToggle
                                href="#"
                                className="btn-soft-secondary btn-sm dropdown"
                                tag="button"
                            >
                                <i className="ri-more-fill align-middle"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem href={`/history/order-history/${cellProps.row.original.order_id}`}>
                                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                                    View
                                </DropdownItem>
                                <DropdownItem onClick={() => handleDownloadInvoicePdf(cellProps.row.original.order_id)}>
                                    <i className="ri-download-2-line align-bottom me-2 text-muted"></i>{" "}
                                    Download Invoice
                                </DropdownItem>
                                <DropdownItem onClick={() => handleDownloadPackingListPdf(cellProps.row.original.order_id)}>
                                    <i className="ri-download-2-line align-bottom me-2 text-muted"></i>{" "}
                                    Download Packing List
                                </DropdownItem>
                                <DropdownItem divider />
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    );
                },
            },
        ],
        [userProfile, handleDownloadInvoicePdf, handleDownloadPackingListPdf]
    );
    
    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />
    if (!userProfile.permissions.view_order_history) return <Redirect to="/not-authorized" />
    
    document.title = "Unshipped | Max Advanced Brakes - Prozone";

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Unshipped" pageTitle="Unshipped" linkTo={'/administrator/unshipped'} />
                    <Row>
                        <Col lg={12}>
                            <Card id="unshipped">
                                <CardHeader className="border-0">
                                    <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-0 flex-grow-1">Unshipped</h5>
                                    </div>
                                </CardHeader>

                                <CardBody className="pt-0">
                                    <div>
                                        {isInvoiceSuccess && invoices ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(invoices || [])}
                                                isGlobalFilter={false}
                                                isAddUserList={false}
                                                customPageSize={30}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="text-muted"
                                                thClass="text-muted"
                                                isInvoiceListFilter={false}
                                            />
                                        ) : (<Loader error={error} />)}
                                        <ToastContainer closeButton={false} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Unshipped;