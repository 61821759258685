import { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { CardBody, Row, Col, Card, Container, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Label, Input, } from "reactstrap";
import * as moment from "moment";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useProfile } from '../../../../Components/Hooks/UserHooks';
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../../../Components/Common/TableContainer";
import { FlatpickrSingleColumnFilter, SelectColumnFilter } from "../../../../Components/Common/filters";
import Loader from "../../../../Components/Common/Loader";
import { downloadFiles } from "../../../../Components/Common/downloadFiles";
import PDFRenderer from "../../../../Components/Common/PDFRenderer";
import DownloadImage from '../../../../assets/images/download.png';

//Import actions
import {
    downloadReturnInvoice as onDownloadReturnInvoice,
    getReturns as onGetReturns,
    getReturnsFiltered as onGetReturnsFiltered,
    getRgrForm,
} from "../../../../store/actions";

const ReturnHistory = () => {
    const { userProfile } = useProfile();
    const dispatch = useDispatch();
    const [returnArr, setReturnArr] = useState(null);
    const [pdfReturnId, setPdfReturnId] = useState(null);
    const [invoiceFilter, setInvoiceFilter] = useState({ product: '' });
    const [isLoading, setIsLoading] = useState(false);

    // pdf modal
    const [openPdfModal, setOpenPdfModal] = useState(false);
    const closePdfModal = () => setOpenPdfModal(false);

    const { returns, isReturnSuccess, rgrForm, isRgrFormDownloadSuccess, returnInvoicePdf, isReturnInvoiceDownloaded, error } = useSelector((state) => ({
        returns: state.Returns.returns,
        isReturnSuccess: state.Returns.isReturnSuccess,
        rgrForm: state.Returns.rgrForm,
        isRgrFormDownloadSuccess: state.Returns.isRgrFormDownloadSuccess,
        returnInvoicePdf: state.Returns.returnInvoicePdf,
        isReturnInvoiceDownloaded: state.Returns.isReturnInvoiceDownloaded,
        error: state.Returns.error,
    }));

    useEffect(() => {
        dispatch({ type: 'RESET_APP' })
    }, [dispatch])

    useEffect(() => {
        setIsLoading(true);
        dispatch(onGetReturns());
    }, [dispatch]);

    useEffect(() => {
        setReturnArr(returns);
        setIsLoading(false);
    }, [returns]);

    useEffect(() => {
        if (isRgrFormDownloadSuccess) {
            downloadFiles(rgrForm, `Max Advanced Brakes_RGR form_GTA_fillable_blank.pdf`, 'application/pdf');
        }
    }, [isRgrFormDownloadSuccess, rgrForm]);

    useEffect(() => {
        if (isReturnInvoiceDownloaded && pdfReturnId) {
            setOpenPdfModal(true);
        }
    }, [returnInvoicePdf, isReturnInvoiceDownloaded, pdfReturnId]);

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("Y-MM-DD");
        return date1;
    };

    function handleFilterInvoices(e) {
        e.preventDefault();
        setIsLoading(true);
        dispatch(onGetReturnsFiltered(invoiceFilter))
    }

    function handleClearFilter() {
        setIsLoading(true);
        setInvoiceFilter({ product: '' });
        dispatch(onGetReturns());
    }

    // Checked All
    const checkedAll = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".invoiceCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    function handleDownloadRgrForm() {
        dispatch(getRgrForm());
    }

    const handleDownloadReturnInvoice = useCallback((returnId) => {
        dispatch(onDownloadReturnInvoice(returnId));
        setPdfReturnId(returnId);
    }, [dispatch])

    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="invoiceCheckBox" value={cellProps.row.original._id} />;
                },
                id: '#',
            },
            {
                Header: "RETURN ID",
                accessor: "id",
                filterable: true,
                Cell: (cell) => {
                    return <Link to={`/history/return-history/${cell.row.original.id}`} className="fw-medium link-primary">{cell.row.original.id}</Link>;
                },
            },
            {
                Header: 'RETURN INVOICE',
                filterable: false,
                Cell: (cell) => (
                    <>
                        <img className="cursorPointer" width="30" height="30" src={DownloadImage} alt="Invoice" onClick={() => handleDownloadReturnInvoice(cell.row.original.id)} />
                    </>
                )
            },
            {
                Header: "COMPANY NAME",
                accessor: (userProfile.access !== 5) ? ('company_name') : (null),
                Filter: SelectColumnFilter,
                Cell: (invoice) => (
                    <>
                        <div className="d-flex align-items-center">
                            {invoice.row.original.company_name}
                        </div>
                    </>
                ),
            },
            {
                Header: "CREATED DATE",
                accessor: 'date_created',
                filterable: false,
                Filter: FlatpickrSingleColumnFilter,
                Cell: (invoice) => (
                    <>
                        {handleValidDate(invoice.row.original.date_created)}
                    </>
                ),
            },
            {
                Header: "UPDATED DATE",
                accessor: 'date_updated',
                filterable: false,
                Filter: FlatpickrSingleColumnFilter,
                Cell: (invoice) => (
                    <>
                        {handleValidDate(invoice.row.original.date_updated)}
                    </>
                ),
            },
            {
                Header: "STATUS",
                accessor: 'status',
                Filter: SelectColumnFilter,
                Cell: (invoice) => (
                    <>
                        {invoice.row.original.status}
                    </>
                ),
            },
        ],
        [userProfile, handleDownloadReturnInvoice]
    );

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />
    if (!userProfile.permissions.view_return_history) return <Redirect to="/not-authorized" />

    document.title = "Return History | Max Advanced Brakes - Prozone";

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Return History" pageTitle="Return History" linkTo={`/history/return-history`} />
                    {
                        openPdfModal && <PDFRenderer
                            fileUrl={URL.createObjectURL(returnInvoicePdf)}
                            modalHeaderText={`Return Invoice ${pdfReturnId}`}
                            openPdfModal={openPdfModal}
                            closePdfModal={closePdfModal} />
                    }
                    <Row>
                        <Col lg={12}>
                            <Card id="returns">
                                <CardHeader className="border-0">
                                    <div className="d-flex justify-content-end align-items-center">
                                        {/* <h5 className="card-title mb-0 flex-grow-1">Returns</h5> */}
                                        <Button color={"max"} onClick={handleDownloadRgrForm}>Download</Button>
                                    </div>
                                </CardHeader>

                                <CardBody className="pt-0">
                                    <form onSubmit={(e) => handleFilterInvoices(e)}>
                                        <Row className="mt-2">
                                            <Col>
                                                <Label>Product</Label>
                                                <Input type="text" value={invoiceFilter.product} onChange={(e) => setInvoiceFilter({ ...invoiceFilter, product: e.target.value })} />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                <Button color={'max'} type="submit">Filter</Button>
                                            </Col>
                                            <Col className="text-end">
                                                <Button color={'secondary'} onClick={handleClearFilter}>Clear</Button>
                                            </Col>
                                        </Row>
                                    </form>
                                    <Row>
                                        <Col>
                                            <div>
                                                {(!isLoading && isReturnSuccess && returns) ? (
                                                    <TableContainer
                                                        columns={columns}
                                                        data={(returns || [])}
                                                        isGlobalFilter={false}
                                                        isAddUserList={false}
                                                        customPageSize={30}
                                                        className="custom-header-css"
                                                        divClass="table-responsive table-card"
                                                        tableClass="align-middle table-nowrap"
                                                        theadClass="text-muted"
                                                        thClass="text-muted"
                                                        isInvoiceListFilter={false}
                                                    />
                                                ) : (<Loader error={error} />)}
                                                <ToastContainer closeButton={false} />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default ReturnHistory;