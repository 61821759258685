import { 
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  POST_REGISTRATION,
} from "./actionType";

const INIT_STATE = {
  
}

const Registration = (state = INIT_STATE, action) => {
  switch(action.type) {
      case API_RESPONSE_SUCCESS:
          switch(action.payload.actionType) {
            case POST_REGISTRATION:
              return {
                ...state,
                isRegistrationPosted: true,
              }
              default:
                  return {
                      ...state,
                  }
          }
      case API_RESPONSE_ERROR:
          switch(action.payload.actionType) {
            case POST_REGISTRATION:
              return {
                ...state,
                isRegistrationPosted: false,
              }
              default:
                  return {
                      ...state,
                  }
          }
      default:
          return{
              ...state,
          }
  }
}
export default Registration;