import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, Col, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Select from "react-select";

import { orderCreditTermsOptions, shipMethodOptions } from "../constants/newOrderDetails";

function OrderDraftInformation({ userProfile, draftInfo, setDraftInfo, username, handleSelectUsername, checkTyping, clearCount }) {
    const { customers } = useSelector((state) => ({
        customers: state.Customer.customers,
    }));

    const [activeTab, setactiveTab] = useState("1");
    const toggle = (tab) => {
        if (activeTab !== tab) setactiveTab(tab);
    };

    useEffect(() => {
        checkTyping();
    }, [draftInfo])

    return (
        <Card className="darkerBoxShadow">
            <CardBody onKeyUp={checkTyping} onKeyDown={clearCount}>
                {(userProfile.access !== 5) ? (
                    <Row className="mb-3">
                        <Col>
                            <Label>Promotion Order:</Label>{' '}
                            <Input type="checkbox" checked={(draftInfo.is_promotion) ? (1) : (0)} onChange={(e) => setDraftInfo({ ...draftInfo, is_promotion: (e.target.checked) ? (1) : (0) })} />
                        </Col>
                        <Col>
                            <Label>Credit Terms:</Label>
                            {/* <Input type="number" step="30" min="30" max="120" onChange={(e) => setDraftInfo({ ...draftInfo, credit_terms: Number(e.target.value) })} /> */}
                            <Select value={orderCreditTermsOptions.find((item) => item.value === draftInfo.credit_terms)} onChange={(e) => setDraftInfo({ ...draftInfo, credit_terms: e.value })} options={orderCreditTermsOptions} />
                        </Col>
                    </Row>
                ) : (null)}
                <Row className="mb-3">
                    {(userProfile.access !== 5) ? (
                        <Col>
                            <Label className="form-label">Placing Order As: {(username !== null) ? (username.value) : (null)}</Label>
                            <Select value={username} isClearable={true} onChange={(e) => handleSelectUsername(e) } options={customers.map((item) => ({ value: item.username, label: `${item.company_name} (${item.username})` }))} />
                        </Col>
                    ) : (null)}
                    <Col>
                        <Label>PO:</Label>
                        <Input type="text" className="form-control" placeholder="PO" id="po" value={draftInfo.po} maxLength={100} onChange={(e) => setDraftInfo(prevState => ({ ...prevState, po: e.target.value }))} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Label>Ship To:</Label>
                        <Input type="text" placeholder="Shipping address" value={draftInfo.shipto} disabled />
                    </Col>
                    <Col md={6}>
                        <Label htmlFor="draftName" className="form-label">Draft Name (Optional):</Label>
                        <Input type="text" className="form-control" placeholder="Draft Name" id="draftName" maxLength={45} defaultValue={draftInfo.draft_name} onChange={(e) => setDraftInfo({ ...draftInfo, 'draft_name': e.target.value })} />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Label className="form-label">Shipping Method: {draftInfo.ship_method}</Label>
                        <Select value={shipMethodOptions.find((item) => item.value === draftInfo.ship_method)} isClearable={true} onChange={(e) => setDraftInfo({ ...draftInfo, ship_method: e.value })} options={shipMethodOptions} />
                    </Col>
                    <Col>
                        <Nav tabs className="nav-tabs mb-3">
                            <NavItem>
                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "1", })} onClick={() => { toggle("1"); }} >
                                    My Notes
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "2", })} onClick={() => { toggle("2"); }} >
                                    Notes to Max Brakes
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="1" id="home">
                                <textarea className="form-control" value={draftInfo.mynotes} rows="3" onChange={(e) => setDraftInfo({ ...draftInfo, 'mynotes': e.target.value })}></textarea>
                                <p className="mb-0">
                                    This note will not be read by Max Brakes and will be printed on all documents related to this order.
                                </p>
                            </TabPane>

                            <TabPane tabId="2" id="product">
                                <textarea className="form-control" value={draftInfo.maxnotes} rows="3" onChange={(e) => setDraftInfo({ ...draftInfo, 'maxnotes': e.target.value })}></textarea>
                                <p className="mb-0">
                                    Max Brakes will acknowledge this note and take suitable actions. This note won't be printed on any document. The nature of the note may cause a delay.
                                </p>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default OrderDraftInformation;