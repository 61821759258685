import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import * as moment from "moment";

import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { useProfile } from '../../../../Components/Hooks/UserHooks';
import TableContainer from '../../../../Components/Common/TableContainer';
import Loader from '../../../../Components/Common/Loader';
import { FlatpickrSingleColumnFilter, SelectColumnFilter } from '../../../../Components/Common/filters';
import PDFRenderer from "../../../../Components/Common/PDFRenderer";

//Import actions
import {
    getReturns as onGetReturns,
    downloadReturnInvoice as onDownloadReturnInvoice,
} from "../../../../store/actions";

const ReturnMaterialAuthorization = () => {
    const { userProfile } = useProfile();
    const dispatch = useDispatch();
    const [returnArr, setReturnArr] = useState([]);
    const [pdfReturnId, setPdfReturnId] = useState(null);

    // pdf modal
    const [openPdfModal, setOpenPdfModal] = useState(false);
    const closePdfModal = () => setOpenPdfModal(false);

    const { returns, isReturnSuccess, returnInvoicePdf, isReturnInvoiceDownloaded, error } = useSelector((state) => ({
        returns: state.Returns.returns,
        isReturnSuccess: state.Returns.isReturnSuccess,
        returnInvoicePdf: state.Returns.returnInvoicePdf,
        isReturnInvoiceDownloaded: state.Returns.isReturnInvoiceDownloaded,
        error: state.Returns.error,
    }));

    useEffect(() => {
        dispatch({ type: 'RESET_APP' })
    }, [dispatch]);

    useEffect(() => {
        dispatch(onGetReturns());
    }, [dispatch]);

    // useEffect(() => {
    //     function getreturns() {
    //         if (returns && !returns.length) {
    //             dispatch(onGetReturns());
    //         }
    //     }
    //     getreturns()

    //     const interval = setInterval(() => getreturns(), 5000)
    //     return () => {
    //         clearInterval(interval);
    //     }
    // }, []);
    // useEffect(() => {
    //     if (returns && !returns.length) {
    //         dispatch(onGetReturns());
    //     }
    // }, [dispatch, returns]);

    useEffect(() => {
        setReturnArr(returns);
    }, [returns]);

    useEffect(() => {
        if (isReturnInvoiceDownloaded && pdfReturnId) {
            setOpenPdfModal(true);
        }
    }, [returnInvoicePdf, isReturnInvoiceDownloaded, pdfReturnId]);

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("Y-MM-DD");
        return date1;
    };

    // Checked All
    const checkedAll = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".invoiceCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    const handleDownloadReturnInvoice = useCallback((returnId) => {
        dispatch(onDownloadReturnInvoice(returnId));
        setPdfReturnId(returnId);
    }, [dispatch])

    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="invoiceCheckBox" value={cellProps.row.original._id} />;
                },
                id: '#',
            },
            {
                Header: "RETURN ID",
                accessor: "id",
                filterable: true,
                Cell: (cell) => {
                    return <Link to={`/order-desk/return-material-authorization/${cell.row.original.id}`} className="fw-medium link-primary">{cell.row.original.id}</Link>;
                },
            },
            {
                Header: "RETURN REQUEST CREATED",
                accessor: 'date_created',
                filterable: false,
                Filter: FlatpickrSingleColumnFilter,
                Cell: (invoice) => (
                    <>
                        {handleValidDate(invoice.row.original.date_created)}
                    </>
                ),
            },
            {
                Header: "USERNAME",
                accessor: (userProfile.access !== 5) ? ('username') : (null),
                Filter: SelectColumnFilter,
                Cell: (invoice) => (
                    <>
                        <div className="d-flex align-items-center">
                            {invoice.row.original.username}
                        </div>
                    </>
                ),
            },
            {
                Header: "STATUS",
                accessor: 'status',
                Filter: SelectColumnFilter,
                Cell: (invoice) => (
                    <>
                        {invoice.row.original.status}
                    </>
                ),
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <UncontrolledDropdown >
                            <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                                <i className="ri-more-fill align-middle"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem href={`/return-material-authorization/${cellProps.row.original.id}`}>
                                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                                    View
                                </DropdownItem>
                                <DropdownItem onClick={() => handleDownloadReturnInvoice(cellProps.row.original.id)}>
                                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                                    Download Return Invoice
                                </DropdownItem>
                                <DropdownItem divider />
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    );
                },
            },
        ],
        [userProfile, handleDownloadReturnInvoice]
    );

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />
    if (!userProfile.permissions.view_return_history) return <Redirect to="/not-authorized" />

    document.title = "RMA | Max Advanced Brakes - Prozone";

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="RMA" pageTitle="RMA" linkTo={`/order-desk/return-material-authorization`} />
                    {
                        openPdfModal && <PDFRenderer
                            fileUrl={URL.createObjectURL(returnInvoicePdf)}
                            modalHeaderText={`Return Invoice ${pdfReturnId}`}
                            openPdfModal={openPdfModal}
                            closePdfModal={closePdfModal} />
                    }
                    <Row>
                        <Col>
                            <Card id="return-request-management">
                                <CardHeader className="border-0">
                                    <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-0 flex-grow-1">RMA</h5>
                                    </div>
                                </CardHeader>

                                <CardBody className="pt-0">
                                    <div>
                                        {(isReturnSuccess && returnArr) ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(returnArr || [])}
                                                isGlobalFilter={false}
                                                isAddUserList={false}
                                                customPageSize={30}
                                                className="custom-header-css"
                                                divClass="table-responsive table-card"
                                                tableClass="align-middle table-nowrap"
                                                theadClass="text-muted"
                                                thClass="text-muted"
                                                isInvoiceListFilter={false}
                                            />
                                        ) : (<Loader error={error} />)}
                                        <ToastContainer closeButton={false} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default ReturnMaterialAuthorization;