import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

function NotAuthorized() {
    document.title="Not Authorized | Max Advanced Brakes Wholesale";
    return (
        <>
            <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="auth-page-content overflow-hidden p-0">
                    <Container fluid={true}>
                        <Row className="justify-content-center">
                            <Col xl={4} className="text-center">
                                <div className="error-401 position-relative">
                                    {/* <img src={error500} alt="" className="img-fluid error-500-img error-img" /> */}
                                    <h1 className="title text-primary">401</h1>
                                </div>
                                <div>
                                    <h4>Not Authorized!</h4>
                                    <p className="text-muted w-75 mx-auto">Not Authorized 401. You are not authorized to access this page.</p>
                                    <Link to="/home" className="btn btn-primary"><i className="mdi mdi-home me-1"></i>Back to home</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default NotAuthorized;