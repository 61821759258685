// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const RESET_APP = "RESET_APP";

// Get Drafts
export const GET_DRAFTS = "GET_DRAFTS";
export const GET_DRAFT = "GET_DRAFT";

// Add New Draft
export const ADD_DRAFT = "ADD_DRAFT";
export const ADD_DRAFT_SUCCESS = "ADD_DRAFT_SUCCESS"
export const ADD_DRAFT_FAIL = "ADD_DRAFT_FAIL"

// Create New Order
export const CREATE_NEW_ORDER = "CREATE_NEW_ORDER";
export const CREATE_NEW_ORDER_SUCCESS = "CREATE_NEW_ORDER_SUCCESS";
export const CREATE_NEW_ORDER_FAIL = "CREATE_NEW_ORDER_FAIL";

// Update Draft fields
export const POST_DRAFT_UPDATE = "POST_DRAFT_UPDATE";
export const POST_DRAFT_UPDATE_SUCCESS = "POST_DRAFT_UPDATE_SUCCESS";
export const POST_DRAFT_UPDATE_FAIL = "POST_DRAFT_UPDATE_FAIL";

// Delete Draft
export const DELETE_DRAFT = "DELETE_DRAFT";
export const DELETE_DRAFT_SUCCESS = "DELETE_DRAFT_SUCCESS";
export const DELETE_DRAFT_FAIL = "DELETE_DRAFT_FAIL";

// Delete Draft items
export const DELETE_DRAFT_ITEMS = "DELETE_DRAFT_ITEMS";
export const DELETE_DRAFT_ITEMS_SUCCESS = "DELETE_DRAFT_ITEMS_SUCCESS";
export const DELETE_DRAFT_ITEMS_FAIL = "DELETE_DRAFT_ITEMS_FAIL";

// Preview order draft and username
export const PREVIEW_ORDER_DRAFT = "PREVIEW_ORDER_DRAFT";
export const PREVIEW_ORDER_USERNAME = "PREVIEW_ORDER_USERNAME";

// Download draft template
export const DOWNLOAD_DRAFT_TEMPLATE = "DOWNLOAD_DRAFT_TEMPLATE";