import { Input } from "reactstrap";

function BasicInfoTableRow({ field, fieldValue, property, type, accountInfo, setAccountInfo }) {
    return (
        <tr>
            <td>{field}</td>
            <td>{fieldValue}</td>
            <td>
                <Input value={accountInfo[property] || ""} type={type} onChange={(e) => setAccountInfo({ ...accountInfo, [property]: e.target.value }) } />    
            </td>
        </tr>
    )
}

export default BasicInfoTableRow;