import { 
    API_RESPONSE_ERROR, 
    API_RESPONSE_SUCCESS, 
    PING
} from "./actionType";

// common success
export const idleTimerApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const idleTimerApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const ping = () => ({
    type: PING,
});
