import { 
    API_RESPONSE_ERROR, 
    API_RESPONSE_SUCCESS, 
    GET_CATEGORIES, 
    GET_MAKES, 
    GET_MODELS, 
    GET_YEARS,
    RESET_VEHICLE_MAKES,
    RESET_VEHICLE_MODELS,
    RESET_VEHICLE_CATEGORIES,
    RESET_VEHICLE_PRODUCTS,
    POST_PRODUCTS_WHOLESALE_INVENTORY,
    POST_PRODUCTS_WHOLESALE_INVENTORY_FAIL,
    POST_PRODUCTS_WHOLESALE_INVENTORY_SUCCESS,
    POST_PRODUCT_ARRAY,
    POST_PRODUCT_ARRAY_FAIL,
    POST_PRODUCT_ARRAY_SUCCESS
} from "./actionType";

// common success
export const vehicleApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const vehicleApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getYears = () => ({
    type: GET_YEARS,
});

export const getMakes = (year) => ({
    type: GET_MAKES,
    payload: year
});

export const getModels = (year, make) => ({
    type: GET_MODELS,
    payload: { year, make }
});

export const getCategories = (year, make, model) => ({
    type: GET_CATEGORIES,
    payload: { year, make, model }
});

export const resetVehicleMakes = () => ({
    type: RESET_VEHICLE_MAKES
});

export const resetVehicleModels = () => ({
    type: RESET_VEHICLE_MODELS
});

export const resetVehicleCategories = () => ({
    type: RESET_VEHICLE_CATEGORIES
});

export const resetVehicleProducts = () => ({
    type: RESET_VEHICLE_PRODUCTS
});

export const postProductArray = (productArr) => ({
    type: POST_PRODUCT_ARRAY,
    payload: productArr
});

export const postProductArraySuccess = (data) => ({
    type: POST_PRODUCT_ARRAY_SUCCESS,
    payload: data
});

export const postProductArrayFail = (error) => ({
    type: POST_PRODUCT_ARRAY_FAIL,
    payload: error
});

export const postProductsWholesaleInventory = (products) => ({
    type: POST_PRODUCTS_WHOLESALE_INVENTORY,
    payload: products
});

export const postProductsWholesaleInventorySuccess = (data) => ({
    type: POST_PRODUCTS_WHOLESALE_INVENTORY_SUCCESS,
    payload: data
});

export const postProductsWholesaleInventoryFail = (error) => ({
    type: POST_PRODUCTS_WHOLESALE_INVENTORY_FAIL,
    payload: error
});