import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { CardBody, Row, Col, Card, Container, CardHeader, Label } from "reactstrap";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CountUp from "react-countup";
import * as moment from "moment";

import { useProfile } from '../../../../Components/Hooks/UserHooks';
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../../../Components/Common/TableContainer";
import { FlatpickrSingleColumnFilter } from "../../../../Components/Common/filters";
import Loader from "../../../../Components/Common/Loader";
import PDFRenderer from "../../../../Components/Common/PDFRenderer";
import { statementARTable } from "../../../../common/data/index";
import DownloadImage from '../../../../assets/images/download.png';
import checkImg from "../../../../assets/images/right.png";

import {
    getStatements as onGetStatements,
    downloadStatement as onDownloadStatement,
} from "../../../../store/actions";

function OverdueStatements() {
    const { userProfile } = useProfile();
    const dispatch = useDispatch();
    const [statementsArr, setStatementsArr] = useState(null);
    const [pdfStatementId, setPdfStatementId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // pdf modal
    const [openPdfModal, setOpenPdfModal] = useState(false);
    const closePdfModal = () => setOpenPdfModal(false);

    const { statements, statementPdf, isStatementPdfDownloaded, error } = useSelector((state) => ({
        statements: state.Statements.statements,
        statementPdf: state.Statements.statementPdf,
        isStatementPdfDownloaded: state.Statements.isStatementPdfDownloaded,
        error: state.Statements.error,
    }));

    useEffect(() => {
        dispatch({ type: 'RESET_APP' });
    }, [dispatch])

    useEffect(() => {
        setIsLoading(true);
        dispatch(onGetStatements());
    }, [dispatch]);

    useEffect(() => {
        let temp = statements.filter((item) => !item.fully_paid || Number(item.multi_term_orders_total) !== Number(item.multi_term_order_payment))
        setStatementsArr(temp);
        setIsLoading(false);
    }, [statements]);

    useEffect(() => {
        if (isStatementPdfDownloaded && pdfStatementId) {
            setOpenPdfModal(true);
        }
    }, [statementPdf, isStatementPdfDownloaded, pdfStatementId])

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("Y-MM-DD");
        return date1;
    };

    // Checked All
    const checkedAll = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".invoiceCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    function arBalanceRange(date1, date2, start, end) {
        return (getDate(date1) > start && getDate(date2) < end)
    }

    function getDate(date_created) {
        return (new Date(Date.now()).getTime() - new Date(date_created).getTime()) / (1000 * 60 * 60 * 24);
    }

    function arBalance(balance, paid_amount) {
        return Number(balance) - Number(paid_amount);
    }

    function getTotalBalance(acc, cur) {
        return acc + cur;
    }

    const remainingBalanceDateRange = (term) => {
        return statementsArr.map((item) => (arBalanceRange(item.date_created, item.date_created, term.start, term.end)) ? (arBalance(item.balance, item.paid_amount)) : (0.00)).reduce(getTotalBalance).toFixed(2).replace(/-/, "");
    }

    const handleDownloadStatementPdf = useCallback((statementId) => {
        dispatch(onDownloadStatement(statementId))
        setPdfStatementId(statementId);
    }, [dispatch])

    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="invoiceCheckBox" value={cellProps.row.original._id} />;
                },
                id: '#',
            },
            {
                Header: "STATEMENT ID",
                accessor: "statement_id",
                filterable: true,
                Cell: (cell) => {
                    return <Link to={`/history/statements/${cell.row.original.statement_id}`} className="fw-medium link-primary">{cell.row.original.statement_id}</Link>;
                },
            },
            {
                Header: "Paid",
                filterable: false,
                Cell: (statement) => (
                    <>
                        {(statement.row.original.fully_paid === 1) ? (<img src={checkImg} alt={1} />) : (null)}
                    </>
                ),
            },
            {
                Header: "DATE CREATED",
                accessor: 'date_created',
                filterable: false,
                Filter: FlatpickrSingleColumnFilter,
                // filter: 'between',
                Cell: (statement) => (
                    <>
                        {handleValidDate(statement.row.original.date_created)}
                        {/* ,{" "} */}
                        {/* <small className="text-muted">{handleValidTime(invoice.row.original.date_created)}</small> */}
                    </>
                ),
            },
            {
                Header: "R. BALANCE",
                filterable: false,
                Cell: (statement) => (
                    <>
                        ${(statement.row.original.fully_paid === 1) ? ("0.00") : ((Number(statement.row.original.balance) - Number(statement.row.original.paid_amount)).toFixed(2) /*.replace(/-/, '')*/)}
                    </>
                ),
            },
            {
                Header: "R. MULTI TERM BALANCE",
                filterable: false,
                Cell: (statement) => (
                    <>
                        ${(Number(statement.row.original.multi_term_orders_total) - Number(statement.row.original.multi_term_order_payment)).toFixed(2).replace(/-/, '')}
                    </>
                ),
            },
            {
                Header: "USERNAME",
                accessor: 'username',
                Cell: (statement) => (
                    <>
                        <div className="d-flex align-items-center">
                            {statement.row.original.username}
                        </div>
                    </>
                ),
            },
            {
                Header: 'STATEMENT INVOICE',
                filterable: false,
                Cell: (cell) => (
                    <>
                        <img className="cursorPointer" width="30" height="30" src={DownloadImage} alt="Invoice" onClick={() => handleDownloadStatementPdf(cell.row.original.statement_id)} />
                    </>
                )
            },
        ],
        [handleDownloadStatementPdf]
    );

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />
    if (!userProfile.permissions.view_statements) return <Redirect to="/not-authorized" />

    document.title = "Overdue Statements | Max Advanced Brakes - Prozone";

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Overdue Statements" pageTitle="Overdue Statements" linkTo={`/accounting/overdue-statements`} />
                {
                    openPdfModal && <PDFRenderer
                        fileUrl={URL.createObjectURL(statementPdf)}
                        modalHeaderText={`Statement ${pdfStatementId}`}
                        openPdfModal={openPdfModal}
                        closePdfModal={closePdfModal} />
                }
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <Label>A/R Term 10 - 2% 10, 30 Statement</Label>
                            </Col>
                        </Row>
                        <Row>
                            {statementARTable.map((statementARwidget, key) => (
                                <React.Fragment key={key}>
                                    <Col xl={2} md={4}>
                                        <Card className="card-animate">
                                            <CardBody>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-grow-1">
                                                        <p className="text-uppercase fw-medium text-muted mb-0">
                                                            {statementARwidget.label}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                    <div>
                                                        <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                                            {(statementsArr && statementsArr.length) ? (
                                                                <CountUp
                                                                    start={0}
                                                                    prefix={'$'}
                                                                    decimals="2"
                                                                    end={Number(remainingBalanceDateRange(statementARwidget))}
                                                                    duration={0.1}
                                                                    className="counter-value"
                                                                />
                                                            ) : ('0.00')}
                                                        </h4>
                                                    </div>
                                                    <div className="avatar-sm flex-shrink-0">
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </React.Fragment>
                            ))}
                        </Row>
                        <Row>
                            <Col>
                                <Label>Total A/R Balance: $
                                    {(statementsArr && statementsArr.length !== 0) ? (
                                        statementsArr.map(item => {
                                            return (
                                                (item.fully_paid !== 1) ? (
                                                    Number(item.balance) - Number(item.paid_amount)
                                                ) : (0.00)
                                            )
                                        }).reduce((acc, cur) => acc + cur).toFixed(2).replace(/-/, "")
                                    ) : ("0.00")}
                                </Label>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Row>
                    <Col lg={12}>
                        <Card id="statements">
                            <CardHeader className="border-0">
                                <div className="d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Statements</h5>
                                </div>
                            </CardHeader>

                            <CardBody className="pt-0">
                                <div>
                                    {!isLoading ? (
                                        <TableContainer
                                            columns={columns}
                                            data={(statementsArr || [])}
                                            isGlobalFilter={false}
                                            isAddUserList={false}
                                            customPageSize={30}
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-nowrap"
                                            theadClass="text-muted"
                                            thClass="text-muted"
                                            isInvoiceListFilter={false}
                                        />
                                    ) : (<Loader error={error} />)}
                                    <ToastContainer closeButton={false} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default OverdueStatements;