import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ListGroupItem } from "reactstrap";

import Loader from "../../Common/Loader";

import { 
    getYears as onGetYears,
    resetVehicleMakes,
    resetVehicleModels,
    resetVehicleCategories,
    resetVehicleProducts
} from "../../../store/actions";

function VehicleSearchYearList() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    let match = useRouteMatch('/products/vehicle-search');
    let location = useLocation();

    const { years } = useSelector((state) => ({
        years: state.Vehicle.years,
    }))
    
    useEffect(() => {
        if (match.isExact || !years.length) {
            setIsLoading(true)
            dispatch(onGetYears());
        }
    }, [dispatch]);
    
    useEffect(() => {
        if (years.length) {
            setIsLoading(false);
        }
    }, [years])

    const handleYearSelection = (to) => {
        dispatch(resetVehicleMakes());
        history.push(to);
    };

    return (
        (!isLoading && years) ? (
            years.sort().reverse().map((item, index) => {
                const toLink = `/products/vehicle-search/${item}`;
                return (
                    <ListGroupItem className="p-0" onClick={() => handleYearSelection(toLink) } active={(location.pathname.match(item) !== null) ? (true) : (false)} action tag="button" key={index}>
                        <a className="text-black d-block w-100 padding-0_7-1" to={`/products/vehicle-search/${item}`}>{item}</a>
                    </ListGroupItem>
                )
            })
        ) : (<Loader />)
    )
}

export default VehicleSearchYearList;