// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const RESET_APP = 'RESET_APP';

export const GET_YEARS = "GET_YEARS";
export const GET_MAKES = "GET_MAKES";
export const GET_MODELS = "GET_MODELS";
export const GET_CATEGORIES = "GET_CATEGORIES";

export const RESET_VEHICLE_MAKES = 'RESET_VEHICLE_MAKES';
export const RESET_VEHICLE_MODELS = 'RESET_VEHICLE_MODELS';
export const RESET_VEHICLE_CATEGORIES = 'RESET_VEHICLE_CATEGORIES';
export const RESET_VEHICLE_PRODUCTS = 'RESET_VEHICLE_PRODUCTS';

export const POST_PRODUCT_ARRAY = "POST_PRODUCT_ARRAY";
export const POST_PRODUCT_ARRAY_SUCCESS = "POST_PRODUCT_ARRAY_SUCCESS";
export const POST_PRODUCT_ARRAY_FAIL = "POST_PRODUCT_ARRAY_FAIL";

export const POST_PRODUCTS_WHOLESALE_INVENTORY = "POST_PRODUCTS_WHOLESALE_INVENTORY";
export const POST_PRODUCTS_WHOLESALE_INVENTORY_SUCCESS = "POST_PRODUCTS_WHOLESALE_INVENTORY_SUCCESS";
export const POST_PRODUCTS_WHOLESALE_INVENTORY_FAIL = "POST_PRODUCTS_WHOLESALE_INVENTORY_FAIL";