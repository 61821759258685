import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Invoice Redux States
import {
    GET_INVOICES,
    GET_INVOICE_DETAILS,
    GET_INVOICE,
    POST_INVOICE_DETAILS_PRODUCT_QUANTITY,
    GET_INVOICE_DETAILS_PRODUCT_INTERCHANGE,
    DOWNLOAD_INVOICE_PDF,
    DOWNLOAD_PACKING_LIST_PDF,
    POST_SHIP_ORDER,
    UPDATE_ORDER_STATUS,
    POST_CANCEL_ORDER,
    GET_FILTERED_INVOICES,
    GET_REFORMAT_ORDERITEMS,
    GET_ARCHIVED_INVOICES,
    GET_FILTERED_ARCHIVED_INVOICES,
    GET_OLD_ORDERS_ORDER_ITEMS,
} from "./actionType";

import {
    invoiceApiResponseSuccess,
    invoiceApiResponseError,
    postInvoiceDetailsProductQuantityFail,
    postInvoiceDetailsProductQuantitySuccess,
    postShipOrderFail,
    postShipOrderSuccess,
    updateOrderStatusSuccess,
    updateOrderStatusFail,
    postCancelOrderFail,
    postCancelOrderSuccess,
} from "./action";

//Include Both Helper File with needed methods
import {
    getInvoices as getInvoicesApi,
    getFilteredInvoices as getFilteredInvoicesApi,
    getArchivedInvoices as getArchivedInvoicesApi,
    getFilteredArchivedInvoices as getFilteredArchivedInvoicesApi,
    getInvoice as getInvoiceApi,
    getInvoiceDetails as getInvoiceDetailsApi,
    postInvoiceDetailsProductQuantity as postInvoiceDetailsProductQuantityApi,
    getProducts as getProductsApi,
    downloadInvoicePdf as downloadInvoicePdfApi,
    downloadPackingListPdf as downloadPackingListPdfApi,
    postShipOrder as postShipOrderApi,
    updateOrderStatus as updateOrderStatusApi,
    postCancelOrder as postCancelOrderApi,
    getOldOrdersOrderitems as getOldOrdersOrderitemsApi,
    getReformatOrderItems as getReformatOrderItemsApi
} from "../../../helpers/backend_helper";

function* getInvoices() {
    try {
        const response = yield call(getInvoicesApi);
        yield put(invoiceApiResponseSuccess(GET_INVOICES, response));
    } catch (error) {
        yield put(invoiceApiResponseError(GET_INVOICES, error));
    }
}

function* getFilteredInvoices({ payload: filter }) {
    try {
        const response = yield call(getFilteredInvoicesApi, filter);
        yield put(invoiceApiResponseSuccess(GET_FILTERED_INVOICES, response));
    } catch (error) {
        yield put(invoiceApiResponseError(GET_FILTERED_INVOICES, error));
    }
}

function* getArchivedInvoices() {
    try {
        const response = yield call(getArchivedInvoicesApi);
        yield put(invoiceApiResponseSuccess(GET_ARCHIVED_INVOICES, response));
    } catch (error) {
        yield put(invoiceApiResponseError(GET_ARCHIVED_INVOICES, error));
    }
}

function* getFilteredArchivedInvoices({ payload: filter }) {
    try {
        const response = yield call(getFilteredArchivedInvoicesApi, filter);
        yield put(invoiceApiResponseSuccess(GET_FILTERED_ARCHIVED_INVOICES, response));
    } catch (error) {
        yield put(invoiceApiResponseError(GET_FILTERED_ARCHIVED_INVOICES, error));
    }
}

function* getInvoice({ payload: order_id }) {
    try {
        const response = yield call(getInvoiceApi, order_id);
        yield put(invoiceApiResponseSuccess(GET_INVOICE, response));
    } catch (error) {
        yield put(invoiceApiResponseError(GET_INVOICE, error));
    }
}

function* getInvoiceDetails({ payload: order_id }) {
    try {
        const response = yield call(getInvoiceDetailsApi, order_id);
        yield put(invoiceApiResponseSuccess(GET_INVOICE_DETAILS, response));
    } catch (error) {
        yield put(invoiceApiResponseError(GET_INVOICE_DETAILS, error));
    }
}

function* postInvoiceDetailsProductQuantity({ payload: product }) {
    try {
        const response = yield call(postInvoiceDetailsProductQuantityApi, product);
        yield put(postInvoiceDetailsProductQuantitySuccess(response));
    } catch (error) {
        yield put(postInvoiceDetailsProductQuantityFail(error));
    }
}

function* getInvoiceDetailsProductInterchange({ payload: product }) {
    try {
        const response = yield call(getProductsApi, product);
        yield put(invoiceApiResponseSuccess(GET_INVOICE_DETAILS_PRODUCT_INTERCHANGE, response));
    } catch (error) {
        yield put(invoiceApiResponseError(GET_INVOICE_DETAILS_PRODUCT_INTERCHANGE, error));
    }
}

function* downloadInvoicePdf({ payload: orderId }) {
    try {
        const response = yield call(downloadInvoicePdfApi, orderId);
        yield put(invoiceApiResponseSuccess(DOWNLOAD_INVOICE_PDF, response));
    } catch (error) {
        yield put(invoiceApiResponseError(DOWNLOAD_INVOICE_PDF, error))
    }
}

function* downloadPackingListPdf({ payload: orderId }) {
    try {
        const response = yield call(downloadPackingListPdfApi, orderId);
        yield put(invoiceApiResponseSuccess(DOWNLOAD_PACKING_LIST_PDF, response));
    } catch (error) {
        yield put(invoiceApiResponseError(DOWNLOAD_PACKING_LIST_PDF, error))
    }
}

function* postShipOrder({ payload: params }) {
    try {
        const response = yield call(postShipOrderApi, params);
        yield put(postShipOrderSuccess(response));
    } catch (error) {
        yield put(postShipOrderFail(error));
    }
}

function* updateOrderStatus({ payload: obj }) {
    try {
        const response = yield call(updateOrderStatusApi, obj.status, obj.order_id, obj.requested_shipped_date);
        yield put(updateOrderStatusSuccess(response));
    } catch (error) {
        yield put(updateOrderStatusFail(error));
    }
}

function* postCancelOrder({ payload: order_id }) {
    try {
        yield call(postCancelOrderApi, order_id);
        yield put(postCancelOrderSuccess());
    } catch (error) {
        yield put(postCancelOrderFail(error));
    }
}

function* getOldOrdersOrderitems() {
    try {
        const response = yield call(getOldOrdersOrderitemsApi);
        yield put(invoiceApiResponseSuccess(GET_OLD_ORDERS_ORDER_ITEMS, response));
    } catch (error) {
        yield put(invoiceApiResponseError(GET_OLD_ORDERS_ORDER_ITEMS, error));
    }
}

function* getReformatOrderItems() {
    try {
        const response = yield call(getReformatOrderItemsApi);
        yield put(invoiceApiResponseSuccess(GET_REFORMAT_ORDERITEMS, response))
    } catch(error) {
        yield put(invoiceApiResponseError(GET_REFORMAT_ORDERITEMS, error));
    }
}

export function* watchGetInvoices() {
    yield takeEvery(GET_INVOICES, getInvoices);
}

export function* watchGetFilteredInvoices() {
    yield takeEvery(GET_FILTERED_INVOICES, getFilteredInvoices);
}

export function* watchGetArchivedInvoices() {
    yield takeEvery(GET_ARCHIVED_INVOICES, getArchivedInvoices);
}

export function* watchGetFilteredArchivedInvoices() {
    yield takeEvery(GET_FILTERED_ARCHIVED_INVOICES, getFilteredArchivedInvoices);
}

export function* watchGetInvoice() {
    yield takeEvery(GET_INVOICE, getInvoice);
}

export function* watchGetInvoiceDetails() {
    yield takeEvery(GET_INVOICE_DETAILS, getInvoiceDetails);
}

export function* watchPostInvoiceDetailsProductQuantity() {
    yield takeEvery(POST_INVOICE_DETAILS_PRODUCT_QUANTITY, postInvoiceDetailsProductQuantity)
}

export function* watchGetInvoiceDetailsProductInterchange() {
    yield takeEvery(GET_INVOICE_DETAILS_PRODUCT_INTERCHANGE, getInvoiceDetailsProductInterchange)
}

export function* watchDownloadInvoicePdf() {
    yield takeEvery(DOWNLOAD_INVOICE_PDF, downloadInvoicePdf);
}

export function* watchDownloadPackingListPdf() {
    yield takeEvery(DOWNLOAD_PACKING_LIST_PDF, downloadPackingListPdf);
}

export function* watchPostShipOrder() {
    yield takeEvery(POST_SHIP_ORDER, postShipOrder);
}

export function* watchUpdateOrderStatus() {
    yield takeEvery(UPDATE_ORDER_STATUS, updateOrderStatus);
}

export function* watchPostCancelOrder() {
    yield takeEvery(POST_CANCEL_ORDER, postCancelOrder);
}

export function* watchGetOldOrdersOrderitems() {
    yield takeEvery(GET_OLD_ORDERS_ORDER_ITEMS, getOldOrdersOrderitems);
}

export function* watchGetReformatOrderItems() {
    yield takeEvery(GET_REFORMAT_ORDERITEMS, getReformatOrderItems);
}

function* invoiceSaga() {
    yield all([
        fork(watchGetInvoices),
        fork(watchGetFilteredInvoices),
        fork(watchGetArchivedInvoices),
        fork(watchGetFilteredArchivedInvoices),
        fork(watchGetInvoice),
        fork(watchGetInvoiceDetails),
        fork(watchPostInvoiceDetailsProductQuantity),
        fork(watchGetInvoiceDetailsProductInterchange),
        fork(watchDownloadInvoicePdf),
        fork(watchDownloadPackingListPdf),
        fork(watchPostShipOrder),
        fork(watchUpdateOrderStatus),
        fork(watchPostCancelOrder),
        fork(watchGetOldOrdersOrderitems),
        fork(watchGetReformatOrderItems),
    ]);
}

export default invoiceSaga;