import { 
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    DOWNLOAD_GROUP_TEMPLATE,
    GET_GROUPS,
    GET_GROUP_ITEMS,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUP_FAIL,
    POST_GROUPS,
    GET_ACCESSORY,
    POST_ACCESSORY,
    POST_ADD_ACCESSORY,
    DELETE_ACCESSORY_SUCCESS,
    DELETE_ACCESSORY_FAIL,
} from "./actionType";

const INIT_STATE = {
    prices: [],
}

const PromotionsCoupons = (state = INIT_STATE, action) => {
    switch(action.type) {
        case API_RESPONSE_SUCCESS:
            switch(action.payload.actionType) {
                case DOWNLOAD_GROUP_TEMPLATE:
                    return {
                        ...state,
                        isGroupTemplateDownloaded: true,
                        groupTemplate: action.payload.data,
                    }
                case GET_GROUPS:
                    return {
                        ...state,
                        isGroupsFetched: true,
                        groups: action.payload.data,
                    }
                case GET_GROUP_ITEMS:
                    return {
                        ...state,
                        isGroupItemsFetched: true,
                        groupItems: action.payload.data,
                    }
                case POST_GROUPS:
                    return {
                        ...state,
                        isGroupPosted: true,
                    }
                case GET_ACCESSORY:
                    return {
                        ...state,
                        isAccessoryFetched: true,
                        accessorys: action.payload.data,
                    }
                case POST_ACCESSORY:
                    return {
                        ...state,
                        isAccessoryPosted: true,
                    }
                case POST_ADD_ACCESSORY:
                    return {
                        ...state,
                        isAddAccessoryPosted: true,
                    }
                default:
                    return {
                        ...state,
                    }
            }
        case API_RESPONSE_ERROR:
            switch(action.payload.actionType) {
                case DOWNLOAD_GROUP_TEMPLATE:
                    return{
                        ...state,
                        isGroupTemplateDownloaded: false,
                        error: action.payload.error,
                    }
                case GET_GROUPS:
                    return {
                        ...state,
                        isGroupsFetched: false,
                        error: action.payload.error,
                    }
                case GET_GROUP_ITEMS:
                    return {
                        ...state,
                        isGroupItemsFetched: false,
                        error: action.payload.error,
                    }
                case POST_GROUPS:
                    return {
                        ...state,
                        isGroupPosted: false,
                    }
                case GET_ACCESSORY:
                    return {
                        ...state,
                        isAccessoryFetched: false,
                        error: action.payload.error,
                    }
                case POST_ACCESSORY:
                    return {
                        ...state,
                        isAccessoryPosted: false,
                    }
                case POST_ADD_ACCESSORY:
                    return {
                        ...state,
                        isAddAccessoryPosted: false,
                    }
                default:
                    return {
                        ...state,
                    }
            }
        case DELETE_GROUP_SUCCESS:
            return {
                ...state,
                isGroupDeletedSuccess: true,
                isGroupDeletedFail: false,
            }
        case DELETE_GROUP_FAIL:
            return{
                ...state,
                isGroupDeletedSuccess: false,
                isGroupDeletedFail: true,
            }
        case DELETE_ACCESSORY_SUCCESS:
            return {
                ...state,
                isAccessoryDeletedSuccess: true,
                isAccessoryDeletedFail: false,
            }
        case DELETE_ACCESSORY_FAIL:
            return {
                ...state,
                isAccessoryDeletedSuccess: false,
                isAccessoryDeletedFail: true,
            }
        default:
            return{
                ...state,
            }
    }
}
export default PromotionsCoupons;