import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Container, List, Row, UncontrolledCarousel } from 'reactstrap';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import carouselArtBoard1 from '../../../assets/images/Artboard1.png';
import carouselArtBoard2 from '../../../assets/images/Artboard2.png';
import carouselArtBoard3 from '../../../assets/images/Artboard3.png';
import carouselArtBoard4 from '../../../assets/images/Artboard4.png';
import carouselArtBoard5 from '../../../assets/images/Artboard5.png';
import carouselArtBoard7 from '../../../assets/images/Artboard7.png';

import { 
    getSystemMessagesToday as onGetSystemMessagesToday
} from '../../../store/actions';

const items = [
    {
        src: carouselArtBoard7,
        altText: 'Artboard 7',
        caption: '',
        key: 6,
    },
    {
        src: carouselArtBoard1,
        altText: 'Artboard 1',
        caption: '',
        key: 1,
    },
    {
        src: carouselArtBoard2,
        altText: 'Artboard 2',
        caption: '',
        key: 2,
    },
    {
        src: carouselArtBoard3,
        altText: 'Artboard 3',
        caption: '',
        key: 3,
    },
    {
        src: carouselArtBoard4,
        altText: 'Artboard 4',
        caption: '',
        key: 4,
    },
    {
        src: carouselArtBoard5,
        altText: 'Artboard 5',
        caption: '',
        key: 5,
    },
]

function Home() {
    const dispatch = useDispatch()

    const { systemMessages } = useSelector((state) => ({
        systemMessages: state.SystemMessages.systemMessages
    }))

    useEffect(() => {
        dispatch({ type: 'RESET_APP' });
        dispatch(onGetSystemMessagesToday());
    }, [dispatch])

    document.title = "Home | Max Advanced Brakes - Prozone";

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Home" pageTitle="Home" linkTo={`/home`} />
                    {(systemMessages.length) ? (
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader>
                                        <h5>System Messages</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <List>
                                            {systemMessages.map((item, index) => {
                                                return (
                                                    <li key={index}>{item.system_message}</li>
                                                )
                                            })}
                                        </List>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ) : (null)}
                    <Row className="mb-5">
                        <Col>
                            <UncontrolledCarousel
                                items={items}
                                className="border-radius-7px"
                            />   
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col>
                            <Card>
                                <CardBody>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Home;