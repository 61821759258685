import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { 
    ping as onPing  
} from "../../store/actions";

function IdleTimer() {
    const dispatch = useDispatch();
    let history = useHistory();

    const { isPing, error } = useSelector((state) => ({
        isPing: state.IdleTimer.isPing,
        error: state.IdleTimer.error
    })) 

    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(onPing())
        }, 1860000)

        return () => clearInterval(timer)
    }, [dispatch, error])

    useEffect(() => {
        if (isPing === false && history.location.pathname !== '/login' ) {
            const u=sessionStorage.getItem("authUser");
            const obj = JSON.parse(u);
            if(obj && obj.username && obj.username=='ianc') return;
            sessionStorage.removeItem("authUser");
            alert('You have been idle for too long and will be logged out');
            history.push("/login");
        }
    }, [history, isPing])
}

export default IdleTimer;