import { 
    API_RESPONSE_ERROR, 
    API_RESPONSE_SUCCESS, 
    DOWNLOAD_GROUP_TEMPLATE,
    GET_GROUPS,
    GET_GROUP_ITEMS,
    DELETE_GROUP,
    POST_GROUPS,
    GET_ACCESSORY,
    POST_ACCESSORY,
    POST_ADD_ACCESSORY,
    DELETE_ACCESSORY,
} from "./actionType";

// common success
export const promotionsCouponsApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const promotionsCouponsApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getGroupTemplate = () => ({
    type: DOWNLOAD_GROUP_TEMPLATE
});

export const getGroups = () => ({
    type: GET_GROUPS
});

export const getGroupItems = (id) => ({
    type: GET_GROUP_ITEMS,
    payload: id,
});

export const deleteGroup = (deleteid) => ({
    type: DELETE_GROUP,
    payload: deleteid,
});

export const postGroups = (groupParam) => ({
    type: POST_GROUPS,
    payload: groupParam,
});

export const getAccessory = () => ({
    type: GET_ACCESSORY,
});

export const postAccessory = (accessoryParam) => ({
    type: POST_ACCESSORY,
    payload: accessoryParam,
});

export const postAddAccessory = (addAccessoryParam) => ({
    type: POST_ADD_ACCESSORY,
    payload: addAccessoryParam,
});

export const deleteAccessory = (accessoryBucket, accessoryPart) => ({
    type: DELETE_ACCESSORY,
    payload: {accessoryBucket, accessoryPart},
})