import {
    API_RESPONSE_ERROR,
    API_RESPONSE_SUCCESS,
    DELETE_CUSTOMER_EXCLUSION_FAIL,
    DELETE_CUSTOMER_EXCLUSION_SUCCESS,
    DELETE_CUSTOMER_FAIL,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_PRODUCT_GROUP_FROM_USER_FAIL,
    DELETE_PRODUCT_GROUP_FROM_USER_SUCCESS,
    GET_CUSTOMERS,
    GET_CUSTOMER_INFO,
    GET_CUSTOMER_PRODUCT_EXCLUSION,
    GET_CUSTOMER_USER_GROUP,
    GET_PRODUCT_GROUPS,
    GET_UPDATE_USER_CREDIT_BALANCES,
    GET_USERS_PRODUCT_GROUPS,
    GET_USER_GROUPS,
    POST_NEW_CUSTOMER_EXCLUSION_FAIL,
    POST_NEW_CUSTOMER_EXCLUSION_SUCCESS,
    POST_NEW_CUSTOMER_FAIL,
    POST_NEW_CUSTOMER_SUCCESS,
    POST_NEW_PRODUCT_GROUP_TO_USER_FAIL,
    POST_NEW_PRODUCT_GROUP_TO_USER_SUCCESS,
    POST_USER_PASSWORD_RESET_FAIL,
    POST_USER_PASSWORD_RESET_SUCCESS,
    RESET_APP,
    UPDATE_CUSTOMER_INFO_FAIL,
    UPDATE_CUSTOMER_INFO_SUCCESS,
    UPDATE_USER_GROUP_FAIL,
    UPDATE_USER_GROUP_SUCCESS,
} from "./actionType";

const INIT_STATE = {
    customers: [],
    userInfo: {},
    error: {},
};

// TODO: Add switch cases for product groups

const Customer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_APP:
            return INIT_STATE
        case API_RESPONSE_SUCCESS:
            switch(action.payload.actionType) {
                case GET_CUSTOMERS:
                    return {
                        ...state,
                        customers: action.payload.data,
                        isPostNewCustomerSuccess: false,
                        isPostNewCustomerFail: false,
                    }
                case GET_CUSTOMER_INFO:
                    return {
                        ...state,
                        userInfo: action.payload.data,
                        isUserInfoSuccess: true,
                        isUpdateCustomerInfoSuccess: false,
                        isUpdateCustomerInfoFail: false,
                        isUserPasswordResetSuccess: false,
                        isUserPasswordResetFail: false,
                    };
                case GET_CUSTOMER_PRODUCT_EXCLUSION:
                    return {
                        ...state,
                        customerProductExclusion: action.payload.data,
                        isPostNewExclusionSuccess: false,
                        isPostNewExclusionFail: false,
                        isDeleteExclusionSuccess: false,
                        isDeleteExclusionFail: false,
                    }
                case GET_CUSTOMER_USER_GROUP:
                    return {
                        ...state,
                        userGroup: action.payload.data,
                        isUpdateUserGroupSuccess: false,
                        isUpdateUserGroupFail: false,
                    }
                case GET_USER_GROUPS:
                    return {
                        ...state,
                        userGroups: action.payload.data,
                    }
                case GET_PRODUCT_GROUPS:
                    return {
                        ...state,
                        productGroups: action.payload.data
                    }
                case GET_USERS_PRODUCT_GROUPS:
                    return {
                        ...state,
                        userProductGroups: action.payload.data,
                        isPostNewProductToUserSuccess: false,
                        isPostNewProductToUserFail: false,
                        isDeleteProductGroupFromUserSuccess: false,
                        isDeleteProductGroupFromUserFail: false,
                    }
                case GET_UPDATE_USER_CREDIT_BALANCES:
                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch(action.payload.actionType) {
                case GET_CUSTOMERS:
                case GET_CUSTOMER_PRODUCT_EXCLUSION:
                case GET_CUSTOMER_USER_GROUP:
                case GET_USER_GROUPS:
                case GET_PRODUCT_GROUPS:
                case GET_USERS_PRODUCT_GROUPS:
                    return {
                        ...state,
                        error: action.payload.error,
                    }
                case GET_CUSTOMER_INFO:
                    return {
                        ...state,
                        error: action.payload.error,
                        isUserInfoSuccess: false,
                    };
                case GET_UPDATE_USER_CREDIT_BALANCES:
                default:
                    return { ...state };
            }

        case UPDATE_CUSTOMER_INFO_SUCCESS: 
            return {
                ...state,
                userInfo: action.payload,
                isUpdateCustomerInfoSuccess: true,
                isUpdateCustomerInfoFail: false,
            }

        case DELETE_CUSTOMER_SUCCESS: 
            return {
                ...state,
                userInfo: {},
                isDeleteUserSuccess: true
            }

        case POST_NEW_CUSTOMER_EXCLUSION_SUCCESS: 
            return {
                ...state,
                isPostNewExclusionSuccess: true,
                isPostNewExclusionFail: false,
            }

        case DELETE_CUSTOMER_EXCLUSION_SUCCESS: 
            return {
                ...state,
                isDeleteExclusionSuccess: true,
                isDeleteExclusionFail: false,
            }

        case UPDATE_USER_GROUP_SUCCESS:
            return {
                ...state,
                isUpdateUserGroupSuccess: true,
                isUpdateUserGroupFail: false,
            }

        case POST_NEW_CUSTOMER_SUCCESS:
            return {
                ...state,
                isPostNewCustomerSuccess: true,
                isPostNewCustomerFail: false,
            }

        case POST_USER_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                isUserPasswordResetSuccess: true,
                isUserPasswordResetFail: false,
            }

        case POST_NEW_PRODUCT_GROUP_TO_USER_SUCCESS:
            return {
                ...state,
                isPostNewProductToUserSuccess: true,
                isPostNewProductToUserFail: false,
            }

        case DELETE_PRODUCT_GROUP_FROM_USER_SUCCESS:
            return {
                ...state,
                isDeleteProductGroupFromUserSuccess: true,
                isDeleteProductGroupFromUserFail: false,
            }

        case UPDATE_CUSTOMER_INFO_FAIL: 
            return {
                ...state,
                error: action.payload.error,
                isUpdateCustomerInfoSuccess: false,
                isUpdateCustomerInfoFail: true,
            }

        case DELETE_CUSTOMER_FAIL: 
            return {
                ...state,
                isDeleteUserSuccess: false
            }

        case POST_NEW_CUSTOMER_EXCLUSION_FAIL: 
            return {
                ...state,
                error: action.payload.error,
                isPostNewExclusionSuccess: false,
                isPostNewExclusionFail: true,
            }

        case DELETE_CUSTOMER_EXCLUSION_FAIL: 
            return {
                ...state,
                isDeleteExclusionSuccess: false,
                isDeleteExclusionFail: true,
            }

        case UPDATE_USER_GROUP_FAIL:
            return {
                ...state,
                error: action.payload.error,
                isUpdateUserGroupSuccess: false,
                isUpdateUserGroupFail: true,
            }

        case POST_NEW_CUSTOMER_FAIL:
            return {
                ...state,
                error: action.payload.error,
                isPostNewCustomerSuccess: false,
                isPostNewCustomerFail: true,
            }

        case POST_USER_PASSWORD_RESET_FAIL:
            return {
                ...state,
                isUserPasswordResetSuccess: false,
                isUserPasswordResetFail: true,
            }

        case POST_NEW_PRODUCT_GROUP_TO_USER_FAIL:
            return {
                ...state,
                isPostNewProductToUserFail: true,
                isPostNewProductToUserSuccess: false,
            }

        case DELETE_PRODUCT_GROUP_FROM_USER_FAIL:
            return {
                ...state,
                isDeleteProductGroupFromUserFail: true,
                isDeleteProductGroupFromUserSuccess: false,
            }

        default:
            return { ...state };
    }
};

export default Customer;