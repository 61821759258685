import { Input, ListGroupItem } from "reactstrap";

function AdminListGroupItemMaxQuantity({ field, fieldValue, accountInfo, setAccountInfo }) {
    return (
        <ListGroupItem>
            <span className="font-size-18">[{field}: {fieldValue}]</span>
            <br /><br />
            <Input bsSize="sm" type="number" min="0" max="1200" value={accountInfo[field]} onChange={(e) => setAccountInfo({ ...accountInfo, [field]: e.target.value })} />
        </ListGroupItem>
    )
}

export default AdminListGroupItemMaxQuantity;