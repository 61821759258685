import { 
    API_RESPONSE_ERROR, 
    API_RESPONSE_SUCCESS, 
    DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID_FAIL, 
    DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID_SUCCESS, 
    DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID_FAIL, 
    DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID_SUCCESS, 
    GET_PRODUCTS, 
    GET_PRODUCTS_WITH_ROWID, 
    ON_BACK_EMPTY_PRODUCTS, 
    POST_PRICELIST, 
    POST_PRICELIST_WITH_ROWID, 
    POST_PRODUCT_AND_PRICE_ARRAY_FAIL, 
    POST_PRODUCT_AND_PRICE_ARRAY_SUCCESS, 
    POST_PRODUCT_SELECT_LOG_FAIL, 
    POST_PRODUCT_SELECT_LOG_SUCCESS, 
    RESET_APP 
} from "./actionType";

const INIT_STATE = {
    products: [],
    prices: [],
    draftProducts: {},
    rowLogId: {},
    draftPrices: {},
    error: {}
}

const Products = (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_APP:
            return INIT_STATE;
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_PRODUCTS:
                    return {
                        ...state,
                        products: action.payload.data,
                    };
                case POST_PRICELIST:
                    return {
                        ...state,
                        prices: action.payload.data,
                    }
                case GET_PRODUCTS_WITH_ROWID:
                    return {
                        ...state,
                        draftProducts: { 
                            ...state.draftProducts, 
                            [action.payload.data.drafthash]: {
                                ...state.draftProducts[action.payload.data.drafthash],
                                [action.payload.data.rowId]: action.payload.data.products.int.concat(action.payload.data.products.sub)
                            }
                        },
                        rowLogId: {
                            ...state.rowLogId,
                            [action.payload.data.drafthash]: {
                                ...state.rowLogId[action.payload.data.drafthash],
                                [action.payload.data.rowId]: action.payload.data.products.logId
                            }
                        },
                    };
                case POST_PRICELIST_WITH_ROWID:
                    return {
                        ...state,
                        draftPrices: {
                            ...state.draftPrices,
                            [action.payload.data.drafthash]: {
                                ...state.draftPrices[action.payload.data.drafthash], 
                                [action.payload.data.rowId]: action.payload.data.prices 
                            }
                        },
                    }
                default:
                    return { ...state };
            }
        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_PRODUCTS:
                case GET_PRODUCTS_WITH_ROWID:
                case POST_PRICELIST:
                case POST_PRICELIST_WITH_ROWID:
                    return {
                        ...state,
                        error: action.payload.error,
                    }
                default:
                    return { ...state };
            }

        case DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID_SUCCESS:
            delete state.draftProducts[action.payload.drafthash];
            delete state.draftPrices[action.payload.drafthash];
            return {
                ...state,
                draftProducts: state.draftProducts,
                draftPrices: state.draftPrices
            }

        case POST_PRODUCT_AND_PRICE_ARRAY_SUCCESS:
            return {
                ...state,
                draftProducts: { 
                    ...state.draftProducts, 
                    [action.payload.drafthash]: {
                        ...action.payload.data.map((item) => item.int.concat(item.sub))
                    }
                },
                rowLogId: {
                    ...state.rowLogId,
                    [action.payload.drafthash]: {
                        ...action.payload.data.map((item) => item.logId)
                    }
                },
            }

        case ON_BACK_EMPTY_PRODUCTS:
            return {
                ...state,
                draftProducts: {}
            }

        case DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID_SUCCESS:
            delete state.draftProducts[action.payload.drafthash][action.payload.rowId];
            delete state.draftPrices[action.payload.drafthash][action.payload.rowId];

            return {
                ...state,
                draftProducts: state.draftProducts,
                draftPrices: state.draftPrices,
            }

        case POST_PRODUCT_SELECT_LOG_SUCCESS:
            return {
                ...state
            }

        case DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID_FAIL:
        case DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID_FAIL:
        case POST_PRODUCT_SELECT_LOG_FAIL:
        case POST_PRODUCT_AND_PRICE_ARRAY_FAIL:
            return {
                ...state,
                error: action.payload.error
            }

        default:
            return { ...state };
    }
}

export default Products;