import Select from "react-select";

function AdminTableRowSelect({ field, fieldValue, options, property, accountInfo, setAccountInfo }) {
    return (
        <tr>
            <td>{field}</td>
            <td>{fieldValue}</td>
            <td>
                <Select
                    options={options}
                    value={options.filter(option => option.value === accountInfo[property])}
                    onChange={(e) => setAccountInfo({ ...accountInfo, [property]: e.value })}
                />
            </td>
        </tr>
    )
}

export default AdminTableRowSelect;