import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, Input, Row, Table } from 'reactstrap';

// Part Search components
import TableRows from '../../../../Components/Products/PartSearch/TableRow';
import { order, pattern } from '../../../../Components/constants/products';
import Loader from '../../../../Components/Common/Loader';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';

// Formik validation
import * as Yup from "yup";
import { useFormik } from 'formik';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// actions
import { 
    getProducts as onGetProducts, 
    postPriceList as onPostPriceList
} from "../../../../store/actions";

const PartSearch = () => {
    const dispatch = useDispatch();
    const [productsArr, setProductsArr] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isQuerySubmit, setIsQuerySubmit] = useState(false);

    const { products, prices, error } = useSelector(state => ({
        products: state.Products.products,
        prices: state.Products.prices,
        error: state.Products.error,
    }));

    useEffect(() => {
        setIsLoading(true);
        setProductsArr([]);
        dispatch({ type: 'RESET_APP' });
    }, [dispatch])

    useEffect(() => {
        if (!products || !Object.keys(products).length || (!products.int || !products.sub) || (!products.int.length && !products.sub.length) || !validation.values.query) {
            onGuardTrue();
            return;
        }

        let filteredProductsArr = filterProductsArr();

        let newArr = { username: "", data: [] };

        // Get all product numbers and store them in newArr.data
        newArr.data = filteredProductsArr.map(item => item.n);
        
        dispatch(onPostPriceList(newArr));
    }, [dispatch, products])

    useEffect(() => {
        if ((!products || !Object.keys(products).length) || (!prices || !prices.length) || (!products.int || !products.sub) || (!products.int.length && !products.sub.length)) {
            onGuardTrue();
            return;
        }

        let filteredProductsArr = filterProductsArr();
        
        // Find the product number that match the prices and add it to product info
        for (let i of filteredProductsArr) {
            let x = prices.find(item => item.product.toUpperCase() === i.n.toUpperCase());
            x = (x.status === "OK") ? (x.price) : (0);

            let productLine = i.n.match(pattern);
            Object.defineProperties(i, { 
                'price': { value: Number(x) },
                'order': { value: order[productLine] }
            });
        }

        // Sort the products 
        filteredProductsArr = filteredProductsArr.sort((a, b) => {
            return a.order - b.order;
        })

        setProductsArr(filteredProductsArr);
        setIsLoading(false);
        setIsQuerySubmit(false);
    }, [prices])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            query: '',
        },
        validationSchema: Yup.object({
            query: Yup.string().required("Please enter a product code"),
        }),
        onSubmit: (values) => {
            setIsLoading(true);
            setIsQuerySubmit(false);
            setProductsArr([]);
            dispatch(onGetProducts(values));
        }
    })

    function filterProductsArr() {
        let tempArr = products.int.concat(products.sub);

        // Filter productArr for duplicate values
        tempArr = tempArr.filter((item, index, arr) => {
            return arr.map(itemObj => itemObj.n).indexOf(item.n) === index && item.n.match(pattern) !== null;
        });
        
        return tempArr;
    }

    function onGuardTrue() {
        setProductsArr([]);
        setIsLoading(false);
        if (validation.values.query !== '') setIsQuerySubmit(true);
    }

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />

    document.title = "Part Search | Max Advanced Brakes - Prozone";

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Products" pageTitle="Part Search" linkTo={`/products/part-search`} />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Form className="mb-3"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col xxl={3} md={6}>
                                                <div>
                                                    <Input type="text" className="form-control" id="query" onChange={validation.handleChange} onBlur={validation.handleBlur} value={validation.values.query} />
                                                </div>
                                            </Col>
                                            <Col>
                                                <Button color="max" className="btn btn-max" type="submit">Search</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    {(isLoading) ? (<Loader />) : (null)}
                                    {(productsArr && productsArr.length) ? (
                                        <div className="table-responsive">
                                            <Table className="align-middle table-striped table-bordered table-nowrap mb-0">
                                                <thead className="table-dark">
                                                    <tr>
                                                        <th>Product #</th>
                                                        <th>Description</th>
                                                        <th>Price</th>
                                                        <th>Availability</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {productsArr.map((item, index) => <TableRows key={index} item={item} index={index} />)}
                                                </tbody>
                                            </Table>
                                        </div>
                                    ) : (null)} 
                                    {(isQuerySubmit && !productsArr.length) ? (<p>Query {validation.values.query} was not found</p>) : (null)}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default PartSearch;