import { useState } from "react";
import { Button, Col, Input, Row } from "reactstrap"

function VehicleSearchAddToCart({ product, addToTempCart }) {
    const [inputQty, setInputQty] = useState(0);

    return (
        <Row>
            <Col>
                <Input value={inputQty} type="number" onChange={(e) => setInputQty(e.target.value)} />
            </Col>
            <Col>
                <Button color={'max'} className="float-end" onClick={() => addToTempCart(inputQty, product)}>Add</Button>
            </Col>
        </Row>
    )
}
export default VehicleSearchAddToCart