import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useRouteMatch, useHistory } from "react-router-dom";
import { ListGroupItem } from "reactstrap";

import { 
    getMakes as onGetMakes,
    resetVehicleModels,
    resetVehicleCategories,
    resetVehicleProducts
} from "../../../store/actions";
import Loader from "../../Common/Loader";

function VehicleSearchMakeList() {
    const dispatch = useDispatch();
    const history = useHistory();
    let { year } = useParams();
    let match = useRouteMatch('/products/vehicle-search/:year');
    let location = useLocation();

    const [isLoading, setIsLoading] = useState(true);

    const { makes } = useSelector((state) => ({
        makes: state.Vehicle.makes,
    }))
    
    useEffect(() => {
        if (match.isExact || !makes.length) {
            setIsLoading(true);
            dispatch(onGetMakes(year));
        }
    }, [dispatch, year]);

    useEffect(() => {
        if (makes && makes.length) {
            setIsLoading(false);
        }
    }, [makes]);

    const handleMakeSelection = (to) => {
        dispatch(resetVehicleModels());
        history.push(to);
    };

    return (
        <>
            {
                (isLoading) ? (<Loader />) :
                ((makes) ? (
                    makes.sort().map((item, index) => {
                        const toLink = `/products/vehicle-search/${year}/${item}`;
                        return (
                            <ListGroupItem className="p-0" onClick={() => handleMakeSelection(toLink) } active={(location.pathname.match(item) !== null) ? (true) : (false)} action tag="button" key={index}>
                                <a className="text-black d-block w-100 padding-0_7-1">{item}</a>
                            </ListGroupItem>
                        )
                    })
                ) : (null))
            }
        </>
    )
}

export default VehicleSearchMakeList;