import { useSelector } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { Col, Container, ListGroup, Row, Table } from "reactstrap";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import { useProfile } from "../../../../Components/Hooks/UserHooks";
import VehicleSearchYearList from "../../../../Components/Products/VehicleSearch/VehicleSearchYearList";

import { AccessRoute } from "../../../../Routes/AuthProtected";

const VehicleSearch = ({ routes }) => {
    const { userProfile } = useProfile();

    const { error } = useSelector((state) => ({
        error: state.Vehicle.error,
    }))

    document.title = "Vehicle Search | Max Advanced Brakes - Prozone";

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />
    if (!userProfile.permissions.order_creation) return <Redirect to="/not-authorized" />

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Vehicle Search" pageTitle="Products" linkTo={`/products/vehicle-search`} />
                    <Row>
                        <Col className="mobile-overflow">
                            <Table id="vehicle-search-table" className="tableLayoutFixed mobile-inherit" bordered>
                                <thead>
                                    <tr>
                                        <th>Year</th>
                                        <th>Make</th>
                                        <th>Model</th>
                                        <th>Category</th>
                                        <th>Product</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <ListGroup>
                                                <VehicleSearchYearList />
                                            </ListGroup>
                                        </td>
                                        <td>
                                            <ListGroup>
                                                <Switch>
                                                    {routes.map((route, i) => {
                                                        if (route.path === "/products/vehicle-search/:year") return <AccessRoute key={i} {...route} />
                                                        return undefined
                                                    })}
                                                </Switch>
                                            </ListGroup>
                                        </td>
                                        <td>
                                            <ListGroup>
                                                <Switch>
                                                    {routes.map((route, i) => {
                                                        if (route.path === "/products/vehicle-search/:year/:make") return <AccessRoute key={i} {...route} />
                                                        return undefined
                                                    })}
                                                </Switch>
                                            </ListGroup>
                                        </td>
                                        <td>
                                            <ListGroup>
                                                <Switch>
                                                    {routes.map((route, i) => {
                                                        if (route.path === "/products/vehicle-search/:year/:make/:model") return <AccessRoute key={i} {...route} />
                                                        return undefined
                                                    })}
                                                </Switch>
                                            </ListGroup>
                                        </td>
                                        <td>
                                            <ListGroup>
                                                <Switch>
                                                    {routes.map((route, i) => {
                                                        if (route.path === "/products/vehicle-search/:year/:make/:model/:category") return <AccessRoute key={i} {...route} />
                                                        return undefined
                                                    })}
                                                </Switch>
                                            </ListGroup>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </div>
        </> 
    )
}

export default VehicleSearch;