import { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.ca";

import NewUserInputFields from '../../../../Components/Administrator/NewUserInputFields';
import NewUserSelectRow from '../../../../Components/Account/NewUserSelectRow';
import { accessOptions, defaultShipMethodOptions, numOfDaysToShippedDateOptions, clientTypeOptions } from '../../../../Components/constants/accountDetails';

import BreadCrumb from '../../../../Components/Common/BreadCrumb';

import { 
    postNewCustomer as onPostNewCustomer,
    getUserGroups as onGetUserGroups,
} from '../../../../store/actions';

const NewUser = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const [newUserInfo, setNewUserInfo] = useState({ firstname: "", lastname: "", company_name: "", phone_primary: "", title: "", state: "ON", email: "", emailConfirm: "", username: "", password: "", confirmPassword: "", active: 1, access: 5, currentsupplier: "", partexp: 0, stockinv: 0, estimatedvol: 0, yearestablished: 0, default_moq: 200.00, same_day_shipping: 0, default_ship_method: 1, num_of_days_to_shipped_date: 1, client_type_id: 1 });

    const { isPostNewCustomerSuccess, isPostNewCustomerFail, userGroups, error } = useSelector((state) => ({
        isPostNewCustomerSuccess: state.Customer.isPostNewCustomerSuccess,
        isPostNewCustomerFail: state.Customer.isPostNewCustomerFail,
        userGroups: state.Customer.userGroups,
        error: state.Customer.error,
    }));

    // useEffect(() => {
    //     dispatch(onGetUserGroups())
    // }, [])

    useEffect(() => {
        if (isPostNewCustomerSuccess) {
            alert('New Customer Created');
            history.push('/administrator/account-management');
        }
        if (isPostNewCustomerFail) alert('Fail to create new customer');
    }, [isPostNewCustomerSuccess, isPostNewCustomerFail, history]);

    function handleCreateNewUser() {
        let test = Object.entries(newUserInfo).every(([key, value]) => {
            if (key === 'currentsupplier') return true;
            else return value !== '';
        })
        
        if (test && newUserInfo.email === newUserInfo.emailConfirm && newUserInfo.password === newUserInfo.confirmPassword) dispatch(onPostNewCustomer(newUserInfo));
        else alert('Email or Password do not match')
    }

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />

    document.title = "New User | Max Advanced Brakes - Prozone";

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="New User" pageTitle="Account Settings" linkTo={`/administrator/account-management`} />
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader className="fs-4">New User Information</CardHeader>
                                <CardHeader>Required fields are indicated by <span className="text-danger">*</span></CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col>
                                            <NewUserInputFields labelName={'First Name'} type={'text'} field={'firstname'} value={newUserInfo.firstname} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                        </Col>
                                        <Col>
                                            <NewUserInputFields labelName={'Last Name'} type={'text'} field={'lastname'} value={newUserInfo.lastname} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <NewUserInputFields labelName={'Company or Agency Legal Name'} field={'company_name'} type={'text'} value={newUserInfo.company_name} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <Label>Business Phone <span className="text-danger">*</span></Label>
                                            <Cleave placeholder="xxx xxx xxxx" options={{ phone: true, phoneRegionCode: "CA" }} value={newUserInfo.phone_primary} onChange={(e) => setNewUserInfo({ ...newUserInfo, phone_primary: e.target.rawValue })} className="form-control" />
                                        </Col>
                                        <Col>
                                            <NewUserInputFields labelName={'Title'} type={'text'} field={'title'} value={newUserInfo.title} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <NewUserInputFields labelName={'Province/State'} type={'text'} field={'state'} value={newUserInfo.state} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                        </Col>
                                    </Row>
                                    <Card>
                                        <CardHeader className="fs-6">Email Address</CardHeader>
                                        <CardBody>
                                            <Row className="mb-3">
                                                <Col>
                                                    <NewUserInputFields labelName={'Email'} type={'email'} field={'email'} value={newUserInfo.email} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <NewUserInputFields labelName={'Confirm Email'} type={'email'} field={'emailConfirm'} value={newUserInfo.emailConfirm} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardHeader className="fs-4">User Access Information</CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col>
                                            <NewUserInputFields labelName={'Username'} type={'text'} field={'username'} value={newUserInfo.username} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <NewUserInputFields labelName={'Password'} type={'password'} field={'password'} value={newUserInfo.password} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <NewUserInputFields labelName={'Confirm Password'} type={'password'} field={'confirmPassword'} value={newUserInfo.confirmPassword} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <NewUserSelectRow field={'Access'} fieldValue={newUserInfo.access} options={accessOptions} property={'access'} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                        </Col>
                                        <Col>
                                            <NewUserSelectRow field={'Client Type'} fieldValue={newUserInfo.client_type} options={clientTypeOptions} property={'client_type'} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <NewUserSelectRow field={'Default Ship Method'} fieldValue={newUserInfo.default_ship_method} options={defaultShipMethodOptions} property={'default_ship_method'} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                        </Col>
                                        <Col>
                                            <NewUserInputFields labelName={'MOQ for Freight Prepaid (CAD)'} type={'number'} field={'default_moq'} value={newUserInfo.default_moq} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <Label>{'Same Day Shipping'} <span className="text-danger">*</span></Label>
                                            <Input type={'checkbox'} value={newUserInfo.same_day_shipping} onChange={(e) => setNewUserInfo({ ...newUserInfo, same_day_shipping: (e.target.checked) ? (1) : (0) })} />
                                        </Col>
                                        <Col>
                                            <NewUserSelectRow field={'Number Of days to Shipped Date'} fieldValue={newUserInfo.num_of_days_to_shipped_date} options={numOfDaysToShippedDateOptions} property={'num_of_days_to_shipped_date'} newUserInfo={newUserInfo} setNewUserInfo={setNewUserInfo} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <div className="text-center">
                                <Button color={'max'} onClick={handleCreateNewUser}>Create New User</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default NewUser;