import { all, call, fork, put, takeEvery } from "redux-saga/effects";

// Home Redux Statee 
import { 
    DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID,
    DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID,
    GET_PRODUCTS, 
    GET_PRODUCTS_WITH_ROWID, 
    POST_PRICELIST, 
    POST_PRICELIST_WITH_ROWID, 
    POST_PRODUCT_AND_PRICE_ARRAY, 
    POST_PRODUCT_SELECT_LOG,
    POST_PRODUCT_WITH_PRICES
} from "./actionType";

import { 
    deleteDraftProductsAndPriceListWithDraftIdFail,
    deleteDraftProductsAndPriceListWithDraftIdSuccess,
    deleteDraftProductsAndPriceListWithRowIdFail,
    deleteDraftProductsAndPriceListWithRowIdSuccess,
    postProductAndPriceArrayFail,
    postProductAndPriceArraySuccess,
    postProductSelectLogFail,
    postProductSelectLogSuccess,
    productsApiResponseError, 
    productsApiResponseSuccess
} from "./action";

//Include Both Helper File with needed methods
import {
    getProducts as getProductsApi,
    postPriceList as postPriceListApi,
    postProductSelectLog as postProductSelectLogApi,
    postProductWithPrices as postProductWithPricesApi,
    postProductAndPriceArray as postProductAndPriceArrayApi,
} from "../../../helpers/backend_helper";

function* getProducts({ payload: obj }) {
    try {
        const response = yield call(getProductsApi, obj.query); 
        yield put(productsApiResponseSuccess(GET_PRODUCTS, response));
    } catch (error) {
        yield put(productsApiResponseError(GET_PRODUCTS, error));
    }
}

function* getProductsWithRowId({ payload: obj }) {
    try {
        const response = yield call(postProductWithPricesApi, obj.query, obj.username);
        yield put(productsApiResponseSuccess(GET_PRODUCTS_WITH_ROWID, { products: response, rowId: obj.rowId, drafthash: obj.drafthash }));
    } catch (error) {
        yield put(productsApiResponseError(GET_PRODUCTS_WITH_ROWID, error));
    }
}

function* postProductWithPrices({ payload: query }) {
    try {
        const response = yield call(postProductWithPricesApi, query);
        yield put(productsApiResponseSuccess(POST_PRODUCT_WITH_PRICES, response))
    } catch (error) {
        yield put(productsApiResponseError(POST_PRODUCT_WITH_PRICES, error))
    }
}

function* postPriceList({ payload: obj }) {
    try {
        const response = yield call(postPriceListApi, obj); 
        yield put(productsApiResponseSuccess(POST_PRICELIST, response));
    } catch (error) {
        yield put(productsApiResponseError(POST_PRICELIST, error));
    }
}

function* postPriceListWithRowId({ payload: obj }) {
    try {
        const response = yield call(postPriceListApi, { data: obj.data, username: obj.username }); 
        yield put(productsApiResponseSuccess(POST_PRICELIST_WITH_ROWID, { prices: response, rowId: obj.rowId, drafthash: obj.drafthash }));
    } catch (error) {
        yield put(productsApiResponseError(POST_PRICELIST_WITH_ROWID, error));
    }
}

function* deleteDraftProductsAndPriceListWithDraftId({ payload: drafthash }) {
    try {
        yield put(deleteDraftProductsAndPriceListWithDraftIdSuccess(drafthash));
    } catch (error) {
        yield put(deleteDraftProductsAndPriceListWithDraftIdFail(error));
    }
}

function* deleteDraftProductsAndPriceListWithRowId({ payload: obj }) {
    try {
        yield put(deleteDraftProductsAndPriceListWithRowIdSuccess({ drafthash: obj.drafthash, rowId: obj.rowId }));
    } catch (error) {
        yield put(deleteDraftProductsAndPriceListWithRowIdFail(error));
    }
}

function* postProductSelectLog({ payload: obj }) {
    try {
        const response = yield call(postProductSelectLogApi, obj.logId, obj.product);
        yield put(postProductSelectLogSuccess(response));
    } catch (error) {
        yield put(postProductSelectLogFail(error));
    }
}

function* postProductAndPriceArray({ payload: obj }) {
    try {
        const response = yield call(postProductAndPriceArrayApi, obj.productArr, obj.username)
        yield put(postProductAndPriceArraySuccess(response, obj.drafthash))
    } catch (error) {
        yield put(postProductAndPriceArrayFail(error))
    }
}

export function* watchGetProducts() {
    yield takeEvery(GET_PRODUCTS, getProducts);
}

export function* watchGetProductsWithRowId() {
    yield takeEvery(GET_PRODUCTS_WITH_ROWID, getProductsWithRowId);
}

export function* watchPostPriceList() {
    yield takeEvery(POST_PRICELIST, postPriceList);
}

export function* watchPostPriceListWithRowId() {
    yield takeEvery(POST_PRICELIST_WITH_ROWID, postPriceListWithRowId);
}

export function* watchDeleteDraftProductsAndPriceListWithDraftId() {
    yield takeEvery(DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID, deleteDraftProductsAndPriceListWithDraftId);
}

export function* watchDeleteDraftProductsAndPriceListWithRowId() {
    yield takeEvery(DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID, deleteDraftProductsAndPriceListWithRowId);
}

export function* watchPostProductSelectLog() {
    yield takeEvery(POST_PRODUCT_SELECT_LOG, postProductSelectLog);
}

export function* watchPostProductWithPrices() {
    yield takeEvery(POST_PRODUCT_WITH_PRICES, postProductWithPrices);
}

export function* watchPostProductAndPriceArray() {
    yield takeEvery(POST_PRODUCT_AND_PRICE_ARRAY, postProductAndPriceArray);
}

function* productsSaga() {
    yield all([
      fork(watchGetProducts),
      fork(watchGetProductsWithRowId),
      fork(watchPostPriceList),
      fork(watchPostPriceListWithRowId),
      fork(watchDeleteDraftProductsAndPriceListWithDraftId),
      fork(watchDeleteDraftProductsAndPriceListWithRowId),
      fork(watchPostProductSelectLog),
      fork(watchPostProductWithPrices),
      fork(watchPostProductAndPriceArray),
    ]);
}

export default productsSaga;