import {
    GET_INVOICES,
    GET_INVOICE_DETAILS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    GET_INVOICE,
    POST_INVOICE_DETAILS_PRODUCT_QUANTITY,
    POST_INVOICE_DETAILS_PRODUCT_QUANTITY_SUCCESS,
    POST_INVOICE_DETAILS_PRODUCT_QUANTITY_FAIL,
    GET_INVOICE_DETAILS_PRODUCT_INTERCHANGE,
    DOWNLOAD_INVOICE_PDF,
    DOWNLOAD_PACKING_LIST_PDF,
    POST_SHIP_ORDER,
    POST_SHIP_ORDER_FAIL,
    POST_SHIP_ORDER_SUCCESS,
    UPDATE_ORDER_STATUS,
    UPDATE_ORDER_STATUS_FAIL,
    UPDATE_ORDER_STATUS_SUCCESS,
    POST_CANCEL_ORDER,
    POST_CANCEL_ORDER_SUCCESS,
    POST_CANCEL_ORDER_FAIL,
    GET_FILTERED_INVOICES,
    GET_REFORMAT_ORDERITEMS,
    GET_FILTERED_ARCHIVED_INVOICES,
    GET_ARCHIVED_INVOICES,
    GET_OLD_ORDERS_ORDER_ITEMS,
} from "./actionType";

// common success
export const invoiceApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const invoiceApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getInvoices = () => ({
    type: GET_INVOICES,
});

export const getFilteredInvoices = (filter) => ({
    type: GET_FILTERED_INVOICES,
    payload: filter
});

export const getArchivedInvoices = () => ({
    type: GET_ARCHIVED_INVOICES,
});

export const getFilteredArchivedInvoices = (filter) => ({
    type: GET_FILTERED_ARCHIVED_INVOICES,
    payload: filter
});

export const getInvoice = (order_id) => ({
    type: GET_INVOICE,
    payload: order_id,
});

export const getInvoiceDetails = (order_id) => ({
    type: GET_INVOICE_DETAILS,
    payload: order_id,
});

export const postInvoiceDetailsProductQuantity = (product) => ({
    type: POST_INVOICE_DETAILS_PRODUCT_QUANTITY,
    payload: product,
});

export const postInvoiceDetailsProductQuantitySuccess = (data) => ({
    type: POST_INVOICE_DETAILS_PRODUCT_QUANTITY_SUCCESS,
    payload: data,
});

export const postInvoiceDetailsProductQuantityFail = (error) => ({
    type: POST_INVOICE_DETAILS_PRODUCT_QUANTITY_FAIL,
    payload: error,
});

export const getInvoiceDetailsProductInterchange = (product) => ({
    type: GET_INVOICE_DETAILS_PRODUCT_INTERCHANGE,
    payload: product,
});

export const downloadInvoicePdf = (orderId) => ({
    type: DOWNLOAD_INVOICE_PDF,
    payload: orderId
});

export const downloadPackingListPdf = (orderId) => ({
    type: DOWNLOAD_PACKING_LIST_PDF,
    payload: orderId
});

export const postShipOrder = (params) => ({
    type: POST_SHIP_ORDER,
    payload: params
});

export const postShipOrderSuccess = (data) => ({
    type: POST_SHIP_ORDER_SUCCESS,
    payload: data
});

export const postShipOrderFail = (error) => ({
    type: POST_SHIP_ORDER_FAIL,
    payload: error
});

export const updateOrderStatus = (status, order_id, requested_shipped_date) => ({
    type: UPDATE_ORDER_STATUS,
    payload: { status, order_id, requested_shipped_date }
});

export const updateOrderStatusSuccess = (data) => ({
    type: UPDATE_ORDER_STATUS_SUCCESS,
    payload: data
});

export const updateOrderStatusFail = (error) => ({
    type: UPDATE_ORDER_STATUS_FAIL,
    payload: error
});

export const postCancelOrder = (order_id) => ({
    type: POST_CANCEL_ORDER,
    payload: order_id
});

export const postCancelOrderSuccess = () => ({
    type: POST_CANCEL_ORDER_SUCCESS
});

export const postCancelOrderFail = () => ({
    type: POST_CANCEL_ORDER_FAIL
});

export const getOldOrdersOrderitems = () => ({
    type: GET_OLD_ORDERS_ORDER_ITEMS
})

export const getReformatOrderItems = () => ({
    type: GET_REFORMAT_ORDERITEMS
})