import { Label } from 'reactstrap';
import Select from 'react-select';

function AdminInfoSelect({ label, field, accountInfo, setAccountInfo, options }) {
    return (
        <>
            <Label>{label}</Label>
            <Select
                options={options}
                value={options.find((option) => option.value === accountInfo[field])}
                onChange={(e) => setAccountInfo({ ...accountInfo, [field]: e.value })}
            />
        </>
    )
}

export default AdminInfoSelect;