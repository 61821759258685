import { 
    API_RESPONSE_ERROR, 
    API_RESPONSE_SUCCESS, 
    PING
} from "./actionType";

const INIT_STATE = {
    isPing: null,
    error: {},
};

const IdleTimer = (state = INIT_STATE, action) => {
    switch(action.type) {
        case API_RESPONSE_SUCCESS:
            switch(action.payload.actionType) {    
                case PING:
                    return {
                        ...state,
                        isPing: true,
                        error: {}
                    }

                default:
                    return {...state, }
            }
        case API_RESPONSE_ERROR:
            switch(action.payload.actionType) {    
                case PING:
                    return {
                        ...state,
                        isPing: false,
                        error: action.payload.error
                    }
                    
                default:
                    return {...state, }
            }

        default: 
            return { ...state }
    }
}

export default IdleTimer;