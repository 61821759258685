import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Row, Table } from "reactstrap";

import GlobalInput from "./GlobalInput";
import { useProfile } from "../Hooks/UserHooks";

import {
    getUsers as onGetUsers,
    postGlobalMax,
} from "../../store/actions";

function GlobalSettingsMaxQuantity({ users }) {
    const dispatch = useDispatch();
    const { userProfile } = useProfile();
    const [globalMaxUser, setGlobalMaxUser] = useState('all');
    const [globalMax, setGlobalMax] = useState({ maxonz: 0, maxocg: 0, maxsnz: 0, maxscb: 0, maxafn: 0, maxcdm: 0, maxcdp: 0, maxcnz: 0, maxmd: 0, maxbearing: 0, maxother: 0,maxtsu:0 });

    function getUserMaxInfo(username) {
        setGlobalMaxUser(username)
        let user = users.find((item) => item.username === username);
        if (username === "all") {
            setGlobalMax({ maxonz: 0, maxocg: 0, maxsnz: 0, maxscb: 0, maxafn: 0, maxcdm: 0, maxcdp: 0, maxcnz: 0, maxmd: 0, maxbearing: 0, maxother: 0,maxtsu:0 });
        }
        setGlobalMax({ maxonz: user.maxonz, maxocg: user.maxocg, maxsnz: user.maxsnz, maxscb: user.maxscb, maxafn: user.maxafn, maxcdm: user.maxcdm, maxcdp: user.maxcdp, maxcnz: user.maxcnz, maxmd: user.maxmd, maxbearing: user.maxbearing, maxother: user.maxother,maxtsu:user.maxtsu });
    }

    function handlePostMaxQuantityDefault() {
        try {
            let param = {};
            if (globalMaxUser !== "all") {
                param = { ...globalMax, username: globalMaxUser };
            } else {
                param = globalMax;
            }

            dispatch(postGlobalMax(param));
            alert('Max Quantity Updated');
            onGetUsers();
        } catch (error) {
            console.log("handlePostMaxQuantityDefault Failed: " + error.message);
        }
    }

    return (
        <>
            <h4>Max Quantity</h4>
            <br></br>
            <Table bordered>
                <thead>
                    <tr>
                        <th>Customer</th>
                        <th>Maximum Quantity of Product</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <FormGroup className="p-3">
                                <Label for="customerSelect">Select one or all Customer</Label>
                                <Input type="select" name="selectCustomer" id="selectCustomer" onChange={e => { getUserMaxInfo(e.target.value) }}>
                                    <option value="all">All</option>
                                    {(users !== undefined && users !== null) ? (
                                        users.map((user, username) => <option key={username} value={user.username}>{user.company_name} ({user.firstname} {user.lastname})</option>)
                                    ) : (null)}
                                </Input>
                            </FormGroup>
                        </td>
                        <td>
                            <Row>
                                <Col>
                                    <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxonz'} defaultValue={globalMax.maxonz} disabled={false} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxocg'} defaultValue={globalMax.maxocg} disabled={false} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxsnz'} defaultValue={globalMax.maxsnz} disabled={false} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxscb'} defaultValue={globalMax.maxscb} disabled={false} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxafn'} defaultValue={globalMax.maxafn} disabled={false} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxcdm'} defaultValue={globalMax.maxcdm} disabled={false} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxcdp'} defaultValue={globalMax.maxcdp} disabled={false} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxtsu'} defaultValue={globalMax.maxcdp} disabled={false} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxmd'} defaultValue={globalMax.maxmd} disabled={false} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxbearing'} defaultValue={globalMax.maxbearing} disabled={false} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxcnz'} defaultValue={globalMax.maxcnz} disabled={false} />
                                </Col>
                                <Col>
                                    <GlobalInput objectValues={globalMax} setObjectValues={setGlobalMax} productLine={'maxother'} defaultValue={globalMax.maxother} disabled={false} />
                                </Col>
                            </Row>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <div className="text-center justify-content-center">
                <Button type="button" color="warning" onClick={handlePostMaxQuantityDefault}>Update</Button>
            </div>
        </>
    )
}

export default GlobalSettingsMaxQuantity;