import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";

import { batchReturnPattern } from "../constants/products";
import Loader from "../Common/Loader";
import removeButton from '../../assets/images/close.png';

import { useProfile } from "../Hooks/UserHooks";

import {
    deleteReturnTableItem as onDeleteReturnTableItem,
    getCustomerProducts as onGetCustomerProducts,
} from "../../store/actions";

const BatchReturnTableRow = ({ rowId, customerProducts, productsToReturnArray, setProductsToReturnArray, fileItem, username }) => {
    const dispatch = useDispatch();
    const { userProfile } = useProfile();
    const [query, setQuery] = useState(null);
    const [customerProductsArr, setCustomerProductsArr] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [totalShipped, setTotalShipped] = useState(null);
    const [totalReturn, setTotalReturn] = useState(null);
    const [amountToReturn, setAmountToReturn] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const queryRef = useRef();

    const toggle = () => setIsDropdownOpen(prevState => !prevState);

    useEffect(() => {
        if (query) {
            setIsLoading(true);
            setSelectedProduct(null);
            setTotalReturn(null);
            setTotalShipped(null);
            setAmountToReturn(null);

            let tempUsername = (username) ? (username) : (userProfile.username)
            tempUsername = encodeURIComponent(tempUsername);
            let line = query.match(batchReturnPattern);

            if (line === null) line = '';

            dispatch(onGetCustomerProducts(tempUsername, query, line, rowId));
        }
    }, [dispatch, rowId, query, userProfile, username])

    useEffect(() => {
        if (fileItem) {
            queryRef.current.value = fileItem.product;
            setQuery(fileItem.product);
        }
    }, [dispatch, rowId, fileItem])

    useEffect(() => {
        if (customerProducts && customerProducts.length !== 0) setCustomerProductsArr(customerProducts);
        
        setIsLoading(false);
    }, [customerProducts])

    useEffect(() => {
        if (fileItem && customerProductsArr && customerProductsArr.length !== 0 && query === fileItem.product) {
            let temp = customerProductsArr.find((item) => item.product === fileItem.product || item.max_product === fileItem.product)
            handleSelectedProduct(temp);
        }
    }, [customerProductsArr]);

    useEffect(() => {
        if (fileItem && customerProductsArr && customerProductsArr.length !== 0 && selectedProduct && selectedProduct.product === fileItem.product) {
            handleOnAmountChange(fileItem.qty);
        }
    }, [selectedProduct])

    function handleOnChangeQuery(e) {
        if (e.key === 'Enter' && e.target.value !== "") setQuery(e.target.value);
    }

    function handleOnBlurQuery(e) {
        if (e.target.value !== '') setQuery(e.target.value);
    }

    function handleSelectedProduct(product) {
        let total_shipped = 0;
        let total_return = 0;
        let total_qty_to_return = 0;

        total_shipped = product.qty_shipped; 
        total_return = product.qty_returned;
        total_qty_to_return = Number(product.qty_to_return)/* + (Number(product.adjustment_qty) || 0)*/;
        
        if (!total_return && !total_qty_to_return) {
            total_return = total_shipped;
        } else {
            total_return = total_shipped - total_return - total_qty_to_return;
        }

        if (total_return < 0) total_return = 0;

        setSelectedProduct(product);
        setTotalShipped(total_shipped);
        setTotalReturn(total_return);

        let tempProductsToReturnArray = productsToReturnArray;
        tempProductsToReturnArray = tempProductsToReturnArray.map((item) => {
            if (item.rowId !== rowId) return item;

            item.part = product.product;
            item.qty = 0;
            item.product_query = query;
            return item;
        });
        
        setProductsToReturnArray(tempProductsToReturnArray);
        setAmountToReturn(0);
    }

    function handleOnAmountChange(amount) {
        let tempAmountToReturn = amount;
        let tempProductsToReturn = productsToReturnArray;
        let tempCheck = 0;

        if (amount > totalReturn) tempAmountToReturn = totalReturn;
        else if (amount < 0) tempAmountToReturn = 0;
        
        if (selectedProduct) {
            tempCheck = tempProductsToReturn.findIndex((item) => item.rowId === rowId);
            
            if (tempCheck !== -1) {
                tempProductsToReturn[tempCheck] = { part: selectedProduct.product, qty: tempAmountToReturn, rowId: rowId, product_query: query };
            } else {
                tempProductsToReturn.push({ part: selectedProduct.product, qty: tempAmountToReturn, rowId: rowId, product_query: query });
            }

            setProductsToReturnArray(tempProductsToReturn);
        }

        setAmountToReturn(tempAmountToReturn);
    }

    function handleRemoveReturnBatchTableRow() {
        let newProductsToReturnArray = [...productsToReturnArray];
        newProductsToReturnArray = newProductsToReturnArray.filter((item) => item.rowId !== rowId);
        
        dispatch(onDeleteReturnTableItem(rowId));
        setCustomerProductsArr([]);
        setQuery('');
        setTotalShipped(null);
        setTotalReturn(null);
        setAmountToReturn(null);
        setProductsToReturnArray(newProductsToReturnArray);
        queryRef.current.value = '';
    }

    return (
        <tr key={rowId}>
            <td>
                <img className="enable-click-event cursorPointer" src={removeButton} alt="remove button" onClick={handleRemoveReturnBatchTableRow} />
            </td>
            <td>
                <Input innerRef={queryRef} type="text" tabIndex={(rowId * 3) + 1} onKeyDown={(e) => handleOnChangeQuery(e)} onBlur={(e) => handleOnBlurQuery(e)} />
            </td>
            <td>
                {(isLoading) ? (<Loader />) : (null)}
                {(customerProductsArr && customerProductsArr.length !== 0) ? (
                    <ButtonGroup>
                        <Dropdown isOpen={isDropdownOpen} toggle={toggle}>
                            <DropdownToggle tag="button" className="btn btn-warning" tabIndex={(rowId * 3) + 2}>
                                {selectedProduct?.product}<i className="mdi mdi-chevron-down"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                {customerProductsArr.map((product, index) => {
                                    return <DropdownItem key={'row-' + rowId + '-item-' + index} onClick={() => handleSelectedProduct(product)}>{product.product}</DropdownItem>
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </ButtonGroup>
                ) : (null)}
            </td>
            <td>{(totalShipped || totalShipped === 0) ? (totalShipped) : (null)}</td>
            <td>{(totalReturn || totalReturn === 0) ? (totalReturn) : (null)}</td>
            <td>
                <Input type="number" value={amountToReturn || ''} min="0" max={totalReturn} tabIndex={(rowId * 3) + 3} onChange={(e) => handleOnAmountChange(e.target.value)} />
            </td>
        </tr>
    )
}

export default BatchReturnTableRow;