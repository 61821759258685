const invoiceWidgets = [
    {
        id: 1,
        label: "Invoices Sent",
        percentage: "+89.24 %",
        percentageClass: "success",
        icon: "ri-arrow-right-up-line",
        counter: "559.25",
        badge: "2,258",
        caption: "Invoices sent",
        feaIcon: "file-text",
        decimals: 1,
        prefix: "$",
        suffix: "k"
    },
    {
        id: 2,
        label: "Paid Invoices",
        percentage: "+8.09 %",
        percentageClass: "danger",
        icon: "ri-arrow-right-down-line",
        counter: "409.66",
        badge: "1,958",
        caption: "Paid by clients",
        feaIcon: "check-square",
        decimals: 2,
        prefix: "$",
        suffix: "k"
    },
    {
        id: 3,
        label: "Unpaid Invoices",
        percentage: "+9.01 %",
        percentageClass: "danger",
        icon: "ri-arrow-right-down-line",
        counter: "136.98",
        badge: "338",
        caption: "Unpaid by clients",
        feaIcon: "clock",
        decimals: 2,
        prefix: "$",
        suffix: "k"
    },
    {
        id: 4,
        label: "Cancelled Invoices",
        percentage: "+7.55 %",
        percentageClass: "success",
        icon: "ri-arrow-right-up-line",
        counter: "84.2",
        badge: "502",
        caption: "Cancelled by clients",
        feaIcon: "x-octagon",
        decimals: 1,
        prefix: "$",
        suffix: "k"
    },
];

const statementARTable = [
    {
        id: 1,
        label: "1 - 30 Days",
        start: 0,
        end: 30
    },
    {
        id: 2,
        label: "30 - 60 Days",
        start: 30,
        end: 60
    },
    {
        id: 3,
        label: "60 - 90 Days",
        start: 60,
        end: 90
    },
    {
        id: 4,
        label: "90 - 120 Days",
        start: 90,
        end: 120
    },
    {
        id: 5,
        label: "120+ Days",
        start: 120,
        end: Infinity
    },
];

export { invoiceWidgets, statementARTable };