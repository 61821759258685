import { 
    API_RESPONSE_ERROR,
    API_RESPONSE_SUCCESS, 
    DOWNLOAD_CREDIT_INVOICE,
    GET_CREDIT, 
    GET_CREDITS, 
    GET_CREDITS_FILTERED, 
    GET_CREDIT_DETAILS,
} from "./actionType";

const INIT_STATE = {
    credits: [],
    credit: {},
    creditDetails: [],
    error: {},
};

const Credit = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_CREDITS:
                case GET_CREDITS_FILTERED:
                    return {
                        ...state,
                        credits: action.payload.data,
                        credit: {},
                        creditDetails: [],
                        isCreditCreated: false,
                        isCreditSuccess: true,
                        isCreditDetailsSuccess: false,
                        isCreditInvoiceDownloaded: false,
                    };
                case GET_CREDIT:
                    return {
                        ...state,
                        credit: action.payload.data[0],
                        isCreditCreated: false,
                        isCreditSuccess: true,
                    };
                case GET_CREDIT_DETAILS:
                    return {
                        ...state,
                        creditDetails: action.payload.data,
                        isCreditCreated: false,
                        isCreditDetailsSuccess: true
                    }
                case DOWNLOAD_CREDIT_INVOICE:
                    return {
                        ...state,
                        creditInvoicePdf: action.payload.data,
                        isCreditInvoiceDownloaded: true,
                    }
                default:
                    return { ...state };
            }
        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_CREDITS:
                case GET_CREDITS_FILTERED:
                    return {
                        ...state,
                        error: action.payload.error,
                        isCreditCreated: false,
                        isCreditSuccess: false
                    };
                case GET_CREDIT:
                    return {
                        ...state,
                        error: action.payload.error,
                        isCreditCreated: false,
                        isCreditSuccess: false,
                    };
                case GET_CREDIT_DETAILS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isCreditCreated: false,
                        isCreditDetailsSuccess: false,
                    }
                case DOWNLOAD_CREDIT_INVOICE:
                    return {
                        ...state,
                        error: action.payload.error,
                        isCreditInvoiceDownloaded: false,
                    }
                default:
                    return { ...state };
            }

        default:
            return { ...state };
    }
};

export default Credit;