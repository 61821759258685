import { FormGroup, Input, Label } from 'reactstrap';

function GlobalInput({ objectValues, setObjectValues, productLine, defaultValue, disabled }) {
    return (
        <FormGroup>
            <Label>{productLine.toUpperCase()}: </Label>
            <Input type="number" min="0" onChange={(e) => setObjectValues({...objectValues, [productLine]: Number(e.target.value)})} value={(defaultValue)? defaultValue : 0} disabled={disabled} />
        </FormGroup>
    )
}

export default GlobalInput;