import { 
    API_RESPONSE_ERROR, 
    API_RESPONSE_SUCCESS, 
    DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID, 
    DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID_FAIL, 
    DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID_SUCCESS, 
    DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID, 
    DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID_FAIL, 
    DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID_SUCCESS, 
    GET_PRODUCTS, 
    GET_PRODUCTS_WITH_ROWID, 
    POST_PRODUCT_WITH_PRICES, 
    POST_PRICELIST, 
    POST_PRICELIST_WITH_ROWID, 
    POST_PRODUCT_SELECT_LOG,
    POST_PRODUCT_SELECT_LOG_FAIL,
    POST_PRODUCT_SELECT_LOG_SUCCESS,
    POST_PRODUCT_AND_PRICE_ARRAY,
    POST_PRODUCT_AND_PRICE_ARRAY_SUCCESS,
    POST_PRODUCT_AND_PRICE_ARRAY_FAIL,
    ON_BACK_EMPTY_PRODUCTS,
} from "./actionType";

// common success
export const productsApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const productsApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getProducts = (query) => ({
    type: GET_PRODUCTS,
    payload: query
});

export const getProductsWithRowId = (query) => ({
    type: GET_PRODUCTS_WITH_ROWID,
    payload: query
});

export const postProductWithPrices = (query) => ({
    type: POST_PRODUCT_WITH_PRICES,
    payload: query
})

export const postPriceList = (products) => ({
    type: POST_PRICELIST,
    payload: products
});

export const postPriceListWithRowId = (products) => ({
    type: POST_PRICELIST_WITH_ROWID,
    payload: products
});

export const deleteDraftProductsAndPriceListWithDraftId = (drafthash) => ({
    type: DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID,
    payload: drafthash
});

export const deleteDraftProductsAndPriceListWithDraftIdSuccess = (data) => ({
    type: DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID_SUCCESS,
    payload: data
});

export const deleteDraftProductsAndPriceListWithDraftIdFail = (error) => ({
    type: DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_DRAFTID_FAIL,
    payload: error
});

export const deleteDraftProductsAndPriceListWithRowId = (ids) => ({
    type: DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID,
    payload: ids
});

export const deleteDraftProductsAndPriceListWithRowIdSuccess = (data) => ({
    type: DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID_SUCCESS,
    payload: data
});

export const deleteDraftProductsAndPriceListWithRowIdFail = (error) => ({
    type: DELETE_DRAFT_PRODUCTS_AND_PRICELIST_WITH_ROWID_FAIL,
    payload: error
});

export const postProductSelectLog = (logId, product) => ({
    type: POST_PRODUCT_SELECT_LOG,
    payload: { logId, product }
});

export const postProductSelectLogSuccess = (data) => ({
    type: POST_PRODUCT_SELECT_LOG_SUCCESS,
    payload: data
});

export const postProductSelectLogFail = (error) => ({
    type: POST_PRODUCT_SELECT_LOG_FAIL,
    payload: error
});

export const postProductAndPriceArray = (productArr, username, drafthash) => ({
    type: POST_PRODUCT_AND_PRICE_ARRAY,
    payload: { productArr, username, drafthash }
});

export const postProductAndPriceArraySuccess = (data, drafthash) => ({
    type: POST_PRODUCT_AND_PRICE_ARRAY_SUCCESS,
    payload: { data, drafthash }
});

export const postProductAndPriceArrayFail = (error) => ({
    type: POST_PRODUCT_AND_PRICE_ARRAY_FAIL,
    payload: error
});

export const onBackEmptyProducts = () => ({
    type: ON_BACK_EMPTY_PRODUCTS
});