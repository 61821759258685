import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardBody, Col, FormGroup, Input, Row, Table } from "reactstrap";

import AccessoryInput from "./AccessoryInput";
import AddAccessoryInput from "./AddAccessoryInput";

import {
    getAccessory as onGetAccessory,
    postAccessory,
    postAddAccessory,
    deleteAccessory,
} from "../../store/actions";

function PromotionAndCouponsAccessoryPromotion() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState("0");
    const [accessory, setAccessory] = useState([]);
    const [addAccessory, setAddAccessory] = useState([{ bucket: 0, part: '', discount: 0, qty: 0 }]);
    const [numRowsOne, setNumRowsOne] = useState(accessory.length);
    const [numRowsTwo, setNumRowsTwo] = useState(1);
    const [deleteAccessoryBucket, setDeleteAccessoryBucket] = useState('');
    const [deleteAccessoryPart, setDeleteAccessoryPart] = useState('');

    let rowOne = [];
    let rowTwo = [];

    const { isAccessoryFetched, accessorys, isAccessoryPosted, isAddAccessoryPosted, } = useSelector(state => ({
        isAccessoryFetched: state.PromotionsCoupons.isAccessoryFetched,
        accessorys: state.PromotionsCoupons.accessorys,
        isAccessoryPosted: state.PromotionsCoupons.isAccessoryPosted,
        isAddAccessoryPosted: state.PromotionsCoupons.isAddAccessoryPosted,
    }));

    const [accordion, setAccordion] = useState(false);
    const toggleAccordion = () => setAccordion(!Accordion);

    const toggle = (id) => {
        if (open === id) setOpen();
        else setOpen(id);
    };

    const [activeTab, setactiveTab] = useState("1");
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };

    useEffect(() => {
        dispatch(onGetAccessory());
    }, [dispatch]);

    useEffect(() => {
        if (isAccessoryFetched === true) {
            let tempArr = accessorys;
            let tempBucket = [];

            tempArr.forEach((item) => {
                let i = tempBucket.findIndex(x => (x.bucket === item.bucket));
                if (i <= -1) {
                    tempBucket.push({ bucket: item.bucket, discount: item.discount, qty: item.qty });
                }
                return null;
            })

            let temp = [];
            tempBucket.forEach((item) => {
                let i = tempArr.filter(x => (x.bucket === item.bucket));
                temp.push({ bucket: item.bucket, parts: i, discount: item.discount, qty: item.qty })
            })

            setAccessory(temp);
            setNumRowsOne(temp.length);
        }
    }, [isAccessoryFetched])

    function accessoryTableRow(id) {
        return (
            <tr key={id}>
                <td>{accessory[id].bucket}</td>
                <td>
                    <Accordion open={open} toggle={toggle}>
                        <AccordionItem>
                            <AccordionHeader targetId={`${id}`}>Parts</AccordionHeader>
                            <AccordionBody accordionId={`${id}`}>
                                {accessory[id].parts.map((item, index) =>
                                    <ul key={index}>
                                        <li>{item.part}</li>
                                    </ul>
                                )}
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </td>
                <td><AccessoryInput rowid={id} objectValues={accessory} setObjectValues={setAccessory} property={'discount'} min={'0.00'} type={'number'} defaultValue={accessory[id].discount} /></td>
                <td><AccessoryInput rowid={id} objectValues={accessory} setObjectValues={setAccessory} property={'qty'} min={'0'} type={'number'} defaultValue={accessory[id].qty} /></td>
            </tr>
        )
    }

    function handleAddAccessoryRow() {
        let newArr = addAccessory;
        newArr.push({ bucket: 0, part: '', discount: 0, qty: 0 });
        setAddAccessory(newArr);
        setNumRowsTwo(numRowsTwo + 1);
    }

    function addAccessoryTableRow(id) {
        return (
            <tr key={id}>
                <td><AddAccessoryInput rowid={id} objectValues={addAccessory} setObjectValues={setAddAccessory} property={'bucket'} min={'1'} type={'number'}></AddAccessoryInput></td>
                <td><AddAccessoryInput rowid={id} objectValues={addAccessory} setObjectValues={setAddAccessory} property={'part'} type={'text'}></AddAccessoryInput></td>
                <td><AddAccessoryInput rowid={id} objectValues={addAccessory} setObjectValues={setAddAccessory} property={'discount'} min={'0.00'} type={'number'}></AddAccessoryInput></td>
                <td><AddAccessoryInput rowid={id} objectValues={addAccessory} setObjectValues={setAddAccessory} property={'qty'} min={'0'} type={'number'}></AddAccessoryInput></td>
            </tr>
        )
    }

    function handlePostAccessory() {
        let params = [];
        accessory.map((item) => item.parts.map((x) => params.push(x)))
        try {
            dispatch(postAccessory(params))
            alert("Accessory Promotion Prices Updated");
        } catch (error) {
            console.log("postglobalAccessory failed: " + error.message);
        }
    }

    function handlePostAddAccessory() {
        let params = addAccessory;
        try {
            dispatch(postAddAccessory(params));
            alert("Accessory Promotion Prices Updated");
        } catch (error) {
            console.log("postAccessory Failed:" + error.message);
        }
    }

    function handleDeleteAccessory() {
        try {
            dispatch(deleteAccessory(deleteAccessoryBucket, deleteAccessoryPart));
            alert("Accessory Promotion Table");
        } catch (error) {
            console.log(error.message);
        }
    }

    for (let i = 0; i < numRowsOne; i++) {
        rowOne[i] = accessoryTableRow(i);
    }

    for (let i = 0; i < numRowsTwo; i++) {
        rowTwo[i] = addAccessoryTableRow(i);
    }

    return (
        <>
            <Row>
                <Col>
                    <br></br>
                    <Card outline color="warning">
                        <CardBody>
                            <h4>Accessory Promotion</h4>
                            <br></br>
                            <Table className="table-bordered">
                                <thead>
                                    <tr>
                                        <th>Bucket</th>
                                        <th>Product</th>
                                        <th>Discount</th>
                                        <th>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rowOne}
                                </tbody>
                            </Table>
                            <Row xxl="auto" className="align-items-center justify-content-center">
                                <Col md="auto">
                                    <Button type="button" color="warning" onClick={handlePostAccessory}>Submit Table</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card outline color="warning">
                        <CardBody>
                            <h4> Add Accessory</h4>
                            <br></br>
                            <Table className="table-bordered">
                                <thead>
                                    <tr>
                                        <th>Bucket</th>
                                        <th>Product</th>
                                        <th>Discount</th>
                                        <th>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rowTwo}
                                </tbody>
                            </Table>
                            <Row xxl="auto" className="align-items-center justify-content-center">
                                <Col>
                                    <Button type="button" onClick={handleAddAccessoryRow}>Add Row</Button>
                                </Col>
                                <Col>
                                    <Button type="button" color="warning" onClick={handlePostAddAccessory}>Submit Table</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card outline color="warning">
                        <CardBody>
                            <h4>Delete Accessory</h4>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Input type="select" onChange={(e) => setDeleteAccessoryBucket(e.target.value)}>
                                            <option value=""></option>
                                            {(accessory) ? (
                                                accessory.map((item, index) => <option key={"bucket" + index} value={item.bucket}>{item.bucket}</option>)
                                            ) : (null)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Input type="select" onChange={(e) => setDeleteAccessoryPart(e.target.value)}>
                                            <option value=""></option>
                                            {(accessory) ? (
                                                accessory.map((item) => item.parts.map((bucket, index) => <option key={"part" + index} value={bucket.part}>{bucket.part}</option>))
                                            ) : (null)}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <Button color="danger" onClick={handleDeleteAccessory}>Delete</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default PromotionAndCouponsAccessoryPromotion;