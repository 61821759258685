import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Input, Label, Row, Table } from 'reactstrap';

import { useProfile } from '../../../Components/Hooks/UserHooks';

import BreadCrumb from '../../../Components/Common/BreadCrumb';

import {
    createNewOrder as onCreateNewOrder,
    deleteDraft as onDeleteDraft,
    deleteDraftProductsAndPriceListWithDraftId as onDeleteDraftProductAndPriceListWithDraftId,
    onBackEmptyProducts,
} from "../../../store/actions";

const PreviewOrder = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const { userProfile } = useProfile();
    const { draftDetails, isOrderCreated, isDraftsDeleted, previewDraftItems, previewDraftUsername } = useSelector((state) => ({
        draftDetails: state.Drafts.draftDetails,
        isOrderCreated: state.Drafts.isOrderCreated,
        isDraftsDeleted: state.Drafts.isDraftsDeleted,
        previewDraftItems: state.Drafts.previewDraftItems,
        previewDraftUsername: state.Drafts.previewDraftUsername,
    }))

    useEffect(() => {
        if (isOrderCreated !== true) return;

        dispatch(onDeleteDraftProductAndPriceListWithDraftId(draftDetails.drafthash));
        dispatch(onDeleteDraft(draftDetails.drafthash));
    }, [dispatch, isOrderCreated, draftDetails])

    useEffect(() => {
        if (isOrderCreated !== true && isDraftsDeleted !== true) return;

        alert('Order Created');
        history.push("/new-order");
    }, [isOrderCreated, isDraftsDeleted, history])

    function handleBackToDraft() {
        dispatch(onBackEmptyProducts());
        history.goBack()
    }

    function handleSubmitOrder() {
        let tempDraftDetails = draftDetails;
        let tempDraftItems = tempDraftDetails.draftitems;
        delete tempDraftDetails.draftitems;
        tempDraftDetails.items = previewDraftItems;
        
        if (previewDraftUsername !== '') {
            tempDraftDetails.username = previewDraftUsername;
        }

        dispatch(onCreateNewOrder(tempDraftDetails));
    }

    document.title = "Preview Order | Max Advanced Brakes - Prozone";
    
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Preview Order" pageTitle="New Order" linkTo={`/new-order`} />
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Max Product#</th>
                                            <th>Product#</th>
                                            <th>Description</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    {(previewDraftItems && isOrderCreated !== true && isDraftsDeleted !== true) ? (
                                        <tbody>
                                            {previewDraftItems.map((item, index) => {
                                                return (
                                                    <tr key={`previewTableRow-${index}`}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.max_product}</td>
                                                        <td>{item.product}</td>
                                                        <td>{item.description}</td>
                                                        <td>{item.qty}</td>
                                                        <td>${item.price}</td>
                                                        <td>${item.total}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    ) : (null)}
                                    <tfoot>
                                        {(previewDraftItems) ? (
                                            <tr>
                                                <th>Total</th>
                                                <td colSpan={3}></td>
                                                <td>{previewDraftItems.reduce((total, item) => total + item.qty, 0)}</td>
                                                <td></td>
                                                <td>{ /*${previewDraftItems.reduce((total, item) => total + Number(item.total), 0.00).toFixed(2)} */}</td>
                                            </tr>
                                        ) : (null)}
                                        {(draftDetails && isOrderCreated !== true && isDraftsDeleted !== true) ? (
                                            <>
                                                <tr>
                                                    <th className="text-end" colSpan={6}>Subtotal:</th>
                                                    <td>${Number(draftDetails.draft_total).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <th className="text-end" colSpan={6}>Tax:</th>
                                                    <td>${(userProfile.taxrate * draftDetails.draft_total).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <th className="text-end" colSpan={6}>Total:</th>
                                                    <td>${((userProfile.taxrate * draftDetails.draft_total) + Number(draftDetails.draft_total)).toFixed(2)}</td>
                                                </tr>
                                            </>
                                        ) : (null)}
                                    </tfoot>
                                </Table>
                            </CardBody>
                        </Card>
                        <Row className="mb-3">
                            <Col md={3}>
                                <Label htmlFor="couponInput" className="form-label">Coupon:</Label>
                                <Input type="text" className="form-control" id="couponInput" />
                            </Col>
                            <Col>
                                {/* {(userProfile.access !== 5) ? (
                                    <div className="text-end float-end form-check mb-2">
                                        <Input className="form-check-input" type="checkbox" id="formCheck1" />
                                        <Label className="form-check-label" htmlFor="formCheck1">Promotion Order</Label>
                                    </div>
                                ) : (null)} */}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="d-grid">
                                    <Button color={'secondary'} className="btn btn-secondary" onClick={handleBackToDraft}>Back</Button>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-grid">
                                    <Button color={'warning'} className="btn btn-warning" onClick={handleSubmitOrder}>Submit</Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default PreviewOrder;