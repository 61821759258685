import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { 
    idleTimerApiResponseError, 
    idleTimerApiResponseSuccess 
} from "./action";

import { 
    PING 
} from "./actionType";

import {
    ping as pingApi
} from "../../helpers/backend_helper";

function* ping() {
    try {
        yield call(pingApi);
        yield put(idleTimerApiResponseSuccess(PING))
    } catch (error) {
        yield put(idleTimerApiResponseError(PING, error))
    }
}

export function* watchPing() {
    yield takeEvery(PING, ping)
}

function* idleTimerSaga() {
    yield all([
        fork(watchPing),
    ])
}

export default idleTimerSaga;