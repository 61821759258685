import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Credit Redux States
import { 
    DOWNLOAD_CREDIT_INVOICE,
    GET_CREDIT,
    GET_CREDITS, 
    GET_CREDITS_FILTERED, 
    GET_CREDIT_DETAILS
} from "./actionType";

import { 
    creditApiResponseError,
    creditApiResponseSuccess,
    downloadCreditInvoiceFail,
    downloadCreditInvoiceSuccess,
} from "./action";

//Include Both Helper File with needed methods
import {
    getCredits as getCreditsApi,
    getCreditsFiltered as getCreditsFilteredApi,
    getCredit as getCreditApi,
    getCreditDetails as getCreditDetailsApi,
    downloadCreditInvoice as downloadCreditInvoiceApi,
} from "../../../helpers/backend_helper";

function* getCredits() {
    try {
        const response = yield call(getCreditsApi);
        yield put(creditApiResponseSuccess(GET_CREDITS, response));
    } catch (error) {
        yield put(creditApiResponseError(GET_CREDITS, error));
    }
}

function* getCreditsFiltered({ payload: filter }) {
    try {
        const response = yield call(getCreditsFilteredApi, filter);
        yield put(creditApiResponseSuccess(GET_CREDITS_FILTERED, response));
    } catch (error) {
        yield put(creditApiResponseError(GET_CREDITS_FILTERED, error));
    }
}

function* getCredit({ payload: credit_id }) {
    try {
        const response = yield call(getCreditApi, credit_id);
        yield put(creditApiResponseSuccess(GET_CREDIT, response));
    } catch (error) {
        yield put(creditApiResponseError(GET_CREDIT, error));
    }
}

function* getCreditDetails({ payload: credit_id }) {
    try {
        const response = yield call(getCreditDetailsApi, credit_id);
        yield put(creditApiResponseSuccess(GET_CREDIT_DETAILS, response));
    } catch (error) {
        yield put(creditApiResponseError(GET_CREDIT_DETAILS, error));
    }
}

function* downloadCreditInvoice({ payload: creditId }) {
    try {
        const response = yield call(downloadCreditInvoiceApi, creditId)
        yield put(creditApiResponseSuccess(DOWNLOAD_CREDIT_INVOICE, response));
    } catch (error) {
        yield put(creditApiResponseError(DOWNLOAD_CREDIT_INVOICE, error));
    }
}

export function* watchGetCredits() {
    yield takeEvery(GET_CREDITS, getCredits);
}

export function* watchGetCreditsFiltered() {
    yield takeEvery(GET_CREDITS_FILTERED, getCreditsFiltered)
}

export function* watchGetCredit() {
    yield takeEvery(GET_CREDIT, getCredit);
}

export function* watchGetCreditDetails() {
    yield takeEvery(GET_CREDIT_DETAILS, getCreditDetails);
}

export function* watchDownloadCreditInvoice() {
    yield takeEvery(DOWNLOAD_CREDIT_INVOICE, downloadCreditInvoice);
}

function* creditSaga() {
    yield all([
        fork(watchGetCredits),
        fork(watchGetCreditsFiltered),
        fork(watchGetCredit),
        fork(watchGetCreditDetails),
        fork(watchDownloadCreditInvoice),
    ]);
}

export default creditSaga;