import { 
    API_RESPONSE_ERROR, 
    API_RESPONSE_SUCCESS, 
    DELETE_RETURN_BUCKET_ITEM, 
    DELETE_RETURN_BUCKET_ITEM_FAIL, 
    DELETE_RETURN_BUCKET_ITEM_SUCCESS, 
    DOWNLOAD_RETURN_INVOICE, 
    GET_CUSTOMER_PRODUCTS, 
    GET_RETURN,
    GET_RETURNS,
    GET_RETURN_BUCKET,
    GET_RETURN_DETAILS,
    GET_RGR_FORM,
    POST_RETURN_BUCKET,
    POST_RETURN_BUCKET_FAIL,
    POST_RETURN_BUCKET_SUCCESS,
    POST_RETURN_INVOICE_CREATE,
    POST_RETURN_INVOICE_CREATE_FAIL,
    POST_RETURN_INVOICE_CREATE_SUCCESS,
    UPDATE_RETURN_BUCKET,
    UPDATE_RETURN_BUCKET_FAIL,
    UPDATE_RETURN_BUCKET_ITEMS,
    UPDATE_RETURN_BUCKET_ITEMS_FAIL,
    UPDATE_RETURN_BUCKET_ITEMS_SUCCESS,
    UPDATE_RETURN_BUCKET_SUCCESS,
    POST_RETURN_INVOICE_APPROVAL,
    POST_RETURN_INVOICE_APPROVAL_SUCCESS,
    POST_RETURN_INVOICE_APPROVAL_FAIL,
    UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET,
    UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET_SUCCESS,
    UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET_FAIL,
    GET_RETURN_DETAILS_EXTRA,
    GET_RETURN_ADJUSTMENTS,
    GET_RETURN_ADJUSTMENT,
    GET_RETURN_ADJUSTMENT_DETAILS,
    UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL,
    UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL_SUCCESS,
    UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL_FAIL,
    UPDATE_RETURN_INVOICE_CREDIT_ISSUE,
    UPDATE_RETURN_INVOICE_CREDIT_ISSUE_FAIL,
    UPDATE_RETURN_INVOICE_CREDIT_ISSUE_SUCCESS,
    DOWNLOAD_RETURN_BATCH_TEMPLATE,
    DELETE_RETURN_TABLE_ITEM,
    GET_RETURNS_FILTERED,
} from "./actionType";

// common success
export const returnApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const returnApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getReturns = () => ({
    type: GET_RETURNS,
});

export const getReturnsFiltered = (filter) => ({
    type: GET_RETURNS_FILTERED,
    payload: filter
});

export const getReturn = (return_id) => ({
    type: GET_RETURN,
    payload: return_id,
});

export const getReturnDetails = (return_id) => ({
    type: GET_RETURN_DETAILS,
    payload: return_id,
});

export const getReturnDetailsExtra = (return_id) => ({
    type: GET_RETURN_DETAILS_EXTRA,
    payload: return_id
});

export const getCustomerProducts = (username, query, line, rowId) => ({
    type: GET_CUSTOMER_PRODUCTS,
    payload: { username, query, line, rowId },
});

export const getRgrForm = () => ({
    type: GET_RGR_FORM,
});

export const getReturnAdjustments = () => ({
    type: GET_RETURN_ADJUSTMENTS
});

export const getReturnAdjustment = (return_adjustment_id) => ({
    type: GET_RETURN_ADJUSTMENT,
    payload: return_adjustment_id
});

export const getReturnAdjustmentDetails = (return_adjustment_id) => ({
    type: GET_RETURN_ADJUSTMENT_DETAILS,
    payload: return_adjustment_id
});

export const postReturnBucket = (productsToReturn, username) => ({
    type: POST_RETURN_BUCKET,
    payload: { productsToReturn, username }
});

export const postReturnBucketSuccess = (data) => ({
    type: POST_RETURN_BUCKET_SUCCESS,
    payload: { data },
});

export const postReturnBucketFail = (error) => ({
    type: POST_RETURN_BUCKET_FAIL,
    payload: error,
});

export const updateReturnBucket = (returnBucketId, value, column) => ({
    type: UPDATE_RETURN_BUCKET,
    payload: { returnBucketId, value, column }
})

export const updateReturnBucketSuccess = (data) => ({
    type: UPDATE_RETURN_BUCKET_SUCCESS,
    payload: data
})

export const updateReturnBucketFail = (error) => ({
    type: UPDATE_RETURN_BUCKET_FAIL,
    payload: error
})

export const updateReturnBucketItems = (value, product, return_bucket_id, column) => ({
    type: UPDATE_RETURN_BUCKET_ITEMS,
    payload: { value, product, return_bucket_id, column }
});

export const updateReturnBucketItemsSuccess = (data) => ({
    type: UPDATE_RETURN_BUCKET_ITEMS_SUCCESS,
    payload: data
});

export const updateReturnBucketItemsFail = (error) => ({
    type: UPDATE_RETURN_BUCKET_ITEMS_FAIL,
    payload: error
});

export const deleteReturnBucketItem = (product, returnBucketId) => ({
    type: DELETE_RETURN_BUCKET_ITEM,
    payload: { product, returnBucketId }
});

export const deleteReturnBucketItemSuccess = (id) => ({
    type: DELETE_RETURN_BUCKET_ITEM_SUCCESS,
    payload: id
});

export const deleteReturnBucketItemFail = (error) => ({
    type: DELETE_RETURN_BUCKET_ITEM_FAIL,
    payload: error
});

export const getReturnBucket = (username) => ({
    type: GET_RETURN_BUCKET,
    payload: username
});

export const postReturnInvoiceCreate = (username, returnBucketItems, returnTotal, returnQty) => ({
    type: POST_RETURN_INVOICE_CREATE,
    payload: { username, returnBucketItems, returnTotal, returnQty }
});

export const postReturnInvoiceCreateSuccess = () => ({
    type: POST_RETURN_INVOICE_CREATE_SUCCESS,
});

export const postReturnInvoiceCreateFail = (error) => ({
    type: POST_RETURN_INVOICE_CREATE_FAIL,
    payload: error
});

export const downloadReturnInvoice = (returnId) => ({
    type: DOWNLOAD_RETURN_INVOICE,
    payload: returnId
});

export const postReturnInvoiceApproval = (status, reject_reason, ship_method, admin_fee, returnId) => ({
    type: POST_RETURN_INVOICE_APPROVAL,
    payload: { returnId, reject_reason, ship_method, admin_fee, status }
});

export const postReturnInvoiceApprovalSuccess = () => ({
    type: POST_RETURN_INVOICE_APPROVAL_SUCCESS
});

export const postReturnInvoiceApprovalFail = (error) => ({
    type: POST_RETURN_INVOICE_APPROVAL_FAIL,
    payload: error
});

export const updateReturnInvoiceItemsWorksheet = (items, extra, returnId) => ({
    type: UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET,
    payload: { items, extra, returnId }
});

export const updateReturnInvoiceItemsWorksheetSuccess = (data) => ({
    type: UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET_SUCCESS,
    payload: data
});

export const updateReturnInvoiceItemsWorksheetFail = (error) => ({
    type: UPDATE_RETURN_INVOICE_ITEMS_WORKSHEET_FAIL,
    payload: error
});

export const updateReturnAdjustmentItemsApproval = (returnAdjustmentItems, status, returnAdjustmentId) => ({
    type: UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL,
    payload: { returnAdjustmentItems, status, returnAdjustmentId }
});

export const updateReturnAdjustmentItemsApprovalSuccess = () => ({
    type: UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL_SUCCESS,
});

export const updateReturnAdjustmentItemsApprovalFail = (error) => ({
    type: UPDATE_RETURN_ADJUSTMENT_ITEMS_APPROVAL_FAIL,
    payload: error
});

export const updateReturnInvoiceCreditIssue = (status, returnId) => ({
    type: UPDATE_RETURN_INVOICE_CREDIT_ISSUE,
    payload: { status, returnId }
});

export const updateReturnInvoiceCreditIssueSuccess = () => ({
    type: UPDATE_RETURN_INVOICE_CREDIT_ISSUE_SUCCESS,
});

export const updateReturnInvoiceCreditIssueFail = (error) => ({
    type: UPDATE_RETURN_INVOICE_CREDIT_ISSUE_FAIL,
    payload: error
});

export const donwloadReturnBatchTemplate = () => ({
    type: DOWNLOAD_RETURN_BATCH_TEMPLATE
});

export const deleteReturnTableItem = (rowId) => ({
    type: DELETE_RETURN_TABLE_ITEM,
    payload: rowId
})