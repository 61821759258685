import { Card, CardBody, Table } from "reactstrap";
import AccountOverviewBasicInfoTableRow from "./AccountOverviewBasicInfoTableRow";
import AccountOverviewPasswordReset from "./AccountOverviewPasswordReset";

function AccountOverviewBasicInfo({ userInfo }) {
    return (
        <Card className="mt-3">
            <CardBody>
                <Table className="text-center tableLayoutFixed" striped bordered>
                    <thead>
                        <tr>
                            <th colSpan={2}>Account Overview</th>
                        </tr>
                    </thead>
                    <tbody>
                        <AccountOverviewBasicInfoTableRow field={'Username'} fieldValue={userInfo.username} />
                        <AccountOverviewBasicInfoTableRow field={'First Name'} fieldValue={userInfo.firstname} />
                        <AccountOverviewBasicInfoTableRow field={'Last Name'} fieldValue={userInfo.lastname} />
                        <AccountOverviewBasicInfoTableRow field={'Company Name'} fieldValue={userInfo.company_name} />
                        <AccountOverviewBasicInfoTableRow field={'Email 1'} fieldValue={userInfo.email} />
                        <AccountOverviewBasicInfoTableRow field={'Email 2'} fieldValue={userInfo.email_secondary} />
                        <AccountOverviewBasicInfoTableRow field={'Email 3'} fieldValue={userInfo.email_tertiary} />
                        <AccountOverviewBasicInfoTableRow field={'Phone Number 1'} fieldValue={userInfo.phone_primary} />
                        <AccountOverviewBasicInfoTableRow field={'Phone Number 2'} fieldValue={userInfo.phone_secondary} />
                        <AccountOverviewBasicInfoTableRow field={'Phone Number 3'} fieldValue={userInfo.phone_tertiary} />
                        <AccountOverviewBasicInfoTableRow field={'Fax'} fieldValue={userInfo.fax} />
                        <tr>
                            <th colSpan={2}>Shipping Address</th>
                        </tr>
                        <AccountOverviewBasicInfoTableRow field={'Address'} fieldValue={userInfo.address} />
                        <AccountOverviewBasicInfoTableRow field={'City'} fieldValue={userInfo.city} />
                        <AccountOverviewBasicInfoTableRow field={'State/Province'} fieldValue={userInfo.state} />
                        <AccountOverviewBasicInfoTableRow field={'Postal Code'} fieldValue={userInfo.zip} />
                        <AccountOverviewBasicInfoTableRow field={'Country'} fieldValue={userInfo.country} />
                        <tr>
                            <th colSpan={2}>Your Account Manager</th>
                        </tr>
                        <AccountOverviewBasicInfoTableRow field={'Sales Representative'} fieldValue={`${userInfo.sales_firstname} ${userInfo.sales_lastname}`} />
                        <AccountOverviewBasicInfoTableRow field={'Email'} fieldValue={userInfo.sales_email} />
                        <AccountOverviewBasicInfoTableRow field={'Phone'} fieldValue={userInfo.sales_phone} />
                        {/* TODO: Allow customers to download their prices for varying product line in. separate files for each product line */}
                        <tr>
                            <th colSpan={2}>Your Prices</th>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Password Reset</th>
                        </tr>
                        <AccountOverviewPasswordReset username={userInfo.username} />
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    )
}

export default AccountOverviewBasicInfo;