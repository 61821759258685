import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Container } from 'reactstrap';

function ReturnPolicy() {

    document.title = "Return Goods Policy | Max Advanced Brakes - Prozone";

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Return Policy" pageTitle="Return Policy" linkTo={`/returnpolicy`} />
                    <h1 style={{color:'orange'}}>RETURN GOODS POLICY</h1>
                    <hr></hr>
                    <h4 style={{color:'orange'}}>Max Advanced Brakes Warranty </h4>
                    <ul>
                        <li>1 Year Warranty against manufacturer defects and cracking for all Brake Rotors</li>
                        <li>1 Year Warranty against manufacturer and quality defects for all Brake Pads.</li>
                        <li>Limited Lifetime Warranty against manufacturer and quality defects for all Wheel Bearings.</li>
                        <li>The warranty does not apply to any product that has been improperly installed,
misused, neglected, modified, machined or damaged accidentally.</li>
<li>Max Advanced Brakes will not be liable for any costs greater than the original purchase
price of the product nor expenses connected with the replacement of the product on
the vehicle.</li>
                    </ul>
                    <br></br><br></br>
                    <h4 style={{color:'orange'}}>Max Advanced Brakes Returns Terms & Conditions</h4>
                    <ul>
                        <li>All merchandise returned without prior approval will be discarded or returned to the
customer at customer’s cost.</li>
<li>All unlisted merchandise returned will be applied with 15% administrative fee.</li>
<li>For Defective Returns, <br></br><br></br>
- A limit of maximum of 4% of the previous year’s purchase value will be accepted
for returns older than 12 months from the customer’s sales date.<br></br><br></br>
- Credit will be issued only if the returned merchandise is evaluated as
defective by our technicians.<br></br><br></br></li>
<li>For New Returns,<br></br>
- A limit of maximum of 4% of the previous year’s purchase value will be accepted
for returns older than 12 months from the customer’s sales date.<br></br><br></br>
- Credit will be issued only if the returned merchandise is evaluated as
defective by our technicians.<br></br><br></br>
- Only goods purchased within 24 months will be accepted, with a limit of
maximum of 4% of the previous year’s purchase value.<br></br><br></br>
- No credit will be issued on discontinued products.<br></br><br></br>
- Credit will be issued after all returned merchandise is inspected.<br></br><br></br>
- A 10% restocking charge may be applied.<br></br><br></br>
- A 10% repackaging fee will be applied if the packaging of the returned
merchandise is not in resalable condition.</li>
                    </ul>
                </Container>
            </div>
        </>
    );
}

export default ReturnPolicy;