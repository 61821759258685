import { 
    ADD_DRAFT_FAIL,
    ADD_DRAFT_SUCCESS,
    API_RESPONSE_ERROR,
    API_RESPONSE_SUCCESS, 
    CREATE_NEW_ORDER_FAIL, 
    CREATE_NEW_ORDER_SUCCESS, 
    DELETE_DRAFT_FAIL, 
    DELETE_DRAFT_ITEMS_FAIL, 
    DELETE_DRAFT_ITEMS_SUCCESS, 
    DELETE_DRAFT_SUCCESS, 
    DOWNLOAD_DRAFT_TEMPLATE, 
    GET_DRAFT, 
    GET_DRAFTS,
    POST_DRAFT_UPDATE_FAIL,
    POST_DRAFT_UPDATE_SUCCESS,
    PREVIEW_ORDER_DRAFT,
    PREVIEW_ORDER_USERNAME,
    RESET_APP,
} from "./actionType";

const INIT_STATE = {
    drafts: [],
    isDraftsCreated: false,
    isDraftsDelete: false,
    error: {},
};

const Drafts = (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_APP:
            return INIT_STATE;
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_DRAFTS:
                    return {
                        ...state,
                        drafts: action.payload.data,
                        // draft: {},
                        isDraftsCreated: false,
                        isDraftsUpdated: false,
                        isDraftsDelete: false,
                        isDraftsSuccess: true,
                        isOrderCreated: false,
                        isDraftItemsDelete: false,
                        isDraftTemplateDownloaded: false,
                        draftTemplate: null,
                        error: {},
                    };
                case GET_DRAFT:
                    return {
                        ...state,
                        draft: action.payload.data,
                        isDraftsUpdated: false,
                        isDraftItemsDelete: false,
                    }
                case DOWNLOAD_DRAFT_TEMPLATE:
                    return {
                        ...state,
                        draftTemplate: action.payload.data,
                        isDraftTemplateDownloaded: true,
                    }
                default:
                    return { ...state };
            }
        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_DRAFTS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isDraftsCreated: false,
                        isDraftsUpdated: false
                    };
                case GET_DRAFT:
                    return {
                        ...state,
                        error: action.payload.error,
                        isDraftsSuccess: false,
                    }
                case DOWNLOAD_DRAFT_TEMPLATE:
                    return {
                        ...state,
                        error: action.payload.error,
                        isDraftTemplateDownloaded: false,
                    }
                default:
                    return { ...state };
            }

        case CREATE_NEW_ORDER_SUCCESS:
            return {
                ...state,
                draft: {},
                isOrderCreated: true
            }

        case ADD_DRAFT_SUCCESS:
            return {
                ...state,
                isDraftsCreated: true,
            }

        case POST_DRAFT_UPDATE_SUCCESS:
            return {
                ...state,
                draftDetails: action.payload.data,
                isDraftsUpdated: true,
                isDraftsUpdatedFail: false,
            }

        case DELETE_DRAFT_SUCCESS:
            return {
                ...state,
                drafts: state.drafts.filter(draft => draft.drafthash.toString() !== action.payload.toString()),
                isDraftsDelete: true,
                isDraftsDeleteFail: false,
                previewDraftItems: [],
                previewDraftUsername: '',
            }

        case DELETE_DRAFT_ITEMS_SUCCESS:
            state.draft.draftitems = [];
            state.draft.draft_total = 0.00;

            return {
                ...state,
                draft: state.draft,
                isDraftItemsDelete: true,
            }

        case CREATE_NEW_ORDER_FAIL:
            return {
                ...state,
                isOrderCreated: false,
            }

        case ADD_DRAFT_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case POST_DRAFT_UPDATE_FAIL:
            return {
                ...state,
                isDraftsUpdated: false,
                isDraftsUpdatedFail: true,
            }

        case DELETE_DRAFT_FAIL:
            return {
                ...state,
                isDraftsDelete: false,
                isDraftsDeleteFail: true,
            }

        case DELETE_DRAFT_ITEMS_FAIL:
            return {
                ...state,
                error: action.payload,
                isDraftItemsDelete: false,
            }

        case PREVIEW_ORDER_DRAFT:
            return {
                ...state,
                previewDraftItems: action.payload,
            }

        case PREVIEW_ORDER_USERNAME:
            return {
                ...state,
                previewDraftUsername: action.payload,
            }
                
        default:
            return { ...state };
    }
};

export default Drafts;