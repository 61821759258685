// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const RESET_APP = 'RESET_APP';

export const GET_ALLPRICES_DATA = "GET_ALLPRICES_DATA";
export const GET_USERS = "GET_USERS";
export const GET_SALES_REPRESENTATIVES = "GET_SALES_REPRESENTATIVES";

export const POST_GLOBAL_PRICES = "POST_GLOBAL_PRICES";

export const POST_GLOBAL_MAX = "POST_GLOBAL_MAX";
export const POST_GLOBAL_DISCOUNT = "POST_GLOBAL_DISCOUNT";

export const POST_SALES_REPRESENTATIVE = "POST_SALES_REPRESENTATIVE";
export const POST_SALES_REPRESENTATIVE_SUCCESS = "POST_SALES_REPRESENTATIVE_SUCCESS";
export const POST_SALES_REPRESENTATIVE_FAIL = "POST_SALES_REPRESENTATIVE_FAIL";

export const DELETE_SALES_REPRESENTATIVE = "DELETE_SALES_REPRESENTATIVE";
export const DELETE_SALES_REPRESENTATIVE_SUCCESS = "DELETE_SALES_REPRESENTATIVE_SUCCESS";
export const DELETE_SALES_REPRESENTATIVE_FAIL = "DELETE_SALES_REPRESENTATIVE_FAIL";

export const UPDATE_SALES_REPRESENTATIVE = "UPDATE_SALES_REPRESENTATIVE"
export const UPDATE_SALES_REPRESENTATIVE_SUCCESS = "UPDATE_SALES_REPRESENTATIVE_SUCCESS"
export const UPDATE_SALES_REPRESENTATIVE_FAIL = "UPDATE_SALES_REPRESENTATIVE_FAIL"

export const GET_MONTH_TEST = "GET_MONTH_TEST";
export const GET_MONTH_TEST_MONTH_YEAR = "GET_MONTH_TEST_MONTH_YEAR";
export const GET_STATEMENT_OVERDUE_SUMMARY = "GET_STATEMENT_OVERDUE_SUMMARY";