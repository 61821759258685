import React from 'react';

//import Scss
import './assets/scss/themes.scss';
import IdleTimer from './Components/Common/IdleTime';

//imoprt Route
import Route from './Routes';

function App() {
    return (
        <React.Fragment>
            <IdleTimer />
            <Route />
        </React.Fragment>
    );
}

export default App;
