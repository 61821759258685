import { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { CardBody, Row, Col, Card, Container, CardHeader, Button, Nav, NavItem, NavLink, TabContent, TabPane, } from "reactstrap";
import * as moment from "moment";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// User info
import { useProfile } from '../../../../Components/Hooks/UserHooks';
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../../../Components/Common/TableContainer";
import { FlatpickrSingleColumnFilter, SelectColumnFilter } from "../../../../Components/Common/filters";
import PDFRenderer from "../../../../Components/Common/PDFRenderer";
import Loader from "../../../../Components/Common/Loader";
import DownloadImage from "../../../../assets/images/download.png"

//Import actions
import {
    getInvoices as onGetInvoices,
    getFilteredInvoices as onGetFilteredInvoices,
    downloadInvoicePdf as onDownloadInvoicePdf,
    downloadPackingListPdf as onDownloadPackingListPdf,
} from "../../../../store/actions";

const Dispatch = () => {
    const { userProfile } = useProfile();
    const dispatch = useDispatch();
    const [pdfOrderId, setPdfOrderId] = useState(null);
    const [pdfPackingOrderId, setPdfPackingOrderId] = useState(null);
    const [invoiceToday, setInvoiceToday] = useState([]);
    const [invoiceAll, setInvoiceAll] = useState([]);
    const [invoicePreviousIncomplete, setInvoicePreviousIncomplete] = useState([]);
    const [invoicePlusOneDay, setInvoicePlusOneDay] = useState([]);
    const [invoicePlusTwoDay, setInvoicePlusTwoDay] = useState([]);
    const [invoicePlusThreeDay, setInvoicePlusThreeDay] = useState([]);
    const [invoiceShipped, setInvoiceShipped] = useState([]);

    // pdf modal
    const [openInvoicePdfModal, setOpenInvoicePdfModal] = useState(false);
    const closeInvoicePdfModal = () => setOpenInvoicePdfModal(false);

    const [openPackingListPdfModal, setOpenPackingListPdfModal] = useState(false);
    const closePackingListPdfModal = () => setOpenPackingListPdfModal(false);

    const { invoices, isInvoiceSuccess, invoicePdf, isInvoicePdfDownloaded, packingListPdf, isPackingListPdfDownloaded, error } = useSelector((state) => ({
        invoices: state.Invoice.invoices,
        isInvoiceSuccess: state.Invoice.isInvoiceSuccess,
        invoicePdf: state.Invoice.invoicePdf,
        isInvoicePdfDownloaded: state.Invoice.isInvoicePdfDownloaded,
        packingListPdf: state.Invoice.packingListPdf,
        isPackingListPdfDownloaded: state.Invoice.isPackingListPdfDownloaded,
        error: state.Invoice.error,
    }));

    const [currentActiveTab, setCurrentActiveTab] = useState("1");

    useEffect(() => {
        dispatch({ type: 'RESET_APP' });
    }, [dispatch])

    useEffect(() => {
        dispatch(onGetInvoices());
    }, [dispatch]);

    useEffect(() => {
        let currentDate = new Date().toLocaleDateString();
        let datePlusOne = new Date();
        let datePlusTwo = new Date();
        let datePlusThree = new Date();

        let tempToday = invoices.filter((item) => {
            if (item.requested_shipped_date && currentDate === new Date(item.requested_shipped_date.replace(/-/g, '/')).toLocaleDateString() && item.status === 'processing') {
                return true;
            }

            return false;
        });

        let tempAll = invoices.filter((item) => item.status === 'processing');

        let tempPreviousIncomplete = invoices.filter((item) => {
            if (item.requested_shipped_date && currentDate > new Date(item.requested_shipped_date.replace(/-/g, '/')).toLocaleDateString() && item.status === 'processing') {
                return true;
            }

            return false;
        });

        datePlusOne.setDate(datePlusOne.getDate() + 1);
        let tempPlusOneDay = invoices.filter((item) => {
            if (item.requested_shipped_date && datePlusOne.toLocaleDateString() === new Date(item.requested_shipped_date.replace(/-/g, '/')).toLocaleDateString() && item.status === 'processing') {
                return true;
            }

            return false;
        });

        datePlusTwo.setDate(datePlusTwo.getDate() + 2);
        let tempPlusTwoDay = invoices.filter((item) => {
            if (item.requested_shipped_date && datePlusTwo.toLocaleDateString() === new Date(item.requested_shipped_date.replace(/-/g, '/')).toLocaleDateString() && item.status === 'processing') {
                return true;
            }

            return false;
        });

        datePlusThree.setDate(datePlusThree.getDate() + 3);
        let tempPlusThreeDay = invoices.filter((item) => {
            if (item.requested_shipped_date && datePlusThree.toLocaleDateString() === new Date(item.requested_shipped_date.replace(/-/g, '/')).toLocaleDateString() && item.status === 'processing') {
                return true;
            }

            return false;
        });

        let tempShippedOrders = invoices.filter((item) => {
            if (item.status === 'shipped' || item.status === 'completed') {
                return true;
            }

            return false;
        })

        setInvoiceToday(tempToday);
        setInvoiceAll(tempAll);
        setInvoicePreviousIncomplete(tempPreviousIncomplete);
        setInvoicePlusOneDay(tempPlusOneDay);
        setInvoicePlusTwoDay(tempPlusTwoDay);
        setInvoicePlusThreeDay(tempPlusThreeDay);
        setInvoiceShipped(tempShippedOrders);
    }, [invoices]);

    useEffect(() => {
        if (isInvoicePdfDownloaded && pdfOrderId) {
            setOpenInvoicePdfModal(true);
        }
    }, [invoicePdf, isInvoicePdfDownloaded, pdfOrderId])

    useEffect(() => {
        if (isPackingListPdfDownloaded && pdfPackingOrderId) {
            setOpenPackingListPdfModal(true);
        }
    }, [packingListPdf, isPackingListPdfDownloaded, pdfPackingOrderId]);

    function changeTabContent(tabNum) {
        if (currentActiveTab !== tabNum) setCurrentActiveTab(tabNum);
    }

    function handleUpdatePage() {
        setInvoiceToday(null)
        dispatch(onGetInvoices());
    }

    const handleValidDate = date => {
        if (!date) return date
        const date1 = moment.utc(new Date(date)).format("Y-MM-DD");
        return date1;
    };

    // Checked All
    const checkedAll = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".invoiceCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    const handleDownloadInvoicePdf = useCallback((orderId) => {
        dispatch(onDownloadInvoicePdf(orderId));
        setPdfOrderId(orderId);
    }, [dispatch])

    const handleDownloadPackingListPdf = useCallback((orderId) => {
        dispatch(onDownloadPackingListPdf(orderId));
        setPdfPackingOrderId(orderId);
    }, [dispatch])

    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="invoiceCheckBox" value={cellProps.row.original._id} />;
                },
                id: '#',
            },
            {
                Header: "ORDER ID",
                accessor: "order_id",
                filterable: true,
                Cell: (cell) => {
                    return <Link to={`/warehouse/dispatch/${cell.row.original.order_id}`} className="fw-medium link-primary">{cell.row.original.order_id}</Link>;
                },
            },
            {
                Header: "COMPANY NAME",
                accessor: 'company_name',
                Filter: SelectColumnFilter,
                Cell: (cell) => (
                    <>
                        {cell.row.original.company_name}
                    </>
                )
            },
            {
                Header: "METHOD",
                accessor: "ship_method",
                filterable: false,
                Filter: SelectColumnFilter,
                Cell: (invoice) => (
                    <>
                        {invoice.row.original.ship_method}
                    </>
                ),
            },
            {
                Header: "PROVINCE/STATE",
                accessor: 'state',
                Filter: SelectColumnFilter,
                Cell: (cell) => (
                    <>
                        {cell.row.original.state}
                    </>
                )
            },
            {
                Header: 'INVOICE',
                filterable: false,
                Cell: (cell) => (
                    <>
                        <img className="cursorPointer" width="30" height="30" src={DownloadImage} alt="Invoice" onClick={() => handleDownloadInvoicePdf(cell.row.original.order_id)} />
                    </>
                )
            },
            {
                Header: 'PACKING LIST',
                filterable: false,
                Cell: (cell) => (
                    <>
                        <img className="cursorPointer" width="30" height="30" src={DownloadImage} alt="Packing List" onClick={() => handleDownloadPackingListPdf(cell.row.original.order_id)} />
                    </>
                )
            },
            {
                Header: "TOTAL",
                accessor: 'cost_final',
                Filter: '',
                Cell: (cell) => (
                    <>
                        ${cell.row.original.cost_final}
                    </>
                )
            },
            {
                Header: "DATE ORDERED",
                accessor: 'date_created',
                filterable: false,
                Filter: FlatpickrSingleColumnFilter,
                Cell: (invoice) => (
                    <>
                        {handleValidDate(invoice.row.original.date_created)}
                    </>
                ),
            },
            {
                Header: "DATE SHIP BY",
                accessor: 'requested_shipped_date',
                filterable: false,
                Filter: FlatpickrSingleColumnFilter,
                Cell: (invoice) => (
                    <>
                        {handleValidDate(invoice.row.original.requested_shipped_date)}
                    </>
                ),
            },
        ],
        [handleDownloadInvoicePdf, handleDownloadPackingListPdf]
    );

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />
    if (!userProfile.permissions.view_order_history) return <Redirect to="/not-authorized" />

    document.title = "Processing | Max Advanced Brakes - Prozone";

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Dispatch" pageTitle="Dispatch" linkTo={'/warehouse/dispatch'} />
                    {
                        openInvoicePdfModal && <PDFRenderer
                            fileUrl={URL.createObjectURL(invoicePdf)}
                            modalHeaderText={`Invoice ${pdfOrderId}`}
                            openPdfModal={openInvoicePdfModal}
                            closePdfModal={closeInvoicePdfModal} />
                    }
                    {
                        openPackingListPdfModal && <PDFRenderer
                            fileUrl={URL.createObjectURL(packingListPdf)}
                            modalHeaderText={`Packing List ${pdfPackingOrderId}`}
                            openPdfModal={openPackingListPdfModal}
                            closePdfModal={closePackingListPdfModal} />
                    }
                    <Card color={'dark'} outline>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Button onClick={handleUpdatePage}>Update Page</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Row>
                        <Col lg={12}>
                            <Card color={'dark'} outline id="invoices">
                                <CardHeader className="border-0">
                                    <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-0 flex-grow-1">Dispatch</h5>
                                    </div>
                                </CardHeader>

                                <CardBody className="pt-0">
                                    <Nav className="mt-3" tabs>
                                        <NavItem>
                                            <NavLink className={currentActiveTab === "1" ? "active" : ""} onClick={() => changeTabContent("1")}>All Dispatch Orders</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={currentActiveTab === "2" ? "active" : ""} onClick={() => changeTabContent("2")}>Todays Orders</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={currentActiveTab === "3" ? "active" : ""} onClick={() => changeTabContent("3")}>Previous Incomplete Orders</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={currentActiveTab === "4" ? "active" : ""} onClick={() => changeTabContent("4")}>1+ Days Orders</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={currentActiveTab === "5" ? "active" : ""} onClick={() => changeTabContent("5")}>2+ Days Orders</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={currentActiveTab === "6" ? "active" : ""} onClick={() => changeTabContent("6")}>3+ Days Orders</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={currentActiveTab === "7" ? "active" : ""} onClick={() => changeTabContent("7")}>Shipped Orders</NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={currentActiveTab}>
                                        <TabPane tabId={'1'}>
                                            <Card>
                                                <CardBody>
                                                    {(isInvoiceSuccess && invoiceAll) ? (
                                                        <TableContainer
                                                            columns={columns}
                                                            data={(invoiceAll || [])}
                                                            isGlobalFilter={false}
                                                            customPageSize={30}
                                                            className="custom-header-css"
                                                            divClass="table-responsive table-card"
                                                            tableClass="align-middle table-nowrap"
                                                            theadClass="text-muted"
                                                            thClass="text-muted"
                                                        />
                                                    ) : (<Loader error={error} />)}
                                                    <ToastContainer closeButton={false} />
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        <TabPane tabId={'2'}>
                                            <Card>
                                                <CardBody>
                                                    {(isInvoiceSuccess && invoiceToday) ? (
                                                        <TableContainer
                                                            columns={columns}
                                                            data={(invoiceToday || [])}
                                                            isGlobalFilter={false}
                                                            customPageSize={30}
                                                            className="custom-header-css"
                                                            divClass="table-responsive table-card"
                                                            tableClass="align-middle table-nowrap"
                                                            theadClass="text-muted"
                                                            thClass="text-muted"
                                                        />
                                                    ) : (<Loader error={error} />)}
                                                    <ToastContainer closeButton={false} />
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        <TabPane tabId={'3'}>
                                            <Card>
                                                <CardBody>
                                                    {(isInvoiceSuccess && invoicePreviousIncomplete) ? (
                                                        <TableContainer
                                                            columns={columns}
                                                            data={(invoicePreviousIncomplete || [])}
                                                            isGlobalFilter={false}
                                                            customPageSize={30}
                                                            className="custom-header-css"
                                                            divClass="table-responsive table-card"
                                                            tableClass="align-middle table-nowrap"
                                                            theadClass="text-muted"
                                                            thClass="text-muted"
                                                        />
                                                    ) : (<Loader error={error} />)}
                                                    <ToastContainer closeButton={false} />
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        <TabPane tabId={'4'}>
                                            <Card>
                                                <CardBody>
                                                    {(isInvoiceSuccess && invoicePlusOneDay) ? (
                                                        <TableContainer
                                                            columns={columns}
                                                            data={(invoicePlusOneDay || [])}
                                                            isGlobalFilter={false}
                                                            customPageSize={30}
                                                            className="custom-header-css"
                                                            divClass="table-responsive table-card"
                                                            tableClass="align-middle table-nowrap"
                                                            theadClass="text-muted"
                                                            thClass="text-muted"
                                                        />
                                                    ) : (<Loader error={error} />)}
                                                    <ToastContainer closeButton={false} />
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        <TabPane tabId={'5'}>
                                            <Card>
                                                <CardBody>
                                                    {(isInvoiceSuccess && invoicePlusTwoDay) ? (
                                                        <TableContainer
                                                            columns={columns}
                                                            data={(invoicePlusTwoDay || [])}
                                                            isGlobalFilter={false}
                                                            customPageSize={30}
                                                            className="custom-header-css"
                                                            divClass="table-responsive table-card"
                                                            tableClass="align-middle table-nowrap"
                                                            theadClass="text-muted"
                                                            thClass="text-muted"
                                                        />
                                                    ) : (<Loader error={error} />)}
                                                    <ToastContainer closeButton={false} />
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        <TabPane tabId={'6'}>
                                            <Card>
                                                <CardBody>
                                                    {(isInvoiceSuccess && invoicePlusThreeDay) ? (
                                                        <TableContainer
                                                            columns={columns}
                                                            data={(invoicePlusThreeDay || [])}
                                                            isGlobalFilter={false}
                                                            customPageSize={30}
                                                            className="custom-header-css"
                                                            divClass="table-responsive table-card"
                                                            tableClass="align-middle table-nowrap"
                                                            theadClass="text-muted"
                                                            thClass="text-muted"
                                                        />
                                                    ) : (<Loader error={error} />)}
                                                    <ToastContainer closeButton={false} />
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        <TabPane tabId={'7'}>
                                            <Card>
                                                <CardBody>
                                                    {(isInvoiceSuccess && invoiceShipped) ? (
                                                        <TableContainer
                                                            columns={columns}
                                                            data={(invoiceShipped || [])}
                                                            isGlobalFilter={false}
                                                            customPageSize={30}
                                                            className="custom-header-css"
                                                            divClass="table-responsive table-card"
                                                            tableClass="align-middle table-nowrap"
                                                            theadClass="text-muted"
                                                            thClass="text-muted"
                                                        />
                                                    ) : (<Loader error={error} />)}
                                                    <ToastContainer closeButton={false} />
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Dispatch;