// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";

// Get Invoice
export const GET_INVOICES = "GET_INVOICES";
export const GET_FILTERED_INVOICES = "GET_FILTERED_INVOICES";
export const GET_ARCHIVED_INVOICES = "GET_ARCHIVED_INVOICES";
export const GET_FILTERED_ARCHIVED_INVOICES = "GET_FILTERED_ARCHIVED_INVOICES";
export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_DETAILS = "GET_INVOICE_DETAILS";
export const GET_INVOICE_DETAILS_PRODUCT_INTERCHANGE = "GET_INVOICE_DETAILS_PRODUCT_INTERCHANGE";

// Post Invoice Details Product Quantity
export const POST_INVOICE_DETAILS_PRODUCT_QUANTITY = "POST_INVOICE_DETAILS_PRODUCT_QUANTITY";
export const POST_INVOICE_DETAILS_PRODUCT_QUANTITY_SUCCESS = "POST_INVOICE_DETAILS_PRODUCT_QUANTITY_SUCCESS";
export const POST_INVOICE_DETAILS_PRODUCT_QUANTITY_FAIL = "POST_INVOICE_DETAILS_PRODUCT_QUANTITY_FAIL";

// Download invoice pdf
export const DOWNLOAD_INVOICE_PDF = "DOWNLOAD_INVOICE_PDF";
export const DOWNLOAD_PACKING_LIST_PDF = "DOWNLOAD_PACKING_LIST_PDF";

// Post Ship Order
export const POST_SHIP_ORDER = "POST_SHIP_ORDER";
export const POST_SHIP_ORDER_SUCCESS = "POST_SHIP_ORDER_SUCCESS";
export const POST_SHIP_ORDER_FAIL = "POST_SHIP_ORDER_FAIL";

// Update Order Status
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAIL = "UPDATE_ORDER_STATUS_FAIL";

// Post Cancel Order
export const POST_CANCEL_ORDER = "POST_CANCEL_ORDER";
export const POST_CANCEL_ORDER_SUCCESS = "POST_CANCEL_ORDER_SUCCESS";
export const POST_CANCEL_ORDER_FAIL = "POST_CANCEL_ORDER_FAIL";

// Get Old Orders Order Items
export const GET_OLD_ORDERS_ORDER_ITEMS = "GET_OLD_ORDERS_ORDER_ITEMS";

// Get Reformat Order Items
export const GET_REFORMAT_ORDERITEMS = "GET_REFORMAT_ORDERITEMS";