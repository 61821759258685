import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";

import { useProfile } from "../../../Components/Hooks/UserHooks";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AccountOverviewBasicInfo from "../../../Components/Account/AccountOverviewBasicInfo";

// actions
import {
    getCustomerInformation as onGetCustomerInformation,
} from "../../../store/actions";
import GroupManagement from "../../../Components/Account/GroupManagement";

const AccountOverview = () => {
    const dispatch = useDispatch();
    const { userProfile } = useProfile();
    const [accountInfo, setAccountInfo] = useState(null);
    const [currentActiveTab, setCurrentActiveTab] = useState("1");
    const { userInfo, isDeleteUserSuccess, error } = useSelector((state) => ({
        userInfo: state.Customer.userInfo,
        isDeleteUserSuccess: state.Customer.isDeleteUserSuccess,
        error: state.Customer.error
    }));
    
    let location = useLocation();

    useEffect(() => {
        setAccountInfo(null);
        dispatch({ type: 'RESET_APP' });
    }, [dispatch])

    useEffect(() => {
        let username = userProfile.username;
        dispatch(onGetCustomerInformation(username));
    }, [dispatch, location, userProfile])

    useEffect(() => {
        if (userInfo && Object.keys(userInfo).length !== 0) setAccountInfo(userInfo);
    }, [userInfo])

    useEffect(() => {
        if (isDeleteUserSuccess) console.log('User deleted')
    }, [isDeleteUserSuccess])

    function changeTabContent(tabNum) {
        if (currentActiveTab !== tabNum) setCurrentActiveTab(tabNum);
    }

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Account Overview" pageTitle="Account Overview" linkTo={`/account-overview`} />
                    <Row>
                        <Col lg={12}>
                            {(userInfo && accountInfo) ? (
                                <Card>
                                    <CardBody>
                                        <Nav className="mt-3" tabs>
                                            <NavItem>
                                                <NavLink className={currentActiveTab === "1" ? "active" : ""} onClick={() => changeTabContent("1")}>Account Overview</NavLink>
                                            </NavItem>
                                            {(userProfile.permissions.group_management) ? (
                                                <NavItem>
                                                    <NavLink className={currentActiveTab === "2" ? "active" : ""} onClick={() => changeTabContent("2")}>Group Management</NavLink>
                                                </NavItem>
                                            ) : (null)}
                                        </Nav>
                                        <TabContent activeTab={currentActiveTab}>
                                            <TabPane tabId={'1'}>
                                                <AccountOverviewBasicInfo userInfo={userInfo} />
                                            </TabPane>
                                            {(userProfile.permissions.group_management) ? (
                                                <TabPane tabId={'2'}>
                                                    <GroupManagement username={userInfo.username} userGroupId={userInfo.user_group_id} companyName={userInfo.company_name} />
                                                </TabPane>
                                            ) : (null)}
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            ) : (null)}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default AccountOverview;