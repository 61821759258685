import Select from "react-select";
import { Label } from "reactstrap";

function NewUserSelectRow({ field, fieldValue, options, property, newUserInfo, setNewUserInfo }) {
    return (
        <>
            <Label>{field} <span className="text-danger">*</span></Label>
            <Select
                options={options}
                value={options.filter(option => option.value === newUserInfo[property])}
                onChange={(e) => setNewUserInfo({ ...newUserInfo, [property]: e.value })}
            />
        </>
    )
}

export default NewUserSelectRow;