import {Label, Input} from 'reactstrap';

function RegistrationOptionalInput(props) {
    return (
        <fieldset>
            <Label>{props.label}</Label>
            <Input type={props.type} name={props.name} value={props.value} min={props.min} max={props.max} onChange={(e) => props.setNewUser({...props.newUser, [props.name]: e.target.value})} />
        </fieldset>
    )
}

export default RegistrationOptionalInput;