import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD, POST_RESET_PASSWORD } from "./actionTypes";
import { 
    userForgetPasswordSuccess, 
    userForgetPasswordError, 
    postResetPasswordFail,
    postResetPasswordSuccess
} from "./actions";

//Include Both Helper File with needed methods
import {
    postForgotPassword as postForgotPasswordApi,
    postResetPassword as postResetPasswordApi
} from "../../../helpers/backend_helper";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
    try {
        const response = yield call(postForgotPasswordApi, user);
        if (response) {
            yield put(userForgetPasswordSuccess("Check the email inbox for the reset link and verification code."));
        }
    } catch (error) {
        yield put(userForgetPasswordError("If there's an account registered to that email address, a password reset email will be seen in your inbox."));
    }
}

function* postResetPassword({ payload: { code, hash, password } }) {
    try {
        const response = yield call(postResetPasswordApi, code, hash, password);
        if (response) {
            yield put(postResetPasswordSuccess('User password is updated.'));
        }
    } catch (error) {
        yield put(postResetPasswordFail(error));
    }
}

export function* watchUserPasswordForget() {
    yield takeEvery(FORGET_PASSWORD, forgetUser);
}

export function* watchPostResetPassword() {
    yield takeEvery(POST_RESET_PASSWORD, postResetPassword);
}

function* forgetPasswordSaga() {
    yield all([
        fork(watchUserPasswordForget),
        fork(watchPostResetPassword),
    ]);
}

export default forgetPasswordSaga;
