export const downloadFiles = (data, title, type) => {
    const blob = new Blob([data], { type: type });
    const blobUrl = window.URL.createObjectURL(blob);

    //Download files except pdf type
    if(type !== 'application/pdf'){
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', title,);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    // Open pdf file in new tab
    if(type === 'application/pdf'){
        // Check for Internet Explorer
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {

            window.navigator.msSaveOrOpenBlob(blob, title);
        } else {

            const iframe = document.createElement('iframe');
            iframe.src = blobUrl;
            iframe.width = '100%';
            iframe.height = '100%';
            iframe.style.border = 'none';
 
            const newWindow = window.open('', '_blank');
            newWindow.document.body.appendChild(iframe);
            newWindow.document.title = title;
        }
    }
}