import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Container, Button, Label } from "reactstrap";
import classnames from "classnames";

//import Components
import { useProfile } from '../../../../Components/Hooks/UserHooks';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import GlobalPricing from '../../../../Components/Administrator/GlobalPricing';
import GlobalSettingsMaxQuantity from '../../../../Components/Administrator/GlobalSettingsMaxQuantity';
import GlobalSettingsItemDiscounts from '../../../../Components/Administrator/GlobalSettingsItemDiscounts';
import GlobalSettingsExclusions from '../../../../Components/Administrator/GlobalSettingsExclusions';
import GlobalSettingsSalesRepresentatives from '../../../../Components/Administrator/GlobalSettingsSalesRepresentatives';
import PromotionsAndCouponGroupManager from '../../../../Components/Administrator/PromotionsAndCouponGroupManager';
import PromotionAndCouponsAccessoryPromotion from '../../../../Components/Administrator/PromotionAndCouponsAccessoryPromotion';

import {
    getOldOrdersOrderitems as onGetOldOrdersOrderitems,
    getReformatOrderItems as onGetReformatOrderItems,
    getUsers as onGetUsers,
    getMonthTest as onGetMonthTest,
    getMonthTestMonthYear as onGetMonthTestMonthYear,
    getStatementOverdueSummary as onGetStatementOverdueSummary,
    getUpdateUserCreditBalances as onGetUpdateUserCreditBalances,
} from "../../../../store/actions";

const GlobalSettings = () => {
    const dispatch = useDispatch();
    const { userProfile } = useProfile();
    const currentYear = new Date().getFullYear().toString();
    const startYear = 2018;
    const currentMonth = ('0'+ (new Date().getMonth() + 1)).slice(-2);
    const [monthYearStatement, setMonthYearStatement] = useState({ month: currentMonth, year: currentYear });
    const [returnText,setReturnText]=useState('');
    let years = [];
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    const { users } = useSelector(state => ({
        users: state.GlobalSettings.users,
    }));

    const [activeTab, setactiveTab] = useState((userProfile.access !== 1) ? ("2") : ("1"));
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };
    
    const handleGetUsers = useCallback(() => {
        dispatch(onGetUsers());
    }, [dispatch])


    useEffect(() => {
        handleGetUsers();
    }, [handleGetUsers])

    function handleMonthTest() {
        dispatch(onGetMonthTest())
    }

    function handleMonthTestByMonth(e) {
        e.preventDefault();
        dispatch(onGetMonthTestMonthYear(monthYearStatement))
    }

    function handleReformatOrderitems() {
        dispatch(onGetReformatOrderItems());
    }

    function handleGenerateStatementOverdueSummary() {
        dispatch(onGetStatementOverdueSummary());
    }

    function handleUpdateUserCreditbalances() {
        let x=dispatch(onGetUpdateUserCreditBalances());//.then(res=>console.log(res));
        console.log(x);
    }

    function handleGetOldOrdersOrderitems() {
        dispatch(onGetOldOrdersOrderitems());
    }

    for (let i = 0; startYear + i <= currentYear; i++) {
        years.push(startYear + i);
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Global Settings" pageTitle="Global Settings" linkTo={'/administrator/globalsettings'} />
                    <Row>
                        <Col xxl={12}>
                            <Card outline color="warning">
                                <CardBody>
                                    <Nav tabs className="nav-tabs mb-3">
                                        {(userProfile.access === 1) ? (
                                            <NavItem>
                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "1", })} onClick={() => { toggle("1"); }} >
                                                    Pricing
                                                </NavLink>
                                            </NavItem>
                                        ) : (null)}
                                        {(userProfile.access === 1) ? (
                                            <NavItem>
                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "2", })} onClick={() => { toggle("2"); }} >
                                                    Category Discounts
                                                </NavLink>
                                            </NavItem>
                                        ) : (null)}

                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "3", })} onClick={() => { toggle("3"); }} >
                                                Group Manager
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "4", })} onClick={() => { toggle("4"); }} >
                                                Accessories
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "5", })} onClick={() => { toggle("5"); }} >
                                                Exclusions
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "6", })} onClick={() => { toggle("6"); }} >
                                                Max Quantity
                                            </NavLink>
                                        </NavItem>
                                        
                                        
                                        {(userProfile.access === 1) ? (
                                            <NavItem>
                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "7", })} onClick={() => { toggle("7"); }} >
                                                    Sales Representatives
                                                </NavLink>
                                            </NavItem>
                                        ) : (null)}
                                        <NavItem>
                                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "8", })} onClick={() => { toggle("8"); }} >
                                                Test
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <div className="tab-content">
                                        <TabContent activeTab={activeTab} className="text-muted">
                                            {(userProfile.access === 1) ? (
                                                <TabPane tabId="1" id="pricing">
                                                    <GlobalPricing />
                                                </TabPane>
                                            ) : (null)}

                                            {(userProfile.access === 1) ? (
                                                <TabPane tabId="2" id="item-discount">
                                                    <GlobalSettingsItemDiscounts users={users} />
                                                </TabPane>
                                            ) : (null)}

                                            <TabPane tabId="3" id="exclusions">
                                                <PromotionsAndCouponGroupManager />
                                            </TabPane>

                                            <TabPane tabId="4" id="max-quantity">
                                                <PromotionAndCouponsAccessoryPromotion />
                                            </TabPane>
                                            

                                            <TabPane tabId="5" id="exclusions">
                                                <GlobalSettingsExclusions username={'all'} />
                                            </TabPane>

                                            <TabPane tabId="6" id="max-quantity">
                                                <GlobalSettingsMaxQuantity users={users} />
                                            </TabPane>

                                            {(userProfile.access === 1) ? (
                                                <TabPane tabId="7" id="sales-representatives">
                                                    <GlobalSettingsSalesRepresentatives users={users} />
                                                </TabPane>
                                            ) : (null)}

                                            <TabPane tabId="8" id="test">
                                                <Row className="mb-5">
                                                    <Col>
                                                        <Label>Statement for Previous Month:</Label>{' '}
                                                        <Button onClick={handleMonthTest}>Monthly Statement Test</Button>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-5">
                                                    <Col>
                                                        <form onSubmit={handleMonthTestByMonth}>
                                                            <Label>Statement for current Month:</Label>{' '}
                                                            <select value={monthYearStatement.year} onChange={(e) => setMonthYearStatement({ ...monthYearStatement, year: e.target.value })}>
                                                                {(years && years.length) ? (
                                                                    years.map((year, index) => <option key={index} value={year}>{year}</option>)
                                                                ) : (null)}
                                                            </select>
                                                            <select value={monthYearStatement.month} onChange={(e) => setMonthYearStatement({ ...monthYearStatement, month: e.target.value })}>
                                                                {(months && months.length) ? (
                                                                    months.map((month, index) => <option key={index} value={('0'+ (index + 1)).slice(-2)}>{month}</option>)
                                                                ) : (null)}
                                                            </select>
                                                            <Button type="submit">Submit Monthly Statement</Button>
                                                        </form>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <Button onClick={handleReformatOrderitems}>Reformat orderitems</Button>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <Button onClick={handleGenerateStatementOverdueSummary}>Statement Overdue Summary</Button>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col>
                                                        <Button onClick={handleGetOldOrdersOrderitems}>Old Orders Orderitems</Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Button onClick={handleUpdateUserCreditbalances}>User Credit Balance</Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                    <Label>{returnText}</Label>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
};
export default GlobalSettings;