import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import BasicInfoAccountPanel from "../../../../Components/Account/BasicInfoAccountPanel";
import AdminInfoAccountPanel from "../../../../Components/Account/AdminInfoAccountPanel";
import GroupManagement from "../../../../Components/Account/GroupManagement";
import ProductGroupsAccountPanel from "../../../../Components/Account/ProductGroupsAccountPanel";
import { useProfile } from "../../../../Components/Hooks/UserHooks";

// actions
import {
    getCustomerInformation as onGetCustomerInformation,
    getSalesRepresentatives as onGetSalesRepresentatives,
    updateCustomerInformation as onUpdateCustomerInformation,
} from "../../../../store/actions";

const Account = () => {
    let location = useLocation();
    const dispatch = useDispatch();
    const { userProfile } = useProfile()
    const [accountInfo, setAccountInfo] = useState(null);
    
    const { userInfo, isDeleteUserSuccess, isUpdateCustomerInfoSuccess, isUpdateCustomerInfoFail, salesRepresentatives, error } = useSelector((state) => ({
        userInfo: state.Customer.userInfo,
        isUpdateCustomerInfoSuccess: state.Customer.isUpdateCustomerInfoSuccess,
        isUpdateCustomerInfoFail: state.Customer.isUpdateCustomerInfoFail,
        isDeleteUserSuccess: state.Customer.isDeleteUserSuccess,
        salesRepresentatives: state.GlobalSettings.salesRepresentatives,
        error: state.Customer.error
    }));

    const [currentActiveTab, setCurrentActiveTab] = useState("1");

    useEffect(() => {
        setAccountInfo(null);
        dispatch({ type: 'RESET_APP' });
    }, [dispatch])

    useEffect(() => {
        let username = location.pathname.split('/')[3];
        dispatch(onGetCustomerInformation(username));
        dispatch(onGetSalesRepresentatives())
    }, [dispatch, location])

    useEffect(() => {
        if (userInfo && Object.keys(userInfo).length !== 0) setAccountInfo(userInfo);
    }, [userInfo])

    useEffect(() => {
        if (isUpdateCustomerInfoSuccess) alert('Customer Updated');
        if (isUpdateCustomerInfoFail) alert('Customer Failed to update');
    }, [userInfo, isUpdateCustomerInfoSuccess, isUpdateCustomerInfoFail])

    useEffect(() => {
        if (isDeleteUserSuccess) console.log('User deleted')
    }, [isDeleteUserSuccess])

    function changeTabContent(tabNum) {
        if (currentActiveTab !== tabNum) setCurrentActiveTab(tabNum);
    }

    function handleSubmitModifiedUserInfo() {
        dispatch(onUpdateCustomerInformation(accountInfo));
    }

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Account" pageTitle="Account Settings" linkTo={`/administrator/account-management`} />
                    <Row>
                        <Col lg={12}>
                            {(userInfo && accountInfo) ? (
                                <Card>
                                    <CardBody>
                                        <Button color={"danger"} className="float-end">Delete Account</Button>
                                        <Button color={"max"} className="float-end me-3" onClick={handleSubmitModifiedUserInfo}>Save Changes</Button>
                                        <Nav className="mt-3" tabs>
                                            <NavItem>
                                                <NavLink className={currentActiveTab === "1" ? "active" : ""} onClick={() => changeTabContent("1")}>Basic Info</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={currentActiveTab === "2" ? "active" : ""} onClick={() => changeTabContent("2")}>Admin Info</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={currentActiveTab === "3" ? "active" : ""} onClick={() => changeTabContent("3")}>Product Groups</NavLink>
                                            </NavItem>
                                            {(userProfile.permissions.group_management) ? (
                                                <NavItem>
                                                    <NavLink className={currentActiveTab === "4" ? "active" : ""} onClick={() => changeTabContent("4")}>Account Access</NavLink>
                                                </NavItem>
                                            ) : (null)}
                                        </Nav>
                                        <TabContent activeTab={currentActiveTab}>
                                            <TabPane tabId={'1'}>
                                                <BasicInfoAccountPanel accountInfo={accountInfo} setAccountInfo={setAccountInfo} userInfo={userInfo} salesRepresentatives={salesRepresentatives} />
                                            </TabPane>
                                            <TabPane tabId={'2'}>
                                                <AdminInfoAccountPanel accountInfo={accountInfo} setAccountInfo={setAccountInfo} userInfo={userInfo} />
                                            </TabPane>
                                            <TabPane tabId={'3'}>
                                                <ProductGroupsAccountPanel userInfo={userInfo} />
                                            </TabPane>
                                            {(userProfile.permissions.group_management) ? (
                                                <TabPane tabId={'4'}>
                                                    <GroupManagement username={userInfo.username} userGroupId={userInfo.user_group_id} companyName={userInfo.company_name} />
                                                </TabPane>
                                            ) : (null)}
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            ) : (null)}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Account;