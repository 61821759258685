import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { 
    postSystemMessageFail,
    postSystemMessageSuccess,
    systemMessagesApiResponseError, 
    systemMessagesApiResponseSuccess
} from "./action";

import { 
    GET_SYSTEM_MESSAGES, 
    GET_SYSTEM_MESSAGES_TODAY, 
    POST_SYSTEM_MESSAGE 
} from "./actionType";

import {
    getSystemMessages as getSystemMessagesApi,
    getSystemMessagesToday as getSystemMessagesTodayApi,
    postSystemMessage as postSystemMessageApi,
} from "../../../helpers/backend_helper";

function* getSystemMessages() {
    try {
        const response = yield call(getSystemMessagesApi);
        yield put(systemMessagesApiResponseSuccess(GET_SYSTEM_MESSAGES, response));
    } catch (error) {
        yield put(systemMessagesApiResponseError(GET_SYSTEM_MESSAGES, error))
    }
}

function* getSystemMessagesToday() {
    try {
        const response = yield call(getSystemMessagesTodayApi);
        yield put(systemMessagesApiResponseSuccess(GET_SYSTEM_MESSAGES_TODAY, response));
    } catch (error) {
        yield put(systemMessagesApiResponseError(GET_SYSTEM_MESSAGES_TODAY, error))
    }
}

function* postSystemMessage({ payload: system_message }) {
    try {
        yield call(postSystemMessageApi, { ...system_message });
        yield put(postSystemMessageSuccess());
    } catch (error) {
        yield put(postSystemMessageFail(error));
    }
}

export function* watchGetSystemMessages() {
    yield takeEvery(GET_SYSTEM_MESSAGES, getSystemMessages);
}

export function* watchGetSystemMessagesToday() {
    yield takeEvery(GET_SYSTEM_MESSAGES_TODAY, getSystemMessagesToday);
}

export function* watchPostSystemMessage() {
    yield takeEvery(POST_SYSTEM_MESSAGE, postSystemMessage);
}

function* systemMessagesSaga() {
    yield all([
        fork(watchGetSystemMessages),
        fork(watchGetSystemMessagesToday),
        fork(watchPostSystemMessage),
    ]);
}

export default systemMessagesSaga;