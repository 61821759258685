import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Row, Table } from "reactstrap";
import Flatpickr from "react-flatpickr";
import * as moment from "moment";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";

import { 
    getSystemMessages as onGetSystemMessages,
    postSystemMessage as onPostSystemMessage
} from "../../../../store/actions";

function SystemMessages() {
    const dispatch = useDispatch();
    const [systemMessage, setSystemMessage] = useState({ system_message: '', start_time: new Date(), end_time: new Date() });

    const { systemMessages, isPostSystemMessageSuccess, isPostSystemMessageFail } = useSelector((state) => ({
        systemMessages: state.SystemMessages.systemMessages,
        isPostSystemMessageSuccess: state.SystemMessages.isPostSystemMessageSuccess,
        isPostSystemMessageFail: state.SystemMessages.isPostSystemMessageFail, 
    }))

    useEffect(() => {
        dispatch(onGetSystemMessages());
    }, [dispatch, isPostSystemMessageSuccess])

    useEffect(() => {
        if (isPostSystemMessageSuccess) alert('System Message created');
        if (isPostSystemMessageFail) alert('System Message failed to be created');
    }, [isPostSystemMessageSuccess, isPostSystemMessageFail])

    const handleValidDate = (date, time) => {
        const date1 = moment(new Date(date)).format(`Y-MM-DD ${time}`);
        return date1;
    };

    const handleValidDateColumn = (date, time, column) => {
        const date1 = moment(new Date(date)).format(`Y-MM-DD ${time}`);
        setSystemMessage({ ...systemMessage, [column]: date1});
    };

    function handleSumbitNewSystemMessage(e) {
        e.preventDefault();

        if (!systemMessage.system_message || !systemMessage.start_time || !systemMessage.end_time) {
            alert("System message must have a message, start time and end time");
            return;
        }

        let formattedSystemMessage = systemMessage;
        
        formattedSystemMessage.start_time = handleValidDate(formattedSystemMessage.start_time, '00:00:00');
        formattedSystemMessage.end_time = handleValidDate(formattedSystemMessage.end_time, '23:59:59');

        dispatch(onPostSystemMessage(formattedSystemMessage));
        setSystemMessage({ system_message: '', start_time: new Date(), end_time: new Date() });
    }

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="System Messages" pageTitle="System Messages" linkTo={`/administrator/system-messages`} />
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <h5>New System Message</h5>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={(e) => handleSumbitNewSystemMessage(e)}>
                                    <Row className="mb-3">
                                        <Col>
                                            <Label>System Message</Label>
                                            <Input type="textarea" value={systemMessage.system_message} onChange={(e) => setSystemMessage({ ...systemMessage, system_message: e.target.value })} />
                                        </Col>
                                        <Col>
                                            <Label>Start Time:</Label>
                                            <Flatpickr className="form-control" value={systemMessage.start_time} options={{ minDate: "today", dateFormat: "Y-m-d" }} onChange={(e) => handleValidDateColumn(e[0], '00:00:00', 'start_time')} />
                                        </Col>
                                        <Col>
                                            <Label>End Time:</Label>
                                            <Flatpickr className="form-control" value={systemMessage.end_time} options={{ minDate: "today", dateFormat: "Y-m-d" }} onChange={(e) => handleValidDateColumn(e[0], '23:59:59', 'end_time')} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button color={'max'} type={'submit'}>Submit</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>System Message</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Date Created</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(systemMessages.length) ? (
                                            systemMessages.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.system_message}</td>
                                                        <td>{item.start_time}</td>
                                                        <td>{item.end_time}</td>
                                                        <td>{item.date_created}</td>
                                                    </tr>
                                                )
                                            })
                                        ) : (null)}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SystemMessages;