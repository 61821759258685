import {Label, Input} from 'reactstrap';

function RegistrationSelectInput(props) {
    return (
        <fieldset className={props.class}>
            <Label>{props.label}</Label>
            <Input type="select" id={props.id} name={props.name} value={props.value} onChange={e => props.setNewUser({...props.newUser, [props.name]: e.target.value})}>
                <option value=""></option>
                <option value={1}>Yes</option>
                <option value={0}>No</option>
            </Input>
        </fieldset>
    )
}

export default RegistrationSelectInput;