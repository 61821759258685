import { useCallback, useState, useEffect, useMemo } from "react";
import { Link, Redirect } from "react-router-dom";
import { CardBody, Row, Col, Card, Container, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Input, Button, } from "reactstrap";
import * as moment from "moment";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// User info
import { useProfile } from '../../../../Components/Hooks/UserHooks';

import Loader from "../../../../Components/Common/Loader";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../../../Components/Common/TableContainer";
import { FlatpickrSingleColumnFilter, SelectColumnFilter } from "../../../../Components/Common/filters";
import PDFRenderer from "../../../../Components/Common/PDFRenderer";
import DownloadImage from '../../../../assets/images/download.png';

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import {
    downloadCreditInvoice as onDownloadCreditInvoice,
    getCredits as onGetCredits,
    getCreditsFiltered as onGetCreditsFiltered,
} from "../../../../store/actions";

const CreditHistory = () => {
    const { userProfile } = useProfile();
    const dispatch = useDispatch();
    const [credit, setCredit] = useState(null);
    const [pdfCreditId, setPdfCreditId] = useState(null);
    const [invoiceFilter, setInvoiceFilter] = useState({ product: '' });
    const [isLoading, setIsLoading] = useState(false);
    // pdf modal
    const [openPdfModal, setOpenPdfModal] = useState(false);
    const closePdfModal = () => setOpenPdfModal(false);

    const { credits, isCreditSuccess, creditInvoicePdf, isCreditInvoiceDownloaded, error } = useSelector((state) => ({
        credits: state.Credit.credits,
        isCreditSuccess: state.Credit.isCreditSuccess,
        creditInvoicePdf: state.Credit.creditInvoicePdf,
        isCreditInvoiceDownloaded: state.Credit.isCreditInvoiceDownloaded,
        error: state.Credit.error,
    }));

    useEffect(() => {
        dispatch({ type: "RESET_APP" });
    }, [dispatch])

    useEffect(() => {
        setIsLoading(true);
        dispatch(onGetCredits());
    }, [dispatch]);

    useEffect(() => {
        setCredit(credits);
        setIsLoading(false);
    }, [credits]);

    useEffect(() => {
        if (isCreditInvoiceDownloaded && pdfCreditId) {
            setOpenPdfModal(true);
        }
    }, [creditInvoicePdf, pdfCreditId, isCreditInvoiceDownloaded])

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("Y-MM-DD");
        return date1;
    };

    function handleFilterInvoices(e) {
        e.preventDefault();
        setIsLoading(true);
        dispatch(onGetCreditsFiltered(invoiceFilter));
    }

    function handleClearFilter() {
        setIsLoading(true);
        setInvoiceFilter({ product: '' });
        dispatch(onGetCredits());
    }

    // Checked All
    const checkedAll = () => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".invoiceCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
    };

    const handleDownloadCreditInvoicePdf = useCallback((creditId) => {
        dispatch(onDownloadCreditInvoice(creditId));
        setPdfCreditId(creditId);
    }, [dispatch])

    // Column
    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="invoiceCheckBox" value={cellProps.row.original._id} />;
                },
                id: '#',
            },
            {
                Header: "CREDIT ID",
                accessor: "id",
                filterable: true,
                Cell: (cell) => {
                    return <Link to={`/history/credit-history/${cell.row.original.id}`} className="fw-medium link-primary">{cell.row.original.id}</Link>;
                },
            },
            {
                Header: 'CREDIT INVOICE',
                filterable: false,
                Cell: (cell) => (
                    <>
                        <img className="cursorPointer" width="30" height="30" src={DownloadImage} alt="Invoice" onClick={() => handleDownloadCreditInvoicePdf(cell.row.original.id)} />
                    </>
                )
            },
            {
                Header: "COMPANY NAME",
                accessor: (userProfile.access !== 5) ? ('company_name') : (null),
                Filter: SelectColumnFilter,
                Cell: (invoice) => (
                    <>
                        <div className="d-flex align-items-center">
                            {invoice.row.original.company_name}
                        </div>
                    </>
                ),
            },
            {
                Header: "DATE",
                accessor: 'date_created',
                filterable: false,
                Filter: FlatpickrSingleColumnFilter,
                // filter: 'between',
                Cell: (invoice) => (
                    <>
                        {handleValidDate(invoice.row.original.date_created)}
                        {/* ,{" "} */}
                        {/* <small className="text-muted">{handleValidTime(invoice.row.original.date_created)}</small> */}
                    </>
                ),
            },
            {
                Header: "TOTAL",
                filterable: false,
                Cell: (invoice) => (
                    <>
                        ${invoice.row.original.refund_total}
                    </>
                ),
            },
        ],
        [userProfile, handleDownloadCreditInvoicePdf]
    );

    if (typeof error === 'string' && error.match("401")) return <Redirect to="/login" />
    if (!userProfile.permissions.view_credit_history) return <Redirect to="/not-authorized" />

    document.title = "Credits | Max Advanced Brakes - Prozone";

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Credit History" pageTitle="Credit History" linkTo={`/history/credit-history`} />
                    {
                        openPdfModal && <PDFRenderer
                            fileUrl={URL.createObjectURL(creditInvoicePdf)}
                            modalHeaderText={`Credit Invoice ${pdfCreditId}`}
                            openPdfModal={openPdfModal}
                            closePdfModal={closePdfModal} />
                    }
                    <Row>
                        <Col lg={12}>
                            <Card id="credits">
                                <CardHeader className="border-0">
                                    <div className="d-flex align-items-center">
                                        {/* <h5 className="card-title mb-0 flex-grow-1">Credits</h5> */}
                                    </div>
                                </CardHeader>
                                <CardBody className="pt-0">
                                    <form onSubmit={(e) => handleFilterInvoices(e)}>
                                        <Row className="mt-2">
                                            <Col>
                                                <Label>Product</Label>
                                                <Input type="text" value={invoiceFilter.product} onChange={(e) => setInvoiceFilter({ ...invoiceFilter, product: e.target.value })} />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                <Button color={'max'} type="submit">Filter</Button>
                                            </Col>
                                            <Col className="text-end">
                                                <Button color={'secondary'} onClick={handleClearFilter}>Clear</Button>
                                            </Col>
                                        </Row>
                                    </form>
                                    <Row>
                                        <Col>
                                            <div>
                                                {(!isLoading && isCreditSuccess && credits) ? (
                                                    <TableContainer
                                                        columns={columns}
                                                        data={(credits || [])}
                                                        isGlobalFilter={false}
                                                        isAddUserList={false}
                                                        customPageSize={30}
                                                        className="custom-header-css"
                                                        divClass="table-responsive table-card"
                                                        tableClass="align-middle table-nowrap"
                                                        theadClass="text-muted"
                                                        thClass="text-muted"
                                                        isInvoiceListFilter={false}
                                                    />
                                                ) : (<Loader error={error} />)}
                                                <ToastContainer closeButton={false} />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default CreditHistory;