import { Alert, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { withRouter, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from '../../Components/Common/Loader';

import { 
    postResetPassword 
} from "../../store/actions";

import logoLight from "../../assets/images/MAX_LOGO_NEW_On_white copy.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";


function PasswordReset(props) {
    const dispatch = useDispatch();
    const location = useLocation();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            verificationCode: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            verificationCode: Yup.string().required("Enter Your Verification Code Provided In the Email"),
            password: Yup.string().required("Please Enter Your New Password"),
            confirmPassword: Yup.string().required("Confirm New Password").oneOf([Yup.ref("password")], 'Must match new password')
        }),
        onSubmit: (values) => {
            dispatch(postResetPassword(values.verificationCode, location.pathname.split('/')[3], values.password));
        }
    });

    const { resetError, resetSuccessMsg } = useSelector(state => ({
        resetError: state.ForgetPassword.resetError,
        resetSuccessMsg: state.ForgetPassword.resetSuccessMsg,
    }));

    document.title = "Reset Password | Velzon - React Admin & Dashboard Template";

    return (
        <ParticlesAuth>
            <div className="auth-page-content">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-sm-5 mb-4 text-white-50">
                                <div>
                                    <Link to="/" className="d-inline-block auth-logo">
                                        <img src={logoLight} alt="" height="150" />
                                    </Link>
                                </div>
                                <p className="mt-3 fs-15 fw-medium">MAX PRO SUPERIOR QUALITY BRAKES FOR DISTRIBUTORS</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">
                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary">Reset Password</h5>

                                        <lord-icon
                                            src="https://cdn.lordicon.com/rhvddzym.json"
                                            trigger="loop"
                                            colors="primary:#00bd9d"
                                            className="avatar-xl"
                                            style={{ width: "120px", height: "120px" }}
                                        >
                                        </lord-icon>

                                    </div>

                                    <Alert className="alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                                        Enter your a new password!
                                    </Alert>
                                    <div className="p-2">
                                        {resetError ? (
                                            <Alert color="danger" style={{ marginTop: "13px" }}>
                                                {resetError}
                                            </Alert>
                                        ) : null}
                                        {resetSuccessMsg ? (
                                            <Alert color="success" style={{ marginTop: "13px" }}>
                                                {resetSuccessMsg}
                                            </Alert>
                                        ) : (
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                <div className="mb-4">
                                                    <Label className="form-label">Verification Code</Label>
                                                    <Input
                                                        name="verificationCode"
                                                        className="form-control"
                                                        placeholder="Enter Verification Code"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.verificationCode || ""}
                                                        invalid={
                                                            validation.touched.verificationCode && validation.errors.verificationCode ? true : false
                                                        }
                                                    />
                                                    {validation.touched.verificationCode && validation.errors.verificationCode ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.verificationCode}</div></FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-4">
                                                    <Label className="form-label">New Password</Label>
                                                    <Input
                                                        name="password"
                                                        className="form-control"
                                                        placeholder="Enter New Password"
                                                        type="password"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={
                                                            validation.touched.password && validation.errors.password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.password && validation.errors.password ? (
                                                        <FormFeedback type="invalid">
                                                            <div>{validation.errors.password}</div>
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-4">
                                                    <Label className="form-label">Confirm New Password</Label>
                                                    <Input
                                                        name="confirmPassword"
                                                        className="form-control"
                                                        placeholder="Confirm New Password"
                                                        type="password"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.confirmPassword || ""}
                                                        invalid={
                                                            validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                                                        }
                                                    />
                                                    {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                                        <FormFeedback type="invalid">
                                                            <div>{validation.errors.confirmPassword}</div>
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="text-center mt-4">
                                                    <button className="btn btn-success w-100" type="submit">Reset Password</button>
                                                </div>
                                            </Form>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    )
}

export default PasswordReset;