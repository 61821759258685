import {
    API_RESPONSE_ERROR,
    API_RESPONSE_SUCCESS,
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_EXCLUSION,
    DELETE_CUSTOMER_EXCLUSION_FAIL,
    DELETE_CUSTOMER_EXCLUSION_SUCCESS,
    DELETE_CUSTOMER_FAIL,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_PRODUCT_GROUP_FROM_USER,
    DELETE_PRODUCT_GROUP_FROM_USER_FAIL,
    DELETE_PRODUCT_GROUP_FROM_USER_SUCCESS,
    GET_CUSTOMERS,
    GET_CUSTOMER_INFO,
    GET_CUSTOMER_PRODUCT_EXCLUSION,
    GET_CUSTOMER_USER_GROUP,
    GET_PRODUCT_GROUPS,
    GET_UPDATE_USER_CREDIT_BALANCES,
    GET_USERS_PRODUCT_GROUPS,
    GET_USER_GROUPS,
    POST_NEW_CUSTOMER,
    POST_NEW_CUSTOMER_EXCLUSION,
    POST_NEW_CUSTOMER_EXCLUSION_FAIL,
    POST_NEW_CUSTOMER_EXCLUSION_SUCCESS,
    POST_NEW_CUSTOMER_FAIL,
    POST_NEW_CUSTOMER_SUCCESS,
    POST_NEW_PRODUCT_GROUP_TO_USER,
    POST_NEW_PRODUCT_GROUP_TO_USER_FAIL,
    POST_NEW_PRODUCT_GROUP_TO_USER_SUCCESS,
    POST_USER_PASSWORD_RESET,
    POST_USER_PASSWORD_RESET_FAIL,
    POST_USER_PASSWORD_RESET_SUCCESS,
    UPDATE_CUSTOMER_INFO,
    UPDATE_CUSTOMER_INFO_FAIL,
    UPDATE_CUSTOMER_INFO_SUCCESS,
    UPDATE_USER_GROUP,
    UPDATE_USER_GROUP_FAIL,
    UPDATE_USER_GROUP_SUCCESS
} from "./actionType";

export const customerApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data }
});

export const customerApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error }
});

export const getCustomers = () => ({
    type: GET_CUSTOMERS,
})

export const getCustomerInformation = (username) => ({
    type: GET_CUSTOMER_INFO,
    payload: username,
});

export const getCustomerProductExclusion = (username) => ({
    type: GET_CUSTOMER_PRODUCT_EXCLUSION,
    payload: username
})

export const getCustomerUserGroup = (userGroupId) => ({
    type: GET_CUSTOMER_USER_GROUP,
    payload: userGroupId
})

export const getUserGroups = () => ({
    type: GET_USER_GROUPS,
})

export const updateCustomerInformation = (userInfo) => ({
    type: UPDATE_CUSTOMER_INFO,
    payload: userInfo,
});

export const updateCustomerInformationSuccess = userInfo => ({
    type: UPDATE_CUSTOMER_INFO_SUCCESS,
    payload: userInfo,
});

export const updateCustomerInformationFail = error => ({
    type: UPDATE_CUSTOMER_INFO_FAIL,
    payload: error,
});

export const deleteCustomer = user => ({
    type: DELETE_CUSTOMER,
    payload: user
});

export const deleteCustomerSuccess = () => ({
    type: DELETE_CUSTOMER_SUCCESS,
});

export const deleteCustomerFail = error => ({
    type: DELETE_CUSTOMER_FAIL,
    payload: error
});

export const postNewCustomerExclusion = (username, type, line) => ({
    type: POST_NEW_CUSTOMER_EXCLUSION,
    payload: { username, type, line }
});

export const postNewCustomerExclusionSuccess = (data) => ({
    type: POST_NEW_CUSTOMER_EXCLUSION_SUCCESS,
    payload: data
});

export const postNewCustomerExclusionFail = (error) => ({
    type: POST_NEW_CUSTOMER_EXCLUSION_FAIL,
    payload: error
});

export const deleteCustomerExclusion = (id) => ({
    type: DELETE_CUSTOMER_EXCLUSION,
    payload: id
});

export const deleteCustomerExclusionSuccess = (data) => ({
    type: DELETE_CUSTOMER_EXCLUSION_SUCCESS,
    payload: data
});

export const deleteCustomerExclusionFail = (error) => ({
    type: DELETE_CUSTOMER_EXCLUSION_FAIL,
    payload: error
});

export const updateUserGroup = (userGroupArr) => ({
    type: UPDATE_USER_GROUP,
    payload: userGroupArr
});

export const updateUserGroupSuccess = (data) => ({
    type: UPDATE_USER_GROUP_SUCCESS,
    payload: data
});

export const updateUserGroupFail = (error) => ({
    type: UPDATE_USER_GROUP_FAIL,
    payload: error
});

export const postNewCustomer = (newUserInfo) => ({
    type: POST_NEW_CUSTOMER,
    payload: newUserInfo
})

export const postNewCustomerSuccess = (data) => ({
    type: POST_NEW_CUSTOMER_SUCCESS,
    payload: data
});

export const postNewCustomerFail = (error) => ({
    type: POST_NEW_CUSTOMER_FAIL,
    payload: error
});

export const postUserPasswordReset = (username, password) => ({
    type: POST_USER_PASSWORD_RESET,
    payload: { username, password }
});

export const postUserPasswordResetSuccess = (data) => ({
    type: POST_USER_PASSWORD_RESET_SUCCESS,
    payload: data
});

export const postUserPasswordResetFail = (error) => ({
    type: POST_USER_PASSWORD_RESET_FAIL,
    payload: error
});

export const getUpdateUserCreditBalances = () => ({
    type: GET_UPDATE_USER_CREDIT_BALANCES
});

export const getProductGroups = () => ({
    type: GET_PRODUCT_GROUPS
});

export const getUserProductGroups = (username) => ({
    type: GET_USERS_PRODUCT_GROUPS,
    payload: username
});

export const postNewProductGroupToUser = (username, groupId) => ({
    type: POST_NEW_PRODUCT_GROUP_TO_USER,
    payload: { username, groupId }
});

export const postNewProductGroupToUserSuccess = () => ({
    type: POST_NEW_PRODUCT_GROUP_TO_USER_SUCCESS
});

export const postNewProductGroupToUserFail = (error) => ({
    type: POST_NEW_PRODUCT_GROUP_TO_USER_FAIL,
    payload: error
});

export const deleteProductGroupFromUser = (username, groupId) => ({
    type: DELETE_PRODUCT_GROUP_FROM_USER,
    payload: { username, groupId }
});

export const deleteProductGroupFromUserSuccess = () => ({
    type: DELETE_PRODUCT_GROUP_FROM_USER_SUCCESS
});

export const deleteProductGroupFromUserFail = (error) => ({
    type: DELETE_PRODUCT_GROUP_FROM_USER_FAIL,
    payload: error
});