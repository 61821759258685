import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";

import { Filter, DefaultColumnFilter } from "./filters";

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);
    
    return (
        <React.Fragment>
            <CardBody className="border border-dashed border-end-0 border-start-0">
                <form>
                    <Row className="g-3">
                        <Col>
                            <div className={"search-box me-2 mb-2 d-inline-block col-12"}>
                                <input
                                    onChange={(e) => {
                                        setValue(e.target.value);
                                        onChange(e.target.value);
                                    }}
                                    id="search-bar-0"
                                    type="text"
                                    className="form-control search /"
                                    placeholder={`${count} Search...`}
                                    value={value || ""}
                                />
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </Col>
                    </Row>
                </form>
            </CardBody>

        </React.Fragment>
    );
}


const TableContainer = ({
    columns,
    data,
    isGlobalSearch,
    isGlobalFilter,
    isAddOptions,
    isAddUserList,
    handleOrderClicks,
    handleUserClick,
    handleCustomerClick,
    isAddCustList,
    customPageSize,
    tableClass,
    theadClass,
    trClass,
    thClass,
    divClass,
    // renderRowSubComponent
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { 
                Filter: DefaultColumnFilter 
            },
            initialState: {
                pageIndex: 0, 
                pageSize: customPageSize, 
                selectedRowIds: 0,
                hiddenColumns: columns.map((column) => {
                    if (column.show === false) return column.accessor || column.id
                })
                /* 
                sortBy: [
                    {
                        desc: true,
                    },
                ],*/
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    const generateSortingIndicator = (column) => {
        return column.isSorted ? (column.isSortedDesc ? " v" : " ^") : "";
    };

    const onChangeInSelect = (event) => {
        setPageSize(Number(event.target.value));
    };
    const onChangeInInput = (event) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPage(page);
    };

    return (
        <Fragment>
            <Row className="mb-3">
                {isGlobalSearch && (
                    <Col md={1}>
                        <select
                            className="form-select"
                            value={pageSize}
                            onChange={onChangeInSelect}
                        >
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </Col>
                )}
                {isGlobalFilter && (
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}                       
                    />
                )}
                {isAddOptions && (
                    <Col sm="7">
                        <div className="text-sm-end">
                            <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                                onClick={handleOrderClicks}
                            >
                                <i className="mdi mdi-plus me-1" />
                                Add New Order
                            </Button>
                        </div>
                    </Col>
                )}
                {isAddUserList && (
                    <Col sm="7">
                        <div className="text-sm-end">
                            <Button
                                type="button"
                                color="primary"
                                className="btn mb-2 me-2"
                                onClick={handleUserClick}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Create New User
                            </Button>
                        </div>
                    </Col>
                )}
                {isAddCustList && (
                    <Col sm="7">
                        <div className="text-sm-end">
                            <Button
                                type="button"
                                color="success"
                                className="btn-rounded mb-2 me-2"
                                onClick={handleCustomerClick}
                            >
                                <i className="mdi mdi-plus me-1" />
                                New Customers
                            </Button>
                        </div>
                    </Col>
                )}
            </Row>


            <div className={divClass}>
                <Table hover {...getTableProps()} className={tableClass}>
                    <thead className={theadClass}>
                        {headerGroups.map((headerGroup) => (
                            <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th key={column.id} className={thClass}>
                                        <span {...column.getSortByToggleProps()}>
                                            {column.render("Header")}
                                            {/* Sort direction indicator */}
                                            {generateSortingIndicator(column)}
                                        </span>
                                        <Filter column={column} />
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td key={cell.id} {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>

                                    {/* {(row.original.return_invoice_id) ? (
                                        <tr>
                                            <td colSpan={7}>
                                                {renderRowSubComponent({ row })}
                                            </td>
                                        </tr>
                                    ) : (null)} */}
                                </Fragment>
                            );
                        })}
                    </tbody>
                </Table>
            </div>

            {(!page.length) ? (
                <div className="noresult" style={{display: 'block'}}>
                    <div className="text-center">
                        <h5 className="mt-2">Sorry! No Result Found</h5>
                        {/* <p className="text-muted mb-0">We've searched more than 150+ invoices We did not find any invoices for you search.</p> */}
                    </div>
                </div>
            ) : (null)}
            
            <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                        <Button
                            color="primary"
                            onClick={previousPage}
                            disabled={!canPreviousPage}
                        >
                            {"<"}
                        </Button>
                    </div>
                </Col>
                <Col className="col-md-auto d-none d-md-block">
                    Page{" "}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </Col>
                <Col className="col-md-auto">
                    <Input
                        type="number"
                        min={1}
                        style={{ width: 70 }}
                        max={pageOptions.length}
                        defaultValue={pageIndex + 1}
                        onChange={onChangeInInput}
                    />
                </Col>

                <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                        <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
                            {">"}
                        </Button>
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};

TableContainer.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;