import { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "reactstrap";

import QualityAssuranceWorksheetTableRow from "./QualityAssuranceWorksheetTableRow";
import QualityAssuranceWorksheetTableRowMain from "./QualityAssuranceWorksheetTableRowMain";

function QualityAssuranceWorksheet({ returnId, returnDetailsArr, setReturnDetailsArr, returnDetailsExtraArr, setReturnDetailsExtraArr, status }) {
    const [numRowsMain, setNumRowsMain] = useState(null);
    const [numRows, setNumRows] = useState(null);
    const [tableInstance, setTableInstance] = useState(0);
    let rowsMain = [];
    let rows = [];

    useEffect(() => {
        setNumRowsMain(returnDetailsArr.length)
    }, [returnDetailsArr])

    function handleReturnDetailsChange(id, value, field) {
        let temp = returnDetailsArr;
        temp = temp.map((item) => {
            if (item.id === id) {
                item[field] = value;
            }
            return item;
        })
        setReturnDetailsArr(temp);
    }

    function handleReturnedQtyChanges(index, id, value, field, ref) {
        let temp = returnDetailsArr;
        let currentReturnQty = Number(temp[index].restock_qty) + Number(temp[index].scrap_qty) + Number(temp[index].misc_qty);
        let updatedReturnQty = (currentReturnQty - Number(temp[index][field])) + Number(value);
        let max_qty_to_return = Number(temp[index].qty_to_return);
        
        if (((currentReturnQty < max_qty_to_return) || (Number(value) < temp[index][field])) && updatedReturnQty <= max_qty_to_return) {
            handleReturnDetailsChange(id, value, field);
        } else {
            ref.current.value = temp[index][field];
            alert('Return quantity cannot exceed row quantity to return. Add extra to table below.');
        }
    }

    function addNewReturnedProductRow() {
        setNumRows(numRows + 1);
        setTableInstance(tableInstance + 1);
    }

    function handelRemoveRow(index) {
        let temp = returnDetailsExtraArr;
        
        temp = temp.filter((item, itemIndex) => itemIndex !== index);
        
        if (!temp || !temp.length) temp = null;

        rows = rows.filter(((item, itemIndex) => itemIndex !== index));

        setReturnDetailsExtraArr(temp);
        setNumRows(rows.length);
        setTableInstance(tableInstance + 1);
    }

    for (let i = 0; i < numRowsMain; i++) {
        rowsMain[i] = <QualityAssuranceWorksheetTableRowMain item={returnDetailsArr[i]} index={i} status={status} handleReturnDetailsChange={handleReturnDetailsChange} handleReturnedQtyChanges={handleReturnedQtyChanges} />
    }

    for (let i = 0; i < numRows; i++) {
        rows[i] = <QualityAssuranceWorksheetTableRow returnId={returnId} index={i} returnDetailsExtraArr={returnDetailsExtraArr} setReturnDetailsExtraArr={setReturnDetailsExtraArr} handelRemoveRow={handelRemoveRow} />
    }

    return (
        <>
            <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                <thead>
                    <tr className="table-active">
                        <th scope="col" rowSpan={2} valign="middle">#</th>
                        <th scope="col" rowSpan={2} valign="middle">Product</th>
                        <th scope="col" rowSpan={2} valign="middle">Order Id</th>
                        <th scope="col" rowSpan={2} valign="middle">Requested</th>
                        <th className="w-50" scope="col" colSpan={2/*3*/}>Returned Quantity</th>
                        <th className="w-25" scope="col" rowSpan={2} valign="middle">Type</th>
                        <th className="w-25" scope="col" rowSpan={2} valign="middle">Reason</th>
                        <th className="w-25" scope="col" rowSpan={2} valign="middle">Warehouse Reason</th>
                        {/* <th className="w-25" scope="col" rowSpan={2} valign="middle">Condition</th> */}
                    </tr>
                    <tr className="table-active">
                        <th scope="col">Restock</th>
                        <th scope="col">Scrap</th>
                        {/* <th scope="col">Misc</th> */}
                    </tr>
                </thead>
                <tbody>
                    {rowsMain}
                </tbody>
            </Table>
            <Table key={tableInstance} className="mt-5 table-borderless text-center table-nowrap align-middle mb-0">
                <thead>
                    <tr className="table-active">
                        <th scope="col">Product</th>
                        <th className="w-25" scope="col">Returned Quantity</th>
                        <th className="w-25" scope="col">Description</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
            <Row className="mt-3">
                <Col>
                    <Button onClick={addNewReturnedProductRow}>Add Row</Button>
                </Col>
            </Row>
        </>
    )
}

export default QualityAssuranceWorksheet;